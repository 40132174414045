import { TransferType } from "../enums/transferType";

export interface ITransferFees {
  transferFeeId?: string;
  transferName?: string;
  transferType?: TransferType;
  convertFee?: number;
  sendFee?: number;
  description?: string;
  currencyId?: string;
  casRate?: number;
  casFee?: number,
  fiatMaxSendAmount?: number,
  percent?: number,
  brokerfeeDto?:IBrokerFees
}

export interface IBrokerFees {
  maxSendfee: number,
  minSendfee: number,
  sendPercentFee: number
}

export interface ITransferFeesList {
  transferFeesList: ITransferFees[];
}



export interface ITransferFeesByCurrencyModel {
  currencyId: string;
  transactionAmount: string;
  transferType: number;
  isBroker?: boolean;
}
export class TransferFeesByCurrencyModel implements ITransferFeesByCurrencyModel {
  constructor(
    public currencyId: string,
    public transactionAmount: string,
    public transferType: number,
    public isBroker?: boolean,
  ) { }
}



export interface IFiatInOutTransferFees {
  fiatTransferType: number;
  transactionType: TransferType;
  minAmount: number;
  maxAmount: number;
  feeAmount: number;
  otherFeeAmountInPercent: number;
  isBroker?: boolean;
}

export class FiatInOutTransferFees implements IFiatInOutTransferFees {
  constructor(
    public fiatTransferType: number,
    public transactionType: TransferType,
    public minAmount: number,
    public maxAmount: number,
    public feeAmount: number,
    public otherFeeAmountInPercent: number,
    public isBroker?: boolean,
  ) { }
}