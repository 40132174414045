<cashaa-sidenav>
  <section class="mh-100">
    <div class="bg-white p-4">
      <div class="sm-recipient-btn-padding-mobile">
        <button mdbBtn type="button" color="primary" mdbWavesEffect (click)="addFiatBankRecipient()" class="btn-sm">
          Add Bank Account
        </button>
        <button mdbBtn type="button" outline="true" mdbWavesEffect class="sm-btn-input-hover btn-border btn-sm"
          (click)="addCryptoAddress()">
          Add Crypto Address
        </button>
      </div>
    </div>
  </section>
  <cashaa-recipient-list></cashaa-recipient-list>
</cashaa-sidenav>