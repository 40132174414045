import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { WithdrawRoutingModule } from "./withdraw-routing.module";
import { WithdrawAddressWhitelistComponent } from "./withdraw-address-whitelist/withdraw-address-whitelist.component";
import { SharedModule } from "../shared/shared.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MDBBootstrapModulesPro } from "ng-uikit-pro-standard";
import { WithdrawFundComponent } from "./withdraw-fund/withdraw-fund.component";
import { ToastrModule } from "ngx-toastr";

@NgModule({
  declarations: [WithdrawAddressWhitelistComponent, WithdrawFundComponent],
  imports: [
    CommonModule,
    WithdrawRoutingModule,
    SharedModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    MDBBootstrapModulesPro.forRoot(),
  ],
})
export class WithdrawModule {}
