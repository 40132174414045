import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";
import { ISignin, Signin } from "src/app/core/models/signin";
import { AccountService } from "../sevices/account.service";
import { ActivatedRoute, Router } from "@angular/router";
import { APP_ROUTES, RegisterType } from "src/app/app.constants";
import { AuthStatus } from "src/app/core/enums/authStatus";
import { IpInfo } from "src/app/core/models/ipinfo";
import { UserService } from "src/app/core/services/user/user.service";
import { ModalService } from "src/app/core/services/modal/modal.service";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "cashaa-account-signin",
  templateUrl: "./account-signin.component.html",
  styleUrls: ["./account-signin.component.scss"],
})
export class AccountSigninComponent implements OnInit {
  code: string[] = ["", "", "", "", "", ""];
  whitelistCode: string = "";
  showOtpBox: boolean;
  ipInfo: IpInfo;
  allCodeEntered;
  /** is Signingin in progress */
  isSigningIn = false;

  /** Form Group */
  form: FormGroup;

  /** error message when failed */
  errorMessage: string;
  redirectUrl;

  @ViewChildren("inputs") inputs: QueryList<ElementRef>;

  /**
   * Inject services
   * @param fb form builder
   */
  constructor(
    private accountService: AccountService,
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService,
    private modalService: ModalService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.form = this.fb.group({
      email: ["", [ValidatorUtility.Required, ValidatorUtility.EmailValidator]],
      password: ["", [ValidatorUtility.Required]],
    });
  }

  ngOnInit(): void {
    this.onChanges();
    this.accountService.setDisableCross(true);
    this.redirectUrl = this.activatedRoute.snapshot.queryParamMap.get('returnUrl');
    if(this.redirectUrl) {
    sessionStorage.setItem('isRedirect', "true");
    }
  }

  onChanges(): void {
    this.form.valueChanges.subscribe((val) => {
      if (val !== undefined) {
        this.errorMessage = undefined;
      }
    });
  }
  /**
   * on Form submit
   */
  onFormSubmit(): void {
    this.isSigningIn = true;
    this.errorMessage = undefined;
    const data = this.form.value;
    let signinData: ISignin = {
      email: data.email,
      password: data.password,
      otp: " ",
      registerType: RegisterType.web
    };

    if (this.showOtpBox) {
      signinData.WhiteListOtp = this.whitelistCode;
    }

    this.signInUser(signinData);
  }

  /**
   * Signin user
   * @param data signin data
   */
  signInUser(signinData: Signin): void {
    this.accountService.signinAccount(signinData).subscribe(
      (res) => {
        this.isSigningIn = false;
        this.checkNextStep(res.authStatus, signinData);
      },
      (err: HttpErrorResponse) => {
        this.code = ["", "", "", "", "", ""];
        this.isSigningIn = false;
        if (err.status === 400) {
          if (err.error.message == "resetpassword") {
            this.router.navigateByUrl(APP_ROUTES.FORGET_PASSWORD + "?c=reset");
          } else {
            this.errorMessage = err.error.message;
          }
        } else {
          throw err;
        }
      }
    );
  }

  /**
   * Check next step for user
   * @param authStatus check next step for user
   */
  checkNextStep(authStatus: AuthStatus, signinData: Signin): void {
    switch (authStatus) {
      case AuthStatus.Success:
        if(this.redirectUrl) {
          this.router.navigateByUrl(this.redirectUrl);
        } else {
          this.router.navigateByUrl(APP_ROUTES.ACTIVITY_LIST);
        }
        console.log(navigator.userAgent);
        break;
      case AuthStatus.OtpAndTwoFactorAuthRequired:
        this.accountService.emitSigninData(signinData);
        this.router.navigateByUrl(APP_ROUTES.TWO_FACTOR_AUTH);
        break;
      case AuthStatus.NewSignInDevice:
        this.showOtpBox = true;
        this.errorMessage = "OTP has been sent to your email."
        this.isSigningIn = false;
        break;
      case AuthStatus.EmailNotConfirmed:
      case AuthStatus.OtpRequired:
        this.accountService.emitSigninData(signinData);
        this.router.navigateByUrl(APP_ROUTES.TWO_FACTOR_AUTH, {
          state: { data: signinData },
        });
        break;
    }
  }

  removeError(): void {
    if (this.errorMessage !== undefined) {
      this.errorMessage = undefined;
    }
  }

  /**
   * Check if google input authenticator
   * code is filled in all 6 boxes
   */
  checkIfCodeIsFilled(idx: number): void {
    if (idx >= 0 && idx < 5 && this.code[idx]) {
      this.inputs.toArray()[idx + 1].nativeElement.focus();
    }

    this.allCodeEntered = true;
    this.code.forEach((val) => {
      if (val === null || val.length === 0) {
        this.allCodeEntered = false;
      }
    });
    if (this.code[5] && idx === -1) {
      this.inputs.toArray()[5].nativeElement.focus();
    }
    this.whitelistCode = this.code.join("");
  }

  /**
   * On code paste automatically
   * fill all 6 boxes
   */
  onCodePaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData("text");

    if (pastedText && pastedText.length === 6) {
      this.code = pastedText.split("");
      this.checkIfCodeIsFilled(-1);
    }
    this.whitelistCode = this.code.join("");
  }

  removeBack(event: any, index: number) {
    const keyCode = event.keyCode;
    if (keyCode === 8) {
      this.inputs.toArray()[index].nativeElement.focus();
    }
    this.whitelistCode = this.code.join("");
  }
}
