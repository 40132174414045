import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WalletService } from '../services/wallet.service';

@Component({
  selector: 'cashaa-repay',
  templateUrl: './repay.component.html',
  styleUrls: ['./repay.component.scss']
})
export class RepayComponent implements OnInit {
  @Input() currencyId: string;
  creditLoanList = [];
  loading = false;
  copied = false;

  constructor(private router: Router, private walletService: WalletService, private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.getLoanList();
  }

  getLoanList() {
    this.loading = true;
    this.walletService.getCreditLoanList().subscribe(res => {
      this.loading = false;
      this.creditLoanList = res.creditLines;
    }, err => {
      this.loading = false;
    })
  }

  getLoanDetails(item) {
    let id = window.btoa(item.id);
    this.router.navigateByUrl(`loan-details/${id}`)
  }

  getInterestOnLoan(loanId) {
    let id = window.btoa(loanId);
    this.router.navigateByUrl(`loan-interest/${id}`)
  }

  loanRepayment(item) {
    let id = window.btoa(item.id);
    this.router.navigateByUrl(`loan-repayment/${id}`)
  }

  copyLoanId(selectedItem) {
    this.copied = true;
    navigator.clipboard.writeText(selectedItem.loanNumber);
    this.toastrService.toastrConfig.preventDuplicates = true;
    this.toastrService.success("Text copied to clipboard");
    setTimeout(() => (this.copied = false), 300);
  }

}


