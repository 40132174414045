import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HelpdeskCreateTicketComponent } from "./helpdesk-create-ticket/helpdesk-create-ticket.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { SharedModule } from "../shared/shared.module";
import { HelpdeskRoutingModule } from './helpdesk-routing.module';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { HelpdeskHomeTicketComponent } from './helpdesk-home-ticket/helpdesk-home-ticket.component';

@NgModule({
  declarations: [HelpdeskCreateTicketComponent, HelpdeskHomeTicketComponent],
  imports: [CommonModule,
    HelpdeskRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    MDBBootstrapModulesPro.forRoot()],
})
export class HelpdeskModule { }
