<div class="p-4" [hidden]="loading">
    <div class="px-3 d-flex justify-content-between">
        <h6 class="heading">Trending Coins</h6>
        <div class="d-flex">
            <span class="content mr-4" style="color: #3570F4;"><a routerLink="/statement" style="text-decoration: underline;">View Statement</a></span>
            <div class="mr-2">
                <mdb-icon (click)="previous()" class="arrow-icon arrow-left" fas icon="chevron-left"></mdb-icon>
            </div>
            <div>
                <mdb-icon (click)="next()" class="arrow-icon arrow-right" fas icon="chevron-right"></mdb-icon>
            </div>
        </div>
    </div>
    <mdb-carousel #slide [isControls]="false" class="carousel-multi-item multi-animation" [type]="'carousel-multi-item'" [animation]="'slide'" [interval]="'0'">
        <mdb-carousel-item *ngFor="let item of slides">
            <div class="col-12 col-md-3 col-lg-4 col-xl-5" *ngFor="let balance of item">
                <div *ngIf="balance.currencyType !== 'AUSD'">
                    <cashaa-balance-box (emitCurrency)="updateLedgerList($event)" [currency]="balance.currencyType" [balance]="balance.available" [currencyId]="balance.currencyId" [onHold]="balance.onhold" [currencyNature]="balance.currencynature"
                     [isFDActive]="balance.isFDActive" [minFlexAmount]="balance.minFlexAmount" [isBorrowActive]="balance.isBorrowActive" [creditInterest]="creditInterest"></cashaa-balance-box>
                </div>
            </div>
        </mdb-carousel-item>
    </mdb-carousel>
</div>
<div class="p-4 text-center" [hidden]="!loading">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
</div>