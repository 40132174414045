<div class="modal-body py-md-5 px-md-5 py-sm-3 px-sm-3 rounded-lg bg-white">


  <div style="text-align: center">
    <i class="fa fa-check-circle fa-5x"></i>
    <br><br>
    <h5 [hidden]="!flag" style="color: black;">Withdraw Verification</h5>
    <h5 [hidden]="!flag" style="color: black;">{{isVerified}}<br></h5>

    <!-- <i class="fa fa-check-circle fa-5x"></i> -->
    <!-- <br><br>
            <h5 style="color: black;">Withdraw Verification</h5> -->
    <!-- <h5 style="color: black;">Invalid or Expired Request<br></h5> -->
  </div>

  <!-- </ng-container>
    -->


</div>
