import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SEESION_FLAG, UNABLE_TO_PERFORM_ERROR } from 'src/app/app.constants';
import { ChangeEmailRequest } from 'src/app/core/models/changeEmail';
import { SettingsService } from '../services/settings.service';

@Component({
  selector: 'cashaa-setting-email-confirm',
  templateUrl: './setting-email-confirm.component.html',
  styleUrls: ['./setting-email-confirm.component.scss']
})
export class SettingEmailConfirmComponent implements OnInit {

  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }
  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
  /** event name */
  event: string;

  /** token */
  token: string;

  /**newmail */
  newmail: string;

  errorMessage: string;

  showLoadingIndicator: boolean = true;

  oldmail: string;

  status: boolean;

  constructor(private router: Router, private activateRoute: ActivatedRoute,
    private settingsService: SettingsService, private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.activateRoute.queryParams.subscribe((params) => {
      let decodeVal: string = params["token"]
        ? this.decodeKey(params["token"])
        : "";

      this.token = decodeVal ? decodeVal.split(" ").join("+") : "";
      this.newmail = params["newmail"];
      this.oldmail = params["oldemail"];

      if (this.oldmail && this.token && this.newmail) {
         const data = new ChangeEmailRequest(
          this.oldmail,
          this.newmail,
          this.token
        );
        this.settingsService.changeEmail(data).subscribe(
          (res) => {
            this.showLoadingIndicator = false;
            this.status = true;
            localStorage.clear();
            localStorage.setItem(SEESION_FLAG, "false");
            sessionStorage.clear();
          },
          (err) => {
            if (err.error.message) {
              this.toastrService.error(err.error.message);
            } else {
              this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
            }
            this.status = false;
            this.showLoadingIndicator = false;
          }
        )
      }
    });
  }

  onContinue(): void {
    this.router.navigateByUrl("/account/signin");
  }

}
