<div class="contianer my-5 justify-content-center">

    <h4 class="heading text-center mb-4 fnt-size">Welcome to Cashaa</h4>
    <h4 class="heading text-center text-dark">Start your crypto journey with a few easy steps.</h4>
    <div class="welcome-img">
        <img src="../../../assets/images/welcomeCashaa.png" class="" alt="Welcome" />
    </div>
    <!-- <img src="../src/assets/images/business_reserve.png" alt="azaz"> -->
    <!-- Images -->
    <div class="d-flex justify-content-center">
        <button type="button" class="btn-sm app-primary-btn font-weight-bold" mdbBtn mdbWavesEffect (click)="goToCrypto()">
        Deposit Crypto
      </button>
        <button class="btn-sm app-primary-btn btn_green text-light font-weight-bold" routerLink="/deposit" mdbBtn type="button" mdbWavesEffect>
    Deposit Fiat
  </button>
    </div>
</div>
