<div class="modal-body py-md-5 px-md-5 py-sm-3 px-sm-3 rounded-lg bg-white">
   
    <div style="text-align: center">
        <i class="fa fa-check-circle fa-5x"></i>
        <br><br>
        
        <h5>Payment failed<br></h5>
    </div>

   
    
  </div>
  