import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { IResendKYC } from "src/app/core/models/customer/customer";
import { ICustomer } from "src/app/core/models/recipient/recipient-add-usd";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  private apiUrl = environment.api;
  private isLoading = new BehaviorSubject<boolean>(false);
  private customerAddFlag = new BehaviorSubject<boolean>(false);
  private deleteRecipientFlg = new Subject<boolean>();

  constructor(private http: HttpClient) {}

  createCustomer(
    customer: ICustomer
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${this.apiUrl}/broker/create`,
      customer
    );
  }

  getAllCustomers(): Observable<ICustomer[]> {
    return this.http.get<ICustomer[]>(`${this.apiUrl}/broker/getCustomers`);
  }

  resendKYC(
    customerId: IResendKYC
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${this.apiUrl}/broker/sendkycverificationlink`,
      customerId
    );
  }

  emitLoadingStatus(loadingStatus: boolean): void {
    this.isLoading.next(loadingStatus);
  }
  emitCustomerAdd(customerAdd: boolean): void {
    this.customerAddFlag.next(customerAdd);
  }

  getCustomerAdd(): Observable<boolean> {
    return this.customerAddFlag.asObservable();
  }

  getLoadingStatus(): Observable<boolean> {
    return this.isLoading.asObservable();
  }

  editCustomer(formData: FormData): Observable<boolean> {
    return this.http.post<boolean>(`${this.apiUrl}/recipients/editbroker`, formData);
  }

  editCustomerUSD(formData: FormData): Observable<boolean> {
    return this.http.post<boolean>(`${this.apiUrl}/recipients/editbrokerusd`, formData);
  }

  emitDeleteRecipientFlag(flag: boolean): void {
    this.deleteRecipientFlg.next(flag);
  }

  getDeleteRecipientFlagEmitter(): Observable<boolean> {
    return this.deleteRecipientFlg.asObservable();
  }
}
