import { WalletService } from './../../wallet/services/wallet.service';
import { ICryptoAccountDetails } from './../../core/models/crypto-account-details';
import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { USER_NAME_ID } from "src/app/app.constants";

@Component({
  selector: "cashaa-invite-home-link",
  templateUrl: "./invite-home-link.component.html",
  styleUrls: ["./invite-home-link.component.scss"],
})
export class InviteHomeLinkComponent implements OnInit {
  userId = sessionStorage.getItem(USER_NAME_ID);
  constructor(private toastrService: ToastrService,private walletService: WalletService) {}
  
  accountDetailsCryptoData: ICryptoAccountDetails;
  /** Error message if any */
  errorMessageCrypto?: string;
  cryptoLoading = false;

  ngOnInit(): void {

    this.accountDetailsCryptoData = null;
    this.errorMessageCrypto = null;

    this.walletService.getCryptoAccountDetails("CAS").subscribe(
      (res) => {
        this.accountDetailsCryptoData = res;
        this.cryptoLoading = false;
      },
      (err) => {
        this.cryptoLoading = false;
      }
    );

  }

  copyInvite(): void {
    navigator.clipboard.writeText(this.accountDetailsCryptoData.memo);
    this.toastrService.toastrConfig.preventDuplicates = true;
    this.toastrService.success("Text copied to clipboard");
  }
}
