<div class="d-flex flex-row p-3">
  <img class="mr-3 mt-2 flag-icon" [src]="'../../../../assets/icons/' + currency + '.png'" />
  <div class=" text-center bal-align">
    <h6 class="text-dark font-weight-bolder m-auto">
      {{ balance | number: "0.0-2" }}
      {{ currency }}
    </h6>
  </div>

  <button *ngIf="isEarnAndFixedDepositActive() && item.isFDActive; else US" mdbBtn type="button" class="btn-sm earn-upto" (click)="openEarnPlan()">Earn up to
    {{rate}}%</button>
      <ng-template #US>
    <button *ngIf="userType !== '4' && item.isFDActive" mdbBtn type="button" class="btn-sm earn-upto" (click)="openEarnPlan()">Earn up to
        {{rate}}%</button>
    </ng-template>
  <button [style.visibility]="!isHidden(currency) && isDepositActive ? 'visible' : 'hidden'"
  [style.marginLeft]="!isHidden(currency) && isDepositActive ? '0' : '74px'" mdbBtn type="button"
    class="btn-sm app-primary-btn" (click)="onAddClick(currency)">Deposit</button>
  <button  *ngIf="!isHidden(currency) && isDepositActive; else nonBankingFiat" mdbBtn type="button" class="btn-sm app-secondary-btn"
    (click)="onConvertClick(item.currencyType)">Exchange</button>
    <ng-template #nonBankingFiat>
      <button mdbBtn type="button" class="btn-sm app-secondary-btn"
      (click)="onBuyClick(item.currencyType)">Buy</button>
      <button mdbBtn type="button" class="btn-sm app-secondary-btn"
      (click)="onConvertClick(item.currencyType)">Sell</button>
    </ng-template>
  <button *ngIf="!isHidden(currency) && isDepositActive" mdbBtn type="button" [disabled]="!isSendActive"
    class="btn-sm app-tertiary-btn" (click)="onSendClick(currency)">Withdrawl</button>
</div>