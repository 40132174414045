import { ReceiveFundResponse } from "./../../core/models/receive-fund";
import { ActivatedRoute, Router } from "@angular/router";
import { KycService } from "./../services/kyc.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { CustomerEmailConfermation } from "src/app/core/models/kyc-customer-email-verification-model";
import { UNABLE_TO_PERFORM_ERROR } from "src/app/app.constants";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "cashaa-kyc-customer-verification-email",
  templateUrl: "./kyc-customer-verification-email.component.html",
  styleUrls: ["./kyc-customer-verification-email.component.scss"],
})
export class KycCustomerVerificationEmailComponent implements OnInit {
  form: FormGroup;
  id: string;
  email: string;
  response: string;
  errorMessage: string;
  // @Output()
  // continue = new EventEmitter<ICreateBusinessUser>();
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private kycService: KycService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.id = this.route.snapshot.queryParamMap.get("id");
    this.email = this.route.snapshot.queryParamMap.get("email");
    this.verifyCustomerEmail(this.email, this.id);
  }
  createForm(): void {
    this.form = this.fb.group({});
  }
  verifyCustomerEmail(email: string, id: string): void {
    const data = new CustomerEmailConfermation(email, id);
    this.kycService.verifyCustomerEmail(data).subscribe(
      (res) => {
        this.response = res.message;
      },
      (err) => {
        if (err.error.message) {
          this.response = err.error.text;
          this.toastrService.error(err.error.message);
          this.errorMessage = err.error.message;
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
      }
    );
  }
}
