import { ModalService } from "src/app/core/services/modal/modal.service";
import { Component, OnInit, Input } from "@angular/core";
import { ILedgerList } from "src/app/core/models/activity/recent-transaction-list";

@Component({
  selector: "cashaa-activity-balance-usd-staking",
  templateUrl: "./activity-balance-usd-staking.component.html",
  styleUrls: ["./activity-balance-usd-staking.component.scss"],
})
export class ActivityBalanceUsdStakingComponent implements OnInit {
  @Input() ledger: ILedgerList;
  disableBtn;
  verificationClickCount = 0;
  isStackingLocked: boolean = false;
  constructor(
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.isStackingLocked =
      this.ledger.status === 11 && this.ledger.islocked === true;
  }

  openDetailBox(): void {
    this.modalService.openActivityTransferDetail(this.ledger);
  }

  help(): void {
    this.modalService.openTicketCreateModal(this.ledger.ledgerType);
  }

  isSuccessClass(step): boolean {
    if (this.ledger.ledgerType === 12) {
      if (step === 4) {
        if (this.ledger.islocked) {
          return false;
        } else {
          return true;
        }
      }
      return true;
    } else if (
      this.ledger.currency === "ETH" ||
      this.ledger.currency == "BTC" ||
      this.ledger.currency == "CAS" ||
      this.ledger.currency == "USDT" ||
      this.ledger.currency == "GBP" ||
      this.ledger.currency == "EUR" ||
      this.ledger.currency == "USD"
    ) {
      switch (step) {
        case 2:
          if (this.ledger.status === 1 && !this.ledger.withdrawConfirm) {
            return true;
          } else if (this.ledger.status === 2 && this.ledger.withdrawConfirm) {
            return true;
          } else if (this.ledger.withdrawConfirm && this.ledger.status === 1) {
            return true;
          }
          return false;
        case 3:
          if (this.ledger.status === 1 && this.ledger.withdrawConfirm) {
            return true;
          } else if (this.ledger.status === 2 && this.ledger.withdrawConfirm) {
            return true;
          }
          return false;
        case 4:
          if (this.ledger.status === 2 && this.ledger.withdrawConfirm) {
            return true;
          }
          return false;
      }
    } else {
      return true;
    }
  }
}
