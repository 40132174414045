<cashaa-sidenav>
    <section class="mh-100">
        <div class="bg-white p-4">
            <div class="sm-recipient-btn-padding-mobile">
                <button mdbBtn type="button" color="primary" mdbWavesEffect routerLink="create" class="btn-sm">
                    Create API
                </button>
            </div>
        </div>
    </section>
    <section class="p-4 sm-width-phone-700">
        <h6 class="font-weight-normal">Your API({{ apiData?.length }})</h6>
        <hr class="mb-4" />
        <div class="d-flex pl-5 pt-3 mb-5" *ngIf="apiData.length === 0 && !loader">
            <mdb-icon fas icon="user-plus" size="3x"></mdb-icon>
            <div class="ml-4">
              <p class="font-weight-bolder mb-1">Add API details</p>
              <p class="font-weight-normal">
                Click the 'Create API' button above
              </p>
            </div>
          </div>
        <div [hidden]="loader" *ngFor="let api of apiData; let i = index">
            <cashaa-api-manager-data [data]="api"></cashaa-api-manager-data>
        </div>
        <div class="p-4 text-center" [hidden]="!loader">
            <mdb-spinner spinnerColor="blue"></mdb-spinner>
        </div>
    </section>
</cashaa-sidenav>