import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class InviteService {
  private api = environment.api;

  constructor(private http: HttpClient) {}

  invitePeople(
    inviteDto: any
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${this.api}/invite`,
      inviteDto
    );
  }
}
