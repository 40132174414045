import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { USER_EMAIL_PATH, USER_NAME_ID } from 'src/app/app.constants';
import { IFiatRecipient } from 'src/app/core/models/fIat/fiat-pay';
import { IRecipient } from 'src/app/core/models/fIat/recipients';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { FiatService } from 'src/app/fiat/services/fiat.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'cashaa-deposit-recipient',
  templateUrl: './deposit-recipient.component.html',
  styleUrls: ['./deposit-recipient.component.scss']
})
export class DepositRecipientComponent implements OnInit {

  recipients: IRecipient[];
  selectedRecipients: IRecipient[];
  symbol: string;
  loading = false;
  @Input() selected: IRecipient
  @Input() set skipped(status: boolean) {
    if (status) {
        this.selectRecipients(this.selected);
    }
  }

  /** on continue click */
  @Output() continue = new EventEmitter<void>();

  subSink = new SubSink();

  constructor(
    private fiatService: FiatService,
    private modalService: ModalService,
    private router: Router
  ) {}
recipientType : number;
  ngOnInit(): void {
    this.getCurrencySymbol();
  }

  /**
   * Get recipient list
   */
  getRecipientList(symbol: string, network: number, recipientType: number): void {
    this.loading = true;
    this.recipientType = recipientType;
    this.subSink.add(this.fiatService.getRecipents(symbol, network, recipientType).subscribe((recipentsListData) => {
      this.loading = false;
      if (recipentsListData) {
        this.recipients = recipentsListData;
      }
    }));
  }

  /**
   * on some recipient is selected
   * Selected recipient
   */
  selectRecipients(recipient: IRecipient): void {
    this.fiatService.emitFiatRecipientdData(recipient);
    this.continue.emit();
  }

  getCurrencySymbol(): void {
    this.loading = true;
    this.subSink.add(this.fiatService.getCurrencySymbolEmitter().subscribe((data:IFiatRecipient) => {
      if (data) {
        this.symbol = data.currencySymbol;
        this.loading = false;
        this.getRecipientList(this.symbol, data.network, Number(data.recipientType));
      } else {
        this.loading = false;
      }
    }));
  }

  /**
   * Add new business
   */
  openAddBusiness(): void {
    this.modalService.openSendMoneyAddRecipientBusiness(this.symbol);
  }

  openAddRecipient(): void {
    const name = sessionStorage.getItem(USER_NAME_ID);
    const email = sessionStorage.getItem(USER_EMAIL_PATH);
    // if(this.recipientType === 1) {
    //   this.modalService.openRecipientAddModal(name, email, this.symbol, "Self Bank Account");
    // } else {
      // this.openAddRecipientOther();
    // }
    this.router.navigateByUrl('add-customer');
    
  }
  

  // openAddRecipientOther(): void {
  //   // this.modalService.openRecipientAddModal("", "", this.symbol, "Other Bank Account");
  //   // this.router.navigateByUrl('add-customer');
  // }

  openAddRecipientCharity(): void {
    this.modalService.openRecipientAddModal("", "", this.symbol, "Charitable");
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

}
