<cashaa-sidenav>
  <section class="mh-100">
    <div class="bg-white p-4">
      <h1 class="sm-page-heading">Helpdesk</h1>
    </div>
  </section>
  <div class="p-5 m-5 bg-white sm-mt">
    <div class="d-flex align-items-center justify-content-between">
      <h4 class="font-weight-bold">All Tickets</h4>
      <button style="font-size: 14px;" type="button" mdbBtn color="primary" class="relative waves-light"
        (click)="showTicketCreate()" mdbWavesEffect>New Ticket</button>
    </div>
    <hr />
    <div class="table-responsive" *ngIf="loading">
      <table class="table table-padded">
        <thead>
          <tr style="background-color: #eee;">
            <th width="250" class="text-center">Date</th>
            <th width="180">Category</th>
            <th>Subject</th>
            <th width="100" class="text-center">Status</th>
          </tr>
        </thead>
        <tbody *ngIf="ticketDetail.length > 0">
          <ng-container *ngFor="let otd of ticketDetail">
            <tr>
              <td class="text-center font-weight-normal">{{otd.created | date:'medium'}}</td>
              <td class="font-weight-normal">{{otd.categoryName}}</td>
              <td class="font-weight-normal">{{otd.subject}}</td>
              <td class="text-center font-weight-normal"
                [ngClass]="{'text-green': otd.status == '1', 'text-red': otd.status == '5'}">
                {{getStatusName(otd.status)}}</td>
            </tr>
          </ng-container>
        </tbody>
        <tbody *ngIf="ticketDetail.length == 0">
          <tr>
            <td colspan="8">
              <div class="no-response text-center">
                <p class="c-light">There is no statements list found.</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="p-4 text-center" [hidden]="loading">
      <mdb-spinner spinnerColor="blue"></mdb-spinner>
    </div>
  </div>
</cashaa-sidenav>
