<!-- Nav tabs -->
<mdb-tabset
  buttonClass="classic-tabs"
  contentClass="card"
  class="classic-tabs"
  (getActiveTab)="onTabChange($event)"
>
  <!--Panel 1-->
  <mdb-tab heading="Buy">
    <div class="p-1 px-lg-3 py-lg-5">
      <cashaa-trade-buy-form *ngIf="shownTab == 0"></cashaa-trade-buy-form>
    </div>
  </mdb-tab>
  <!--Panel 2-->
  <mdb-tab heading="Sell">
    <div class="p-1 px-lg-3 py-lg-5">
      <cashaa-trade-sell-form *ngIf="shownTab == 1"></cashaa-trade-sell-form>
    </div>
  </mdb-tab>
</mdb-tabset>
