<p>receive-fund works!</p>

<form [formGroup]="createReceiveFund" class="my-4">

    <div class="flex-1">

        <cashaa-form-select-balance [type]="'crypto'" (changed)="OnCurrencySelect($event)">
        </cashaa-form-select-balance>
    </div>


    <br><br><br>
    <div>
        <label style="color:black" *ngIf="serviceResponse">{{serviceResponse.address}} </label>
        &nbsp; &nbsp; &nbsp;
        <a id="copyid" (click)="copyText(serviceResponse.address)" *ngIf="serviceResponse">Copy</a>

    </div>

    <br><br><br>
    <div>
        <label *ngIf="serviceResponse">Memo: {{serviceResponse.memo}} </label>
        &nbsp; &nbsp; &nbsp;


    </div>

    <br><br><br>
    <div>
        <label style="color:red"> Both a MEMO and an Address are required to successfully
            deposit your CAS coins.
        </label>
        &nbsp; &nbsp; &nbsp;


    </div>
    <br><br><br>
    <div>
        <ul>Important</ul>
        <li>Send only CAS-BEP2 to this address. Sending any other coin or token to this address may result in the loss
            of your deposit.</li>
        <li>Coins will be deposited immediately after 3 network confirmations</li>
        <li>After making a deposit, you can track its progress on the Reports</li>
        <!-- <button mdbBtn type="button" (click)="copyText(serviceResponse.address)"
            class="rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block" mdbWavesEffect>

        </button> -->

    </div>


</form>