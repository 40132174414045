import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuardService } from "../core/services/auth-guard/auth-guard.service";
import { NotFoundComponent } from "../shared/components/not-found/not-found.component";
import { EventFeeHomeComponent } from "./event-fee-home/event-fee-home.component";

const routes: Routes = [
  {
    path: "spot",
    component: NotFoundComponent,
    canActivate: [AuthGuardService]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EventFeeRoutingModule {}
