import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cashaa-payment-failure',
  templateUrl: './payment-failure.component.html',
  styleUrls: ['./payment-failure.component.scss']
})
export class PaymentFailureComponent implements OnInit {

  
completed = false;
constructor(
 
  private router: Router,
  private activatedRoute: ActivatedRoute
) {
 
}

ngOnInit(): void {
 this.completed = false;
}

}
