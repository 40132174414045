<div class="modal-header position-relative sm-form-title">
  <button type="button" class="close pull-right sm-close" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">×</span>
  </button>
  <h3 class="modal-title w-100 heading" id="myModalLabel">
   Update Memo
  </h3>
</div>
<div class="modal-body">
  <div class="p-4 text-center" [hidden]="!isLoading">
    <!-- <mdb-spinner spinnerColor="blue"></mdb-spinner> -->
  </div>
  <form [formGroup]="updateMemoForm" class="position-relative sm-font-family sm-form-padding">
    <div>
      <div class="mb-4">
        <cashaa-form-select-transfer-currency [initialValue]="initialValue" [noInput]='true' [noAccountCurrency]='true'
          [form]="updateMemoForm" class="width-100" [currencyControl]="updateMemoForm.controls.currency"
          label="Currency" currencyControlName="currency" [disable]="true"
          [isRecipientComponent]='true'>
        </cashaa-form-select-transfer-currency>
      </div>
      <div class="mb-4">
        <cashaa-form-input name="cryptoAddress" type="text" [form]="updateMemoForm"
          [control]="updateMemoForm.controls.cryptoAddress" label="Address"></cashaa-form-input>
      </div>
      <div class="mb-5">
        <cashaa-form-select [form]="updateMemoForm" [control]="updateMemoForm.controls.networkType"
          name="networkType" label="Network Type" [options]="networkTypeOptions" [disable]="true">
        </cashaa-form-select>
      </div>
      <div class="mb-4">
          <cashaa-form-input name="cryptoMemo" type="text" [form]="updateMemoForm"
          [control]="updateMemoForm.controls.cryptoMemo" label="Memo">
        </cashaa-form-input>
      </div>
      <div class="mt-4">
        <button mdbBtn (click)="onFormSubmit()" block="true" type="button"
          class="rounded-sm position-relative btn btn-primary waves-light btn-block sm-font" mdbWavesEffect>
         Update
        </button>
      </div>
    </div>
  </form>

  <!-- Progress bar -->
  <mdb-progress-bar *ngIf="isInProgress" class="progress primary-color-dark position-absolute" mode="indeterminate">
  </mdb-progress-bar>
</div>