<mdb-accordion>
    <mdb-accordion-item [collapsed]='isCollapsed'>
        <mdb-accordion-item-head [customClass]="isOpen ? 'pl-4' : 'settings-card pl-4'"
            (click)="isOpen = !isOpen; showForm = false">
            <div class="d-flex align-items-center">
                <!-- user fee mode icon 4.png -->
                <img [src]="'../../../../assets/images/settings/fee-mode.svg'" />
                <!-- <mdb-icon far icon="envelope" class="p-2 card-icon" [ngClass]="isOpen ? '' : 'bg-white'"></mdb-icon> -->
                <div class="ml-4 setting-card-open">
                    <h6 class="mb-1 heading">Fee Mode </h6>
                    <span class="mb-0 font-weight-normal status-fees">
                        Current Fee Mode:
                        <span class="green-text status-fees">
                            {{ userFeeMode.split('In').join('In ') }}
                        </span>
                    </span>
                </div>
            </div>
        </mdb-accordion-item-head>
        <mdb-accordion-item-body customClass="pl-5 position-relative">
            <div class="ml-5">
                <hr class="mb-4" />
                <!-- <div class="email-info" *ngIf="!showForm">
                    <button mdbBtn (click)="showForm = true" block="true" type="button"
                        class="rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block w-auto ml-0 mb-4"
                        mdbWavesEffect>
                        Update default transaction fee mode
                    </button>
                </div> -->

                <div class="email-form">
                    <p>{{errorMessage}}</p>
                    <div class="mb-4">
                        <!-- <cashaa-form-select [form]="form" [control]="form.controls.userFeeMode" name="userFeeMode"
                            placeholder="Select your favorite fee Mode" [options]="feeMode">
                        </cashaa-form-select> -->
                        <div  [formGroup]="form" class="switch-sec justify-content-center">Fee in Native <label class="switch">
                            <input (input)="onFeeChangeFormSubmit()" type="checkbox" formControlName="ufeeMode"
                            [(ngModel)]="isChecked">
                            <span class="slider round"></span> </label>
                        <span>Fee in Cashaa
                        </span>
                    </div>

                    </div>


                    <!-- <button [disabled]="isInProgress || form.invalid" mdbBtn (click)="onFeeChangeFormSubmit()"
                        block="true" type="button"
                        class="rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block w-25 w-60 ml-0 mb-4"
                        mdbWavesEffect>
                        Change Fee Mode
                    </button> -->
                </div>
            </div>

            <!-- Progress bar -->
            <mdb-progress-bar *ngIf="isInProgress" class="progress primary-color-dark position-absolute"
                mode="indeterminate"></mdb-progress-bar>
        </mdb-accordion-item-body>
    </mdb-accordion-item>
</mdb-accordion>