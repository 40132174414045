import { WalletCurrency } from '../enums/walletcurrency';

export interface IDepositAmount {
  currency: string;
  amount: number;
  option?: number;
  fee: number;
  deliveryMech: number;
  symbol?: string;
  recipientType?: number;
}

export class CreateDepositAmount implements IDepositAmount {
  constructor (
      public currency: string,
      public amount: number,
      public fee: number,
      public deliveryMech: number,
  ) {}
}

export interface IDepositDetail {
  currency: string;
  paymentMode: string;
  payeeName: string;
  bankName: string;
  iban: string;
  bankCode: string;
  sortCode: string;
  accountNumber: string;
  address1: string;
  address2: string;
  address3: string;
  defaultAccount: true;
  referanceNumber: 0;
  depositFee: 0;
  depositMinimumAmount: 0;
  otherCountryWalletId: string;
  bankId?: string;
}

export interface IDepositHistoryPayload {
  status: number;
  currencyId: string;
}

export interface IDepositHistory {
  bankName: string;
  currency: string;
  amount: number;
  serviceFee: number;
  status: number;
  remark: string;
  depositDate: string;
  transactionId: string;
}

export interface IDepositCreate {
  transactionId: string;
  currencyId: string;
  paymentMode: number;
  amount: number;
  serviceFee: number;
  remark: string;
  transactionDate: string;
  deliveryMech: number,
  recipientId: string
  recipientType: string
}

export interface IPayousDepositCreate {
  wallet: WalletCurrency;
  amount: number;
  fee: number;
  instiutionId: string;
}
