<!-- <div class="d-flex align-items-center justify-content-between">
    <h4 class="font-weight-bold text-align">Beneficiaries Details</h4>
</div>
<hr />
<div class="p-4 text-center" [hidden]="!isloading">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
</div>

<div class="table-responsive" *ngIf="!isloading">
    <table class="table table-padded">
        <thead>
            <tr style="background-color:#4285f4;color: white;">
                <th width="100" class="text-center">Name</th>
                <th width="100" class="text-center">Currency</th>
                <th width="100" class="text-center">Account No</th>
                <th width="100" class="text-center">Status</th>
                <th width="100" class="text-center">KYC Link</th>
                <th width="100" class="text-center">Action</th>
            </tr>
        </thead>
        <tbody *ngIf="customerDetail.length > 0">
            <ng-container *ngFor="let otd of customerDetail;let i = index">
                <tr>
                    <td class="text-center font-weight-normal">{{otd.currency === 'USD' ? otd.firstName.concat('
                        ').concat(otd.middleName).concat(' ').concat(otd.lastName) : otd.firstName}}</td>
                    <td class="text-center font-weight-normal">{{otd.currency}}</td>
                    <td class="text-center font-weight-normal">{{otd.currency === 'USD' ? otd.accountNo :
                        otd.accountNo}}</td>
                    <td class="text-center font-weight-normal">{{getStatus(otd.status)}}</td>
                    <td class="text-center font-weight-bolder text-primary"><span *ngIf="getResendLinkStatus(otd)"><a
                                (click)="resendKYC(otd.customerId, otd.currency,i)">KYC Link</a>
                            <div class="kyc-link-loader pl-2" style="display: inline;" [hidden]="!isloadingKYC[i]">
                                <mdb-spinner spinnerColor="blue"></mdb-spinner>
                            </div>
                        </span>
                        <span *ngIf="getUploadDocStatus(otd)"><a (click)="uploadDoc(otd)">Upload KYC Document</a></span>
                    </td>
                    <td class="text-center font-weight-bolder text-black">
                        <button *ngIf="getEditStatus(otd)" mdbBtn type="button" outline="true" mdbWavesEffect
                            class="sm-btn-input-hover btn-border btn-sm btn-des" (click)="editCustomer(otd)">
                            Edit
                        </button>
                        <button mdbBtn type="button" outline="true" mdbWavesEffect
                            class="btn-border btn-sm btn-des btn-border-delete" (click)="removeCustomer(otd)">
                            <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"
                                *ngIf="otd.isOperationCompleted"></span>
                            Delete
                        </button>
                    </td>
                </tr>
            </ng-container>
        </tbody>
        <tbody *ngIf="customerDetail.length === 0">
            <tr>
                <td colspan="8">
                    <div class="no-response text-center">
                        <p class="c-light">There is no statements list found.</p>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div> -->

<section class="activity-box px-3 mb-2">
    <div class="details">
        <mdb-accordion [multiple]="false" aria-multiselectable="false">
            <mdb-accordion-item class="no-collase">
                <mdb-accordion-item-head class="p-2" mdbWavesEffect>
                    <div class="d-flex flex-row px-4 py-2 align-items-center">
                        <div class="">
                            <small>{{ getNameInitials() }}</small>
                            <img [src]="'../../../assets/icons/' + data.currency + '.png'" class="mr-2 cur-img" />
                        </div>
                        <div class="status ml-4" style="width: 50%;">
                            <span class="text-primary-dark font-weight-bold">{{data.currency === 'USD' ?
                                data.firstName.concat('
                                ').concat(data.middleName).concat(' ').concat(data.lastName) : data.firstName}}</span>
                            <br />
                            <span class="text-gray"> {{data.currency |uppercase}} account ending in
                                {{
                                data?.currency === 'USD' ? data?.accountNumber?.substr(data?.accountNumber?.length - 4)
                                : data?.accountNo?.substr(data?.accountNo?.length - 4)
                                }}</span>
                        </div>
                    </div>
                </mdb-accordion-item-head>
                <mdb-accordion-item-body class="border-top border-light px-4 position-relative">
                    <div class="container px-4 pt-2">
                        <div class="row">
                            <div class="col-sm">
                                Summary<br />
                                <b class="text-primary">{{data.currency |uppercase}} account ending in
                                    {{
                                    data?.currency === 'USD' ? data?.accountNumber?.substr(data?.accountNumber?.length -
                                    4) : data?.accountNo?.substr(data?.accountNo?.length - 4)
                                    }}</b>
                            </div>

                            <div class="col-sm">
                                Account holder name <br />
                                <b class="text-primary">{{data.currency === 'USD' ? data.firstName.concat('
                                    ').concat(data.middleName).concat(' ').concat(data.lastName) : data.firstName}}</b>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-sm">
                                Account number<br />
                                <b class="text-primary">{{data.currency === 'USD' ? data.accountNumber :
                                    data.accountNo}}</b>
                            </div>
                            <div class="col-sm">
                                Status<br />
                                <b class="text-primary">{{getStatus(data.status)}}</b>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="d-flex bd-highlight">
                        <div *ngIf="getEditStatus(data)" class="bd-highlight">
                            <button (click)="editCustomer(data)" mdbBtn type="button" outline="true" mdbWavesEffect class="sm-btn-input-hover btn-border btn-sm">
                                Edit
                            </button>
                        </div>
                        <div class="bd-highlight">
                            <button *ngIf="getResendLinkStatus(data)" [disabled]="timerFlag[0] || disableBtn[0]" mdbBtn type="button" outline="true" mdbWavesEffect class="sm-btn-input-hover btn-border btn-sm" (click)="resendKYC(data.customerId, data.currency,0)">
                                Send KYC Link to your Customer
                            </button>
                            <cashaa-timer *ngIf="!isloadingKYC[0]" [time]="timerTime[0]" [start]="timerStart[0]" [noExpiry]="true" [isRecipient]="true" (expire)="timerExpire(0)">
                            </cashaa-timer>
                        </div>
                         <div>
                            <button *ngIf="data.status === 1" (click)="onSendClick(data.currency)" mdbBtn type="button" outline="true" mdbWavesEffect class="sm-btn-input-hover btn-border btn-sm">
                                Send
                            </button>
                         </div>
                         <div>
                            <button *ngIf="data.status === 1" (click)="onReceiveClick(data.currency)" mdbBtn type="button" outline="true" mdbWavesEffect class="sm-btn-input-hover btn-border btn-sm">
                                Recieve
                            </button>
                         </div>
                        <div class="ml-auto bd-highlight">
                            <button (click)="removeCustomer(data)" class="btn-border-delete btn-sm" mdbBtn type="button" outline="true" mdbWavesEffect>
                                <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"
                                    *ngIf="data.isOperationCompleted"></span>
                                Delete
                            </button>
                        </div>
                    </div>

                </mdb-accordion-item-body>
            </mdb-accordion-item>
        </mdb-accordion>
    </div>
</section>