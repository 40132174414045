import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BASE_PLAN, DIAMOND_PLAN, GOLD_PLAN, SILVER_PLAN, USER_TYPE } from 'src/app/app.constants';
import { IDepositInterestPlan } from 'src/app/core/models/deposit-interest/deposit-interest';
import { IUserInterestList, IUserPlan, IUserPlanDetail, PlanDetails } from 'src/app/core/models/user-plan/user-detail';
import { UserService } from 'src/app/core/services/user/user.service';
import { DepositInterestService } from 'src/app/deposit-interest/service/deposit-interest.service';

@Component({
  selector: 'cashaa-plan-details',
  templateUrl: './plan-details.component.html',
  styleUrls: ['./plan-details.component.scss']
})
export class PlanDetailsComponent implements OnInit {
  userCurrentPlan: IUserPlanDetail;
  loading: boolean = false;
  isBase: boolean = false;
  isSilver: boolean = false;
  isGold: boolean = false;
  isDiamond: boolean = false;
  basePlans: IUserPlan[] = [];
  silverPlans: IUserPlan[] = [];
  goldPlans: IUserPlan[] = [];
  daimondPlans: IUserPlan[] = [];
  allPlans = [];
  // interestList: IUserInterestList;
  borrowBenefit;
  highLTVRate = [];
  exchangeCashback = [];
  earnBenefits = [];
  additionalBenefits = [];
  basePlanDesc = [];
  silverPlanDesc = [];
  goldPlanDesc = [];
  diamondPlanDesc = [];
  maxCryptoRateCoins;
  cryptoFixedDepoistRate = [];
  maxFiatRateCoins;
  fiatFixedDepoistRate = [];
  maxStableRateCoins;
  stableFixedDepoistRate = [];
  maxCashaaRateCoins;
  cashaaFixedDepoistRate = [];

  userType: string = sessionStorage.getItem(USER_TYPE);
  allplanCasBonus = [{ 'base': 0.5 }, { 'silver': 1 }, { 'gold': 2 }, { 'diamond': 3 }];
  symbol;
  basePlanNameId = '7c57b4ea-3cfc-4520-b2bd-db442be4d763';
  silverPlanNameId = '48654d6c-bd60-4054-85cc-a484ceeeef92';
  goldPlanNameId = 'c138f175-28d7-4ab4-99b6-8bb8b9f8251a';
  diamondPlanNameId = '016688f1-9caa-40ed-b77c-2095d5c85baa';
  cryptoCurrencyWithMaxRate = [];
  fiatCurrencyWithMaxRate = [];
  stableCurrencyWithMaxRate = [];
  planDetails: PlanDetails;
  constructor(private userService: UserService, private router: Router,
    private activateRoute: ActivatedRoute, private depositService: DepositInterestService) { }

  ngOnInit(): void {
    this.loading = true;
    this.getPlanListDetails();
    this.userService.getInterestList().subscribe((data: IUserInterestList) => {
      this.borrowBenefit = [[12, 11, 8, 6], [10, 15, 20, 50]];
      // this.borrowBenefit = data.bowrrowBenefits[0];
      // this.highLTVRate.push(this.borrowBenefit.base[0]);
      // this.highLTVRate.push(this.borrowBenefit.silver[0]);
      // this.highLTVRate.push(this.borrowBenefit.diamond[0]);
      // this.highLTVRate.push(this.borrowBenefit.gold[0]);

      this.exchangeCashback.push(data.exchangeBenefits[0]);

      this.earnBenefits.push(data.earnBenefits[0].base[0]);
      this.earnBenefits.push(data.earnBenefits[0].silver[0]);
      this.earnBenefits.push(data.earnBenefits[0].gold[0]);
      this.earnBenefits.push(data.earnBenefits[0].diamond[0]);

      this.additionalBenefits.push(data.additionalBenefits[0].base[0]);
      this.additionalBenefits.push(data.additionalBenefits[0].silver[0]);
      this.additionalBenefits.push(data.additionalBenefits[0].gold[0]);
      this.additionalBenefits.push(data.additionalBenefits[0].diamond[0]);
    })
    this.userService.getUserPlanDetail().subscribe((data: IUserPlanDetail) => {
      this.userCurrentPlan = data;
      if (this.userCurrentPlan.name === BASE_PLAN) {
        this.isBase = true;
      } else if (this.userCurrentPlan.name === SILVER_PLAN) {
        this.isSilver = true;
      } else if (this.userCurrentPlan.name === GOLD_PLAN) {
        this.isGold = true;
      } else {
        this.isDiamond = true;
      }
      this.loading = false;
    });

      this.userService.getAllPlansDetails().subscribe((data: IUserPlan[]) => {
        this.symbol = data[0].symbol;
        this.allPlans = data;
        this.basePlans = data.filter(plans => plans.loyaltyPlanName === BASE_PLAN);
        this.silverPlans = data.filter(plans => plans.loyaltyPlanName === SILVER_PLAN);
        this.goldPlans = data.filter(plans => plans.loyaltyPlanName === GOLD_PLAN);
        this.daimondPlans = data.filter(plans => plans.loyaltyPlanName === DIAMOND_PLAN);
        const cryptodiamondPlan = this.allPlans.filter(ele => ele.currencyNature === 'Crypto' && ele.loyaltyPlanNameId === this.diamondPlanNameId);
        const cryptoMaxRate = cryptodiamondPlan.map(ele => ele.kindRate);
        let cryptoMax = Math.max(...cryptoMaxRate);
        let cryptoCurrWdMax;
        this.allPlans.filter(ele => {
          if (ele.currencyNature === 'Crypto' && ele.kindRate === cryptoMax) {
            cryptoCurrWdMax = ele;
          }
        });
        let allPlanofCurr = []
        this.allPlans.filter(ele => {
          if (cryptoCurrWdMax.currencyId === ele.currencyId) {
            allPlanofCurr.push(ele);
          }
        })
        allPlanofCurr.map(ele => {
          if (ele.loyaltyPlanNameId === this.basePlanNameId) {
            this.cryptoCurrencyWithMaxRate[0] = ele;
          }
          if (ele.loyaltyPlanNameId === this.silverPlanNameId) {
            this.cryptoCurrencyWithMaxRate[1] = ele;
          }
          if (ele.loyaltyPlanNameId === this.goldPlanNameId) {
            this.cryptoCurrencyWithMaxRate[2] = ele;
          }
          if (ele.loyaltyPlanNameId === this.diamondPlanNameId) {
            this.cryptoCurrencyWithMaxRate[3] = ele;
          }
        })
        const fiatDiamondPlan = this.allPlans.filter(ele => ele.currencyNature === 'Fiat' && ele.loyaltyPlanNameId === this.diamondPlanNameId);
        const fiatMaxRate = fiatDiamondPlan.map(ele => ele.kindRate);
        let fiatMax = Math.max(...fiatMaxRate);
        let fiatCurrWdMax;
        this.allPlans.filter(ele => {
          if (ele.currencyNature === 'Fiat' && ele.kindRate === fiatMax) {
            fiatCurrWdMax = ele;
          }
        });
        let allFiatPlanofCurr = []
        this.allPlans.filter(ele => {
          if (fiatCurrWdMax.currencyId === ele.currencyId) {
            allFiatPlanofCurr.push(ele);
          }
        })
        allFiatPlanofCurr.map(ele => {
          if (ele.loyaltyPlanNameId === this.basePlanNameId) {
            this.fiatCurrencyWithMaxRate[0] = ele;
          }
          if (ele.loyaltyPlanNameId === this.silverPlanNameId) {
            this.fiatCurrencyWithMaxRate[1] = ele;
          }
          if (ele.loyaltyPlanNameId === this.goldPlanNameId) {
            this.fiatCurrencyWithMaxRate[2] = ele;
          }
          if (ele.loyaltyPlanNameId === this.diamondPlanNameId) {
            this.fiatCurrencyWithMaxRate[3] = ele;
          }
        })

        const stableDiamondPlan = this.allPlans.filter(ele => ele.currencyNature === 'StableCoins' && ele.loyaltyPlanNameId === this.diamondPlanNameId);
        const stableMaxRate = stableDiamondPlan.map(ele => ele.kindRate);
        let stableMax = Math.max(...stableMaxRate);
        let stableCurrWdMax;
        this.allPlans.filter(ele => {
          if (ele.currencyNature === 'StableCoins' && ele.kindRate === stableMax) {
            stableCurrWdMax = ele;
          }
        });
        let allStablePlanofCurr = []
        this.allPlans.filter(ele => {
          if (stableCurrWdMax.currencyId === ele.currencyId) {
            allStablePlanofCurr.push(ele);
          }
        })
        allStablePlanofCurr.map(ele => {
          if (ele.loyaltyPlanNameId === this.basePlanNameId) {
            this.stableCurrencyWithMaxRate[0] = ele;
          }
          if (ele.loyaltyPlanNameId === this.silverPlanNameId) {
            this.stableCurrencyWithMaxRate[1] = ele;
          }
          if (ele.loyaltyPlanNameId === this.goldPlanNameId) {
            this.stableCurrencyWithMaxRate[2] = ele;
          }
          if (ele.loyaltyPlanNameId === this.diamondPlanNameId) {
            this.stableCurrencyWithMaxRate[3] = ele;
          }
        })


      });
      // }
    this.getDepositCurrency();
  }

  getDepositCurrency() {
    this.depositService.getAllCurrencyDepositInterest().subscribe((res: IDepositInterestPlan[]) => {
      let allCryptoRate: IDepositInterestPlan [] = [];
      let allFiatRate = [];
      let allStableCoinRate = [];
      let cashaa = []
      res.filter(ele => {
        if (ele.currencyNature === 'Crypto' && ele.rateType === 1) {
          allCryptoRate.push(ele);
        }
        if (ele.currencyNature === 'Fiat' && ele.rateType === 1) {
          allFiatRate.push(ele);
        }
        if (ele.currencyNature === 'StableCoins' && ele.rateType === 1) {
          allStableCoinRate.push(ele);
        }
        if (ele.currencyNature === 'Cashaa' && ele.rateType === 1) {
          cashaa.push(ele);
        }
      });
      if (allCryptoRate.length > 0) {
        let maxCryptoRate = Math.max(...allCryptoRate.map(ele => ele.rate));
        this.maxCryptoRateCoins  = allCryptoRate.filter(item => item.rate === maxCryptoRate);
        this.cryptoFixedDepoistRate = [maxCryptoRate + this.maxCryptoRateCoins[0].inKindPlanA, 
        maxCryptoRate+ this.maxCryptoRateCoins[0].inKindPlanB, maxCryptoRate + this.maxCryptoRateCoins[0].inKindPlanC,
        maxCryptoRate + this.maxCryptoRateCoins[0].inKindPlanD];
        
        let maxFiatRate = Math.max(...allFiatRate.map(ele => ele.rate));
        this.maxFiatRateCoins  = allFiatRate.filter(item => item.rate === maxFiatRate);
        this.fiatFixedDepoistRate = [maxFiatRate + this.maxFiatRateCoins[0].inKindPlanA, 
        maxFiatRate+ this.maxFiatRateCoins[0].inKindPlanB, maxFiatRate + this.maxFiatRateCoins[0].inKindPlanC,
        maxFiatRate + this.maxFiatRateCoins[0].inKindPlanD];
        
        let maxStableRate = Math.max(...allStableCoinRate.map(ele => ele.rate));
        this.maxStableRateCoins  = allStableCoinRate.filter(item => item.rate === maxStableRate);
        this.stableFixedDepoistRate = [maxStableRate + this.maxStableRateCoins[0].inKindPlanA, 
        maxStableRate+ this.maxStableRateCoins[0].inKindPlanB, maxStableRate + this.maxStableRateCoins[0].inKindPlanC,
        maxStableRate + this.maxStableRateCoins[0].inKindPlanD];
        
        let maxCashaaRate = Math.max(...cashaa.map(ele => ele.rate));
        this.maxCashaaRateCoins = cashaa.filter(item => item.rate === maxCashaaRate);
        this.cashaaFixedDepoistRate = [maxCashaaRate + this.maxCashaaRateCoins[0].inKindPlanA, 
        maxCashaaRate+ this.maxCashaaRateCoins[0].inKindPlanB, maxCashaaRate + this.maxCashaaRateCoins[0].inKindPlanC,
        maxCashaaRate + this.maxCashaaRateCoins[0].inKindPlanD];
      }
    });
  }
  goToAccount(): void {
    this.router.navigateByUrl("/wallet?c=CAS#CAS");
  }
  
  goToCrypto(): void {
    this.router.navigateByUrl(`/wallet?crypto=true`);
  }

  getPlanListDetails() {
    this.userService.getPlanList().subscribe((res: PlanDetails) => {
      this.planDetails = res;
    console.log(this.planDetails);
    })
  }

}
