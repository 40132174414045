<cashaa-sidenav>
    <section class="mh-100">
      <div class="bg-white p-4">
        <div class="ml-5" style="height: 46px;">
        </div>
      </div>
    </section>
    <section class="p-4 sm-width-phone-700">
        <div class="row p-1" *ngIf="!loader">
          <table mdbTable>
            <thead style="background: #1740A4; height: 45px; color: white;">
              <tr>
                <th style="text-align: start;">
                  <h6>Sr No</h6>
                </th>
                <th style="text-align: center;">
                  <h6>Portfolio Level</h6>
                </th>
                <th style="text-align: center;">
                  <h6>Date</h6>
                </th>
                <th style="text-align: center;">
                  <h6>Time</h6>
                </th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr mdbTableCol *ngFor="let item of portfolioData; let i = index">
                <td style="text-align: start;">
                  <span>{{i+1}}</span>
                </td>
                <td style="text-align: center;">
                  <span class="font-weight-bold">
                    <img src="../../../assets/images/plan/{{item.planName}}.svg" width="35px">&nbsp;{{item.planName}}</span>
                </td>
                <td style="text-align: center;">
                  <span class="font-weight-bold">{{item.created|date}}</span>
                </td>
                <td style="text-align: center;">
                  <span class="font-weight-bold">{{item.created|date: 'hh:mm'}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    </section>
  </cashaa-sidenav>
  