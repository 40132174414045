<section class="p-5 w-75 mx-auto" *ngIf="!initialLoad && quoteDetail">
  <h5 class="heading mb-5 text-center">
    Does everything look right?
  </h5>
  <div class="details px-4 py-2">
    <div class="d-flex justify-content-between">
      <p class="font-weight-normal text-gray">You're converting</p>
      <h6 class="font-weight-bold text-primary">
        {{quoteDetail?.fromAmount |number: getDecimalNumber(quoteDetail.feeSymbol)}} {{ getCurrencyName(quoteDetail.fromSymbol) }}
      </h6>
    </div>
    <div class="d-flex justify-content-between">
      <p class="font-weight-normal text-gray">To</p>
      <h6 class="font-weight-bold text-primary">
        {{quoteDetail.toAmount|number: "0.0-4"}} {{ getCurrencyName(quoteDetail.toSymbol) }}
      </h6>
    </div>
    <div class="d-flex justify-content-between">
      <p class="text-primary-dark font-weight-bold">Fee</p>
      <h6 class="font-weight-bold text-primary">
        {{quoteDetail.feeAmount | number : getDecimalNumber(quoteDetail.feeSymbol)}} {{ getCurrencyName(quoteDetail.feeSymbol) }}
      </h6>
    </div>
    <div class="d-flex justify-content-between">
      <p class="font-weight-normal text-gray">Rate</p>
      <h6 class="font-weight-bold text-primary">
        {{quoteDetail.quotePrice | number: "0.2-10"}} {{ getCurrencyName(quoteDetail.fromSymbol) }}/{{ getCurrencyName(quoteDetail.toSymbol) }}
      </h6>
    </div>
  </div>

  <div class="conditions d-flex mt-4 p-3 mb-4 justify-content-center align-items-center">
    <cashaa-timer [start]="inView" (expire)="timeExpired = true" *ngIf="!errormsg">
    </cashaa-timer>
    <div class="text-center w-100 mt-3" *ngIf="errormsg">
      <span class="text-primary-light sm-error-font" style="color:red!important;">
        <u style="cursor: default;">
          Conversion is unsuccessful
        </u>
      </span>
    </div>
  </div>
  <div *ngIf="!errormsg">
    <button *ngIf="(isQuote && !timeExpired) || !isQuote" [disabled]="timeExpired || loading" mdbBtn block="true"
      type="button" class="w-100 rounded-sm position-relative btn btn-secondary sm-custom-sec  waves-light btn-block"
      mdbWavesEffect (click)="onFormSubmit()">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-2" role="status"
        aria-hidden="true"></span>Convert
    </button>
  </div>
  <button *ngIf="(isQuote && timeExpired) || errormsg" mdbBtn block="true" type="button"
    class="w-100 rounded-sm position-relative btn btn-secondary sm-custom-sec waves-light btn-block" mdbWavesEffect
    (click)="getQuoteAgain(true)">
    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Get
    Quote Again
  </button>

  <span *ngIf="!errorMessage" class="blue-text pointer sm-blue-custom" (click)="goToAmountPage()">
    <mdb-icon class="sm-cursor-pointer" fas icon="arrow-left"> </mdb-icon>
    <span class="sm-cursor-pointer" style="padding-left: 10px;">Go back</span>
  </span>
</section>

<div *ngIf="errorMessage" class="failure-div text-center">
  <h5  class="text-danger">{{errorMessage}}</h5>
<span class="blue-text pointer sm-blue-custom" (click)="goToAmountPage()">
  <mdb-icon class="sm-cursor-pointer" fas icon="arrow-left"> </mdb-icon>
  <span class="sm-cursor-pointer" style="padding-left: 10px;">Go back</span>
</span>
</div>

<section class="p-5 w-75 mx-auto text-center" *ngIf="initialLoad">
  <mdb-spinner spinnerColor="blue"></mdb-spinner>
</section>