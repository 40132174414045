import { WalletService } from "./../services/wallet.service";
import { IFormSelect } from "./../../core/models/form-select";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "cashaa-wallet-open",
  templateUrl: "./wallet-open.component.html",
  styleUrls: ["./wallet-open.component.scss"],
})
export class WalletOpenComponent implements OnInit {
  /** fiatCurrency options */
  fiatCurrencyOptions: IFormSelect[] = [];

  constructor(private WalletService: WalletService) {}

  ngOnInit(): void {
    this.getFiatCurrency();
  }

  OnOpenAccountClick() {
    //this.accountService.openFiatAccount()
  }

  /**
   * Get Fiat Currencies
   */
  getFiatCurrency(): void {
    this.WalletService.getFiatCurrency().subscribe((res) => {
      this.fiatCurrencyOptions = res.currencies.map((currency) => {
        return {
          value: currency.id,
          label: currency.symbol,
        };
      });
    });
  }
}
