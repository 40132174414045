import { ILedgerList } from "src/app/core/models/activity/recent-transaction-list";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
// import jsPDF from "jspdf";

@Component({
  selector: "cashaa-activity-balance-transfer-detail",
  templateUrl: "./activity-balance-transfer-detail.component.html",
  styleUrls: ["./activity-balance-transfer-detail.component.scss"],
})
export class ActivityBalanceTransferDetailComponent implements OnInit {
  detail: ILedgerList;
  copied: boolean = false;
  // @ViewChild('pdfTable', { static: false }) pdfTable: ElementRef;
  constructor(
    public modalRef: MDBModalRef,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    //     amount: 1
    // cashaaFee: 0
    // cashaaFeeMode: 0
    // cashaaUserId: "1ec66ca5-7e47-443c-8113-44342b3abd83"
    // convertFromamount: 2
    // convertToamount: 1
    // convertTocurrency: "GBP"
    // convertfee: 1
    // convertfromcurrency: "GBP"
    // created: "2020-08-12T12:06:53.794004"
    // currency: "GBP"
    // exchangeRate: 0
    // fromAddress: null
    // fullName: "Anwar Haque"
    // id: "f8913d22-7eeb-4657-9594-476375c68f42"
    // ledgerRecipient: "Amjad Khan"
    // ledgerType: 8
    // networkFee: 0
    // sendCurrency: 0
    // status: 1
    // toAddress: null
    // transactionData: "UserTransaction-1.00"
    // transactionId: "539355c0-019c-4cfd-a4d4-4d71576b2cd1"
    // transferType: 1
  }

  convert(): void {
    // const doc = new jsPDF({
    //   orientation: "portrait",
    //   unit: "in",
    //   format: [4, 2]
    // });
    // //doc.autoTable({html:”#invoice”});
    // doc.text("Hello world!", 1, 1);
    // doc.save("two-by-four.pdf");
    //     const doc = new jsPDF();
    //     const specialElementHandlers = {
    //       '#editor': function (element, renderer) {
    //         return true;
    //       }
    //     };
    //     const pdfTable = this.pdfTable.nativeElement;
    // doc.addHTML(pdfTable.innerHTML,15,15);
    //     doc.fromHTML(pdfTable.innerHTML, 15, 15, {
    //       width: 190,
    //       'elementHandlers': specialElementHandlers
    //     });
    //     doc.save('tableToPdf.pdf');
    // const doc = new jsPDF();
    // const col = ["Details", "Values"];
    // const rows = [];
    // const itemNew = this.detail;
    // itemNew.forEach(element => {
    //   const temp = [element.id, element.name];
    //   rows.push(temp);
    // });
    //doc.autoTa(col, rows);
    //doc.save('Test.pdf');
  }

  copyAddress(): void {
    this.copied = true;
    navigator.clipboard.writeText(this.detail.id);
    this.toastrService.toastrConfig.preventDuplicates = true;
    this.toastrService.success("Text copied to clipboard");
    setTimeout(() => (this.copied = false), 300);
  }

  getAmountSend(): number {
    if (this.detail.ledgerType === 8 || this.detail.ledgerType === 9) {
      return this.detail.convertFromamount;
    } else if (this.detail.ledgerType === 1 || this.detail.ledgerType === 34 || this.detail.ledgerType === 35 || this.detail.ledgerType === 36) {
      return this.detail.amount;
    }
  }

  getCurrency() {
    if (this.detail.ledgerType === 8 || this.detail.ledgerType === 9) {
      if(this.detail.convertfromcurrency === 'DOG') {
        this.detail.convertfromcurrency = 'DOGE';
      }
      return `${this.detail.convertfromcurrency}`;
    } else if (this.detail.ledgerType === 1 || this.detail.ledgerType === 34 || this.detail.ledgerType === 35 || this.detail.ledgerType === 36) {
      if(this.detail.currency === 'DOG') {
        this.detail.currency = 'DOGE';
      }
      return `${this.detail.currency}`;
    }
  }

  isInvalidTransaction(): boolean {
    return this.detail.transactionId?.toLowerCase().includes("-");
  }

  getCurrencyName(curr): string {
    if (curr === "AUSD") return "USD";
    if (curr === "DOG") return "DOGE";
    else return curr;
  }

  isCrypto(): boolean {
    return this.detail.currency === "ETH" ||
      this.detail.currency == "BTC" ||
      this.detail.currency == "CAS" ||
      this.detail.currency == "BNB" ||
      this.detail.currency == "USDT" ||
      this.detail.currency == "DOG" ||
      this.detail.currency == "XRP" ||
      this.detail.currency == "LTC" ||
      this.detail.currency == "ADA" ||
      this.detail.currency == "BCH" ||
      this.detail.currency == "BNB" ||
      this.detail.currency == "BNB20" ||
      this.detail.currency == "LINK" ||
      this.detail.currency == "UNI" ||
      this.detail.currency == "USDC"
  }

  isFiatAndAccount(): boolean {
    return this.detail.currency == "GBP" ||
      this.detail.currency == "EUR" ||
      this.detail.currency == "AUSD" ||
      this.detail.currency == "USD" ||
      this.detail.currency == "SGD" ||
      this.detail.currency == "AUD" ||
      this.detail.currency == "CNH" ||
      this.detail.currency == "CAD" ||
      this.detail.currency == "CHF" ||
      this.detail.currency == "NZD" ||
      this.detail.currency == "JPY" ||
      this.detail.currency == "MXN" ||
      this.detail.currency == "SHIB"

  }

  getRef(): string {
    if (this.detail.currency === 'CAS') {
      return this.detail.networkType ? this.getRefValue(this.detail.currency) : environment.CAS + this.detail.transactionId;
    } else if (this.detail.currency === 'BNB') {
      return this.detail.networkType ? this.getRefValue(this.detail.currency) : environment.BNB + this.detail.transactionId;
    } else if (this.detail.currency === 'BCH') {
      return this.detail.networkType ? this.getRefValue(this.detail.currency) : environment.BCH + this.detail.transactionId;
    } else if (this.detail.currency === 'XRP') {
      return this.detail.networkType ? this.getRefValue(this.detail.currency) : environment.XRP + this.detail.transactionId;
    } else if (this.detail.currency === 'LTC') {
      return this.detail.networkType ? this.getRefValue(this.detail.currency) : environment.LTC + this.detail.transactionId;
    } else if (this.detail.currency === 'DOG') {
      return this.detail.networkType ? this.getRefValue(this.detail.currency) : environment.DOG + this.detail.transactionId;
    } else if (this.detail.currency === 'ETH') {
      return this.detail.networkType ? this.getRefValue(this.detail.currency) : environment.ETH + this.detail.transactionId;
    } else if (this.detail.currency === 'USDT') {
      return this.detail.networkType ? this.getRefValue(this.detail.currency) : environment.USDT + this.detail.transactionId;
    } else if (this.detail.currency === 'BTC') {
      return this.detail.networkType ? this.getRefValue(this.detail.currency) : environment.BTC + this.detail.transactionId;
    } else if (this.detail.currency === 'UNI') {
      return this.detail.networkType ? this.getRefValue(this.detail.currency) : environment.UNI + this.detail.transactionId;
    } else if (this.detail.currency === 'LINK') {
      return this.detail.networkType ? this.getRefValue(this.detail.currency) : environment.LINK + this.detail.transactionId;
    } else if (this.detail.currency === 'USDC') {
      return this.detail.networkType ? this.getRefValue(this.detail.currency) : environment.USDC + this.detail.transactionId;
    }
  }

  getRefValue(currency: string): string {
    if (currency === 'LINK' || currency === 'USDC' || currency === 'UNI' || currency === 'USDT' || currency === 'CAS') {
      if (this.detail.networkType == 1) {
        return environment.NETWORK_ERC20 + this.detail.transactionId;
      } else if (this.detail.networkType == 2) {
        return environment.NETWORK_BEP20 + this.detail.transactionId;
      }
    }

    if ((currency === 'LTC' || currency === 'BNB' || currency === 'ETH' || currency === 'BCH' || currency === 'XRP' || currency === 'DOG') &&
      this.detail.networkType == 2) {
      return environment.NETWORK_BEP20 + this.detail.transactionId;
    }

    if (currency === 'CAS' && this.detail.networkType == 3) {
      return environment.NETWORK_POLYGON + this.detail.transactionId;
    }
    
  }
}


