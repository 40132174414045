import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { param } from 'jquery';
import { WalletService } from '../services/wallet.service';

@Component({
  selector: 'cashaa-loan-details',
  templateUrl: './loan-details.component.html',
  styleUrls: ['./loan-details.component.scss']
})
export class LoanDetailsComponent implements OnInit {

  loanId;
  loanDetails = [];
  loading = false;
  loanRepaymentHistoryInfo = [];
  constructor(private activatedRoute: ActivatedRoute, private walletService: WalletService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe( param => {
      this.loanId = window.atob(param.id);
    })
    if(this.loanId) {
      this.getLoanDetailsByLoanId();
      this.getLoanRepaymentHistory();
    }
  }

  getLoanDetailsByLoanId() {
    this.loading = true;
    this.walletService.getLoanDetailsById(this.loanId).subscribe(res => {
    this.loading = false;  
    this.loanDetails = res.creditLines;
    }, err => {
      this.loading = false;
    })

  }
    getLoanRepaymentHistory() {
    this.loading = true;
    this.walletService.getLoanRepaymentHistory(this.loanId).subscribe(res => {
      this.loading = false;
      this.loanRepaymentHistoryInfo = res.repayment;

    }, err => {
      this.loading = false;
    })
  }

}
