import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { USER_ID } from "src/app/app.constants";

@Component({
  selector: "under-maintenance",
  template: `<div class="main-div">
        <h4 class="mb-4 text-center font-weight-normal text-dark-red">Exchange service is under maintenance. We'll be back soon!</h4>
        <div class="p-5" class="text-center mt-4">
            <button mdbBtn type="button"
                class="sm-width-ph-confirmation btn-sm btn-primary btn btn-height" mdbWavesEffect
                (click) = "getRoute()">
                Cashaa Home Page <i class="fas fa-arrow-circle-right pl-2" style="font-size: 1rem;"></i>
            </button>
        </div>
  </div>`,
  styles: [`.main-div {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left:0;
    right:0}`]
})
export class UnderMaintenanceComponent {
  currentUserId = sessionStorage.getItem(USER_ID);
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  getRoute(): void {
    if (this.currentUserId) {
      this.router.navigateByUrl("/activity");
    } else {
      this.router.navigateByUrl("/account/signin");
    }
  }
}