<!-- <cashaa-sidenav>
  <section class="mh-100">
      <div class="bg-white p-4">
      <div class="d-flex ml-5" style="height: 46px;">
        <h6 class="heading my-auto">CAS SWAP</h6>
      </div>
    </div>
  </section>
  <div class="d-flex">
  <cashaa-form-select class="m-4" label="Ticket Type" [options]="ticketTypeList"></cashaa-form-select>
  <cashaa-form-select class="m-4" label="Currency Type" [options]="filteredOptions"></cashaa-form-select>
  </div>
    <div class="p-4 w-100">
      <section class="banks">
        <div class="bg-unset">
          <div>
            <div class="">
              <div class="row px-5 py-4" style="background-color: white;border-radius: 7px;">
                <div class="col-md-12">
                  <div class="mb-5">
                    <h5 class="mb-3 heading">
                      CAS-ERC20 Deposit Address
                    </h5>
                  </div>
                  <p class="text-dark font-weight-bolder text-center">You
                    will receive your BEP2 token after 13 January 2023 (Completed all steps before 13 December 2022,
                    4:00 AM BST) – Fees 15%"
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="p-4 w-100">
      <div class="border border-primary rounded-lg p-4 bg-white w-100" style="height: 100%;">
        <h5 class="heading mb-3">Deposit History
        </h5>
        <div class=" bg-white table-responsive table-border">
          <table #tableEl="mdbTable" id="userListTable" class="table" mdbTable bordered="true" hover="true">
            <thead class="tableHeaderColor">
              <tr>
                <th aria-controls="userListTable" scope="col" style="width: 20px;">Amount</th>
                <th aria-controls="userListTable" scope="col" style="width: 165px;">Transaction ID</th>
                <th aria-controls="userListTable" scope="col" style="width: 165px;">From Address</th>
                <th aria-controls="userListTable" scope="col">Status</th>
                <th aria-controls="userListTable" scope="col" style="width: 5px;">Is Released</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
</cashaa-sidenav> -->

<!--  -->
<cashaa-sidenav>
  <section class="mh-100">
    <div class="bg-white p-4" style="min-height: 70px;">
    </div>
  </section>

  <div class="p-4 h-100">
    <ng-container>
      <div class="mx-auto stepper w-phone-100 w-100">
        <mdb-stepper #stepper [linear]="true" class="w-100" [hidden]="isConfirmed && isPaymentSuccess || isConfirmed && !isPaymentSuccess">
          <mdb-step name="Select Ticket" class="step">
            <cashaa-ticket-type (onSelect)="select($event)"></cashaa-ticket-type>
          </mdb-step>
          <mdb-step name="Choose Currency" class="step"   *ngIf="!showPersonalPage">
            <cashaa-currency-type (onCurrencySelection)="selectedCurrency($event)" (goBack)="previous()">
            </cashaa-currency-type>
          </mdb-step>
          <mdb-step name="Get Address" class="step" *ngIf="!showPersonalPage">
            <cashaa-address [addressInfo]="paymentAddressData" (onContinue)="confirmTicket()"
              (goBack)="previous()"></cashaa-address>
          </mdb-step>
          <mdb-step name="Personal Details" class="step">
            <cashaa-other-details (goBack)="previous()" (onAddingPersonalDetails)="getPersonalDetails($event)"></cashaa-other-details>
          </mdb-step>
          <mdb-step name="Confirm" class="step">
            <cashaa-confirm (onConfirm)="confirmTicketDetails()"
              [confirmedTicketDetails]="confirmedTicketDetails" (goBack)="previous()"></cashaa-confirm>
          </mdb-step>
        </mdb-stepper>
      </div>
    </ng-container>
    <cashaa-deposit-timer *ngIf="isConfirmed && !isPaymentSuccess" [otherDetails]="otherDetails"></cashaa-deposit-timer>
    <cashaa-payment-history *ngIf="isConfirmed && isPaymentSuccess"></cashaa-payment-history>
  </div>

</cashaa-sidenav>