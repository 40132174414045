export interface Google2faDto {
  secrateKey: string;
  url: string;
  isActive: boolean;
}

export interface IEnabletwofaresponse {
  isActive: boolean;
}

export interface IEnabletwofarequest {
  code: string;
}

export class Enabletwofarequest implements IEnabletwofarequest {
  constructor(public code: string) { }
}

// Create TWOFA
export interface ICreatetwofa {
  enableTwoFA: boolean;
}

export class Createtwofa implements ICreatetwofa {
  constructor(public enableTwoFA: boolean) { }
}
