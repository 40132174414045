import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { USER_ID } from 'src/app/app.constants';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';

@Component({
  selector: 'cashaa-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
 
  currentUserId = sessionStorage.getItem(USER_ID);
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  getRoute(): void {
    if (this.currentUserId) {
      this.router.navigateByUrl("/activity");
    } else {
      this.router.navigateByUrl("/account/signin");
    }
  }


}
