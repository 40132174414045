import {
  USER_ID,
  USER_TYPE,
  USER_NAME_ID,
  USER_FEE_MODE,
  USER_FEE_PERCENT,
  USER_TWO_FACTOR_CODE,
  SEESION_OBJECT,
  SEESION_FLAG,
  USER_INTEREST_MODE,
  USER_COUNTRY_CODE,
  USER_REFERRAL_CODE,
  IS_CAS_SWAP_Enabled,
} from "./../../app.constants";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Signup } from "src/app/core/models/signup";
import { Observable, BehaviorSubject } from "rxjs";
import { ITokenResponse } from "src/app/core/models/token";
import { Signin2fa } from "src/app/core/models/signin-2fa";
import { Signin } from "src/app/core/models/signin";
import { WhitelistDevice } from "src/app/core/models/whitelist-device";
import { ResetPassword } from "src/app/core/models/reset-password";
import { tap } from "rxjs/operators";
import {
  AUTH_TOKEN_PATH,
  USER_NAME_PATH,
  USER_EMAIL_PATH,
} from "src/app/app.constants";
import { ICurrencyList } from "src/app/core/models/currency";
import { CurrencyType } from "src/app/core/enums/currencyType";
import { FiatAccountRequestModel } from "src/app/core/models/fiat-account-request-model";
import { CryptoAccountRequestModel } from "src/app/core/models/crypto-account-request-model";
import { IAccountDetails } from "src/app/core/models/account-details";
import { ICurrencyTypeData } from "src/app/core/models/currency-type-data";
import { ICryptoAccountDetails } from "src/app/core/models/crypto-account-details";

@Injectable({
  providedIn: "root",
})
export class AccountService {
  /** api url */
  private apiUrl = environment.api;

  /** Signin data emitter */
  private signinDataEmitter = new BehaviorSubject<Signin | undefined>(
    undefined
  );
  /** Signin data emitter */
  private disableCrossFlag = new BehaviorSubject<boolean | undefined>(
    undefined
  );

  constructor(private http: HttpClient) {}

  /**
   * Create new personal account
   * @param signupData personal signup data
   */
  createPersonalAccount(
    signupData: Signup
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${this.apiUrl}/account/createpersonal`,
      signupData
    );
  }

  /**
   * Create Business account
   */
  createBusinessAccount(
    signupData: Signup
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${this.apiUrl}/account/createbusiness`,
      signupData
    );
  }

  /**
   * Send forget password email
   * @param email email address
   */
  forgetPassword(
    email: "string"
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${this.apiUrl}/account/forgetpassword`,
      {
        email,
      }
    );
  }

  /**
   * Reset user password
   * @param resetPasswordData data for reset password
   */
  resetPassword(
    resetPasswordData: ResetPassword
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${this.apiUrl}/account/resetpassword`,
      resetPasswordData
    );
  }

  /**
   * Sign in to user
   * @param signinData signin user data
   */
  signinAccount(signinData: Signin): Observable<ITokenResponse> {
    return this.http
      .post<ITokenResponse>(`${this.apiUrl}/account/token`, signinData)
      .pipe(
        tap((res) => {
          if (res.token) {
            if(res.isConsent === false){
              sessionStorage.setItem('isConsent', 'false');
              } else {
                sessionStorage.setItem('isConsent', 'true');
              }
              if(res.isCASSWAPEnabled === false){
                sessionStorage.setItem(IS_CAS_SWAP_Enabled, 'false');
                } else {
                  sessionStorage.setItem(IS_CAS_SWAP_Enabled, 'true');
                }
            sessionStorage.setItem(AUTH_TOKEN_PATH, res.token);
            sessionStorage.setItem(USER_NAME_PATH, res.name);
            sessionStorage.setItem(USER_EMAIL_PATH, res.email);
            sessionStorage.setItem(USER_ID, res.cashaaUserId);
            sessionStorage.setItem(USER_TYPE, res.userType.toString());
            sessionStorage.setItem(USER_NAME_ID, res.userName);
            sessionStorage.setItem(USER_FEE_MODE, res.userFeeMode);
            sessionStorage.setItem(USER_INTEREST_MODE, res.userInterestMode);
            sessionStorage.setItem(USER_FEE_PERCENT, res.conversionFee);
            sessionStorage.setItem(USER_COUNTRY_CODE, res.userCountryCode);
            sessionStorage.setItem(USER_REFERRAL_CODE, res.referalCode);

            let tempObj: any = {};
            tempObj.token = res.token;
            tempObj.name = res.name;
            tempObj.email = res.email;
            tempObj.cashaaUserId = res.cashaaUserId;
            tempObj.userType = res.userType.toString();
            tempObj.userName = res.userName;
            tempObj.userFeeMode = res.userFeeMode;
            tempObj.conversionFee = res.conversionFee;
            tempObj.userCountryCode = res.userCountryCode;
            tempObj.referralCode = res.referalCode;
            localStorage.setItem(SEESION_OBJECT, JSON.stringify(tempObj));
            localStorage.setItem(SEESION_FLAG, "true");
            sessionStorage.setItem(
              USER_TWO_FACTOR_CODE,
              res.twoFactorCode.toString()
            );
          }
        })
      );
  }

  /**
   * authenticate 2fa code
   * @param signinData signin 2fa user data
   */
  authenticate2faCode(signinData: Signin2fa): Observable<ITokenResponse> {
    return this.http.post<ITokenResponse>(
      `${this.apiUrl}/account/token`,
      signinData
    );
  }

  /**
   * Get Signin data emitter
   */
  getSigninDataEmitter(): Observable<Signin> {
    return this.signinDataEmitter.asObservable();
  }

  /**
   * Emit signin data
   */
  emitSigninData(data: Signin): void {
    this.signinDataEmitter.next(data);
  }
  /**
   * Get Signin data emitter
   */
  disableCrossEmitter(): Observable<boolean> {
    return this.disableCrossFlag.asObservable();
  }

  /**
   * Emit signin data
   */
  setDisableCross(flag: boolean): void {
    this.disableCrossFlag.next(flag);
  }

  /**
   * white list a device
   * @param whitelistData whitelistdata
   */
  whitelistDevice(
    whitelistData: WhitelistDevice
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.get<{ statusCode: number; message: string }>(
      `${this.apiUrl}/account/whitelist?whitelistId=${whitelistData.deviceId}`
    );
  }

  isAgreementApproved(): Observable<{ statusCode: number; message: string }> {
      return this.http.post<{ statusCode: number; message: string }>(
        `${this.apiUrl}/account/isagreementapproved`,
        {isAgreementApproved : true}
      )
  }
  isTwoFactorEnabled(): any {
    return this.http.get(`${this.apiUrl}/account/getusertwofa`);
  }
}



