<cashaa-sidenav>
  <section class="mh-100">
    <div class="bg-white p-4">
      <h4 class="font-weight-bold sm-profile-heading-mobile-2">Stake & Earn</h4>
    </div>
    <img style="width: 100%;" src="../../../assets/images/Staking_Banner.jpg" />
  </section>

  <div class="p-4 text-center" [hidden]="!isLoading">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
  </div>
  <div class="staking-container pl-4 pr-4">
    <div class="staking-card mr-4" style="width: 418px;">
      <img src="../../../assets/media/globalNetwork.png" />
      <h4 class="font-w-500 pt-4">Stake only</h4>
      <h5 class="font-w-400">(worldwide)</h5>
      <button mdbBtn type="button" color="primary" mdbWavesEffect (click)="addStaking()" class="btn-sm">
        Stake Now
      </button>
    </div>
    <h3 style="padding-top: 100px;">or</h3>
    <div class="staking-card ml-4">
      <img src="../../../assets/media/accounting.png" />
      <h4 class="font-w-500 pt-4">Stake & open a USD account</h4>
      <h5 class="font-w-400">(USA & Canadian citizens only)</h5>
      <button mdbBtn type="button" color="primary" mdbWavesEffect (click)="addStakingUSD()" class="btn-sm">
        Stake & apply for USD account
      </button>
    </div>

  </div>


  <!-- LEDGER LIST -->
</cashaa-sidenav>