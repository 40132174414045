<!--test new-->
<mdb-accordion>
  <mdb-accordion-item [collapsed]='isCollapsed'>
    <mdb-accordion-item-head [customClass]="isOpen ? 'pl-4' : 'settings-card pl-4'" (click)="isOpen = !isOpen">
      <div class="d-flex align-items-center">
        <!-- <mdb-icon fas icon="mobile-alt" class="p-2 card-icon" [ngClass]="isOpen ? '' : 'bg-white'"></mdb-icon> -->
        <img style="height: 51px;width: 57px;margin: -10px" [src]="'../../../../assets/images/Subscription.png'" />
        <div class="ml-4 setting-card-open">
          <h6 class="mb-1 font-weight-bolder">Subscription Plan</h6>
          <span class="mb-0 font-weight-normal">
            Status:
            <span class="green-text" *ngIf="!status">
              Blue
            </span>
            <span class="green-text" *ngIf="status">
              Premier
            </span>
          </span>
        </div>
      </div>
    </mdb-accordion-item-head>
  </mdb-accordion-item>
</mdb-accordion>