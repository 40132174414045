<cashaa-sidenav>
  <section class="mh-100">
    <div class="bg-white p-4 pb-5">
    </div>
  </section>
  <div class="d-flex ml-5 mr-5 mt-4">
    <h6 class="font-weight-bold text-primary mb-3">
    Currency Wallet
    </h6>
    <div class="custom-control custom-switch ml-auto">
      <span class="font-weight-500">Hide currencies with 0 balance</span>
      <input type="checkbox" class="custom-control-input" id="customSwitches" [(ngModel)]="hideZeroBalance"
        (click)="filterNonZeroBalance()">
      <label class="custom-control-label" for="customSwitches"></label>

    </div>
  </div>
  <section class="banks fiat-section pl-5 pr-5 mb-4  sm-width-phone-700" *ngIf="localBankFiatCurrencyList.length > 0 && !loading">

    <mdb-accordion *ngFor="let item of localBankFiatCurrencyList; let i = index">
      <mdb-accordion-item #collapses [ngClass]="selectedLocalBank == i ? '' : 'bg-unset'">
        <!-- <mdb-accordion-item-head (click)="
            getAccountDetailsByCurrency($event,item, i);
            selectedLocalBank == i
              ? (selectedLocalBank = -1)
              : (selectedLocalBank = i)
          "> -->
        <div class="d-flex flex-row px-4 py-2" [id]="item.currencyType">
          <img class="mr-3 flag-icon" [src]="'../../../../assets/icons/' + item.currencyType + '.png'" />
          <div class=" text-center">
            <h5 class="text-dark font-weight-bolder">
              {{ item.available | number: "0.0-4" }} {{ item.currencyType }}
            </h5>
          </div>
          <button type="submit" [disabled]="false" mdbBtn class="bal-btn-design earn-upto btn-sm m-2">Earn up to</button>
          <button type="submit" [disabled]="!item.isDepositActive" mdbBtn
            class="bal-btn-design deposit waves-light btn-sm m-2" size="lg" mdbWavesEffect>Deposit</button>
          <button type="submit" [disabled]="true" mdbBtn class="bal-btn-design convert-buy-sell btn-sm m-2" size="lg"
            mdbWavesEffect>Convert (Buy/Sell)</button>
          <button type="submit" [disabled]="!item.isSendActive" mdbBtn
            class="bal-btn-design send waves-light btn-sm m-2" size="lg" mdbWavesEffect>Send</button>
        </div>
        <!-- </mdb-accordion-item-head> -->

        <mdb-accordion-item-body>
          <div class="px-5 mx-5"
            *ngIf="!localBankLoading && ((isPersonalKycVerified && userType === '4') || (isBusinessKycVerified && (userType === '5' || userType === '6')))">
            <hr class="mb-4" />
            <!-- <div *ngIf="(accountDetailsByCurrencyData?.symbol !=='USD' ||item.currencyType !=='GBP' || item.currencyType !=='EUR') && accountDetailsByCurrencyData?.kybStatus===1 && 
              accountDetailsByCurrencyData?.isCashaaActive === true && !errorMessage">

              <p class="font-weight-bold text-primary-dark">
                Your Bank details to receive {{ item.currencyType }}
              </p>
              <p class="text-dark font-weight-bolder">
                User your bank details to receive {{ item.currencyType }} from
                friends and business.
              </p>
              <a href="#" class="mb-4"><u>How do i use these?</u></a>

              <p class="p-3 background mt-4">
                Heads: You can't use your {{ item.currencyType }} bank details to
                set up direct debits, get some merchants charge a fee for
                rejected debits,so please don't set up right now.
              </p>
              <div class="background p-3">
                <div class="row mb-4">
                  <div class="col-md-3 mb-2 font-weight-bolder text-primary-dark">
                    <p class="text-dark mb-1">Account Holder</p>
                    {{ accountDetailsByCurrencyData.name }}
                  </div>
                  <div class="col-md-9 mb-2 font-weight-bolder text-primary-dark">
                    <p class="text-dark mb-1">Account number</p>

                    {{ accountDetailsByCurrencyData.accountNumber }}
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-3 mb-2 font-weight-bolder text-primary-dark">

                    <p class="text-dark mb-1">IBAN(to receive GBP
                      from UK only)</p>
                    {{ accountDetailsByCurrencyData.iban }}

                  </div>
                  <div class="col-md-9 mb-2 font-weight-bolder text-primary-dark">
                    <p class="text-dark font-weight-bolder mb-1">
                      UK sort code
                    </p>
                    {{ accountDetailsByCurrencyData.sortCode }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3 mb-2 font-weight-bolder text-primary-dark">
                    <p class="text-dark mb-1">Address</p>
                    {{ accountDetailsByCurrencyData.address1 }}
                    <br *ngIf="accountDetailsByCurrencyData.address1" />
                    {{ accountDetailsByCurrencyData.address2 }}
                    <br *ngIf="accountDetailsByCurrencyData.address2" />
                    {{ accountDetailsByCurrencyData.postTown }}
                    <br *ngIf="accountDetailsByCurrencyData.postTown" />
                    {{ accountDetailsByCurrencyData.postalCode }}
                    <br *ngIf="accountDetailsByCurrencyData.postalCode" />
                    {{ accountDetailsByCurrencyData.country }}
                  </div>
                  <div class="col-md-9 mb-2 font-weight-bolder text-primary-dark">
                    <p *ngIf="accountDetailsByCurrencyData.symbol !=='USD'" class="text-dark mb-1">BIC code</p>
                    {{ accountDetailsByCurrencyData.destinationBic}}
                  </div>
                </div>
              </div>
            </div> -->
            <div class="position-relative" *ngIf="errorMessage && accountDetailsByCurrencyData?.isCashaaActive">
              <mdb-error class="mt-2">{{ errorMessage }}</mdb-error>
            </div>
            <!-- <div
              *ngIf="usdKycStatus !== 7 && !(item.currencyType ==='USD' ||item.currencyType ==='GBP' || item.currencyType ==='EUR') "
              class="background px-5 py-4">
              <h4 class="text-dark mb-4">
                Get your {{ item.currencyType }} account details
              </h4>
              <p>You will receive an account number and sort code with a Cashaa
                {{item.currencyType}} account</p>

              <div style=" color: #2340c9 !important;">
                <a href="https://calendly.com/archit-cashaa/book-a-meeting" target="_blank">Contact Us</a>
              </div>
              <a href="javaScript:void(0);" style="display: none;">What can i do with bank details?</a>
              <div
                *ngIf="accountDetailsByCurrencyData?.kybStatus===1 && accountDetailsByCurrencyData?.isCashaaActive === false && userType === '5'">
                <button class="btn btn-outline-primary rounded text-primary btn-sm mt-2"
                  (click)="onAccountReadyPage(item, 'Fiat')">
                  Get bank details
                </button>
              </div>
            </div> -->

            <div class="d-flex">
              <div class="text-left mr-4 mt-4">
                <span [ngClass]="{'sm-disable-span':item.available===0 || isDisable(item.currencyType)}"
                  [title]="getTootlTip(item.available,item.currencyType)">
                  <button [disabled]="isDisable(item.currencyType)"
                    [ngClass]="{'sm-disable-btn':isDisable(item.currencyType)}"
                    class="sm-btn-input-hover btn-border btn-sm btn rounded mr-2"
                    (click)="onAddClick(item.currencyType)">
                    Deposit
                  </button>
                </span>
                <span [ngClass]="{'sm-disable-span':item.available===0}"
                  [title]="getTootlTip(item.available,item.currencyType)">
                  <button [disabled]="item.available===0" [ngClass]="{'sm-disable-btn':item.available===0}"
                    class="sm-btn-input-hover btn-border btn-sm btn rounded mr-2"
                    (click)="onConvertClick(item.currencyType)">
                    Convert
                  </button>
                </span>
                <span [ngClass]="{'sm-disable-span':item.available===0 || isSendDisable(item.currencyType)}"
                  [title]="getTootlTip(item.available,item.currencyType)">
                  <button [disabled]="item.available===0 || isSendDisable(item.currencyType)"
                    [ngClass]="{'sm-disable-btn':item.available===0 || isSendDisable(item.currencyType)}"
                    class="sm-btn-input-hover btn-border btn-sm btn rounded mr-2"
                    (click)="onSendClick(item.currencyType)">
                    Send {{ item.currencyType }}
                  </button>
                </span>
                <span *ngIf="isEarnAndFixedDepositActive() && (item.earnRate && item.earnRate > 0)"
                  [ngClass]="{'sm-disable-span':item.available===0}"
                  [title]="getEarnTootlTip(item.available,item.currencyType)">
                  <button [disabled]="item.available===0" [ngClass]="{'sm-disable-btn':item.available===0}"
                    class="sm-btn-input-hover btn-border btn-sm btn rounded mr-2"
                    (click)="onDepositInterestClick(item)">
                    Earn up to {{item.earnRate}} %
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div class="px-5 mx-5"
            *ngIf="!isPersonalKycVerified && userType === '4' && (item.currencyType === 'GBP' || item.currencyType === 'EUR' || item.currencyType === 'USD')">
            <span>
              <h4 class="text-dark font-weight-bolder">To Comply with KYC & AML regulations, we request to complete your
                "Full KYC" for FIAT Deposit.</h4>
            </span>
            <div class="m-2 p-2 d-flex justify-content-center">
              <button mdbBtn block="true" type="button"
                class="w-25 sm-width-phone-700 rounded-sm btn btn-primary waves-effect waves-light btn-block"
                mdbWavesEffect routerLink="/kyc/personal">
                Click here to complete "Full KYC"
              </button>
            </div>
          </div>
          <div class="px-5 mx-5"
            *ngIf="!isBusinessKycVerified && (userType === '5' || userType === '6') && (item.currencyType === 'GBP' || item.currencyType === 'EUR' || item.currencyType === 'USD')">
            <span>
              <h4 class="text-dark font-weight-bolder">To Comply with KYC & AML regulations, we request to complete your
                "Business KYC" for FIAT transactions.</h4>
            </span>
            <div class="m-2 p-2 d-flex justify-content-center">
              <button mdbBtn block="true" type="button"
                class="rounded-sm btn btn-primary waves-effect waves-light sm-width-phone-700 btn-block" mdbWavesEffect
                routerLink="/subscription/plan" style="width: 35%;">
                Click here to choose your plan and complete "Business KYC"
              </button>
            </div>
          </div>
          <div class="px-5 mx-5 text-center" *ngIf="localBankLoading">
            <mdb-spinner spinnerColor="blue"></mdb-spinner>
          </div>
        </mdb-accordion-item-body>
      </mdb-accordion-item>
    </mdb-accordion>
  </section>

  <section class="banks crypto-section p-5 mt-4" *ngIf="cryptoBankFiatCurrencyList.length > 0 && !loading">
    <h6 class="font-weight-bold text-primary mb-3">
      Cryptocurrency Wallet
    </h6>
    <mdb-accordion *ngFor="let item of cryptoBankFiatCurrencyList; let idx = index">
      <mdb-accordion-item #collapsesCrypto [ngClass]="selectedCrypto == idx ? '' : 'bg-unset'">
        <!-- <mdb-accordion-item-head (click)="
            getCryptoAccountDetails($event, item, idx);
            selectedCrypto == idx
              ? (selectedCrypto = -1)
              : (selectedCrypto = idx)
          "> -->
        <div class="d-flex flex-row px-4 py-2" [id]="item.currencyType">
          <img class="mr-3 flag-icon" [src]="'../../../../assets/icons/' + item.currencyType + '.png'" />
          <div class=" text-center">
            <h5 class="text-dark font-weight-bolder">
              {{ item.available | number: "0.0-4" }} {{ getCurrencyName(item.currencyType) }}
            </h5>
            <!-- <p class="h6 text-dark font-weight-normal">
                {{ item.currencyType }} Test1
              </p> -->
          </div>
          <button type="submit" [disabled]="false" mdbBtn class="bal-btn-design earn-upto btn-sm m-2">Earn Upto</button>
          <button type="submit" [disabled]="!item.isDepositActive" mdbBtn
            class="bal-btn-design deposit waves-light btn-sm m-2" size="lg" mdbWavesEffect>Deposit</button>
          <button type="submit" [disabled]="true" mdbBtn class="bal-btn-design convert-buy-sell btn-sm m-2" size="lg"
            mdbWavesEffect>Convert (Buy/Sell)</button>
          <button type="submit" [disabled]="!item.isSendActive" mdbBtn
            class="bal-btn-design send waves-light btn-sm m-2" size="lg" mdbWavesEffect>Send</button>
        </div>
        <!-- </mdb-accordion-item-head> -->
        <mdb-accordion-item-body>
          <div class="px-5 mx-5">
            <mdb-tabset #staticTabs [buttonClass]="'nav md-pills pills-primary mb-2'" [justified]="true"
              class="recipientTab">
              <mdb-tab heading={{getTabHeaderData1(item.currencyType)}}>
                <div class="background px-5 py-4" *ngIf="!cryptoLoading && !accountDetailsCryptoData">
                  <h4 class="text-dark mb-4 font-weight-bolder">
                    Get your {{ getCurrencyName(item.currencyType) }} address
                  </h4>
                  <p class="text-dark font-weight-normal">
                    With {{ getCurrencyName(item.currencyType) }} address, people can send
                    {{ getCurrencyName(item.currencyType) }} to your account.
                  </p>
                  <div class="mt-4">
                    <button class="btn btn-outline-primary rounded text-primary btn-sm"
                      (click)="onAccountReadyPage(item, 'Crypto')">
                      Get address
                    </button>
                  </div>
                </div>
                <div class="px-5 mx-5 mt-3 text-center" *ngIf="cryptoLoading">
                  <mdb-spinner spinnerColor="blue"></mdb-spinner>
                </div>
                <div *ngIf="accountDetailsCryptoData">
                  <div class="py-4 pb-0">
                    <p class="font-weight-bold text-primary-dark">
                      Your Blockchain details to receive {{ item.currencyType }}
                    </p>
                    <p class="font-weight-normal text-dark">
                      Use your {{ item.currencyType }} address to receive
                      {{ item.currencyType }}.
                    </p>
                  </div>
                  <div class="row background px-5 py-4">
                    <div class="d-flex justify-content-center w-100">
                      <div class="w-75">
                        <h5 class="text-dark mb-3 font-weight-bolder">
                          {{ item.currencyType }} deposit
                        </h5>
                      </div>
                      <div class="f-shrink-1 p-2 w-10" style="background-color: white;"
                        *ngIf="item.currencyType === 'BNB' || item.currencyType==='CAS' || item.currencyType==='XRP'">
                        <span class="mb-3 font-weight-normal">
                          <mdb-icon far icon="question-circle"></mdb-icon> <a class="p-2" target="_blank"
                            routerLinkActive="customactive"
                            href="https://help.cashaa.com/knowledgebase/how-to-deposit-cryptocurrencies-to-your-cashaa-account/">How
                            to deposit?</a>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="mb-5">
                        <div class="input-group mb-3 sm-nowrap">
                          <div class="sm-qr-box sm-padding-qr-box">
                            <div class="sm-text-center">
                              <h4 class="sm-heading-text">Address</h4>
                            </div>
                            <div class="sm-qr-box-inner">
                              <qr-code [size]="200" [value]="accountDetailsCryptoData.address"></qr-code>
                            </div>
                            <div class="sm-qr-input-box">
                              <span class="sm-spam-text sm-span-text-padding">{{accountDetailsCryptoData.address
                                }}</span>
                              <div class="input-group-append">
                                <span class="sm-input-group-text"><i mdbTooltip="Copy address" placement="top"
                                    class="fas fa-clone sm-copy-color" [ngClass]="copied ? 'text-primary' : ''"
                                    (click)="copyAddress()"></i></span>
                              </div>
                            </div>
                          </div>
                          <div
                            *ngIf="item.currencyType == 'CAS' || item.currencyType == 'BNB' || item.currencyType == 'XRP'"
                            class="input-group mb-3 pl-4 sm-border-line">
                            <div class="sm-qr-box">
                              <div class="sm-text-center">
                                <h4 class="sm-heading-text">{{item.currencyType == 'XRP' ? 'TAG': 'MEMO' }}</h4>
                              </div>
                              <div class="sm-qr-box-inner">
                                <qr-code [size]="200" [value]="accountDetailsCryptoData.memo"></qr-code>
                              </div>
                              <div class="sm-qr-input-box">
                                <span class="sm-spam-text">{{accountDetailsCryptoData.memo}}</span>
                                <div class="input-group-append">
                                  <span class="sm-input-group-text"><i mdbTooltip="Copy memo" placement="top"
                                      class="fas fa-clone sm-copy-color" [ngClass]="copiedMemo ? 'text-primary' : ''"
                                      (click)="copyMemoAddress()"></i></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p class="text-dark font-weight-bolder">Important</p>
                      <ul class="text-dark font-weight-bolder ul-not">
                        <li>
                          Send only {{getContentData(item.currencyType)}} to this
                          address. Sending
                          any other coins or token to this address may result in the
                          loss of your deposit.
                        </li>
                        <li>
                          Coins will be deposited immediately after 3 network
                          confirmation.
                        </li>
                        <li>
                          After making a deposit, you can track its progress on the
                          reports.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </mdb-tab>
              <mdb-tab heading="BEP-20" *ngIf="!(item.currencyType === 'BTC' || item.currencyType === 'BNB')">
                <div class="background px-5 py-4" *ngIf="!cryptoLoading && !accountDetailsETHCryptoData">
                  <h4 class="text-dark mb-4 font-weight-bolder">
                    Get your {{ getCurrencyName(item.currencyType) }} address
                  </h4>
                  <p class="text-dark font-weight-normal">
                    With {{ getCurrencyName(item.currencyType) }} address, people can send
                    {{ getCurrencyName(item.currencyType) }} to your account.
                  </p>
                  <div class="mt-4">
                    <button class="btn btn-outline-primary rounded text-primary btn-sm"
                      (click)="onAccountReadyPage(item, 'Crypto', 'BEP-20')">
                      Get address
                    </button>
                  </div>
                </div>
                <div class="px-5 mx-5 mt-3 text-center" *ngIf="cryptoLoading">
                  <mdb-spinner spinnerColor="blue"></mdb-spinner>
                </div>
                <div *ngIf="accountDetailsETHCryptoData">
                  <div class="py-4 pb-0">
                    <p class="font-weight-bold text-primary-dark">
                      Your Blockchain details to receive {{ item.currencyType }}
                    </p>
                    <p class="font-weight-normal text-dark">
                      Use your {{ item.currencyType }} address to receive
                      {{ item.currencyType }}.
                    </p>
                  </div>
                  <div class="row background px-5 py-4">
                    <div class="d-flex justify-content-center w-100">
                      <div class="w-75">
                        <h5 class="text-dark mb-3 font-weight-bolder">
                          {{ item.currencyType }} deposit
                        </h5>
                      </div>
                      <div class="f-shrink-1 p-2 w-10" style="background-color: white;"
                        *ngIf="item.currencyType === 'BNB' || item.currencyType==='CAS' || item.currencyType==='XRP'">
                        <span class="mb-3 font-weight-normal">
                          <mdb-icon far icon="question-circle"></mdb-icon> <a class="p-2" target="_blank"
                            routerLinkActive="customactive"
                            href="https://help.cashaa.com/knowledgebase/how-to-deposit-cryptocurrencies-to-your-cashaa-account/">How
                            to deposit?</a>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="mb-5">
                        <div class="input-group mb-3 sm-nowrap">
                          <div class="sm-qr-box sm-padding-qr-box">
                            <div class="sm-text-center">
                              <h4 class="sm-heading-text">Address</h4>
                            </div>
                            <div class="sm-qr-box-inner">
                              <qr-code [size]="200" [value]="accountDetailsETHCryptoData.address"></qr-code>
                            </div>
                            <div class="sm-qr-input-box">
                              <span class="sm-spam-text sm-span-text-padding">{{accountDetailsETHCryptoData.address
                                }}</span>
                              <div class="input-group-append">
                                <span class="sm-input-group-text"><i mdbTooltip="Copy address" placement="top"
                                    class="fas fa-clone sm-copy-color" [ngClass]="copied ? 'text-primary' : ''"
                                    (click)="copyAddressETH()"></i></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p class="text-dark font-weight-bolder">Important</p>
                      <ul class="text-dark font-weight-bolder ul-not">
                        <li>
                          Send only {{item.currencyType}} (BEP-20) to this
                          address. Sending
                          any other coins or token to this address may result in the
                          loss of your deposit.
                        </li>
                        <li>
                          Coins will be deposited immediately after 3 network
                          confirmation.
                        </li>
                        <li>
                          After making a deposit, you can track its progress on the
                          reports.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </mdb-tab>
              <mdb-tab heading="Polygon" *ngIf="item.currencyType === 'CAS'">
                <div class="background px-5 py-4" *ngIf="!cryptoLoading && !accountDetailsETHCryptoData">
                  <h4 class="text-dark mb-4 font-weight-bolder">
                    Get your {{ getCurrencyName(item.currencyType) }} address
                  </h4>
                  <p class="text-dark font-weight-normal">
                    With {{ getCurrencyName(item.currencyType) }} address, people can send
                    {{ getCurrencyName(item.currencyType) }} to your account.
                  </p>
                  <div class="mt-4">
                    <button class="btn btn-outline-primary rounded text-primary btn-sm"
                      (click)="onAccountReadyPage(item, 'Crypto', 'BEP-20')">
                      Get address
                    </button>
                  </div>
                </div>
                <div class="px-5 mx-5 mt-3 text-center" *ngIf="cryptoLoading">
                  <mdb-spinner spinnerColor="blue"></mdb-spinner>
                </div>
                <div *ngIf="accountDetailsETHCryptoData">
                  <div class="py-4 pb-0">
                    <p class="font-weight-bold text-primary-dark">
                      Your Blockchain details to receive {{ item.currencyType }}
                    </p>
                    <p class="font-weight-normal text-dark">
                      Use your {{ item.currencyType }} address to receive
                      {{ item.currencyType }}.
                    </p>
                  </div>
                  <div class="row background px-5 py-4">
                    <div class="d-flex justify-content-center w-100">
                      <div class="w-75">
                        <h5 class="text-dark mb-3 font-weight-bolder">
                          {{ item.currencyType }} deposit
                        </h5>
                      </div>
                      <div class="f-shrink-1 p-2 w-10" style="background-color: white;"
                        *ngIf="item.currencyType === 'BNB' || item.currencyType==='CAS' || item.currencyType==='XRP'">
                        <span class="mb-3 font-weight-normal">
                          <mdb-icon far icon="question-circle"></mdb-icon> <a class="p-2" target="_blank"
                            routerLinkActive="customactive"
                            href="https://help.cashaa.com/knowledgebase/how-to-deposit-cryptocurrencies-to-your-cashaa-account/">How
                            to deposit?</a>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="mb-5">
                        <div class="input-group mb-3 sm-nowrap">
                          <div class="sm-qr-box sm-padding-qr-box">
                            <div class="sm-text-center">
                              <h4 class="sm-heading-text">Address</h4>
                            </div>
                            <div class="sm-qr-box-inner">
                              <qr-code [size]="200" [value]="accountDetailsETHCryptoData.address"></qr-code>
                            </div>
                            <div class="sm-qr-input-box">
                              <span class="sm-spam-text sm-span-text-padding">{{accountDetailsETHCryptoData.address
                                }}</span>
                              <div class="input-group-append">
                                <span class="sm-input-group-text"><i mdbTooltip="Copy address" placement="top"
                                    class="fas fa-clone sm-copy-color" [ngClass]="copied ? 'text-primary' : ''"
                                    (click)="copyAddressETH()"></i></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p class="text-dark font-weight-bolder">Important</p>
                      <ul class="text-dark font-weight-bolder ul-not">
                        <li>
                          Send only CAS (Polygon) to this
                          address. Sending
                          any other coins or token to this address may result in the
                          loss of your deposit.
                        </li>
                        <li>
                          Coins will be deposited immediately after 3 network
                          confirmation.
                        </li>
                        <li>
                          After making a deposit, you can track its progress on the
                          reports.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </mdb-tab>
            </mdb-tabset>
            <div class="d-flex">
              <div class="text-left mt-3">
                <span [ngClass]="{'sm-disable-span':item.available===0 || isCryptoDisable(item.currencyType)}"
                  [title]="getTootlTip(item.available,item.currencyType)">
                  <button [disabled]="item.available===0 || isCryptoDisable(item.currencyType)"
                    [ngClass]="{'sm-disable-btn':item.available===0 || isCryptoDisable(item.currencyType)}"
                    class="sm-btn-input-hover btn-border btn-sm btn rounded mr-2"
                    (click)="onConvertClick(item.currencyType)">
                    Convert
                  </button>
                </span>
                <span [ngClass]="{'sm-disable-span':item.available===0 || isCryptoSendDisable(item.currencyType)}"
                  [title]="getTootlTip(item.available,item.currencyType)">
                  <button [disabled]="item.available===0 || isCryptoSendDisable(item.currencyType)"
                    [ngClass]="{'sm-disable-btn':item.available===0 || isCryptoSendDisable(item.currencyType)}"
                    class="sm-btn-input-hover btn-border btn-sm btn rounded mr-2"
                    (click)="onSendClick(item.currencyType)">
                    Send {{ item.currencyType }}
                  </button>
                </span>
                <span *ngIf="isEarnAndFixedDepositActive() && ((item.currencyType === 'DOG' && userType === '4') || item.currencyType !== 'DOG') && 
                  (item.earnRate && item.earnRate > 0)" [ngClass]="{'sm-disable-span':item.available===0}"
                  [title]="getEarnTootlTip(item.available,item.currencyType)">
                  <button [disabled]="item.available===0" [ngClass]="{'sm-disable-btn':item.available===0}"
                    class="sm-btn-input-hover btn-border btn-sm btn rounded mr-2"
                    (click)="onDepositInterestClick(item)">
                    Earn up to {{item.earnRate}} %
                  </button>
                </span>
              </div>
            </div>
          </div>

          <!--Second container for QR code and BTC Deposit Address-->
          <!-- <div class="px-5 mx-5 mt-3" *ngIf="accountDetailsCryptoData && !cryptoLoading">
            <mdb-tabset #staticTabs [buttonClass]="'nav md-pills pills-primary mb-2'" [justified]="true"
              class="recipientTab">
              <mdb-tab heading={{getTabHeaderData1(item.currencyType)}}>
                <div class="py-4 pb-0">
                  <p class="font-weight-bold text-primary-dark">
                    Your Blockchain details to receive {{ item.currencyType }}
                  </p>
                  <p class="font-weight-normal text-dark">
                    Use your {{ item.currencyType }} address to receive
                    {{ item.currencyType }}.
                  </p>
                </div>
                <div class="row background px-5 py-4">
                  <div class="d-flex justify-content-center w-100">
                    <div class="w-75">
                      <h5 class="text-dark mb-3 font-weight-bolder">
                        {{ item.currencyType }} deposit
                      </h5>
                    </div>
                    <div class="f-shrink-1 p-2 w-10" style="background-color: white;"
                      *ngIf="item.currencyType === 'BNB' || item.currencyType==='CAS' || item.currencyType==='XRP'">
                      <span class="mb-3 font-weight-normal">
                        <mdb-icon far icon="question-circle"></mdb-icon> <a class="p-2" target="_blank"
                          routerLinkActive="customactive"
                          href="https://help.cashaa.com/knowledgebase/how-to-deposit-cryptocurrencies-to-your-cashaa-account/">How
                          to deposit?</a>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class="mb-5">
                      <div class="input-group mb-3 sm-nowrap">
                        <div class="sm-qr-box sm-padding-qr-box">
                          <div class="sm-text-center">
                            <h4 class="sm-heading-text">Address</h4>
                          </div>
                          <div class="sm-qr-box-inner">
                            <qr-code [size]="200" [value]="accountDetailsCryptoData.address"></qr-code>
                          </div>
                          <div class="sm-qr-input-box">
                            <span class="sm-spam-text sm-span-text-padding">{{accountDetailsCryptoData.address }}</span>
                            <div class="input-group-append">
                              <span class="sm-input-group-text"><i mdbTooltip="Copy address" placement="top"
                                  class="fas fa-clone sm-copy-color" [ngClass]="copied ? 'text-primary' : ''"
                                  (click)="copyAddress()"></i></span>
                            </div>
                          </div>
                        </div>
                        <div
                          *ngIf="item.currencyType == 'CAS' || item.currencyType == 'BNB' || item.currencyType == 'XRP'"
                          class="input-group mb-3 pl-4 sm-border-line">
                          <div class="sm-qr-box">
                            <div class="sm-text-center">
                              <h4 class="sm-heading-text">{{item.currencyType == 'XRP' ? 'TAG': 'MEMO' }}</h4>
                            </div>
                            <div class="sm-qr-box-inner">
                              <qr-code [size]="200" [value]="accountDetailsCryptoData.memo"></qr-code>
                            </div>
                            <div class="sm-qr-input-box">
                              <span class="sm-spam-text">{{accountDetailsCryptoData.memo}}</span>
                              <div class="input-group-append">
                                <span class="sm-input-group-text"><i mdbTooltip="Copy memo" placement="top"
                                    class="fas fa-clone sm-copy-color" [ngClass]="copiedMemo ? 'text-primary' : ''"
                                    (click)="copyMemoAddress()"></i></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p class="text-dark font-weight-bolder">Important</p>
                    <ul class="text-dark font-weight-bolder">
                      <li>
                        Send only {{item.currencyType}} {{ (item.currencyType === 'USDT' || item.currencyType === 'ETH')
                        ?
                        ' (ERC-20)' : ''}} to this
                        address. Sending
                        any other coins or token to this address may result in the
                        loss of your deposit.
                      </li>
                      <li>
                        Coins will be deposited immediately after 3 network
                        confirmation.
                      </li>
                      <li>
                        After making a deposit, you can track its progress on the
                        reports.
                      </li>
                    </ul>
                  </div>
                </div>
              </mdb-tab>
              <mdb-tab heading="BEP-20" *ngIf="item.currencyType !== 'BTC'">
                <div class="py-4 pb-0">
                  <p class="font-weight-bold text-primary-dark">
                    Your Blockchain details to receive {{ item.currencyType }}
                  </p>
                  <p class="font-weight-normal text-dark">
                    Use your {{ item.currencyType }} address to receive
                    {{ item.currencyType }}.
                  </p>
                </div>
                <div class="row background px-5 py-4">
                  <div class="d-flex justify-content-center w-100">
                    <div class="w-75">
                      <h5 class="text-dark mb-3 font-weight-bolder">
                        {{ item.currencyType }} deposit
                      </h5>
                    </div>
                    <div class="f-shrink-1 p-2 w-10" style="background-color: white;"
                      *ngIf="item.currencyType === 'BNB' || item.currencyType==='CAS' || item.currencyType==='XRP'">
                      <span class="mb-3 font-weight-normal">
                        <mdb-icon far icon="question-circle"></mdb-icon> <a class="p-2" target="_blank"
                          routerLinkActive="customactive"
                          href="https://help.cashaa.com/knowledgebase/how-to-deposit-cryptocurrencies-to-your-cashaa-account/">How
                          to deposit?</a>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class="mb-5">
                      <div class="input-group mb-3 sm-nowrap">
                        <div class="sm-qr-box sm-padding-qr-box">
                          <div class="sm-text-center">
                            <h4 class="sm-heading-text">Address</h4>
                          </div>
                          <div class="sm-qr-box-inner">
                            <qr-code [size]="200" [value]="accountDetailsETHCryptoData.address"></qr-code>
                          </div>
                          <div class="sm-qr-input-box">
                            <span class="sm-spam-text sm-span-text-padding">{{accountDetailsETHCryptoData.address }}</span>
                            <div class="input-group-append">
                              <span class="sm-input-group-text"><i mdbTooltip="Copy address" placement="top"
                                  class="fas fa-clone sm-copy-color" [ngClass]="copied ? 'text-primary' : ''"
                                  (click)="copyAddress()"></i></span>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="item.currencyType == 'CAS' || item.currencyType == 'BNB' || item.currencyType == 'XRP'"
                          class="input-group mb-3 pl-4 sm-border-line">
                          <div class="sm-qr-box">
                            <div class="sm-text-center">
                              <h4 class="sm-heading-text">{{item.currencyType == 'XRP' ? 'TAG': 'MEMO' }}</h4>
                            </div>
                            <div class="sm-qr-box-inner">
                              <qr-code [size]="200" [value]="accountDetailsETHCryptoData.memo"></qr-code>
                            </div>
                            <div class="sm-qr-input-box">
                              <span class="sm-spam-text">{{accountDetailsETHCryptoData.memo}}</span>
                              <div class="input-group-append">
                                <span class="sm-input-group-text"><i mdbTooltip="Copy memo" placement="top"
                                    class="fas fa-clone sm-copy-color" [ngClass]="copiedMemo ? 'text-primary' : ''"
                                    (click)="copyMemoAddress()"></i></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p class="text-dark font-weight-bolder">Important</p>
                    <ul class="text-dark font-weight-bolder">
                      <li>
                        Send only {{item.currencyType}} {{ (item.currencyType === 'USDT' || item.currencyType === 'ETH') ?
                        ' (ERC-20)' : ''}} to this
                        address. Sending
                        any other coins or token to this address may result in the
                        loss of your deposit.
                      </li>
                      <li>
                        Coins will be deposited immediately after 3 network
                        confirmation.
                      </li>
                      <li>
                        After making a deposit, you can track its progress on the
                        reports.
                      </li>
                    </ul>
                  </div>
                </div>
              </mdb-tab>
            </mdb-tabset>
            <div class="d-flex">
              <div class="text-left mt-3">
                <span
                  [ngClass]="{'sm-disable-span':item.available===0 || isCryptoDisable(item.currencyType)}"
                  [title]="getTootlTip(item.available,item.currencyType)">
                  <button [disabled]="item.available===0 || isCryptoDisable(item.currencyType)"
                    [ngClass]="{'sm-disable-btn':item.available===0 || isCryptoDisable(item.currencyType)}"
                    class="sm-btn-input-hover btn-border btn-sm btn rounded mr-2"
                    (click)="onConvertClick(item.currencyType)">
                    Convert
                  </button>
                </span>
                <span [ngClass]="{'sm-disable-span':item.available===0 || isCryptoDisable(item.currencyType)}"
                  [title]="getTootlTip(item.available,item.currencyType)">
                  <button [disabled]="item.available===0 || isCryptoDisable(item.currencyType)"
                    [ngClass]="{'sm-disable-btn':item.available===0 || isCryptoDisable(item.currencyType)}"
                    class="sm-btn-input-hover btn-border btn-sm btn rounded mr-2"
                    (click)="onSendClick(item.currencyType)">
                    Send {{ item.currencyType }}
                  </button>
                </span>
                <span *ngIf="isEarnAndFixedDepositActive() && ((item.currencyType === 'DOG' && userType === '4') || item.currencyType !== 'DOG') && 
                  (item.earnRate && item.earnRate >0)" [ngClass]="{'sm-disable-span':item.available===0}"
                  [title]="getTootlTip(item.available,item.currencyType)">
                  <button [disabled]="item.available===0" [ngClass]="{'sm-disable-btn':item.available===0}"
                    class="sm-btn-input-hover btn-border btn-sm btn rounded mr-2"
                    (click)="onDepositInterestClick(item)">
                    Earn up to {{item.earnRate}} %
                  </button>
                </span>
              </div>
            </div>
          </div> -->

          <!-- <div class="px-5 mx-5 mt-3 text-center" *ngIf="cryptoLoading">
            <mdb-spinner spinnerColor="blue"></mdb-spinner>
          </div> -->

        </mdb-accordion-item-body>
      </mdb-accordion-item>
    </mdb-accordion>
  </section>

  <section class="p-5" *ngIf="!loading">
    <h6 class="share-wd-friend mb-4">Share with your friends and family</h6>
    <div class="d-flex justify-content-center social-icon">
      <!-- <div class=""> -->
        <a href="https://www.linkedin.com/company/cashaa">
          <!-- <img src="https://content.app-us1.com/DQM74/2021/11/27/13248fd4-260e-4ddb-8142-856d2554446c.png" alt="">
           -->
           <i class="fab fa-linkedin-in"></i>
        </a>
        <a href="https://www.facebook.com/cashaaLtd/">
          <!-- <img src="https://content.app-us1.com/DQM74/2021/11/27/3eb29882-c82e-49a9-8701-e1b45e816a9e.png" alt=""> -->
          <i class="fab fa-facebook-f"></i>
        </a>
        <a href="https://twitter.com/yourCashaa">
          <!-- <img src="https://content.app-us1.com/DQM74/2021/11/27/f232a60e-ffaa-4f04-942e-809175acc2ee.png" alt=""> -->
          <i class="fab fa-twitter"></i>
        </a>
        <a href="https://www.instagram.com/yourcashaa/">
          <!-- <img src="https://content.app-us1.com/DQM74/2021/11/27/b8e32572-8381-423b-a0bd-8df6eefc2828.png" alt=""> -->
          <i class="fab fa-instagram"></i>
        </a>
        <a href="https://t.me/cashaanews/">
          <!-- <img src="https://content.app-us1.com/DQM74/2021/11/27/704817b6-4664-4135-9e83-ffa947f927b8.png" alt=""> -->
          <i class="fab fa-telegram"></i>
        </a>
      <!-- </div> -->
    </div>
  </section>

  <div class="banks p-4 text-center" *ngIf="loading">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
  </div>
</cashaa-sidenav>