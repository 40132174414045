export interface ISearchFilter {
    activityType: string;
    fromDate: string;
    toDate: string;
    symbol: Array<string>;
    searchText: string;

}

export class SearchFilter implements ISearchFilter {
    constructor(
        public activityType: string,
        public fromDate: string,
        public toDate: string,
        public symbol: Array<string>,
        public searchText: string,
    ) { }
}