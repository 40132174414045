<cashaa-sidenav>
  <section class="mh-100">
    <div class="bg-white p-4">
      <div class="ml-5 header" style="height: 46px;">
        <h6 class="heading">Refer & Earn</h6>
        <p class="font-weight-normal">
          Invite your friends to discover a better way to save with Cashaa!
        </p>
      </div>
    </div>
  </section>
  <section class="p-4 sm-width-phone-700">
    <div class="row px-4">
      <div class="col-6 bg-white">
        <div class="py-4">
          <h6 class="heading m-4">Learn how much you can earn!</h6>
          <form [formGroup]="referralCalculatorForm" class="my-4">
            <div class="mb-2 px-4">

              <cashaa-form-input name="referralInvest" [form]="referralCalculatorForm" label="How much will your referred user invest?" [control]="referralCalculatorForm.controls.referralInvest" placeholder="Enter your referred user's total investment">
              </cashaa-form-input>
              <cashaa-form-select-currency placeholder="Currency" [isFDActive]="true" class="select-currency" name="currency" label="In which currency?"
                                           [control]="referralCalculatorForm.controls.currency" source="allCurrency" (changed)="onCurrencyTypeChange($event)">
              </cashaa-form-select-currency>
          
              <label class="heading mt-2">For how long?</label>
              <cashaa-form-select-plan *ngIf="!loading" [noInput]='true' [form]="referralCalculatorForm"
                                       class="width-100" [dropdownInfoList]="interestPlanList" [currencyControl]="referralCalculatorForm.controls.planName"
                                       label="Plan Name" (changed)="selectedPlanTerm($event)" [disable]="!referralCalculatorForm.value.currency"></cashaa-form-select-plan>
              <div>
               
              </div>

              <div class="mt-5">
                <div class="switch-sec mr-0 pl-3">
                  Earn in Kind <label class="switch">
                    <input (input)="setMode()" type="checkbox"
                           [checked]="referralCalculatorForm.get('rateType').value === '1' ? false : true">
                    <span class="slider round"></span>
                  </label>
                  <span class="pr-2">
                    Earn in CAS
                  </span>
                </div>
              </div>
            </div>
          </form>
          <button mdbBtn [disabled]="referralCalculatorForm.invalid" block="true" type="button"
                  class="w-100 mt-5 rounded-sm position-relative btn btn-secondary sm-custom-sec waves-effect waves-light btn-block"
                  mdbWavesEffect (click)="calculateFixedDepositEarning()">
            Calculate
          </button>
        </div>
      </div>
      <div class="col-6 p-4 bg-app-blue">
        <div class="d-flex">
          <div class="m-1" style="flex-basis:50%;">
            <h6 class="text-white">Your Referred User’s Earnings</h6>
            <h4 class="text-white font-weight-600">${{referalInterestInUSD}}</h4>
          </div>
          <div class="bg-white ml-auto p-1" style="border-radius:4px;flex-basis: 50%;">
            <h6 class="font-weight-normal mt-1">Your Earnings</h6>
            <h4 class="text-primary-dark font-weight-600">${{interestInUSD}}</h4>
          </div>

        </div>
        <hr style="border-top: 1px solid rgba(255, 255, 255, 0.2);">
        <div>
          <h6 class="text-white">Your Referral Code</h6>
          <div class="copy-edit">
            <div class="copy-box">{{referralCode}}</div>
            <div class="send-btn text-right color_light-blue p-0 m-0">
              <img src="../../../../assets/images/refrel/swape.png" class="icon-width-16" (click)="copyReferralCode(environmentOrigin +'/account/signup?referralCode='+referralCode)"><a style="text-decoration: none;" (click)="copyReferralCode(environmentOrigin + '/account/signup?referralCode='+referralCode)">
                Copy
                link
              </a>
            </div>
          </div>
        </div>
        <h6 class="share-wd-friend mt-3 text-white">Share it on:</h6>
        <div class="socila-media-sec justify-content-center">
        <!--  <a target="_blank" href="https://www.linkedin.com/cws/share?url={{environmentOrigin}}/account/signup?referralCode={{referralCode}}">
            <div class="media-sec">
              <i class="fab fa-linkedin-in"></i>
            </div>
          </a>-->
          <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u={{environmentOrigin}}/account/signup?referralCode={{referralCode}}">
            <div class="media-sec">
              <i class="fab fa-facebook-f"></i>
            </div>
          </a>
          <a target="_blank" href="https://twitter.com/intent/tweet?url={{environmentOrigin}}/account/signup?referralCode={{referralCode}}">
            <div class="media-sec">
              <i class="fab fa-twitter"></i>
            </div>
          </a>
          <!--<a target="_blank" href="https://www.instagram.com/{{environmentOrigin}}/account/signup?referralCode={{referralCode}}">
            <div class="media-sec">
              <i class="fab fa-instagram"></i>
            </div>
          </a>-->
          <a target="_blank" href="https://t.me/share/url?url=https://cashaa.com">
            <div class="media-sec">
              <i class="fas fa-paper-plane"></i>
            </div>
          </a>
        </div>
        <img src="../../../assets/images/triangle.png" class="triangle-img" alt="">
       
        <div class="mt-4 text-start" style="font-size:12px">
          <p class="text-white">
            This calculator is for informational and reference purposes only. Rewards are calculated weekly and may change from time to time, and the rates presented above are not guaranteed for the duration of the above term.
          </p>
          <p class="text-white">*The referral rates presented are for the Diamond Level. <a routerLink="/plans">Read more</a> about the loyalty levels.</p>
        </div>
      </div>
    </div>
    <div class="p-4 h-100">
      <div class="row" *ngIf="!loader && referralInfo">
        <div class="col-md-12 p-0">
          <div class="d-flex justify-content-between bg-white">
         
            <div class="col-lg-1">
              <div class="icon-stack small-circle-box circle-box-green mt-3"><img src="../../../../assets/images/refrel/Icon-3.png"></div>
            </div>
            <div class="col-lg-3 pl-0 border-right">
              <h5 class="heading color_blue mt-3">{{referralInfo.totalReferral}}</h5>
              <p class="font-weight-normal">Total referral links shared</p>
            </div>
            <div class="col-lg-1">
              <div class="icon-stack small-circle-box  circle-box-amber mt-3"><img src="../../../../assets/images/refrel/Icon-2.png"></div>
            </div>
            <div class="col-lg-3 pl-0 border-right">
              <h5 class="heading color_blue mt-3">{{referralInfo.joined}}</h5>
              <p class="font-weight-normal">Total joined</p>
            </div>
            <div class="col-lg-1">
              <div class="icon-stack small-circle-box circle-box-blue mt-3"><img src="../../../../assets/images/refrel/Icon-4.png"></div>
            </div>
            <div class="col-lg-3 pl-0 border-right">
              <h5 class="heading color_blue mt-3">{{referralInfo.totalAmount}}</h5>
              <p class="font-weight-normal">Total amount earned</p>
            </div>
           
          </div>
        </div>
      </div>
      
      <div class="row">
        <h6 class="heading my-3 mx-1">Hold more CAS to increase your percentage of referral income</h6>
      </div>

      <div class="row my-4 p-1">
        <div class="col" [ngClass]="{'sub-hover': isBase}">
          <div class="row m-auto pt-4">
            <div class="pricingTable-header m-auto">
              <img class="plan-icon" src="../../../assets/images/plan/Base.svg" alt="">
              <span class="text-primary-dark font-weight-bold">Base</span>
            </div>
          </div>
          <div class="text-center font-weight-bold">{{planData[0].percent}}%</div>
          <h5 class="text-center plan-name-desc my-2 font-14-reg">
            You will receive {{planData[0].percent}}% of all your referred user’s earnings
          </h5>
          <div class="row my-3">
            <button *ngIf="isBase" mdbBtn type="button" class="btn-sm app-primary-btn m-auto">
              Active <i class="fa fa-check-circle"></i>
            </button>
            <button *ngIf="!isBase" mdbBtn type="button" class="btn-sm app-secondary-btn m-auto">
              Unlocked <i class="fa fa-check-circle"></i>
            </button>
          </div>
        </div>
        <div class="col" [ngClass]="{'sub-hover': isSilver}">
          <div class="row m-auto pt-4">
            <div class="pricingTable-header m-auto">
              <img class="plan-icon" src="../../../assets/images/plan/Silver.svg" alt="">
              <span class="text-primary-dark font-weight-bold">Silver</span>
            </div>
          </div>
          <div class="text-center font-weight-bold">{{planData[1].percent}}%</div>
          <h5 class="text-center plan-name-desc my-2 font-14-reg font-14-reg">
            You will receive {{planData[1].percent}}% of all your referred user’s earnings
          </h5>
          <div class="row my-3">
            <button *ngIf="isSilver" mdbBtn type="button" class="btn-sm app-primary-btn m-auto ">
              Active
              <i class="fa fa-check-circle"></i>
            </button>
            <button *ngIf="!isSilver && !isBase" mdbBtn type="button" class="btn-sm app-secondary-btn m-auto">
              Unlocked <i class="fa fa-check-circle"></i>
            </button>
            <button *ngIf="isBase && userCurrentPlan.amountForAllPlan[0] > 0" mdbBtn type="button" class="btn-sm app-secondary-btn m-auto" (click)="goToPlans()">
              Upgrade
              <i class="fa fa-star" aria-hidden="true"></i>
            </button>
           
          </div>
          <div *ngIf="isBase && userCurrentPlan.amountForAllPlan[0] > 0" class="m-auto p-2 text-center">
            Add <b class="font-weight-bold">{{userCurrentPlan.amountForAllPlan[0] | number}} </b>
            <p>
              CAS to upgrade
            </p>
          </div>
        </div>
        <div class="col" [ngClass]="{'sub-hover': isGold}">
          <div class="row m-auto pt-4">
            <div class="pricingTable-header m-auto">
              <img class="plan-icon" src="../../../assets/images/plan/Gold.svg" alt="">
              <span class="text-primary-dark font-weight-bold">Gold</span>
            </div>
          </div>
          <div class="text-center font-weight-bold">{{planData[2].percent}}%</div>
          <h5 class="text-center plan-name-desc my-2 font-14-reg">
            You will receive {{planData[2].percent}}% of all your referred user’s earnings
          </h5>
          <div class="row my-3">
            <button *ngIf="isGold" mdbBtn type="button" class="btn-sm app-primary-btn m-auto">
              Active <i class="fa fa-check-circle"></i>
            </button>

            <button *ngIf="!isBase && !isSilver && !isGold" mdbBtn type="button" class="btn-sm app-secondary-btn m-auto">
              Unlocked
              <i class="fa fa-check-circle"></i>
            </button>

            <button *ngIf="(isSilver || isBase) && (userCurrentPlan.amountForAllPlan[0] > 0 
              || userCurrentPlan.amountForAllPlan[1] > 0)" mdbBtn type="button" class="btn-sm app-secondary-btn m-auto" (click)="goToPlans()">
              Upgrade
              <i class="fa fa-star" aria-hidden="true"></i>
            </button>
          </div>
          <div *ngIf="(isSilver || isBase) && (userCurrentPlan.amountForAllPlan[0] > 0 
          || userCurrentPlan.amountForAllPlan[1] > 0)" class="m-auto p-2 text-center">
            Add
            <b class="font-weight-bold">
              {{(isBase ?userCurrentPlan.amountForAllPlan[1] : userCurrentPlan.amountForAllPlan[0]) | number }}
            </b>
            <p>
              CAS to upgrade
            </p>
          </div>
          <!-- </div> -->
        </div>
        <div class="col" [ngClass]="{'sub-hover': isDiamond}">
          <div class="row m-auto pt-4">
            <div class="pricingTable-header m-auto">
              <img class="plan-icon" src="../../../assets/images/plan/Diamond.svg" alt="">
              <span class="text-primary-dark font-weight-bold">Diamond</span>
            </div>
          </div>
          <div class="text-center font-weight-bold">{{planData[3].percent}}%</div>
          <h5 class="text-center plan-name-desc my-2 font-14-reg">
            You will receive {{planData[3].percent}}% of all your referred user’s earnings
          </h5>
          <div class="row my-3">
            <button *ngIf="isDiamond" mdbBtn type="button" class="btn-sm app-primary-btn m-auto ">
              Active
              <i class="fa fa-check-circle"></i>
            </button>

            <button *ngIf="!isDiamond && !isBase && isSilver && isGold" mdbBtn type="button" class="btn-sm app-secondary-btn m-auto">
              Unlocked <i class="fa fa-check-circle"></i>
            </button>

            <button *ngIf="(isGold || isSilver || isBase)
            && (userCurrentPlan.amountForAllPlan[0] > 0|| userCurrentPlan.amountForAllPlan[1] > 0
            || userCurrentPlan.amountForAllPlan[2] > 0)" mdbBtn type="button" class="btn-sm app-secondary-btn m-auto" (click)="goToPlans()">
              Upgrade
              <i class="fa fa-star" aria-hidden="true"></i>
            </button>

          </div>
          <div *ngIf="(isGold || isSilver || isBase)
          && (userCurrentPlan.amountForAllPlan[0] > 0|| userCurrentPlan.amountForAllPlan[1] > 0
          || userCurrentPlan.amountForAllPlan[2] > 0)" class="m-auto p-2 text-center">
            Add
            <b class="font-weight-bold">
              {{(isBase ? userCurrentPlan.amountForAllPlan[2] : isSilver ? userCurrentPlan.amountForAllPlan[1]: userCurrentPlan.amountForAllPlan[0]) | number}}
            </b>
            <p>
              CAS to upgrade
            </p>
          </div>
        </div>
      </div>
      <!-- </div> -->
      <!-- five section -->
      <a *ngIf="viewDetails" class="view-details" (click)="viewDetails=!viewDetails">View Details</a>
      <a *ngIf="!viewDetails" class="view-details" (click)="viewDetails=!viewDetails">Hide Details</a>
      <div *ngIf="!loader && !viewDetails" class="table-controls-container mb-2 pt-4" style="flex-direction: row-reverse!important;">
        <div class="flex">
          <div style="display: inline-grid;">

            <cashaa-form-select-filter placeholder="Filter by" [options]="filterOptions" (changed)="filterData($event)" [optionImg]="false">
            </cashaa-form-select-filter>
           
          </div>
          <div style="display: inline-grid;">
            <button mdbBtn type="button" class="btn-sm bg-white btn border black-text" (click)="exportStatement()">
              <img src="../../../../assets/images/Download.png" style="width: 18px; padding-bottom: 7px;margin-top:2px;margin-bottom: 2px;">
              Download report
            </button>
          </div>

        </div>
      </div>
      <div class="text-center row p-4" *ngIf="loader">
        <mdb-spinner spinnerColor="blue"></mdb-spinner>
      </div>
      <div class="row p-1" *ngIf="!loader && !viewDetails">
        <table mdbTable>
          <thead style="background: #1740A4; height: 45px; color: white;">
            <tr>
              <th style="text-align: center;width: 10%;">
                <h6>Sr No</h6>
              </th>
              <th style="text-align: center; width: 20%;">
                <h6>Email ID</h6>
              </th>
              <th style="text-align: center;width: 20%;">
                <h6>Status</h6>
              </th>
              <th style="text-align: center;width: 20%;">
                <h6>Amount</h6>
              </th>
              <th style="text-align: center;width: 10%;">
                <h6>Date</h6>
              </th>
              <th style="text-align: center;width: 20%;">
                <h6>Actions</h6>
              </th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <tr mdbTableCol *ngFor="let item of referralData; let i = index">
              <td style="text-align: center;width: 10%;">
                <span class="content">{{i+1}}</span>
              </td>
              <td style="text-align: center;width: 20%;">
                <span class="content">{{item.email}}</span>
              </td>
              <td style="text-align: center;width: 20%;">

                <span class="content border py-1 px-4" style="border-radius: 50px;" [ngClass]="{'pending-status': isPending(item), 'joined-status':isJoined(item)}">
                  <span class="dot mr-2 ml-0 mt-0"></span>{{isPending(item) ? 'Pending' : 'Joined'}}
                </span>
              </td>
              <td style="text-align: center;width: 20%;">
                <span class="content"><strong>{{item.amount}} {{item.amountCurrency}}</strong></span>
              </td>
              <td style="text-align: center;width: 15%;">
                <span class="content">{{item.joinedDate|date}}</span>
              </td>
              <td style="text-align: center;width: 20%;">
                <span class="content" style="color: #F44336;" [ngClass]="{'disable':!isPending(item)}">
                  Delete<span *ngIf="item.isDeleteOrResend"
                              class="spinner-border spinner-border-sm mr-2" role="status"
                              aria-hidden="true"></span>
                  <a (click)="onDeleteOrResend(item)">
                    <img [src]="'../../../../assets/images/refrel/delete.png'" style="width: 32px; height: 32px;" class="ml-2">
                  </a>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>

</cashaa-sidenav>
