import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from "@angular/core";

@Component({
  selector: "cashaa-timer",
  templateUrl: "./timer.component.html",
  styleUrls: ["./timer.component.scss"],
})
export class TimerComponent implements OnInit, OnChanges {
  @Input() time = 15;

  @Input() start = true;
  @Input() noExpiry = false;

  @Output() expire = new EventEmitter<void>();

  /** is timer expired */
  expired = false;
  resetTime: number;
  @Input() isRecipient: boolean = false;
  private intervalId: any;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.start) {
      if (this.time === 0) {
        this.time = this.resetTime;
      }
      this.resetTime = this.time;
      this.startTimer();
    } else {
      this.expired = false;
      clearInterval(this.intervalId);
    }
  }

  startTimer(): void {
    this.intervalId = setInterval(() => {
      this.time -= 1;
      if (this.time === 0) {
        clearInterval(this.intervalId);
        if (!this.noExpiry) {
          this.expired = true;
        }
        this.expire.emit();
      } else {
        this.expired = false;
      }
    }, 1000);
  }
}
