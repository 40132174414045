import { Router } from "@angular/router";
import { InviteService } from "./../services/invite.service";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { USER_EMAIL_PATH } from "src/app/app.constants";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "cashaa-invite-home-email",
  templateUrl: "./invite-home-email.component.html",
  styleUrls: ["./invite-home-email.component.scss"],
})
export class InviteHomeEmailComponent implements OnInit {
  form: FormGroup;

  /** list of email addresa dded */
  emailAddress: string[] = [];

  loader = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private inviteService: InviteService,
    private toastrService: ToastrService
  ) {
    this.form = this.fb.group({
      email: ["", [ValidatorUtility.Required]],
    });
  }

  ngOnInit(): void {}

  addEmailAddress(): void {
    if (
      this.form.controls.email.value === sessionStorage.getItem(USER_EMAIL_PATH)
    ) {
      this.toastrService.error("Cannot invite yourself, add another email.");
    } else if (this.emailAddress.includes(this.form.controls.email.value)) {
      this.toastrService.error("Email already added.");
    } else if (this.form.controls.email.valid) {
      this.emailAddress.push(this.form.controls.email.value);
    }
  }

  deleteEmail(idx: number): void {
    this.emailAddress.splice(idx, 1);
  }

  invitePeople(): void {
    this.loader = true;
    const emails = this.emailAddress.map((email) => {
      return {
        recipientEmail: email,
      };
    });
    const dto = {
      toEmail: emails,
    };

    this.inviteService.invitePeople(dto).subscribe(() => {
      this.loader = false;
      this.router.navigateByUrl("/invite/done");
    });
  }
}
