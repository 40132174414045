<strong>{{ledger | json }}</strong>
<section class="activity-box p-3">
  <div class="date p-2 text-danger">
    Need your attention
  </div>
  <div class="details mb-3">
    <mdb-accordion [multiple]="false" aria-multiselectable="false">
      <mdb-accordion-item class="no-collase">
        <mdb-accordion-item-head class="p-2" mdbWavesEffect>
          <mdb-icon fas icon="exclamation-triangle" class="pull-left text-danger"></mdb-icon>
          <div class="status pl-4 mb-2">
            To <b>Cashaa Technologies</b>
            <br>
            <span class="text-danger">
              Sending Paused
            </span>
          </div>
          <div class="amount pr-1 mb-2">
            <b>99.56 EUR</b><br>
            <span class="text-right">
              100EUR
            </span>
          </div>
        </mdb-accordion-item-head>
        <mdb-accordion-item-body class="px-4">
          <div class="container px-4 pt-2">
            <div class="row border-top border-light pt-3 pb-3">
              <div class="col-md-11">
                <h5>Ready to pay?</h5>
                <span>
                  Next, Send your morning to your GBP account. We'll get started on your transfer
                  <br>
                  the moment we recieve your money.
                </span>

              </div>


              <div class="col-md-1">

                <div class="text-right">
                  <i class="fa fa-ellipsis-h border p-2 m-3 rounded border-dark"></i></div>
              </div>
            </div>

            <div class="row mt-4 pt-3 border-top border-light">
              <div class="col-md-8 ">
                <button class="btn btn-success rounded btn-sm">Choose how to pay</button>
                <button class="btn btn-outline-primary rounded btn-sm">I've not paid</button>
              </div>
              <div class="col-md-4 text-right">
                <button class="btn btn-outline-dark rounded btn-sm pull-right" (click)="cancelTransfer()">Cancel
                  transfer</button>
              </div>
            </div>
          </div>
        </mdb-accordion-item-body>
      </mdb-accordion-item>
    </mdb-accordion>
  </div>
</section>