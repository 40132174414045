import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { APP_ROUTES } from "../../../app.constants";

@Component({
  selector: "cashaa-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  /**
   * Constructor to inject services
   * @param modalService Modal sevices to open
   * various modals
   */
  constructor(private router: Router) {}

  ngOnInit(): void {}

  /**
   * Open sign in user page
   */
  signIn(): void {
    this.router.navigateByUrl(APP_ROUTES.SIGN_IN);
  }
}
