<section class="p-4 sm-width-phone-700">
  <h6 class="font-weight-normal">
    Your customers ({{ customerDetail?.length }})
  </h6>
  <hr class="mb-4" />
  <div [hidden]="loading" *ngFor="let customer of customerDetail; let i = index">
    <cashaa-customer-details [data]="customer"></cashaa-customer-details>
  </div>
  <div class="p-4 text-center" [hidden]="!loading">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
  </div>
</section>
