import { IFeature } from "./feature";

export interface ICreate extends IAPIData, ISecurity {
    callbackUrl?: string,
    redirectUrl?: string,
}

export interface ISecurity {
    emailCode: string,
    twoFaAuthCode: string,
}

export class Security implements ISecurity {
    constructor(public emailCode: string,
        public twoFaAuthCode: string) { }
}

export class APIData implements IAPIData {
    constructor(
        public apiName: string,
        public iplist: IIPList[],
        public apiFeatureTypeList: string[],
        public id?: string
    ) { }
}

export interface IIPList {
    ip: string,
    label: string,
    status?: number
}

export interface IEmailCode {
    apiName: string
}

export class EmailCode implements IEmailCode {
    constructor(public apiName: string) { }
}

export class IPList implements IIPList {
    constructor(public ip: string, public label: string) { }
}

export interface IAPIData {
    apiFeatureTypeList: string[],
    apiName: string,
    iplist: IIPList[],
    id?: string
}

export class Create implements ICreate {
    constructor(public apiFeatureTypeList: string[],
        public apiName: string,
        public iplist: IIPList[],
        public emailCode: string,
        public twoFaAuthCode: string,
        public callbackUrl?: string,
        public redirectUrl?: string,
        public id?: string) { }
}

export interface IApiListRespose {
    id: string,
    apiName: string,
    apiKey: string,
    callbackUrl: string,
    redirectUrl: string,
    secretKey: string,
    active: boolean,
    status: boolean,
    apiFeatureTypeList: IFeature[],
    ipList: IIPList[],
    webHookList: WebHookList
}

export interface WebHookList {
    "cashaaUserId": string,
    "apiManagerId": string,
    "domainUrl": string,
    "domainVerificationUrl": string,
    "domainVerificatioinCode": string,
    "domainVerificationFileGenerated": boolean,
    "domainVerified": boolean,
    "callbackURL": string,
    "sendCallBackUrl": string,
    "sendStatusCallbackUrl": string,
    "approved": boolean
}