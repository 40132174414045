import { UserService } from "./../../core/services/user/user.service";
import {
  IFiatInOutTransferFees,
  TransferFeesByCurrencyModel,
} from "./../../core/models/transfer-types";
import { CurrencyType } from "./../../core/enums/currencyType";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";
import { FiatFlow } from "src/app/core/models/fIat/fiat-flow";
import { FiatService } from "../services/fiat.service";
import { DomainService } from "../../core/services/domain/domain.service";
import { ICurrency } from "../../core/models/currency";
import { ITransferFees } from "../../core/models/transfer-types";
import {
  WALLET_CURRENCY,
  USER_FEE_MODE,
  USER_TYPE,
  SUBSCRIPTION_PERSONAL_PLAN,
  SUBSCRIPTION_BUSINESS_PLAN,
} from "src/app/app.constants";
import { IFormSelect } from "src/app/core/models/form-select";
import { DecimalPipe } from "@angular/common";
import { KycService } from "src/app/kyc/services/kyc.service";
import { AccountUpgradeService } from "src/app/account-upgrade/services/account-upgrade.service";
import { FiatRecipient } from "src/app/core/models/fIat/fiat-pay";
import { SubSink } from "subsink";
import { RecipientType } from "src/app/core/enums/recipientTypeEnum";
import { UserSubscriptionPlan } from "src/app/core/models/account-upgrade/subscription-plan-response";
import { DepositService } from "src/app/deposit/services/deposit.service";
import { IFiatSendFees } from "src/app/core/models/fiat-send";

@Component({
  selector: "cashaa-fiat-flow-amount",
  templateUrl: "./fiat-flow-amount.component.html",
  styleUrls: ["./fiat-flow-amount.component.scss"],
})
export class FiatFlowAmountComponent implements OnInit {
  /** on continue click, emit event */
  @Output() continue = new EventEmitter<boolean>();

  /** input form */
  form: FormGroup;

  /** rate varibale */
  rate: number;

  /** sendCurrencySymbol varibale */
  sendCurrencySymbol: string;

  /** actulaconvertedamount varibale */
  actulaconvertedamount: number;

  /** selectedfeeamount varibale */
  selectedfeeamount: number;
  _inCashaaFee: number;
  transferFeesByCurrencyModel: IFiatInOutTransferFees;
  memoError = false;
  isCashaa = false;
  /** feemode variable */
  userFeeMode = sessionStorage.getItem(USER_FEE_MODE);
  userType = sessionStorage.getItem(USER_TYPE);
  /** cas fee check varibale */
  casFeeCheckBalance: number;
  iscasfee = false;
  _casCurrecnyId = "43796AC1-8F4E-4875-2533-08D7B39928D1";
  casSendAmount = 0;
  fiatCurrencies = ["USD", "EUR", "GBP"];
  loading: boolean = false;
  memoRequiredCur: string[] = [
    "b87668f2-cb17-48a8-2535-08d7b39928d1",
    "2633816a-dc95-4015-2536-08d7b39928d1",
    "43796ac1-8f4e-4875-2533-08d7b39928d1",
  ];
  deliverMechOptions = [
    { value: "2", label: "ACH" },
    { value: "3", label: "WIRE" },
  ];

  networkTypeOptions = [];

  sendCurrency: ICurrency;
  feeAmountObject: ITransferFees;
  isKycDone: boolean = false;
  errorMessage: string;
  currencyFromBeneficiary: string;
  subsink = new SubSink();
  currentUserPlanDetail: UserSubscriptionPlan;
  isPremiumPlan: boolean = true;
  feesData: IFiatSendFees;
  minSendValue: number
  isMinSendValueError: boolean = false;
  showNetworkTypeOption = false;
  isFromBeneficiary = false;
  isFeeFree;
  constructor(
    private fb: FormBuilder,
    private fiatService: FiatService,
    private domainService: DomainService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private decimalNumber: DecimalPipe,
    private kycService: KycService,
    private upgardeService: AccountUpgradeService,
    private depositService: DepositService,
    private activateRoute: ActivatedRoute
  ) {
    this.createForm();
    this.rate = 1;
    this.sendCurrencySymbol = "EUR";
    this.selectedfeeamount = 1;
    this.actulaconvertedamount = 0;
  }

  ngOnInit(): void {
    this.loading = true;
    this.sendAmountChangeEvent();
    this.getGivenCurrency();
    this.getUserBalance();
    if (this.userType === '4') {
      this.getKycDetail();
    } else {
      this.getBusinessKycDetail();
    }
    this.subsink.add(this.upgardeService.getUserActionSubscriptionPlan().subscribe((data: UserSubscriptionPlan) => {
      this.currentUserPlanDetail = data;
    }));
    this.activateRoute.queryParams.subscribe(param => {
      if (param && param.recipientType) {
        if (param.recipientType === '0') {
          param.recipientType = '1';
        }
        this.form.get('recipientType').setValue(param.recipientType);
      }
    })

    this.subsink.add(this.form.controls["recipientType"].valueChanges.subscribe((val) => {
      if (val) {
        if (Number(val) === RecipientType.Others) {
          if (this.userType === '4') {
            if (SUBSCRIPTION_PERSONAL_PLAN.BASIC === this.currentUserPlanDetail.planName) {
              this.isPremiumPlan = false;
            }
          } else {
            if (SUBSCRIPTION_BUSINESS_PLAN.BASIC === this.currentUserPlanDetail.planName) {
              this.isPremiumPlan = false;
            }
          }
        } else {
          this.isPremiumPlan = true;
        }
        this.fiatService.getmonthlyfreewithdrawlimit().subscribe((data: boolean) => {
        this.isFeeFree = data;
        this.getFee(this.sendCurrencySymbol === 'GBP' ? 4: this.sendCurrencySymbol === 'EUR' ? 1 : this.sendCurrencySymbol === 'USD' ? this.form.controls.deliveryMech.value === '2' ? 2 : 3 : undefined, Number(val));
        });
      }
    }));

    this.form.controls.senderAmount.valueChanges.subscribe(res => {
      this.checkMinSendAmountError();
    })

  }

  checkMinSendAmountError() {
    if (this.actulaconvertedamount < this.minSendValue) {
      this.isMinSendValueError = true;
    } else {
      this.isMinSendValueError = false;
    }

  }
  getGivenCurrency(): void {
    this.activatedRoute.queryParamMap.subscribe((val) => {
      let currency = val.get("c"); // from account
      this.currencyFromBeneficiary = val.get("cur"); //  from beneficiary
      if (this.currencyFromBeneficiary) {
        currency = this.currencyFromBeneficiary;
        this.isFromBeneficiary = true;
      }
      this.fiatCurrencies.includes(currency) ? this.showNetworkTypeOption = false : this.showNetworkTypeOption = true;
      this.domainService
        .getCurrenciesList(CurrencyType.all)
        .subscribe((list) => {
          if (currency) {
            const found = list.currencies.filter((c) => c.symbol === currency && c.isSendActive);
            if (found.length > 0) {
              this.form.controls.sendercurrencyId.setValue(found[0].id);
              this.form.controls.receivercurrencyId.setValue(found[0].id);
            } else {
              const gbpFound = list.currencies.filter(
                (c) => c.symbol === WALLET_CURRENCY.EUR
              );
              if (gbpFound.length > 0) {
                this.form.controls.sendercurrencyId.setValue(gbpFound[0].id);
                this.form.controls.receivercurrencyId.setValue(gbpFound[0].id);
              }
            }
          } else {
            const gbpFound = list.currencies.filter(
              (c) => c.symbol === WALLET_CURRENCY.EUR
            );
            if (gbpFound.length > 0) {
              this.form.controls.sendercurrencyId.setValue(gbpFound[0].id);
              this.form.controls.receivercurrencyId.setValue(gbpFound[0].id);
            }
          }
        });
    });
  }

  getFee(deliveryMechanism: number, recipientType: number): void {
    if (deliveryMechanism !=undefined && recipientType !=undefined) {
      this.depositService.getFiatSendFees(this.sendCurrencySymbol, this.userFeeMode === "InNative" ? 2 : 1, deliveryMechanism, 1,
        Number(this.userType === '6' ? '5' : this.userType), recipientType).subscribe((data: IFiatSendFees) => {
          this.feesData = data;
          this.minSendValue = data.minSendAmount;
          if(!this.isFeeFree)  {
          this.feesCalculation(Number(this.form.controls.senderAmount.value.replaceAll(",", "")));
          } else {
           this.selectedfeeamount = 0;
          }
        });
    }
  }

  feesCalculation(amount: number) {
    if (amount && amount > 0 && this.feesData) {
      if (this.userFeeMode === "InNative") {
        if ((amount * this.feesData.feeInPercent) > this.feesData.minimumFee) {
          this.selectedfeeamount = (amount * this.feesData.feeInPercent);
        } else {
          this.selectedfeeamount = this.feesData.minimumFee;
        }
        this.actulaconvertedamount = Number(Number(amount) - this.selectedfeeamount);
      } else {
        if ((amount * this.feesData.feeInPercent) > this.feesData.minimumFee) {
          this.selectedfeeamount = amount * this.feesData.feeInPercent / this.feesData.casRate;
        } else {
          this.selectedfeeamount = this.feesData.minimumFee / this.feesData.casRate;
        }
        this.actulaconvertedamount = Number(Number(amount));
      }

      this.form.controls["receiverAmount"].patchValue(
        (this.actulaconvertedamount > 0
          ? this.actulaconvertedamount
          : 0
        ).toFixed(2),
        { emitEvent: false, onlySelf: true }
      );
      if (this.actulaconvertedamount < this.minSendValue) {
        this.isMinSendValueError = true;
      } else {
        this.isMinSendValueError = false;
      }
    } else {
      if (this.feesData) {
        this.selectedfeeamount = this.userFeeMode === "InNative" ? this.feesData.minimumFee : this.feesData.minimumFee / this.feesData.casRate;
      } else {
        this.selectedfeeamount = 0;
      }
    }

  }

  /**
   * Create new form
   */
  createForm(): void {
    this.form = this.fb.group({
      sendercurrencyId: [""],
      senderAmount: ["", [ValidatorUtility.Required]],
      receivercurrencyId: [{ value: "", disabled: true }],
      receiverAmount: [
        { value: "", disabled: true },
        [ValidatorUtility.Required],
      ],
      sendertransferFeeId: [""],
      deliveryMech: ["2", [ValidatorUtility.Required]],
      memo: [""],
      networkType: [""],
      recipientType: [""]
    });
    this.listenToCurrencyChange();
  }

  /**
   * Listen to currency change
   */
  listenToCurrencyChange(): void {
    this.isMinSendValueError = false;
    this.form.controls.sendercurrencyId.valueChanges.subscribe((value) => {
      this.form.controls.receivercurrencyId.setValue(value);
      this.form.controls.senderAmount.setValue(0);
      this.form.controls.receiverAmount.setValue(0);
      // this.form.controls.recipientType.setValue('');
      this.checkMemoRequirement();
    });
  }

  checkMemoRequirement(): void {
    const cur = this.form.controls.sendercurrencyId.value;
    if (this.memoRequiredCur.indexOf(cur) !== -1) {
      this.memoError = true;
    } else {
      this.memoError = false;
    }
  }

  /**
   * On continue click
   */
  onContinue(): void {
    let payData: FiatFlow;
    let recipientType;
    payData = new FiatFlow(
      this.form.controls["sendercurrencyId"].value,
      this.form.controls["senderAmount"].value.replaceAll(",", ""),
      this.form.controls["receivercurrencyId"].value,
      this.form.controls["receiverAmount"].value.replaceAll(",", ""),
      this.form.controls["sendertransferFeeId"].value,
      this.form.controls["memo"].value,
      this.sendCurrency,
      this.feeAmountObject,
      this.selectedfeeamount,

    );
    if (
      this.sendCurrencySymbol === "USD" ||
      this.sendCurrencySymbol === "AUSD"
    ) {
      payData.deliveryMech = this.form.controls["deliveryMech"].value;
    }

    if (this.sendCurrencySymbol === "EUR") {
      payData.deliveryMech = "1";
    }
    if (this.sendCurrencySymbol === "GBP") {
      payData.deliveryMech = "4";
    }

    if (!this.fiatCurrencies.includes(this.sendCurrencySymbol)) {
      payData.networkType = this.form.controls["networkType"].value;
    }

    if (this.fiatCurrencies.includes(this.sendCurrencySymbol)) {
      payData.recipientType = this.form.controls["recipientType"].value
    }

    console.log(payData);
    this.fiatService.emitfiatFlowPaydDataEmitter(payData);
    this.fiatService.emitCurrencySymbolEmitter(
      new FiatRecipient(this.sendCurrencySymbol, Number(payData.networkType), payData.recipientType));
    if (this.sendCurrencySymbol === this.currencyFromBeneficiary) {
      this.continue.emit(false);
    } else {
      this.continue.emit(true);
    }
  }

  onsendCurrencyChangeEvent(data: ICurrency): void {
    if (this.userFeeMode === "InNative") {
      this.isCashaa = false;
      this.sendCurrencySymbol = data.symbol;
      let toFix = 0;
      if (this.fiatCurrencies.includes(data.symbol)) {
        this.showNetworkTypeOption = false;
        toFix = 2;
        this.convertNewAmount(this.form.controls.senderAmount.value);
      } else {
        if (data.symbol !== this.currencyFromBeneficiary) {
          this.isFromBeneficiary = false;
        }
        this.selectedfeeamount = 0;
        this.loading = true;
        this.fiatService.getmonthlyfreewithdrawlimit().subscribe((data: boolean) => {
          this.isFeeFree = data;
          if (!data) {
            this.loading = false;
            this.showNetworkTypeOption = true;
            if(!this.isFiatCurrency()) {
            this.fiatService.getcryptosendfee(this.sendCurrencySymbol, !(this.sendCurrencySymbol === 'BTC' || this.sendCurrencySymbol === 'BNB') ? Number(this.form.controls.networkType.value) : 0).subscribe((data: { nativeFee: number, casFee: number, minSendAmount: number }) => {
              if (data) {
                this.selectedfeeamount = data.nativeFee;
                this.minSendValue = data.minSendAmount
                this.loading = false;
              }
            });
          }
          } else {
            this.loading = false;
            this.showNetworkTypeOption = false;
          }
        });
        toFix = 4;
      }
      this.form.patchValue(
        {
          senderAmount: parseInt(this.form.controls.senderAmount.value).toFixed(
            toFix
          ),
        },
        {
          emitEvent: false,
        }
      );
    } else {
      this.isCashaa = false;
      this.sendCurrencySymbol = data.symbol;
      if (this.isFiatCurrency()) {
        this.showNetworkTypeOption = false;
        this.convertNewAmount(this.form.controls.senderAmount.value);
      } else {
        this.selectedfeeamount = 0;
        this.loading = true;
        this.fiatService.getmonthlyfreewithdrawlimit().subscribe((res: boolean) => {
          this.isFeeFree = res;
          this.fiatService.getcryptosendfee(this.sendCurrencySymbol, !(this.sendCurrencySymbol === 'BTC' || this.sendCurrencySymbol === 'BNB') ? Number(this.form.controls.networkType.value) : 0).subscribe((data: { nativeFee: number, casFee: number, minSendAmount: number }) => {
            if (data) {
              this.selectedfeeamount = data.casFee;
              this.minSendValue = data.minSendAmount;
              this.loading = false;
            }
          });
          if (!res) {
            this.showNetworkTypeOption = true;
            this.loading = false;
            this.loading = true;
          } else {
            this.loading = false;
            this.showNetworkTypeOption = false;
          }
        });
      }
    }

    if (this.getMemoReferenceValid()) {
      if (this.sendCurrencySymbol === "CAS" || this.sendCurrencySymbol === "BNB" || this.sendCurrencySymbol === "XRP") {
        this.form.controls["memo"].setValidators([Validators.maxLength(20)]);
      } else {
        this.form.controls["memo"].setValidators([Validators.maxLength(8)]);
      }
      this.form.updateValueAndValidity();
    }
    this.sendCurrency = data;
    if (!this.fiatCurrencies.includes(this.sendCurrencySymbol)) {
      this.form.controls["networkType"].setValidators(ValidatorUtility.Required);
      if (this.sendCurrencySymbol === 'BNB') {
        this.networkTypeOptions = [{ value: "0", label: "BNB Beacon Chain (BEP-2)" }];
        this.form.controls["networkType"].setValue("0");
      }

      if (this.sendCurrencySymbol === 'BTC') {
        this.networkTypeOptions = [{ value: "0", label: "BTC (Bitcoin)" }];
        this.form.controls["networkType"].setValue("0");
      }
      if (!(this.sendCurrencySymbol === 'BTC' || this.sendCurrencySymbol === 'BNB')) {
        this.form.controls["networkType"].setValue("");
        this.networkTypeOptions = [{ value: "2", label: "BNB Smart Chain (BEP-20)" }];
       
        if(this.sendCurrencySymbol === 'USDT') {
        this.networkTypeOptions.push({ value: "4", label: "Tron (TRC-20)" });
      }

        if (this.sendCurrencySymbol === 'BNB' || this.sendCurrencySymbol === 'BCH' || this.sendCurrencySymbol === 'DOG' ||
          this.sendCurrencySymbol === 'LTC' || this.sendCurrencySymbol === 'XRP') {
          this.networkTypeOptions.push({ value: "0", label: this.getLabelValue(this.sendCurrencySymbol) });
        } else {
          if(this.sendCurrencySymbol !== 'CAS') {
          this.networkTypeOptions.push({ value: "1", label: "Ethereum (ERC-20)" });
          }
        }

        // if (this.sendCurrencySymbol === 'CAS') {
        //   this.networkTypeOptions.push({ value: "3", label: "Polygon" });
        // }

        if (this.sendCurrencySymbol === 'ETH' || this.sendCurrencySymbol === 'USDT' || this.sendCurrencySymbol === 'UNI' ||
          this.sendCurrencySymbol === 'USDC' || this.sendCurrencySymbol === 'LINK') {
          this.form.controls["networkType"].setValue("1");
        }
        if (this.sendCurrencySymbol === 'BNB' || this.sendCurrencySymbol === 'BCH' || this.sendCurrencySymbol === 'CAS'
          || this.sendCurrencySymbol === 'DOG' || this.sendCurrencySymbol === 'LTC' || this.sendCurrencySymbol === 'XRP') {
          this.form.controls["networkType"].setValue("0");
        }
      }
    }

    if (this.sendCurrencySymbol === 'EUR' || this.sendCurrencySymbol === 'USD' || this.sendCurrencySymbol === 'GBP') {
      this.form.controls.recipientType.setValidators(ValidatorUtility.Required);
      this.fiatService.getmonthlyfreewithdrawlimit().subscribe((res: boolean) => {
        this.isFeeFree = res;
      this.getFee(data.symbol === 'GBP' ? 4: data.symbol === 'EUR' ? 1 : data.symbol === 'USD' ? this.form.controls.deliveryMech.value === '2' ? 2 : 3 : undefined, Number(this.form.controls.recipientType.value));
      })
    } else {
      this.form.controls.recipientType.clearValidators();
    }
    this.form.controls.recipientType.updateValueAndValidity();

  }

  minSendAmount(minSendValue: number): void {
    // this.minSendValue = minSendValue
    if(!this.isFiatCurrency()) {
      this.fiatService.getcryptosendfee(this.sendCurrencySymbol, !(this.sendCurrencySymbol === 'BTC' || this.sendCurrencySymbol === 'BNB') ? Number(this.form.controls.networkType.value) : 0).subscribe((data: { nativeFee: number, casFee: number, minSendAmount: number, maxSendAmount: number }) => {
        if (data) {
          this.minSendValue = data.minSendAmount;
        }
    });
  }
  
  }

  getLabelValue(currency: string): string {
    if (currency === 'BNB') {
      return "BNB Beacon Chain (BEP2)"
    } else {
      return this.getCurrencyName(currency);
    }
  }

  getMemoReferenceValid(): boolean {
    if ((this.sendCurrencySymbol === 'BNB' || this.sendCurrencySymbol === 'XRP' || this.sendCurrencySymbol === 'CAS') &&
      (this.form.controls["networkType"].value == '2' || this.form.controls["networkType"].value == '3')) {
      return false;
    }
    if (
      this.sendCurrencySymbol === "USD" ||
      this.sendCurrencySymbol === "AUSD" ||
      this.sendCurrencySymbol === "EUR" ||
      this.sendCurrencySymbol === "GBP" ||
      this.sendCurrencySymbol === "CAS" ||
      this.sendCurrencySymbol === "BNB" ||
      this.sendCurrencySymbol === "XRP"
    ) {
      return true;
    } else {
      return false;
    }
  }

  sendAmountChangeEvent(): void {
    this.isMinSendValueError = false;
    if (this.userFeeMode === "InNative") {
      this.form.controls["senderAmount"].valueChanges.subscribe((val) => {
        if (val) {
          this.convertNewAmount(val);
        }
      });
    } else {
      this.form.controls["senderAmount"].valueChanges.subscribe((val) => {
        this.actulaconvertedamount = val;
        if (val) {
          this.convertNewAmount(val);
        }
        if (this.selectedfeeamount > this.casFeeCheckBalance) {
          this.memoError = true;
          this.isCashaa = true;
        }
      });
    }
  }

  getDecimalNumber(symbol) {
    if (this.userFeeMode === "InNative") {
      let toFix = "0";
      if (this.fiatCurrencies.includes(symbol)) {
        toFix = "0.2-2";
      } else {
        toFix = "0.4";
      }
      return toFix;
    } else {
      return "0.0";
    }
  }

  convertToDecimal(val, symbol) {
    let toFix = 0;
    if (this.fiatCurrencies.includes(symbol)) {
      toFix = 2;
    } else {
      toFix = 4;
    }
    return parseFloat(parseFloat(val).toFixed(toFix)) * 1;
  }

  convertNewAmount(val: any): void {
    
    val = val ? val.replaceAll(",", "") : val;
    if (this.userFeeMode === "InNative") {
      let toFix = 0;
      if (this.fiatCurrencies.includes(this.sendCurrencySymbol)) {
        if(!this.isFeeFree) {
        this.feesCalculation(val);
        toFix = 2;
        }
      } else {
        toFix = 4;
      }
      this.actulaconvertedamount = Number(Number(val) - this.selectedfeeamount);
      this.form.controls["receiverAmount"].patchValue(
        (this.actulaconvertedamount > 0
          ? this.actulaconvertedamount
          : 0
        ).toFixed(toFix),
        { emitEvent: false, onlySelf: true }
      );

    } else {
      this.isCashaa = false;
      if (this.fiatCurrencies.includes(this.sendCurrencySymbol)) {
        if(!this.isFeeFree) {
        this.feesCalculation(val);
        }
      } else {
        this.actulaconvertedamount = Number(Number(val));
        if (this.sendCurrencySymbol === 'CAS') {
          this.actulaconvertedamount = this.actulaconvertedamount - this.selectedfeeamount;
        }
        this.form.controls["receiverAmount"].patchValue(this.actulaconvertedamount > 0 ? this.actulaconvertedamount.toString() : 0, {
          emitEvent: false,
          onlySelf: true,
        });
      }
    }

    if (parseFloat(this.form.controls["receiverAmount"].value) !== 0) {
      this.form.controls["receiverAmount"].patchValue(
        this.decimalNumber.transform(
          this.form.controls["receiverAmount"].value.replaceAll(",", ""),
          "0.0-4"
        ),
        {
          emitEvent: false,
          onlySelf: true,
        }
      );
    }

    if (this.actulaconvertedamount < this.minSendValue) {
      this.isMinSendValueError = true;
    } else {
      this.isMinSendValueError = false;
    }
  }
  getTransferFeeByCurrecny(): void {
    const data = new TransferFeesByCurrencyModel(
      this.form.controls["sendercurrencyId"].value,
      this.form.controls["senderAmount"].value,
      1,
      false
    );

    this.domainService.getTransferFeeByCurrecny(data).subscribe((res) => {
      this.transferFeesByCurrencyModel = res;
      if (res.otherFeeAmountInPercent > 0) {
        const otherFee = res.otherFeeAmountInPercent;
        const finalFee = otherFee * this.form.controls["senderAmount"].value;
        this._inCashaaFee = res.feeAmount + finalFee;
        if (this._inCashaaFee > this.casFeeCheckBalance) {
          this.iscasfee = true;
          this.memoError = true;
        }

        this.selectedfeeamount = res.feeAmount + finalFee;
        this.actulaconvertedamount = this.form.controls["senderAmount"].value;
        this.form.controls["receiverAmount"].patchValue(
          this.actulaconvertedamount > 0 ? this.actulaconvertedamount : 0,
          { emitEvent: false, onlySelf: true }
        );
        if (this.actulaconvertedamount < this.minSendValue) {
          this.isMinSendValueError = true;
        } else {
          this.isMinSendValueError = false;
        }
      } else {
        this._inCashaaFee = res.feeAmount;
      }
    });
  }
  /**
   * get user balance
   */
  getUserBalance(): void {
    this.iscasfee = false;
    this.userService.getUserBalance().subscribe((balance) => {
      const found = balance.filter((b) => b.currencyType === "CAS");
      if (found.length > 0) {
        this.casFeeCheckBalance = found[0].available;
        this.casFeeCheckBalance = Number(this.casFeeCheckBalance.toFixed(4));
        if (this.selectedfeeamount > this.casFeeCheckBalance) {
          this.memoError = true;
          this.isCashaa = true;
        }
      } else {
        this.casFeeCheckBalance = 0;
      }
    });
  }

  getLabel(): string {
    if (this.sendCurrencySymbol === "CAS" || this.sendCurrencySymbol === "BNB") {
      return "Memo";
    } if (this.sendCurrencySymbol === "XRP") {
      return "Tag"
    } else {
      return "Reference";
    }
  }

  getSentValue(): boolean {
    return this.actulaconvertedamount < 0;
  }

  checkValues(): boolean {
    return (
      this.form.controls.senderAmount.value === "." ||
      parseFloat(this.form.controls.senderAmount.value) === 0
    );
  }

  getCurrencyName(curr): string {
    if (curr === "AUSD") return "USD";
    if (curr === "DOG") return "Dogecoin (DOGE)";
    if (curr === "BCH") return "Bitcoin Cash (BCH)";
    if (curr === "XRP") return "Ripple (XRP)";
    if (curr === "LTC") return "Litecoin (LTC)";
    else return curr;
  }

  getKycDetail(): void {
    this.loading = true;
    this.kycService.getKycDetails().subscribe((res) => {
      if (res) {
        this.loading = false;
        if (res.verified) {
          this.isKycDone = true;
        } else {
          this.errorMessage = `In order to initiate a withdrawal, complete your KYC by <b class="text-info">clicking here</b>.`;
        }
      }
    }, (err) => {
      this.loading = false;
    });
  }

  getBusinessKycDetail(): void {
    this.upgardeService.usdStatus().subscribe(
      (flag) => {
        if (flag.status === 5) {
          this.isKycDone = true;
        } else {
          this.errorMessage = `In order to initiate a withdrawal, complete your KYC by <b class="text-info">clicking here.</b>`;
        }
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  isWithdrawCurrencyFiat(): boolean {
    return this.sendCurrencySymbol === 'GBP' || this.sendCurrencySymbol === 'EUR' || this.sendCurrencySymbol === 'USD';
  }

  isFiatCurrency(): boolean {
    return this.fiatCurrencies.includes(this.sendCurrencySymbol);
  }

  deliveryMechChanges(event: IFormSelect): void {
    this.isMinSendValueError = true;
    this.fiatService.getmonthlyfreewithdrawlimit().subscribe((data: boolean) => {
    this.isFeeFree = data;  
    this.getFee(this.sendCurrencySymbol === 'GBP' ? 4 : this.sendCurrencySymbol === 'EUR' ? 1 : this.sendCurrencySymbol === 'USD' ? event.value === '2' ? 2 : 3 : undefined, Number(this.form.controls.recipientType.value));
    })
  }

  networkTypeChanges(event: IFormSelect): void {
    this.loading = true;
    this.isMinSendValueError = true;
    this.fiatService.getmonthlyfreewithdrawlimit().subscribe((data: boolean) => {
      this.isFeeFree = data;
      let isFee = data;
        this.loading = false;
    //     this.loading = true;
    this.fiatService.getcryptosendfee(this.sendCurrencySymbol, Number(event.value)).subscribe((data: { nativeFee: number, casFee: number, minSendAmount: number, maxSendAmount: number }) => {
      if (data) {
        this.minSendValue = data.minSendAmount;
        this.checkMinSendAmountError();
        if(!isFee) {
        if (this.userFeeMode === "InNative") {
          this.selectedfeeamount = data.nativeFee;
        } else {
          this.selectedfeeamount = data.casFee;
        }
      } else {
        
      }
        this.loading = false;
      }
    });
    });
  }


}
