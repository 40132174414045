import { ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "./../shared/shared.module";
import { MDBBootstrapModulesPro } from "ng-uikit-pro-standard";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { DepositRoutingModule } from "./deposit-routing.module";
import { DepositHomeComponent } from "./deposit-home/deposit-home.component";
import { DepositAmountComponent } from "./deposit-amount/deposit-amount.component";
import { DepositTransferTypeComponent } from "./deposit-transfer-type/deposit-transfer-type.component";
import { DepositDetailComponent } from "./deposit-detail/deposit-detail.component";
import { DepositTransferNumberComponent } from "./deposit-transfer-number/deposit-transfer-number.component";
import { DepositHistoryComponent } from "./deposit-history/deposit-history.component";
import { PaymentSuccessComponent } from "./payment-success/payment-success.component";
import { PaymentFailureComponent } from "./payment-failure/payment-failure.component";
import { ToastrModule } from "ngx-toastr";
import { DepositRecipientComponent } from './deposit-recipient/deposit-recipient.component';

@NgModule({
  declarations: [
    DepositHomeComponent,
    DepositAmountComponent,
    DepositTransferTypeComponent,
    DepositDetailComponent,
    DepositTransferNumberComponent,
    DepositHistoryComponent,
    PaymentSuccessComponent,
    PaymentFailureComponent,
    DepositRecipientComponent,
  ],
  imports: [
    CommonModule,
    MDBBootstrapModulesPro.forRoot(),
    ToastrModule.forRoot(),
    SharedModule,
    ReactiveFormsModule,
    DepositRoutingModule,
  ],
})
export class DepositModule {}
