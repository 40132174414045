<form [formGroup]="form" (ngSubmit)="onSubmit(form.value)" class="my-4">
  <div class="row mb-4">
    <div class="col-12 col-sm-6">
      <cashaa-form-input name="mobileCountryCode" type="text" [form]="form" [control]="form.controls.mobileCountryCode"
        placeholder="Enter Mobile Country Code" label="Mobile Country Code" infoMesg="UK/US/EU">
      </cashaa-form-input>
    </div>
    <div class="col-12 col-sm-6 mobile-mt-4">
      <cashaa-form-input name="mobileNumber" type="number" [form]="form" [control]="form.controls.mobileNumber"
        placeholder="Enter Mobile Number" label="Mobile Number">
      </cashaa-form-input>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-12 col-sm-6">
      <cashaa-form-input name="emailAddress" type="email" [form]="form" [control]="form.controls.emailAddress"
        placeholder="Enter Email Address" label="Email Address">
      </cashaa-form-input>
    </div>
  </div>
  <button mdbBtn block="true" type="submit"
    class="w-25 rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block" mdbWavesEffect
    [disabled]="form.invalid">
    {{isDataLoaded ? 'Next' : 'Save & Next'}}
  </button>
</form>