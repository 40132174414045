export enum LedgerType {
  /// <summary>
  /// All
  /// </summary>

  All = 0,
  /// <summary>
  /// The deposit
  /// </summary>
  Received = 1,
  // Deposit = 1,
  /// <summary>
  /// The with draw
  /// </summary>

  Withdraw = 2,
  /// <summary>
  /// The cashaa fee
  /// </summary>

  CashaaFee = 3,
  /// <summary>
  /// The network fee
  /// </summary>

  NetworkFee = 4,
  /// <summary>
  /// The trade
  /// </summary>

  Trade = 5,
  /// <summary>
  /// The atm withdraw
  /// </summary>

  ATM_Withdraw = 6,

  Shopping = 7,

  // spent
  sent = 8,

  // convert
  convert = 9,
  ToConvert = 10,
  StackingAmount = 11,
  personal_USD_Account_Staking = 12,
  business_USD_Account_Onboarding_Fee = 13,
}
