import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IStatementResponse } from "src/app/core/models/statement/statement";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class StatementService {
    private apiUrl = environment.api;

    constructor(private http: HttpClient) { }

    getStatementList(page: number, pageSize: number, activityType?: number, fromDate?: string, toDate?: string, searchTerm?: string, symbol?: string): Observable<IStatementResponse> {
        let url = `${this.apiUrl}/activity/userstatement?page=${page}&pageSize=${pageSize}`
        
        if (activityType) {
            url = url + `&activityType=${activityType}`
        }

        if (fromDate) {
            url = url + `&fromDate=${fromDate}`
        }

        if (toDate) {
            url = url + `&toDate=${toDate}`
        }

        if (searchTerm) {
            url = url + `&searchTerm=${searchTerm}`
        }

        if (symbol) {
            url = url + `&symbol=${symbol}`
        }

        return this.http.get<IStatementResponse>(url);
    }

    getFixedDepositStatementList(id: string, page: number, pageSize: number, activityType?: number, fromDate?: string, toDate?: string, searchTerm?: string, symbol?: string): Observable<IStatementResponse> {
        let url = `${this.apiUrl}/activity/fdstatement?depositInterestId=${id}&page=${page}&pageSize=${pageSize}`
        
        if (activityType) {
            url = url + `&activityType=${activityType}`
        }

        if (fromDate) {
            url = url + `&fromDate=${fromDate}`
        }

        if (toDate) {
            url = url + `&toDate=${toDate}`
        }

        if (searchTerm) {
            url = url + `&searchTerm=${searchTerm}`
        }

        if (symbol) {
            url = url + `&symbol=${symbol}`
        }

        return this.http.get<IStatementResponse>(url);
    }
}