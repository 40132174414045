<div class="wrapper">
    <div class="card has-bg-img" style="background-image: url(../../../assets/images/event-fee/substract.png)">
        <span class="card-title">
            <div class="my-2 p-1  premium ticket-div">Premium</div>
            <div class="mt-2 mb-3 p-0">
                <p class="mb-0 text-center"><span>800</span><strong>AED</strong></p>
            </div>
            <ul class="list-type">
                <li class="tick">Welcome Reception - Feb 4</li>
                <li class="tick">Networking Drinks - Feb 4</li>
                <li class="tick">Crypto Daddy Beach party - Feb 4</li>
                <li class="tick">Summit Access - Feb 5</li>
                <li class="tick">VIP/Investors Lunch - Feb 5 </li>
                <li class="cross">Access to VIP/Speakers Lounge</li>
                <li class="cross">Golden Excellance Award - Feb 5</li>
                <li class="cross">Gala Dinner - Feb 5</li>
                <li class="tick">Exclusive post event investment report</li>
            </ul>
        </span>
        <button mdbBtn type="button" class="btn-sm app-select-btn btn-align" (click)="select(2)">Select</button>
    </div>

    <div class="card has-bg-img" style="background-image: url(../../../assets/images/event-fee/substract.png)">
        <span class="card-title">
            <div class="my-2 p-1 vip ticket-div">VIP</div>
            <div class="mt-2 mb-3">
                <p class="mb-0 text-center"><span>1500</span><strong>AED</strong></p>
            </div>
            <ul class="list-type">
                <li class="tick">Welcome Reception - Feb 4</li>
                <li class="tick">Networking Drinks - Feb 4</li>
                <li class="tick">Crypto Daddy Beach party - Feb 4</li>
                <li class="tick">Summit Access - Feb 5</li>
                <li class="tick">VIP/Investors Lunch - Feb 5 </li>
                <li class="tick">Access to VIP/Speakers Lounge</li>
                <li class="tick">Golden Excellance Award - Feb 5</li>
                <li class="tick">Gala Dinner - Feb 5</li>
                <li class="tick">Exclusive post event investment report</li>
            </ul>
        </span>
        <button mdbBtn type="button" class="btn-sm app-select-btn btn-align" (click)="select(3)">Select</button>
    </div>
</div>