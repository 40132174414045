<mdb-accordion>
  <mdb-accordion-item [collapsed]="true">
    <mdb-accordion-item-head [customClass]="isOpen ? 'pl-4' : 'settings-card pl-4'" (click)="isOpen = !isOpen">
      <div class="d-flex align-items-center">
        <img [src]="'../../../../assets/images/settings/password.svg'" />
        <!-- <mdb-icon fas icon="lock" class="p-2 card-icon" [ngClass]="isOpen ? '' : 'bg-white'"></mdb-icon> -->
        <div class="ml-4 setting-card-open">
          <h6 class="mb-1 heading">Change Password</h6>
          <p class="mb-0 font-weight-normal">*******</p>
        </div>
      </div>
    </mdb-accordion-item-head>
    <mdb-accordion-item-body customClass="pl-5 position-relative">
      <div class="ml-5">
        <hr class="mb-5" />
        <div class="email-form setting-pass-height-phone">
          <div class="mb-4 w-25 w-60">
            <cashaa-form-input name="oldpassword" type="password" [form]="form" [control]="form.controls.oldpassword"
              placeholder="Old Password" [isSetting]=true label="Old password">
            </cashaa-form-input>
          </div>
          <div class="mb-4 w-25">
            <cashaa-form-input name="newpassword" type="password" [form]="form" [control]="form.controls.newpassword"
              placeholder="New Password" [isSetting]=true label="New password">
            </cashaa-form-input>
            <cashaa-password-strength [passwordToCheck]="form.value.newpassword" [isSetting]=true
              (passwordStrength)="passwordValid($event)">
            </cashaa-password-strength>
            <mdb-error class="sm-error-message mt-2" *ngIf="errorMessage">{{ errorMessage }}</mdb-error>
          </div>

          <button [disabled]="isInProgress || form.invalid" mdbBtn (click)="onPasswordFormSubmit()" block="true"
            type="button"
            class="rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block w-25 w-60 ml-0 mb-4 sm-setting-width"
            mdbWavesEffect>
            Change Password
          </button>
        </div>
      </div>

      <!-- Progress bar -->
      <mdb-progress-bar *ngIf="isInProgress" class="progress primary-color-dark position-absolute" mode="indeterminate">
      </mdb-progress-bar>
    </mdb-accordion-item-body>
  </mdb-accordion-item>
</mdb-accordion>