import { environment } from "src/environments/environment";
import {
  IDepositAmount,
  IPayousDepositCreate,
} from "./../../core/models/deposit";
import { DepositService } from "./../services/deposit.service";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { WalletCurrency } from "src/app/core/enums/walletcurrency";
import { IFormSelect } from "src/app/core/models/form-select";
import { ToastrService } from "ngx-toastr";
import { UNABLE_TO_PERFORM_ERROR } from "src/app/app.constants";
import { SubSink } from "subsink";
//import institutionlist from '../../../assets/json/institutionlist_uat.json';

//const institutionlist = environment.production == true ? require("../../../assets/json/institutionlist_prod.json") : require("../../../assets/json/institutionlist_uat.json");
const institutionlist = require("../../../assets/json/institutionlist_prod.json");

@Component({
  selector: "cashaa-deposit-transfer-type",
  templateUrl: "./deposit-transfer-type.component.html",
  styleUrls: ["./deposit-transfer-type.component.scss"],
})
export class DepositTransferTypeComponent implements OnInit, OnDestroy {
  form: FormGroup;

  @Output() continue = new EventEmitter<void>();

  @Output() back = new EventEmitter<void>();

  @Output() completed = new EventEmitter<void>();

  depositData: IDepositAmount;

  symbol: string;

  insttutionListvalue: string = "Others";

  payousData: IPayousDepositCreate;

  /** form select values */
  options: IFormSelect[] = [];

  optionIcon;

  loading: boolean = false;

  subsink = new SubSink();

  constructor(
    private fb: FormBuilder,
    private depositService: DepositService,
    private toastrService: ToastrService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.depositService.getDepositAmountEmitter().subscribe((data) => {
      if (data) {
        this.depositData = data;
        this.symbol = data.symbol;
        this.form.controls.transferType.setValue(this.symbol === 'EUR' || this.symbol === 'USD' ? 0: undefined);
      }
    });
    this.getInstitutinList();
    
    this.subsink.add(
      this.depositService.getDepositStatus().subscribe((data) => {
        if (data) {
          this.form.patchValue({
            transferType: 0,
          });
        }
      })
    );
  }

  /**
   * Create new form
   */
  createForm(): void {
    this.form = this.fb.group({
      transferType: [ValidatorUtility.Required],
      institutionlist: [],
    });
  }

  onContinue(): void {
    // this.depositData.option = this.form.controls.transferType.value;
    this.insttutionListvalue = this.form.controls.institutionlist.value;
    // if (this.symbol === "GBP" && this.insttutionListvalue !== "Others") {
    //   this.loading = true;
    //   this.createPayousDeposit();
    // } else {
    //   // 
    // }
    this.depositService.emitDepositAmount(this.depositData);
    this.continue.emit();
  }

  onBack(): void {
    this.back.emit();
  }

  createPayousDeposit(): void {
    this.payousData = {
      amount: this.depositData.amount,
      fee: this.depositData.fee,
      wallet: WalletCurrency.GBP,
      instiutionId: this.insttutionListvalue,
    };
    this.depositService.createPayousDeposit(this.payousData).subscribe(
      (res) => {
        this.loading = false;
        window.open(res.paymentlink, "_blank");
        // window.location.href = res.paymentlink;
        this.insttutionListvalue = "Others";
        this.completed.emit();
        //this. onBack();
      },
      (err) => {
        this.loading = false;
        if (err.error.message) {
          this.toastrService.error(err.error.message);
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
      }
    );
  }

  onBankChange(value: string): void {
    this.selectedImg(
      this.options.find((option: IFormSelect) => {
        return option.value === value;
      })
    );
  }

  selectedImg(v) {
    if (v.icon) {
      this.optionIcon = v.icon;
    }
  }

  getInstitutinList(): void {
    this.options = institutionlist;
    // this.depositService.getInstitutinalList().subscribe(
    //   res => {

    //     this.options = res.getInstitutionalList.map((data) => {
    //       return {
    //         value: data.id,
    //         label: data.name,
    //       };
    //     });
    //     this.options.push({value : "Others" , label: "Others"});

    //   },
    //   err => {
    //   }
    // )
  }

  // isValid(): boolean {
  //   if (this.symbol === 'USD' && (this.form.controls.transferType.value === '3' || this.form.controls.transferType.value === '4')) {
  //       return true;
  //   } else if (this.symbol === 'EUR') {
  //     return true;
  //   } else return false;
    
  // }

  isTransferTypeSelected() {
   const transferType = this.form.get('transferType').value;
   return transferType == '0' || transferType == '1' || transferType == '2' ? false: true
  }
 
  ngOnDestroy() {
    this.subsink.unsubscribe();
  }
}
