import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WalletService } from '../services/wallet.service';

@Component({
  selector: 'cashaa-loan-interest',
  templateUrl: './loan-interest.component.html',
  styleUrls: ['./loan-interest.component.scss']
})
export class LoanInterestComponent implements OnInit {
intrestList = [];

  loanId;
  loading = false;
  interestonLoan = [{
    'amout': '',
    'id': '',
    'currencySymbol' : '',
    'date': ''

  }];
  pageSize: number = 10;
  pageIndex: number = 1;
  totalPageSize: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  length: number;
  // loanRepaymentHistoryInfo = [];
  constructor(private activatedRoute: ActivatedRoute, private walletService: WalletService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe( param => {
      this.loanId = window.atob(param.id);
    })
    if(this.loanId) {
    this.getInterestDeductionOnLoan(this.pageIndex, this.pageSize);
    // this.getLoanRepaymentHistory();
    }

  }

  getInterestDeductionOnLoan(pageIndex, pageSize) {
    this.loading = true;
    this.walletService.getInterestDeductionAgainstLoan(this.loanId, pageIndex, pageSize).subscribe(res => {
      this.loading = false;
      this.interestonLoan = res.data;
      this.pageSize = res.pageSize;
      this.pageIndex = res.pageIndex;
      this.length = res.totalCount;
      this.totalPageSize = res.totalPageCount;
      this.hasPreviousPage = res.hasPreviousPage;
      this.hasNextPage = res.hasNextPage;
      

    }, err => {
      this.loading = false;

    })
  }
  previous() {
    if (this.hasPreviousPage) {
      this.getInterestDeductionOnLoan(this.pageIndex - 1, this.pageSize);
    }
  }

  next() {
    if (this.hasNextPage) {
      this.getInterestDeductionOnLoan(this.pageIndex + 1, this.pageSize);
    }
  }

}
