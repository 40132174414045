<div class="row my-4">
    <div class="col-md-8">
        <form [formGroup]="form">
            <div class="col-md-12">
                <div class="row justify-content-between">
                    <div class="col-12">
                        <h3 class="font-weight-bolder">Upload Selfie with ID:</h3>
                        <p class="font-weight-bold pt-2">Please ensure that characters on the ID
                            card are clear and
                            recognizable in the
                            photograph.<br>
                            Support format: JPG
                            and PNG<br>
                            Maximum file size:
                            4MB
                        </p>
                        <div class="font-weight-normal mt-2 mb-4">
                            <div class="pr-4"><img src="../../../assets/images/selfie_example.jpg" alt="" width="235">
                            </div>
                            <div >
                                <p class="font-weight-bold pt-2">Please
                                    provide a
                                    photograph
                                    with ID or
                                    Passport and
                                    a note
                                    marked with
                                    "Cashaa",
                                    "Today's
                                    Date" and
                                    "Signature"
                                    hold by hand
                                    and ensure
                                    the identity
                                    information
                                    and your
                                    face are
                                    clear and
                                    recognizable.</p>
                                <ul class="font-weight-normal">
                                    <li class="mb-1 item">The face
                                        on the
                                        photograph
                                        shall be
                                        clear
                                        and
                                        recognizable.</li>
                                    <li class="mb-1 item">The
                                        characters
                                        on the
                                        ID card
                                        or
                                        passport
                                        shall be
                                        clear
                                        and
                                        recognizable.</li>
                                    <li class="mb-1 item">The note
                                        shall be
                                        marked
                                        with
                                        'Cashaa'.</li>
                                    <li class="mb-1 item">The note
                                        shall be
                                        marked
                                        with
                                        'Today's
                                        Date'
                                        (yy-mm-dd).</li>
                                    <li class="mb-1 item">The note
                                        shall be
                                        marked
                                        with
                                        your
                                        signature.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="w-100">
                        <fieldset class="border p-2">
                            <legend class="w-auto sm-label font-weight-normal">Selfie</legend>
                            <cashaa-form-input-file [docType]="selfiefile" [fileUploadStatus]="true"
                                [isFileUploadCompleted]="selfiefile.isDeletedValid" [isOperationCompleted]="false">
                            </cashaa-form-input-file>
                        </fieldset>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<button [disabled]="!selfiefile?.file"   style="width: 30%!important; text-align: center; margin-top: 30px;" mdbBtn (click)="onDocumentSubmit()"
    block="true" type="button"
    class="w-25 rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block" mdbWavesEffect>
    <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" *ngIf="loader"></span>
    Upload Documents
</button>
