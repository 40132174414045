<p class="red-text mb-1 font-weight-normal" *ngIf="!noInput && amountControl.hasError('max')">
  Amount is higher than available balance
</p>
<p class="red-text mb-1 font-weight-normal" *ngIf="
    !noInput &&
    amountControl.value &&
    amountControl.hasError('min') &&
    (this.module === 'depositInterest') &&
    amountControl.value != 0 && 
    !amountControl.hasError('max')
  ">
  {{ controlCurrencyVal }} amount should not be less than {{this.balanceDetail.minFDAmount}}
</p>
<!-- <p class="red-text mb-1 font-weight-normal" *ngIf="
    !noInput &&
    amountControl.value &&
    amountControl.hasError('min') &&
    (controlCurrencyVal === 'USD' || controlCurrencyVal === 'AUSD' || controlCurrencyVal === 'GBP'|| controlCurrencyVal === 'EUR'
    || controlCurrencyVal === 'SGD' || controlCurrencyVal === 'CAD' || controlCurrencyVal === 'CHF'
    || controlCurrencyVal === 'NZD' ||
    controlCurrencyVal === 'JPY') &&
    amountControl.value != 0 && 
    !amountControl.hasError('max')
  ">
  {{ getCurrencyName(controlCurrencyVal) }} amount should not be less than 50
</p> -->
<!-- <p class="red-text mb-1 font-weight-normal" *ngIf="
    !noInput &&
    amountControl.value &&
    amountControl.hasError('covertCasMultiplicative') &&
    (controlCurrencyVal === 'CAS') &&
      !amountControl.hasError('min') &&
    amountControl.value != 0 && !amountControl.hasError('max')
  ">
  {{ controlCurrencyVal }} amount should be in multiple of 10
</p> -->
<section class="d-flex" [ngClass]="{ 'sm-height': noInput }">
  <div class="position-relative w-100" *ngIf="!noInput && form" [formGroup]="form">
    <input mdbInput cashaaNumDecimal OnlyNumber="true" step=".01" type="text" class="input-box form-control p-4 pt-5"
      (keyup)="numberOnly($event,false)" [formControl]="amountControl" placeholder="0" [ngClass]="{
        'error-border':
          !disable &&
          amountControl?.invalid &&
          (amountControl?.dirty || amountControl?.touched)
      }" />
    <p class="input-box-heading position-absolute">
      {{ label }}
    </p>
  </div>
  <div class="btn-group" [ngClass]="{ 'sm-width': noInput }" mdbDropdown [isDisabled]="disable ? true : false">
    <a mdbDropdownToggle mdbBtn class="dropdown-toggle dropdown black-text text-left d-flex"
      [ngClass]="{ 'sm-a-align': noInput ,'disable-background':disable && noInput}" type="button">
      <span class="sm-placeholder" *ngIf="noInput && !this.selectedCurrency">Select Currency</span>
      <ng-template [ngTemplateOutlet]="information"></ng-template>

      <mdb-icon [ngClass]="{ 'sm-mdb-icon-right': noInput }" *ngIf="send" class="pl-2" fas
        [icon]="'chevron-' + (isDropdownOpened ? 'up' : 'down')">
      </mdb-icon>
    </a>
    <div *ngIf="send" class="dropdown-menu m-0 p-3" [ngClass]="{ 'sm-width-div': noInput }">
      <ng-template [ngTemplateOutlet]="currencyList"></ng-template>
    </div>
  </div>
</section>
<p *ngIf="!noInput && showBalance" class="background mb-0 p-2 font-weight-normal">
  You have
  <a *ngIf="selectedCurrency?.currencyType === 2 ||  getCurrencyName(selectedCurrency?.symbol) === 'USDT' ||  getCurrencyName(selectedCurrency?.symbol) === 'USDC'; else nonStable" class="p-0 text-primary-dark font-weight-bold" (click)="patchBalance(balance)">{{ balance | number: "0.0-2" }} {{
    getCurrencyName(selectedCurrency?.symbol)
    }}</a> 
    <ng-template #nonStable>
      <a class="p-0 text-primary-dark font-weight-bold" (click)="patchBalance(balance)">{{ balance | number: "0.0-4" }} {{
        getCurrencyName(selectedCurrency?.symbol) 
        }}</a> 
    </ng-template>
    in your wallet. <a *ngIf="selectedCurrency?.isDepositActive || selectedCurrency?.currencyType === 1" class="p-1 link-color" (click)="depositBalance()">Deposit balance</a>
</p>

<ng-template #information>
  <div *ngIf="selectedCurrency">
    <img [ngClass]="{ 'sm-img-width': noInput, 'mr-1 ml-2': noInput, 'mr-3': !noInput }"
      [src]="'../../../../assets/icons/' + selectedCurrency?.symbol + '.png'" class="icon" />
  </div>
  <h6 class="heading mb-0" [ngClass]="send ? 'mr-3' : 'recieved-panel'"
    [ngClass]="{'heading pl-2': noInput}">
    {{ getCurrencyName(selectedCurrency?.symbol) }}
  </h6>
  <p *ngIf="noInput" class="ml-2 mb-0 small">{{ selectedCurrency?.name }}</p>
</ng-template>

<ng-template #currencyList>
  <div class="d-flex align-items-center">
    <mdb-icon fas icon="search" aria-hidden="true"></mdb-icon>
    <input class="form-control form-control-sm ml-3 w-75 search-input" type="text"
      placeholder="{{addAddress==='cryptoAddress'? 'Type crypto': addAddress==='fiatAddress'? 'Type a currency': 'Type a currency / crypto'}}" aria-label="Search" mdbInput [(ngModel)]="searchTerm"
      (input)="onSearch($event)" (click)="$event.stopPropagation()" />
  </div>
  <!-- <div *ngIf="!noAccountCurrency && getAccountCurrencies()?.length>0">
    <hr />
    <p class="small">National Currency Account</p>
    <div class="p-0 d-flex align-items-center dropdown-item mb-3" (click)="onCurrencySelection(currency)"
      *ngFor="let currency of getAccountCurrencies()">
      <div>
        <img [src]="'../../../../assets/icons/' + currency.symbol + '.png'" class="icon mr-2" />
      </div>
      <h6 class="font-weight-bolder mb-0 symbol ">{{ getCurrencyName(currency?.symbol) }}</h6>
      <p class="ml-4 mb-0 small">{{ currency?.name }}</p>
    </div>
  </div> -->
  <div *ngIf="getWalletCurrencies()?.length > 0">
    <hr />
    <p class="small"> {{isRecipientComponent ? '' : 'National Currency Wallet'}}</p>
    <div class="p-0 d-flex align-items-center dropdown-item mb-3" (click)="onCurrencySelection(currency)"
      *ngFor="let currency of getWalletCurrencies()">
      <div>
        <img [src]="'../../../../assets/icons/' + currency.symbol + '.png'" class="icon mr-2" />
      </div>
      <h6 class="font-weight-bolder mb-0 symbol ">{{ currency?.symbol }}</h6>
      <p class="ml-4 mb-0 small">{{ currency?.name }}</p>
    </div>
  </div>
  <div
    *ngIf="(source === CURRENCY_TYPE.ALL || source === CURRENCY_TYPE.CRYPTO) && getWithoutAccountCurrencies()?.length>0 && !isRecipientComponent">
    <hr />
    <p class="small">Cryptocurrency</p>
    <div class="p-0 d-flex align-items-center dropdown-item mb-3" (click)="onCurrencySelection(currency)"
      *ngFor="let currency of getWithoutAccountCurrencies()">
      <div>
        <img [src]="'../../../../assets/icons/' + currency.symbol + '.png'" class="icon mr-2" />
      </div>
      <h6 class="font-weight-bolder mb-0 symbol">{{ currency?.symbol === 'DOG' ? 'DOGE' : currency?.symbol}}</h6>
      <p class="ml-4 mb-0 small">{{ currency?.name }}</p>
    </div>
  </div>
</ng-template>
