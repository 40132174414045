<div class="btn-group fee-dropdown" mdbDropdown>
  <a
    mdbDropdownToggle
    mdbBtn
    class="dropdown-toggle dropdown waves-light black-text text-left d-flex p-2"
    type="button"
    mdbWavesEffect
    (click)="isDropdownOpened = !isDropdownOpened"
  >
    <p class="mb-0 text-dark">{{ selectedFee?.transferName }}</p>

    <mdb-icon
      class="ml-2 text-primary-light"
      fas
      [icon]="'chevron-' + (isDropdownOpened ? 'up' : 'down')"
    ></mdb-icon>
  </a>
  <div class="dropdown-menu m-0 p-2">
    <p class="small">Transfer types</p>
    <hr />
    <div *ngFor="let fee of transferFees" (click)="onFeeTypeSelection(fee)">
      <ng-template
        [ngTemplateOutlet]="transferTypes"
        [ngTemplateOutletContext]="{ fee: fee }"
      ></ng-template>
    </div>
  </div>
</div>

<ng-template #transferTypes let-fee="fee">
  <div class="p-2 dropdown-item">
    <div class="d-flex">
      <h6>{{ fee?.transferName }}</h6>
      -
      <p class="text-gray mb-0">{{ fee?.amount | number: "0.2" }} GBP fee</p>
    </div>
    <p class="small mb-0 text-gray">{{ fee?.description }}</p>
  </div>
</ng-template>
