import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { USER_COUNTRY_CODE, USER_TYPE } from "src/app/app.constants";
import { UserType } from "src/app/core/enums/userType";

@Injectable({
  providedIn: "root",
})
export class CommonSavedData {
  public currencies;
  public stakingUrl;
  public isFirstKycCall;

  private confirmationBoxStatus = new Subject<boolean>();

  setCurrencies(data) {
    this.currencies = data;
  }

  getCurrencies() {
    return this.currencies;
  }
  setIsFirstKycCall(data) {
    this.isFirstKycCall = data;
  }

  getStakingUrl() {
    return this.stakingUrl;
  }
  setStakingUrl(data) {
    this.stakingUrl = data;
  }

  getIsFirstKycCall() {
    return this.isFirstKycCall;
  }

  emitConfirmationBoxStatus(status: boolean) {
    this.confirmationBoxStatus.next(status);
  }

  getConfirmationBoxStatus(): Observable<boolean> {
    return this.confirmationBoxStatus.asObservable();
  }

  getEnvironmentOrigin() {
    return window.location.origin;
  }

  isPersonalUS() {
    if(+sessionStorage.getItem(USER_TYPE) === UserType.Personal && sessionStorage.getItem(USER_COUNTRY_CODE) === 'US'  ) {
      return true;
    } else {
      return false;
    }
  }

}
