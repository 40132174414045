import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../core/services/auth-guard/auth-guard.service';
import { EarnGuardService } from '../core/services/earn-guard/earn-guard.service';
import { DepositInterestHomeComponent } from './deposit-interest-home/deposit-interest-home.component';


const routes: Routes = [{
  path: "deposit-interest",
  component: DepositInterestHomeComponent,
  canActivate: [AuthGuardService],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DepositInterestRoutingModule { }
