<section class="activity-box px-3">
  <div class="details">
    <mdb-accordion [multiple]="false" aria-multiselectable="false">
      <mdb-accordion-item class="no-collase ledger-list-item">
        <mdb-accordion-item-head class="px-2 py-1" mdbWavesEffect>
          <div class="d-flex flex-row px-2 align-items-center">
            <div class="px-2 circle">
              <img src="../../../assets/images/fixed-deposit.svg" style="width: 25px;" />
            </div>
            <div class="status ml-2 transaction-detail">
              <span class="text-primary-dark">
                {{ getCurrencyName(ledger?.currency) }}
                Fixed Deposit
              </span>
            </div>
            <div class="amount ml-auto mr-4">
              <b>
                {{ ledger.amount |number: "0.0-4" }}
                {{ getCurrencyName(ledger?.currency) }}
              </b><br />
            </div>
          </div>
        </mdb-accordion-item-head>
        <mdb-accordion-item-body class="px-4 position-relative">
          <div class="container px-4 pt-2">
            <div class="row">
              <div class="col-sm-6">
                Moved From <br />
                <b class="text-primary">
                  Your {{ getCurrencyName(ledger?.currency) }}
                  balance
                </b>
              </div>

              <div class="col-sm-3">
                Deposited<br />
                <b class="text-primary">
                  {{ ledger?.amount | number: "0.0-4" }} {{
                                    getCurrencyName(ledger?.currency)
                  }}
                </b>
              </div>
              <div class="col-sm-3">
                <span class="content mr-4" style="color: #3570F4;">
                  <a routerLink="/statement"
                     [queryParams]="{id: ledger?.depositInterestId, cur: getCurrencyName(ledger?.currency), val: ledger?.amount}" (click)="viewStatement(ledger, rateType)"
                     style="text-decoration: underline;">View Statement</a>
                </span>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm">
                Start Date <br />
                <b class="text-primary">{{ ledger.startDate| date:'longDate'}}</b>
              </div>

              <div class="col-sm">
                End Date <br />
                <b class="text-primary">{{ ledger.endDate| date:'longDate'}}</b>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm">
                Interest Rate Type <br />
                <b class="text-primary">{{ rateType[ledger.rateType].split('In').join('In ') }}</b>
              </div>
              <div class="col-sm">
                Interest Rate <br />
                <b class="text-primary">{{ ledger.interestRate }} %</b>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm">
                Transaction Number <br />
                <b class="text-primary">{{ ledger?.transactionId }}</b>
              </div>
              <!-- <div class="col-sm">
                  Status <br />
                  <b class="text-primary" *ngIf="ledger.isActive">Active</b>
                  <b class="text-primary" *ngIf="!ledger.isActive">In Active</b>
              </div> -->
              <div class=" col-sm d-flex cancel-autorenew" *ngIf="ledger.isActive">
              <!--<div class=" col-sm d-flex cancel-autorenew" *ngIf="ledger.isActive && isFDPrematureWithdraw">-->
                <div class="ml-auto bd-highlight">
                  <button type="button" class="sm-tooltip" mdbTooltip="Applicable fees will be deducted for premature cancellation." placement="top" *ngIf="isFDPrematureWithdraw">
                    <mdb-icon class="pl-2 info-circle" title="" fas icon="info-circle"></mdb-icon>
                  </button>
                  <button style="height: 45px;" (click)="cancelFixedDeposit()" class="btn-border-delete btn-sm w-100" mdbBtn outline="true" mdbWavesEffect *ngIf="isFDPrematureWithdraw">
                    <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"
                          *ngIf="loader"></span>
                    Cancel fixed deposit
                  </button>
                  <div class="switch-sec justify-content-center mt-2">
                    Auto Renewal <label class="switch">
                      <input type="checkbox" [checked]="ledger.isAutoRenew"
                             [value]="ledger.isAutoRenew" [(ngModel)]="ledger.isAutoRenew"
                             (change)="setAutoRenewal()">
                      <span class="slider round"></span>
                    </label>
                    <span *ngIf="ledger.isAutoRenew" class="font-weight-bold">
                      On
                    </span>
                    <span *ngIf="!ledger.isAutoRenew" class="font-weight-bold">
                      Off
                    </span>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <!-- <span *ngIf="ledger.isAutoRenew">
              <hr />
          </span> -->
          <!-- <div class=" col-sm d-flex cancel-autorenew bd-highlight" *ngIf="ledger.isActive">
              <div class="ml-auto bd-highlight">
                  <button type="button" class="sm-tooltip" (click)="$event.stopPropagation()" mdbTooltip="Applicable fees will be deducted for premature cancellation." placement="top">
                      <mdb-icon class="pl-2 info-circle" title="" fas icon="info-circle"></mdb-icon>
                    </button>
                  <button style="height: 45px;" (click)="cancelFixedDeposit()" class="btn-border-delete btn-sm w-100" mdbBtn
                      outline="true" mdbWavesEffect>
                      <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"
                          *ngIf="loader"></span>
                             Cancel fixed deposit
                  </button>
                  <div class="switch-sec justify-content-center mt-2">Auto Renewal <label class="switch">
                      <input type="checkbox" [checked]="ledger.isAutoRenew"
                      [value]="ledger.isAutoRenew" [(ngModel)]="ledger.isAutoRenew"
                       (change)="setAutoRenewal()">
                      <span class="slider round"></span> </label>
                  <span class="font-weight-bold">On
                  </span>
              </div>
              </div>
          </div> -->

        </mdb-accordion-item-body>
      </mdb-accordion-item>
    </mdb-accordion>
  </div>
</section>
