<section class="">
  <h3 class="mb-5  text-center" style="margin-top: 50px;color: #1840a4; font-weight: 700;">
    <u>Refer & Earn CAS</u>
  </h3>
</section>

<div class="mt-5 text-center" style="color: #1840a4;">
  <h4 class="font-weight-400">Earn 100 CAS every time someone uses your referral code and stakes CAS.</h4>
  <h4 class="font-weight-400">Share your referral code with your family and friends.</h4>
  <h4 class="mt-3 font-weight-400">They get all the benefits of Cashaa, including a 30% APR.</h4>
  <h4 class="mt-3 font-weight-400">You get rewarded with 100 CAS.</h4>
</div>
<div class="mt-5 box-center invite-box-center">
  <h6 class="font-weight-bolder mb-3  text-center font-weight-400" style="color: #1840a4;">
    Your Referral Code : {{ !accountDetailsCryptoData && !cryptoLoading?"Loading..":accountDetailsCryptoData.memo}} <span class="sm-input-group-text"><i mdbTooltip="Copy Referral Code" placement="top"
        class="fas fa-clone sm-copy-color" style="cursor: pointer;" (click)="copyInvite()"></i></span>
  </h6>
  <h5 class="font-weight-bolder mb-3  text-center ">
    Copy and share your personal link invite...
  </h5>
  <div class="mb-4">
    <cashaa-invite-share-link *ngIf="accountDetailsCryptoData" [refcode]="accountDetailsCryptoData?.memo" ></cashaa-invite-share-link>
  </div>
  <button mdbBtn block="true" type="button"
    class="rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block font-weight-normal"
    mdbWavesEffect routerLink="/invite/email">
    Invite via email
  </button>

  <img src="../../../assets/images/refer_earn.png" style="display: block;margin: auto;">
</div>