import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";
import { Router, ActivatedRoute } from "@angular/router";
import { ResetPassword } from "src/app/core/models/reset-password";
import { AccountService } from "../sevices/account.service";
import { APP_ROUTES, RegisterType, UNABLE_TO_PERFORM_ERROR } from "src/app/app.constants";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "cashaa-account-reset-password",
  templateUrl: "./account-reset-password.component.html",
  styleUrls: ["./account-reset-password.component.scss"],
})
export class AccountResetPasswordComponent implements OnInit {
  /** Form group */
  form: FormGroup;

  /** token from URL */
  token: string;

  /** email from URL */
  email: string;

  /** Whether to show loading indicator or not */
  showLoadingIndicator = false;

  /** Erorr message if any */
  errorMessage: string;

  passwordInvalid: boolean;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService,
    private toastrService: ToastrService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.getEmailAndTokenFromURL();
    this.accountService.setDisableCross(false);
  }

  /**
   * Get Email and token from URL
   */
  getEmailAndTokenFromURL(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.token = params["token"];
      this.email = params["email"];
    });
  }

  /**
   * Create new form for
   * reset password
   */
  createForm(): void {
    this.form = this.fb.group(
      {
        password: ["", [ValidatorUtility.Required]],
        confirmPassword: ["", [ValidatorUtility.Required]],
      },
      {
        validator: ValidatorUtility.confirmPasswordValidator(
          "password",
          "confirmPassword"
        ),
      }
    );
  }

  /**
   * On Reset password form submit
   */
  onResetPasswordSubmit(): void {
    this.showLoadingIndicator = true;
    this.errorMessage = undefined;
    const data = this.form.value;
    const refineToken = this.token.replace(/ /g, "+");

    const resetPasswordData = new ResetPassword(
      this.email,
      refineToken,
      data.password,
      RegisterType.web
    );

    this.accountService.resetPassword(resetPasswordData).subscribe(
      () => {
        this.showLoadingIndicator = false;
        this.router.navigateByUrl(APP_ROUTES.MESG_BOX+"?source=reset-password");
      },
      (err) => {
        this.showLoadingIndicator = false;
        if (err.error.message) {
          this.toastrService.error(err.error.message);
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }

        this.errorMessage = err.Message;
      }
    );
  }
  passwordValid(event) {
    this.passwordInvalid = event;
  }
}
