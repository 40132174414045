import { Component, EventEmitter, Output, OnInit, Input } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { EventFeeService } from "../services/event-fee.service";

@Component({
  selector: "cashaa-address",
  templateUrl: "./address.component.html",
  styleUrls: ["./address.component.scss"],
})
export class AddressComponent implements OnInit {
  copied
  loading = false;
  copiedAddress: boolean;
  @Output() onContinue = new EventEmitter();
  @Output() goBack = new EventEmitter();
  @Input() addressInfo;

  constructor(private eventFeeService: EventFeeService, private toastrService: ToastrService) { }

  ngOnInit(): void {

  }

  goToPreviousPage() {
    this.goBack.emit();
  }

  continue() {
    this.onContinue.emit();
  }

  copyMemoAddress(): void {
    if (this.addressInfo?.address) {
      this.copiedAddress = true;
      navigator.clipboard.writeText(this.addressInfo?.address);
      this.toastrService.toastrConfig.preventDuplicates = true;
      this.toastrService.success("Text copied to clipboard");
      setTimeout(() => (this.copiedAddress = false), 2000);
    }
  }
}