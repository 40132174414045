import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MdbStepperComponent } from 'ng-uikit-pro-standard';
import { ToastrService } from 'ngx-toastr';
import { ConvertService } from 'src/app/convert/services/convert.service';
import { CreditBalance, CreditLine } from 'src/app/core/models/credit-line';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';
import { ValidatorUtility } from 'src/app/core/utility/validator.utility';
import { WalletService } from '../services/wallet.service';
import { Router } from '@angular/router';

@Component({
  selector: 'cashaa-credit-line',
  templateUrl: './credit-line.component.html',
  styleUrls: ['./credit-line.component.scss']
})
export class CreditLineComponent implements OnInit {
  @ViewChild("stepper", { static: true }) stepper: MdbStepperComponent;

  amountForm: FormGroup;

  /** Active step */
  activeStep = 0;

  /** is completed */
  completed = false;



  isLoading = false;
  totalBalanceInUSD;
  totalCreditInUSD;
  showOtpBox: boolean;
  loanAmountForm: FormGroup;
  errorMessage: string;
  @ViewChildren("inputs") inputs: QueryList<ElementRef>;
  creditLineList: CreditLine[];
  userCreditBalance: CreditBalance;
  currencyId: string;
  loanAmount: number;
  amountBeforeLoan: number;
  amountAfterLoan: number;
  dailyInterestCharged;
  isInterestFetch: boolean;
  loading = false; 

  constructor(private fb: FormBuilder, private walletService: WalletService, private convertService: ConvertService, private navigationService: NavigationService, private toastrService: ToastrService,
    private router: Router) {
    this.createEmptyForm();
    //  this.loanAmountForm = this.fb.group({
    //   'amount' : ['', ValidatorUtility.Required],
    //   'currencyId' : ['', ValidatorUtility.Required]
    //  })
  }

  ngOnInit(): void {
    // this.activeStep = 2;
  }

  createEmptyForm(): void {
    this.amountForm = this.fb.group({
      valid: ["", ValidatorUtility.Required],
    });
  }

  /**
   * on amount form continue click
   */
  // onAmountFormContinue(): void {
  //   this.amountForm.controls.valid.setValue(true);
  //   this.stepper.next();
  //   this.activeStep = 1;
  // }

  /**
   * on go back click, go back
   * given number of steps
   */
  onGoBack(): void {
    if (this.activeStep == 0) {
      this.router.navigateByUrl('/wallet');
    }
    if(this.activeStep > 0) {
      this.activeStep--;
      this.stepper.previous();
    }
 
  }

  convertCompleted(status: boolean): void {
    this.completed = true;
  }

  ngOnDestroy(): void {
    this.convertService.emitConvertCompletedData(undefined);
    this.convertService.emitConvertFlowData(undefined);
    this.convertService.emitCurrencySymbolData(undefined);
    this.convertService.emitQuoteData(undefined);
  }

  backRoute() {
    this.navigationService.back();
  }

  confirm(event) {
    console.log(event);
    this.currencyId = event.currencyId;
    this.loanAmount = event.amount;
    this.amountBeforeLoan = event.amountBeforeLoan;
    this.amountAfterLoan = event.amountAfterLoan;
    this.dailyInterestCharged = event.dailyInterestCharged;
    this.isInterestFetch = event.isInterestFetch;
    this.stepper.next();
    this.activeStep = 1;
  }

  continue(event) {
    console.log(event);
    this.loading = true;
    const payload = {
      "amount": this.loanAmount,
      "currencyId": this.currencyId,
      "TwoFA": event.twoFAOTPCode,
      "EmailCode": event.emailOTPCode,
      "isInterestFetch": this.isInterestFetch
    };

    this.walletService.getCreditLoan(payload).subscribe(res => {
      this.loading = false;
      this.stepper.next();
    }, err => {
      this.loading = false;
      this.errorMessage =  err.error.message;
      this.toastrService.error(this.errorMessage);
    });

  }

}

