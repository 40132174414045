import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ProfileBusinessComponent } from "./profile-business/profile-business.component";
import { ProfileHomeComponent } from "./profile-home/profile-home.component";
import { AuthGuardService } from "./../core/services/auth-guard/auth-guard.service";
const routes: Routes = [
  {
    path: "profile",
    component: ProfileHomeComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "business",
        component: ProfileBusinessComponent,
        canActivate: [AuthGuardService],
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProfileRoutingModule {}
