import { Component, EventEmitter, Output, OnInit } from "@angular/core";
import { EventFeeService } from "../services/event-fee.service";

@Component({
  selector: "cashaa-currency-type",
  templateUrl: "./currency-type.component.html",
  styleUrls: ["./currency-type.component.scss"],
})
export class CurrencyTypeComponent  implements OnInit  {

  @Output() onCurrencySelection = new EventEmitter();
  @Output() goBack = new EventEmitter();
  loading = false;
  currencyType = [{icon: '../../../assets/icons/BTC.png', name: 'BTC', currencyId: '52619583-4cd6-477b-2532-08d7b39928d1'}, {icon: '../../../assets/icons/ETH.png', name: 'ETH', currencyId: 'ffc6c1df-073f-4f50-2534-08d7b39928d1'},
  {icon: '../../../assets/icons/USDC.png', name: 'USDC', currencyId: '3c421887-2020-4980-a257-4cb4a6e14487'}, {icon: '../../../assets/icons/USDT.png', name: 'USDT', currencyId: '2eca555f-379d-4d5e-9b5c-08d8320464d9'}];
  selectedCurrency = 
    {'currencyId': ''}
  constructor(private eventFeeService: EventFeeService) {}
  ngOnInit(): void {
    
    console.log('*********************************');
  
  }

  selectCurrency(event) {
  this.selectedCurrency = event;
  // this.onCurrencySelection.emit(this.selectedCurrency);
  }
  goToPreviousPage() {
  this.goBack.emit();
  }

  continue(){
  
  this.onCurrencySelection.emit(this.selectedCurrency);
  }
}