import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { RateType } from 'src/app/core/enums/rateTypeEnum';
import { ILedgerList } from 'src/app/core/models/activity/recent-transaction-list';


@Component({
  selector: 'cashaa-activity-credit-repaid',
  templateUrl: './activity-credit-repaid.component.html',
  styleUrls: ['./activity-credit-repaid.component.scss']
})
export class ActivityCreditRepaidComponent {
  @Input() ledger: ILedgerList;

  getCurrencyName(curr: string): string {
    if (curr === "AUSD") return "USD";
    if (curr === 'DOG') return 'DOGE';
    else return curr;
  }

  getAmountWithFee() {
    if(this.ledger?.convertFee) {
      return Number(this.ledger.amount) + Number(this.ledger?.convertFee)
    } else {
      return this.ledger.amount;
    }
  
  }

}


