import { ValidatorUtility } from "./../../../core/utility/validator.utility";
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { CONTROLS } from "../../../app.constants";

@Component({
  selector: "cashaa-form-input-sortcode",
  templateUrl: "./form-input-sortcode.component.html",
  styleUrls: ["./form-input-sortcode.component.scss"],
})
export class FormInputSortcodeComponent implements OnInit {
  /** input form group */
  @Input() form: FormGroup;

  /** form control */
  @Input() control: FormControl;

  /** name of control */
  @Input() name: string;

  @Input() label = "";

  input1 = "";
  input2 = "";
  input3 = "";

  @Input() set initialValue(data) {
    if (data) {
      this.onCodePaste(null, data, true);
    }
  }

  @ViewChild("inputfirst") inputFirst: ElementRef;

  @ViewChild("inputsecond") inputSecond: ElementRef;

  @ViewChild("inputthird") inputThird: ElementRef;

  /** Whether to show password in text  */
  showPassword = false;

  /** is Password type */
  isPasswordType = false;

  constructor() {}

  ngOnInit(): void {}

  /**
   * Get Error message for controls
   */
  getErrorMessage(): string {
    for (const propertyName in this.control.errors) {
      if (
        this.control.errors.hasOwnProperty(propertyName) &&
        this.control.touched
      ) {
        return ValidatorUtility.GetValidatorErrorMessage(
          propertyName,
          this.control.errors[propertyName]
        );
      }
    }
  }

  onInputChange(idx: number): void {
    if (idx === 1 && this.input1 && this.input1.toString().length === 2) {
      this.inputSecond.nativeElement.focus();
    } else if (
      idx === 2 &&
      this.input2 &&
      this.input2.toString().length === 2
    ) {
      this.inputThird.nativeElement.focus();
    }
    const fullValue = `${this.input1}${this.input2}${this.input3}`;
    console.log(this.input1);
    this.control.setValue(fullValue);
  }

  /**
   * On code paste automatically
   * fill all 6 boxes
   */
  onCodePaste(event: ClipboardEvent, text?: string, isEdit?: boolean): void {
    let pastedText = "";
    if (isEdit) {
      pastedText = text;
    } else {
      const clipboardData =
        event.clipboardData || (window as any).clipboardData;
      pastedText = clipboardData.getData("text");
    }

    if (pastedText && pastedText.length === 6) {
      this.input1 = pastedText.slice(0, 2);
      this.input2 = pastedText.slice(2, 4);
      this.input3 = pastedText.slice(4, 6);
      const fullValue = `${this.input1 || ""}${this.input2 || ""}${
        this.input3 || ""
      }`;
      if (!isEdit) {
        this.control.setValue(fullValue);
      }
    }
    event?.preventDefault();
  }
}
