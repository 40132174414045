import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import {
  BusinessProfile,
  IBusinessProfile,
} from "src/app/core/models/business-profile";
import { ProfileService } from "../services/profile.service";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { DomainService } from "src/app/core/services/domain/domain.service";
import { IFormSelect } from "src/app/core/models/form-select";
import { PROFILE_MESG, UNABLE_TO_PERFORM_ERROR } from "src/app/app.constants";
import { ToastrService } from "ngx-toastr";
const organisationType = require("../../../assets/json/organisationType.json");
const contactType = require("../../../assets/json/contactType.json");
@Component({
  selector: "cashaa-profile-business-edit",
  templateUrl: "./profile-business-edit.component.html",
  styleUrls: ["./profile-business-edit.component.scss"],
})
export class ProfileBusinessEditComponent implements OnInit {
  /** edit profile form */
  businessProfileForm: FormGroup;

  /** user profile data */
  profile: IBusinessProfile;

  /** company type options */
  businessCompany: IFormSelect[] = [];

  isInProgress: boolean;

  businessRole: IFormSelect[] = [];

  loading: boolean = true;

  /** is form disabled */
  isDisabled = true;
  initialValueCountry;
  constructor(
    public modalRef: MDBModalRef,
    private profileService: ProfileService,
    private fb: FormBuilder,
    private domainService: DomainService,
    private toastrService: ToastrService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.getBusinessProfileDetails();
    this.businessCompany = organisationType;
    this.businessRole = contactType;
  }

  /**
   * Create new form
   */
  createForm(): void {
    this.businessProfileForm = this.fb.group({
      companyName: ["", [ValidatorUtility.Required]],
      companyType: ["", [ValidatorUtility.Required]],
      registrationNumber: [""],
      website: [""],
      category: [""],
      subcategory: [""],
      phoneNumber: ["", [ValidatorUtility.Required]],
      city: ["", [ValidatorUtility.Required]],
      postalCode: ["", [ValidatorUtility.Required]],
      countryId: ["", [ValidatorUtility.Required]],
      addressLine1: ["", [ValidatorUtility.Required]],
      addressLine2: ["", [ValidatorUtility.Required]],
    });
  }

  /**
   * Get user profile details from api
   *
   */
  getBusinessProfileDetails(): void {
    this.profileService.getProfileDetails().subscribe((res) => {
      this.profile = res;
      this.patchFormWithProfile();
    });
  }

  /**
   * Patch form with new values from profile
   */
  patchFormWithProfile(): void {
    this.initialValueCountry = this.profile.countryId;
    this.businessProfileForm.patchValue({
      registrationNumber: this.profile.registration
        ? this.profile.registration
        : "",
      website: this.profile.website ? this.profile.website : "",
      category: this.profile.category ? this.profile.category : "",
      subcategory: this.profile.subCategory ? this.profile.subCategory : "",
      companyType: this.profile.companyType
        ? this.profile.companyType?.toString()
        : "2",
      companyName: this.profile.companyName ? this.profile.companyName : "",
      phoneNumber: this.profile.phoneNumber ? this.profile.phoneNumber : "",
      city: this.profile.city ? this.profile.city : "",
      postalCode: this.profile.postalCode ? this.profile.postalCode : "",
      countryId: this.profile.countryId ? this.profile.countryId : "",
      addressLine1: this.profile.addressLine1 ? this.profile.addressLine1 : "",
      addressLine2: this.profile.addressLine2 ? this.profile.addressLine2 : "",
    });
    setTimeout(() => {
      this.loading = false;
    }, 500);
  }

  /**
   * On Edit profile click
   */
  editProfileClick(): void {
    this.isDisabled = false;
  }

  /**
   * On edit profile form submission
   */
  onFormSubmit(): void {
    const data = this.businessProfileForm.value;
    this.isInProgress = true;
    const editProfileData = new BusinessProfile(
      data.companyName,
      parseInt(data.companyType),
      data.phoneNumber,
      data.city,
      data.postalCode,
      data.countryId.value,
      data.addressLine1,
      data.addressLine2,
      data.registrationNumber,
      data.website,
      data.category,
      data.subcategory
    );

  

    this.profileService.updateBusinessProfile(editProfileData).subscribe(
      (res) => {
        this.isInProgress = false;
        this.modalRef.hide();
        this.toastrService.success(PROFILE_MESG);
      },
      (err) => {
        this.isInProgress = false;
        if (err.error.message) {
          this.toastrService.error(err.error.message);
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
      }
    );
  }
}
