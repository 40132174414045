
<div class="container">
    <div class="d-flex justify-content-between">
        <ng-container  *ngFor="let currency of  currencyType;let i = index">
            <div class="card mx-1" style="width: 15rem;">
                <img class="card-img" src="{{currency.icon}}" alt="Card image cap">
                <!-- <mdb-checkbox [default]="true" class="checkbox_1 position-absolute cursor-pointer" (change)="selectCurrency(currency)"></mdb-checkbox> -->
                <input type="radio"  class="checkbox_1 position-absolute cursor-pointer" (change)="selectCurrency(currency)" name="userType"
                    mdbInput value="{{currency.currencyId}}" />
                <div class="card-body m-auto">
                 <h5 class="card-title font-weight-600">{{currency.name}}</h5>
                </div>
              </div>
              <!-- <div class="row mb-4">
                <div class="form-check col-5 sm-userType-boxes">
                  <input type="radio" formControlName="userType" class="form-check-input" id="personalAccount" name="userType"
                    mdbInput value="4" />
                  <label class="form-check-label" for="personalAccount">Personal</label>
                </div>
                <div class="form-check col-5 sm-userType-boxes">
                  <input type="radio" formControlName="userType" class="form-check-input" id="businessAccount" name="userType"
                    mdbInput value="5" />
                  <label class="form-check-label" for="businessAccount">Business</label>
                </div>
              </div> -->
        </ng-container>
        
    </div>
</div>
<div class="text-center pt-4 pb-3 m-auto w-50">
    <button mdbBtn type="button" class="btn-sm app-success-btn m-auto w-100" (click)="continue()">Continue
    </button>
</div>
<div class="go-back cursor-pointer sm-blue-custom" (click)="goToPreviousPage()">
  <mdb-icon class="sm-cursor-pointer" fas icon="arrow-left"> </mdb-icon>
  <span class="sm-cursor-pointer" style="padding-left: 10px;">Go back</span>
</div>
<div class="p-4 text-center" [hidden]="!loading">
  <mdb-spinner spinnerColor="blue"></mdb-spinner>
</div>