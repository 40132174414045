<section class="p-5 w-75 mx-auto">
  <h5 class="heading mb-5 text-center">
    Does everything look right?
  </h5>
  <div class="details px-4 py-2">
    <div class="d-flex justify-content-between">
      <p class="font-weight-normal text-gray">You're depositing</p>
      <h6 class="font-weight-bold text-primary">
        <ng-container *ngIf="confirmData">
          {{confirmData.amount}} {{confirmData.depositData.currency}}
        </ng-container>
      </h6>
    </div>
    <div class="d-flex justify-content-between">
      <p class="font-weight-normal text-gray">Start Date</p>
      <h6 class="font-weight-bold text-primary">
        <ng-container *ngIf="confirmData">{{currentDate | date: "MMM d, yyyy"}}</ng-container>
      </h6>
    </div>
    <div class="d-flex justify-content-between">
      <p class="font-weight-normal text-gray">End Date</p>
      <h6 class="font-weight-bold text-primary">
        <ng-container *ngIf="confirmData">{{endDate | date: "MMM d, yyyy"}}</ng-container>
      </h6>
    </div>
    <div class="d-flex justify-content-between">
      <p class="font-weight-normal text-gray">Plan Name</p>
      <h6 class="font-weight-bold text-primary">
        <ng-container *ngIf="confirmData">{{confirmData.depositData.planName}}</ng-container>
      </h6>
    </div>
    <div class="d-flex justify-content-between">
      <p class="font-weight-normal text-gray">Interest Rate Type</p>
      <h6 class="font-weight-bold text-primary">
        <ng-container *ngIf="confirmData">{{rateType[confirmData.depositData.rateType].split('In').join('In ')}}</ng-container>
      </h6>
    </div>

    <div class="d-flex justify-content-between">
      <p class="font-weight-normal text-gray">Interest Rate</p>
      <h6 class="font-weight-bold text-primary">
        <ng-container *ngIf="confirmData">{{confirmData.depositData.rate}}</ng-container>
      </h6>
    </div>
    <div class="d-flex justify-content-between">
      <p class="font-weight-normal text-gray">Estimated Earn</p>
      <h6 class="font-weight-bold text-primary">
        <ng-container *ngIf="confirmData">
          &asymp;&nbsp;{{confirmData.depositData.interestEarned}}
          {{confirmData.depositData.interestEarnedCurrency}}
        </ng-container>
        <h6 class="based-on-current heading" *ngIf="confirmData && confirmData.depositData.rateType === 2">Based on current CAS price</h6>
        <!--<h6 class="based-on-current heading" *ngIf="confirmData && confirmData.depositData.rateType === 1">Based on current {{confirmData.depositData.currency}} price</h6>-->
      </h6>
    </div>
  </div>
  <div class="conditions d-flex mt-4 p-3 mb-4">
    <mdb-checkbox [(ngModel)]="conditionsAccepted">
      <span class="font-weight-normal">
        I accept the
        <a target="_blank" href="https://cashaa.com/term-condition" style="padding: 0px;">
          Terms of
          Use
        </a>
      </span>
    </mdb-checkbox>
  </div>
  <button [disabled]="!conditionsAccepted" mdbBtn block="true" type="button"
          class="w-100 rounded-sm position-relative btn btn-secondary sm-custom-sec waves-effect waves-light btn-block"
          mdbWavesEffect (click)="submit()">
    <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    Confirm
  </button>
</section>
