import { IQuoteDetail } from "./../../core/models/quote";
import { APP_ROUTES, USER_FEE_MODE } from "./../../app.constants";
import { Router } from "@angular/router";
import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { ConvertService } from "../services/convert.service";
import { IConvertComplete } from "src/app/core/models/convert/convert-complete-model";
import { ModalService } from "src/app/core/services/modal/modal.service";
import { LedgerType } from "src/app/core/enums/ledgerType";

@Component({
  selector: "cashaa-convert-completed",
  templateUrl: "./convert-completed.component.html",
  styleUrls: ["./convert-completed.component.scss"],
})
export class ConvertCompletedComponent implements OnInit {
  /** on continue click, emit event */
  @Output() continue = new EventEmitter<void>();

  convertCompleteData: IConvertComplete;

  quoteData: IQuoteDetail;

  userFeeMode = sessionStorage.getItem(USER_FEE_MODE);

  fiatCurrencies = ["USD", "GBP", "EUR", "AUSD", "SGD", "AUD", "CNH", "CAD", "CHF", "NZD", "JPY"];

  constructor(
    private convertService: ConvertService,
    private router: Router,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.convertService.getConvertCompletedDataEmitter().subscribe((data) => {
      if (data) {
        this.convertCompleteData = data;
      }
    });
    this.convertService.getQuoteDataEmitter().subscribe((data) => {
      if (data) {
        this.quoteData = data;
      }
    });
  }

  /**
   * On continue click
   */
  onContinue(): void {
    this.router.navigateByUrl(APP_ROUTES.ACTIVITY_LIST);
  }

  helpdeskConvert(): void {
    this.modalService.openTicketCreateModal(LedgerType.convert);
  }

  getCurrencyName(curr): string {
    if (curr === "AUSD") return "USD";
    if (curr === "DOG") return "DOGE";
    else return curr;
  }

  getDecimalNumber(symbol) {
    let toFix = "0.0";
    if (symbol === "CAS") {
      toFix = "0.0-0";
    } else if (this.fiatCurrencies.includes(symbol)) {
      toFix = "0.2-2";
    } else {
      toFix = "0.2-4";
    }
    return toFix;
  }
}
