import { ToastrService } from "ngx-toastr";
import { ValidatorUtility } from "./../../core/utility/validator.utility";
import {
  APP_ROUTES,
  SEESION_FLAG,
  UNABLE_TO_PERFORM_ERROR,
  USER_EMAIL_PATH,
} from "./../../app.constants";
import { SettingsService } from "./../services/settings.service";
import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ChangeEmailRequest } from "../../core/models/changeEmail";
import { ActivatedRoute, Router } from "@angular/router";
import { SBItemComponent } from "ng-uikit-pro-standard";
import { HttpParameterCodec } from "@angular/common/http";

@Component({
  selector: "cashaa-settings-email",
  templateUrl: "./settings-email.component.html",
  styleUrls: ["./settings-email.component.scss"],
})
export class SettingsEmailComponent implements OnInit, HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }
  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
  /** event name */
  event: string;

  /** token */
  token: string;

  /**newmail */
  newmail: string;

  /** is opened */
  isOpen = false;

  @ViewChildren(SBItemComponent) collapses: QueryList<SBItemComponent>[];

  /** show email form */
  showForm = false;

  /** is some call in progress */
  isInProgress = false;

  /** user email address */
  useremail = sessionStorage.getItem(USER_EMAIL_PATH);

  /** form group */
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private activateRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private router: Router,
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.form.controls["currentEmail"].setValue(this.useremail);

    this.activateRoute.queryParams.subscribe((params) => {
      let decodeVal: string = params["token"]
        ? this.decodeKey(params["token"])
        : "";

      this.event = params["event"];
      this.token = decodeVal ? decodeVal.split(" ").join("+") : "";
      this.newmail = params["newmail"];

      if (this.event && this.token && this.newmail) {
        setTimeout(() => {
          this.collapse(false);
        });
        this.form.controls["newEmail"].setValue(this.newmail);
      }
    });
  }

  /**
   * Create new form
   */
  createForm(): void {
    this.form = this.fb.group({
      currentEmail: [{value:'', disabled: true}],
      newEmail: [this.newmail],
    });
  }

  /**
   * On email change form submit
   */
  onEmailChangeFormSubmit(): void {
    this.isInProgress = true;

    const data = new ChangeEmailRequest(
      this.form.controls["currentEmail"].value,
      this.form.controls["newEmail"].value,
      this.token
    );


    this.settingsService.changeEmail(data).subscribe(
      (res) => {
        this.showForm = false;
        this.isInProgress = false;
        this.collapse(true);
        this.isOpen = false;
        this.toastrService.success(res.message);
        if (this.token) {
          localStorage.clear();
          localStorage.setItem(SEESION_FLAG, "false");
          sessionStorage.clear();
          this.router.navigateByUrl(APP_ROUTES.SIGN_IN);
        }
      },
      (err) => {
        if (err.error.message) {
          this.toastrService.error(err.error.message);
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
        this.isInProgress = false;
      }
    );
  }

  collapse(collapsed: boolean) {
    this.collapses.forEach((el: any) => {
      el.toggle(collapsed);
    });
  }
}
