<section class="p-4" style="background-color: white;">
  <div class="p-4 text-center" [hidden]="!loading">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
  </div>
  <button type="button" class="user-profile-app__profile-picture-upload__button sm-default-cursor">
    <div class="tw-avatar tw-avatar--lg tw-avatar--initials tw-avatar--light">
      <div class="tw-avatar__content">{{profileName}}</div>
    </div>
  </button>
  <div class="sm-div-btn-h6">
    <h6 class="font-weight-bold text-primary sm-h6-align">Business information</h6>
    <button mdbBtn block="true" type="button" class="w-auto sm-btn-input-hover btn-border btn-sm btn btn-outline- mr-3"
      mdbWavesEffect (click)="editDetails()">
      Edit Details
    </button>
  </div>
  <hr class="mb-4" />
  <div class="row mb-4">
    <div class="col-6">
      <p class="font-weight-bolder mb-1">Registered business name</p>
      <p class="font-weight-bolder text-primary">{{ profile?.companyName }}</p>
    </div>
    <div class="col-6">
      <p class="font-weight-bolder mb-1">Company Type</p>
      <p class="font-weight-bolder text-primary">{{companyType}}</p>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-6">
      <p class="font-weight-bolder mb-1">Website</p>
      <p class="font-weight-bolder text-primary">{{profile?.website}}</p>
    </div>
    <div class="col-6">
      <p class="font-weight-bolder mb-1">Category</p>
      <p class="font-weight-bolder text-primary">{{profile?.category}}</p>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-6">
      <p class="font-weight-bolder mb-1">SubCategory</p>
      <p class="font-weight-bolder text-primary">{{profile?.subCategory}}</p>
    </div>
    <div class="col-6">
      <p class="font-weight-bolder mb-1">Registration</p>
      <p class="font-weight-bolder text-primary">{{profile?.registration}}</p>
    </div>
  </div>

  <!-- Business Adddress -->
  <h6 class="font-weight-bold text-primary mt-5">Business address</h6>
  <hr class="mb-4" />
  <div class="row mb-4">
    <div class="col-6">
      <p class="font-weight-bolder mb-1">Country</p>
      <p class="font-weight-bolder text-primary">{{ profile?.country}}</p>
    </div>
    <div class="col-6">
      <p class="font-weight-bolder mb-1">City</p>
      <p class="font-weight-bolder text-primary">{{ profile?.city }}</p>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-6">
      <p class="font-weight-bolder mb-1">Address1</p>
      <p class="font-weight-bolder text-primary">{{ profile?.addressLine1 }}</p>
    </div>
    <div class="col-6">
      <p class="font-weight-bolder mb-1">Address2</p>
      <p class="font-weight-bolder text-primary">{{ profile?.addressLine2 }}</p>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-6">
      <p class="font-weight-bolder mb-1">Postal Code</p>
      <p class="font-weight-bolder text-primary">{{ profile?.postalCode}}</p>
    </div>
    <div class="col-6">
      <p class="font-weight-bolder mb-1">Phone Number</p>
      <p class="font-weight-bolder text-primary">{{ profile?.phoneNumber}}</p>
    </div>
  </div>
</section>