import { ICurrency } from "src/app/core/models/currency";
import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { IFormSelect } from "src/app/core/models/form-select";
import { DomainService } from "src/app/core/services/domain/domain.service";
import { CurrencyType } from "src/app/core/enums/currencyType";
import { Observable, Subject } from "rxjs";
import { startWith, map } from "rxjs/operators";
import { CommonSavedData } from "../../services/commonSavedData.service";
@Component({
  selector: "cashaa-form-select-currency-symbol",
  templateUrl: "./form-select-currency-symbol.component.html",
  styleUrls: ["./form-select-currency-symbol.component.scss"],
})
export class FormSelectCurrencySymbolComponent implements OnInit {
  /** Placeholder for sourcetype */
  // fiatCurrency,cryptoCurrency,allCurrency
  @Input() source: string;

  /** Placeholder for input */
  @Input() placeholder = "Select currency";
  /** input form group */
  @Input() form: FormGroup;

  /** form control */
  @Input() control: FormControl;

  /** name of control */
  @Input() name: string;

  @Input() set initialValue(data) {
    if (data) {
      this.getCurrencyList(data);
    }
  }

  /** Form select values */
  @Input() options: IFormSelect[] = [];

  @Output() changed = new EventEmitter<any>();
  @Output() changeIsLoading = new EventEmitter<any>();

  loading = false;

  searchText = new Subject();

  filteredOptions: Observable<any[]>;

  currencies: ICurrency[] = [];

  optionIcon: string;

  constructor(
    private domainService: DomainService,
    private commonSavedData: CommonSavedData
  ) {}

  ngOnInit(): void {
    this.getCurrencyList();
  }

  filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option: any) =>
      option.label.toLowerCase().includes(filterValue)
    );
  }

  getCurrencyList(data?): void {
    this.loading = true;
    this.changeIsLoading.emit(true);
    const currencyType =
      this.source == "allCurrency"
        ? CurrencyType.all
        : this.source == "fiatCurrency"
        ? CurrencyType.Fiat
        : CurrencyType.Crypto;
    this.domainService.getCurrenciesList(currencyType).subscribe((list) => {
      this.currencies = list.currencies;
      this.commonSavedData.setCurrencies(list.currencies);
      this.loading = false;
      this.options = list.currencies.map((currencies) => {
        return {
          value: currencies.symbol,
          label: currencies.symbol,
          icon: `../../../../assets/icons/${currencies.symbol}.png`,
        };
      });

      this.filteredOptions = this.searchText.pipe(
        startWith(""),
        map((value: string) => {
          return this.filter(value);
        })
      );

      if (data) {
        let index;
        index = this.options.findIndex((c) => c.value === data);
        setTimeout(() => {
          if (this.options.length > 0) {
            this.control.setValue(this.options[index]);
            this.onCurrencyChange(this.options[index]);
          }
        });
      } else {
        setTimeout(() => {
          if (this.options.length > 0) {
            this.control.setValue(this.options[0]);
            this.onCurrencyChange(this.options[0]);
          }
        });
      }
      this.changeIsLoading.emit(false);
    });
  }

  onCurrencyChange(newValue: IFormSelect): void {
    const list = this.options.filter((c) => c.label === newValue.label);
    if (list.length > 0) {
      this.changed.emit(list[0]);
    }
    this.selectedImg(
      this.options.find((option: IFormSelect) => {
        return option.value === newValue.value;
      })
    );
  }

  selectedImg(v: IFormSelect) {
    if (v.icon) {
      this.optionIcon = v.icon;
    }
  }
}
