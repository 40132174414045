import { ValidatorUtility } from "./../../../core/utility/validator.utility";
import { FormControl } from "@angular/forms";
import { Component, OnInit, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "cashaa-form-textarea",
  templateUrl: "./form-textarea.component.html",
  styleUrls: ["./form-textarea.component.scss"],
})
export class FormTextareaComponent implements OnInit {
  /** Placeholder for input */
  @Input() placeholder = "";

  /** input form group */
  @Input() form: FormGroup;

  /** form control */
  @Input() control: FormControl;

  @Input() rows: number;

  /** name of control */
  @Input() name: string;

  @Input() label = "";

  @Input() onlyValue = false;

  constructor() {}

  ngOnInit(): void {}

  /**
   * Get Error message for controls
   */
  getErrorMessage(): string {
    for (const propertyName in this.control.errors) {
      if (
        this.control.errors.hasOwnProperty(propertyName) &&
        this.control.touched
      ) {
        let mesg=  ValidatorUtility.GetValidatorErrorMessage(
          propertyName,
          this.control.errors[propertyName]
        );

        if (mesg === "Required") {
          return "Please fill out this field";
        } else {
          return mesg;
        }
      }
    }
  }
}
