<section class="p-4 w-75 mx-auto fiat-flow-amount">
  <div>
    <h4 class="heading mb-4 text-center">
      How much would you like to deposit for interest?
    </h4>
    <div class="d-flex justify-content-center mb-4">
      <cashaa-form-select-transfer-currency (sendCurrencyChangeEvent)="onsendCurrencyChangeEvent($event)" [form]="form"
        [amountControl]="form.controls.senderAmount" [currencyControl]="form.controls.sendercurrencyId"
        label="You deposit" [controlCurrency]="sendCurrencySymbol" amountControlName="senderAmount"
        currencyControlName="sendercurrencyId" [isSendModule]="true" type="send" showBalance="true" class="w-100"
        [noAccountCurrency]="true" module="depositInterest" [minAmount]="minAmount"
        (currencyBalance)="getCurrencyBalance($event)" [initialValue]="setInitialCurrencyValue"
        [isLongTerm]="isLongTerm">
      </cashaa-form-select-transfer-currency>
    </div>
    <form [formGroup]="form">
      <div class="mb-4 position-relative pl-5 block" *ngIf="sendCurrencySymbol !== 'CAS'">
        <div class="d-flex text-primary-dark radio-box">
          <div class="m-auto">
            <h5 class="text-primary-dark font-weight-bolder text-center pt-3">
              Interest Mode
            </h5>
          </div>
          <div class="switch-sec mr-0">
            Earn in Kind <label class="switch">
              <input (input)="setMode()" type="checkbox" [checked]="form.get('rateType').value === '1' ? false : true">
              <span class="slider round"></span>
            </label>
            <span>
              Earn in CAS
            </span>
          </div>
        </div>
        <span class="line-left">
          <img [src]="'../../../../assets/images/Deposit Interest.png'" class="line-icon first-icon"
            style="margin-top: 2.3rem;" />
        </span>
      </div>


      <div *ngIf="!loading" class="mb-5">

        <cashaa-form-select-plan *ngIf="interestPlanList.length > 0" [noInput]='true' [form]="form" class="width-100"
          [dropdownInfoList]="interestPlanList" [currencyControl]="form.controls.planName" label="Plan Name"
          source="wallet" (changed)="getSelectedOption($event)"></cashaa-form-select-plan>
      </div>
      <div>
      </div>


      <div class="bg-plan p-2 m-2 font-weight-bolder"
        *ngIf="interestPlanList.length === 0 && !loading && clienttype === 1">
        No Plan Found
      </div>
      <div *ngIf="clienttype === 1" class="text-center" [hidden]="!loading">
        <mdb-spinner spinnerColor="blue" class="sm-loader-center"></mdb-spinner>
      </div>
    </form>
    <div *ngIf="clienttype === 1" class="d-flex mt-4 p-2 mb-4 justify-content-between">
      <mdb-checkbox [(ngModel)]="conditionsAccepted" [disabled]="currencyBalance === 0">
        <span class="font-weight-normal">Auto Renew</span>
      </mdb-checkbox>
      <div>
        <button type="button" class="sm-tooltip" [mdbPopover]="template" placement="bottom">
          <mdb-icon class="pl-2 info-circle" title="" fas icon="info-circle"></mdb-icon>
        </button>
        <ng-template #template>
          <div *ngIf="cancelFeeDetails.length>0" class="">Cancellation Fee is based on duration and your loyalty level
          </div>
          <div class="d-flex justify-content-between my-2">
            <div *ngFor="let info of cancelFeeDetails;let i=index">
              <div *ngIf="info.discountFee > 0;else other "><s class="text-primary">{{info.feeInPercent}}%</s><span
                  class="font-weight-bold ml-1">{{info.discountFee}}%</span></div>
              <ng-template #other>
                <div><span class="font-weight-bold ml-1">{{info.feeInPercent}}%</span></div>
              </ng-template>
              <div>{{ info.duration }}</div>
              <div *ngIf="i!==0" class="vertical-divider"></div>
            </div>
          </div>
          <div>*Any interest given will be also adjusted from principle.</div>
        </ng-template>
        Cancel fixed deposit
      </div>
    </div>

    <button *ngIf="clienttype === 1" mdbBtn [disabled]="form.invalid || !getAmount()" block="true" type="button"
      class="w-100 mt-5 rounded-sm position-relative btn btn-secondary sm-custom-sec waves-effect waves-light btn-block"
      mdbWavesEffect (click)="onContinue()">
      Continue
    </button>







    <div *ngIf="clienttype === 3 && selectedPlanName" class="newcomarsadd">
      <div class="progress_bar_box">
        <h3>Total pool size $2.5 Million </h3>
        <p #itemElement id="for_tooltip" class="for_tooltip">$ 0.9M</p>
        <div class="progress">
          <div class="progress-bar progress-bar-success" [style]="progressBarWidth" aria-valuenow="85" aria-valuemin="0"
            aria-valuemax="100">
            <span class="popOver" data-toggle="tooltip" data-placement="top" title="85%"> </span>
          </div>
        </div>
        <div class="progress_remark">
          <span>$0M</span>
          <span>$0.5M</span>
          <span>$1M</span>
          <span>$1.5M</span>
          <span>$2M</span>
          <span>$2.5M</span>
        </div>
      </div>
      <div class="download_app_section">
        <div class="AppStore_link">
          <p>Download the Cashaa App</p>
          <div class="link_img_of_store">
            <a href="https://play.google.com/store/apps/details?id=com.cashaa.app&hl=en" target="_blank"><img
                src="../../../assets/images/Cashaa-Google-Play-store.svg" alt=""></a>
            <a href="https://apps.apple.com/in/app/cashaa-buy-earn-crypto/id1637298904" target="_blank"><img
                src="../../../assets/images/cashaa-app-store.svg" alt=""></a>
          </div>
        </div>
        <p class="link_barcode_divide_line">- or -</p>
        <div class="barcode_download_app">
          <img src="https://cashaa-cdn.cashaa.com/assets/qr/barcode-downloadapp.svg" alt="">
          <p>Scan to Download the Cashaa App</p>
        </div>
      </div>
    </div>
<!--  -->
  </div>  
</section>