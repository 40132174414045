import { UpgradeAccountPremierStakingComponent } from "./upgrade-account-premier-staking/upgrade-account-premier-staking.component";
import { AccountUpgradeSubscriptionComponent } from "./account-upgrade-subscription/account-upgrade-subscription.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuardService } from "./../core/services/auth-guard/auth-guard.service";
import { StakingHomeComponent } from "./staking-home/staking-home.component";
import { StakingTimelineComponent } from "./staking-timeline/staking-timeline.component";

// const routes: Routes = [];

const routes: Routes = [
  {
    path: "subscription",
    children: [
      {
        path: "plan",
        component: AccountUpgradeSubscriptionComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "premier-staking",
        component: UpgradeAccountPremierStakingComponent,
        canActivate: [AuthGuardService],
      },
      // {
      //   path: "staking",
      //   component: StakingHomeComponent,
      //   canActivate: [AuthGuardService],
      // },
      {
        path: "staking/timeline",
        component: StakingTimelineComponent,
        canActivate: [AuthGuardService],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountUpgradeRoutingModule {}
