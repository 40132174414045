import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CustomerRoutingModule } from "./customer-routing.module";
import { MDBBootstrapModulesPro } from "ng-uikit-pro-standard";
import { SharedModule } from "../shared/shared.module";
import { ReactiveFormsModule } from "@angular/forms";
import { AddCustomerComponent } from "./add-customer/add-customer.component";
import { CustomerDetailsComponent } from "./customer-details/customer-details.component";
import { ToastrModule } from "ngx-toastr";
import { AddCustomerModalComponent } from "./add-customer-modal/add-customer-modal.component";
import { AddCustomerHomeComponent } from './add-customer-home/add-customer-home.component';

@NgModule({
  declarations: [
    AddCustomerComponent,
    CustomerDetailsComponent,
    AddCustomerModalComponent,
    AddCustomerHomeComponent,
  ],
  imports: [
    CommonModule,
    MDBBootstrapModulesPro.forRoot(),
    ToastrModule.forRoot(),
    SharedModule,
    ReactiveFormsModule,
    CustomerRoutingModule,
  ],
})
export class CustomerModule {}
