import { Component, OnInit, Input } from '@angular/core';
import { ILedgerList } from 'src/app/core/models/activity/recent-transaction-list';

@Component({
  selector: 'cashaa-activity-balance-cancelled',
  templateUrl: './activity-balance-cancelled.component.html',
  styleUrls: ['./activity-balance-cancelled.component.scss']
})
export class ActivityBalanceCancelledComponent implements OnInit {

  @Input() ledger: ILedgerList;
  constructor() { }

  ngOnInit(): void {
  }

}
