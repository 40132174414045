import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { RATE_HUB } from "src/app/app.constants";
import { IMarketRate } from "../../models/market-rate";
import { BehaviorSubject, Observable } from "rxjs";

declare var $: JQueryStatic;

@Injectable({
  providedIn: "root",
})
export class RateWebsocketService {
  /** rate websocket url */
  socketUrl = environment.rateWebsocket;

  /** proxy to websocket */
  proxy: SignalR.Hub.Proxy;

  /** market rate data */
  marketData: IMarketRate[] = [];

  /** Market data emitter */
  marketDataEmitter: BehaviorSubject<IMarketRate[]> = new BehaviorSubject<
    IMarketRate[]
  >([]);

  constructor() {}

  /**
   * Initiate connection to hub
   */
  initiateConnection(): void {
    const connection = $.hubConnection(this.socketUrl);
    this.proxy = connection.createHubProxy(RATE_HUB.HUB_NAME);

    connection
      .start()
      .then(() => {})
      .catch((err) => {
        return console.error(err);
      });

    this.listenToRateData();
  }

  /**
   * Listen to rate data websocket
   */
  listenToRateData(): void {
    this.proxy.on(RATE_HUB.DATA_PATH, (data: IMarketRate) => {
      const foundExisting = this.checkIfAlreadyExist(data);

      // if existing found, replace it
      if (foundExisting && foundExisting.Rate !== data.Rate) {
        const index = this.marketData.indexOf(foundExisting);
        this.marketData[index] = data;
        this.marketDataEmitter.next(this.marketData);
      } else if (!foundExisting) {
        this.marketData.push(data);
        this.marketDataEmitter.next(this.marketData);
      }
      // emit new data
    });
  }

  /**
   * check if market data already exist
   * @param data market data
   */
  checkIfAlreadyExist(data: IMarketRate): IMarketRate | undefined {
    const found = this.marketData
      .filter(
        (d) => d.From === data.From && d.To === data.To && d.side === data.side
      )
      .shift();

    return found;
  }

  /**
   * Get Market data emitter
   */
  getMarketDataEmitter(): Observable<IMarketRate[]> {
    return this.marketDataEmitter.asObservable();
  }
}
