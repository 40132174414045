import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { SettingsHomeComponent } from "./settings-home/settings-home.component";
import { AuthGuardService } from "./../core/services/auth-guard/auth-guard.service";
import { SettingEmailConfirmComponent } from "./setting-email-confirm/setting-email-confirm.component";
const routes: Routes = [
  {
    path: "settings",
    component: SettingsHomeComponent,
    canActivate: [AuthGuardService],
    
  },

  {
    path : "emailconfirm",
    component: SettingEmailConfirmComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class SettingsRoutingModule {}
