<form [formGroup]="form" class="my-4">
  <div class="p-4 text-center" [hidden]="!loader">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
  </div>
  <!-- <h5 *ngIf="businessUserdetails !== undefined && businessUserdetails.emailConfirmed===false">
    <p class="font-weight-normal text-dark" style="text-align: center;">
      Email verification is required,<strong> Not Verified yet!</strong>
    </p>
    <p class="font-weight-normal text-dark" style="text-align: center;">
      <a href="#">Resend</a>verification link.
    </p>
  </h5> -->
  <div class="row mb-4">
    <div class="col-12 col-sm-6">
      <cashaa-form-input name="firstName" type="firstName" [form]="form" [control]="form.controls.firstName"
        placeholder="Enter First Name" label="First Name">
      </cashaa-form-input>
    </div>

    <div class="col-12 col-sm-6">
      <cashaa-form-input name="lastName" type="lastName" [form]="form" [control]="form.controls.lastName"
        placeholder="Enter Last Name" label="Last Name">
      </cashaa-form-input>
    </div>

  </div>

  <div class="row mb-4">
    <div class="col-12 col-sm-6">
      <cashaa-form-input name="email" type="email" [form]="form" [control]="form.controls.email"
        placeholder="Enter Email" label="Email">
      </cashaa-form-input>
    </div>

    <!-- <div class="col-12 col-sm-6">
            <cashaa-form-input name="sourceOfFunds" type="sourceOfFunds" [form]="form"
                [control]="form.controls.sourceOfFunds" placeholder="Enter sourceOfFunds" label="sourceOfFunds">
            </cashaa-form-input>
        </div> -->

    <div class="col-12 col-sm-6">
      <cashaa-form-select [form]="form" [control]="form.controls.sourceOfFunds" name="sourceOfFunds"
        placeholder="Select Source of Fund" [options]="businessSourceOfFunds" label="Source Of Funds">
      </cashaa-form-select>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-12 col-sm-6">
      <cashaa-form-input name="mobileNumber" type="mobileNumber" [form]="form" [control]="form.controls.mobileNumber"
        placeholder="Enter Mobile Number" label="Mobile Number">
      </cashaa-form-input>
    </div>

    <div class="col-12 col-sm-6">
      <cashaa-form-input name="houseNameNumber" type="houseNameNumber" [form]="form"
        [control]="form.controls.houseNameNumber" placeholder="Enter House Number" label="House Number">
      </cashaa-form-input>
    </div>

  </div>
  <div class="row mb-4">
    <div class="col-12 col-sm-6">
      <label class="heading">Date Of Birth</label>
      <mdb-date-picker [selDate]="form?.controls['dateOfBirth'].value" [inline]="true" name="dateOfBirth"
        [placeholder]="'Enter Date of Birth'" [options]="myDatePickerOptions" (dateChanged)="setFormControl($event)">
      </mdb-date-picker>
    </div>

    <div class="col-12 col-sm-6">
      <cashaa-form-select-country name="countryId" [initialValue]="initialValue" type="country" [form]="form"
        [control]="form.controls.countryId" placeholder="Select Country" label="Country"
        (changed)="onCountryChange($event)"></cashaa-form-select-country>
    </div>


  </div>
  <div class="row mb-4">
    <div class="col-12 col-sm-6">
      <cashaa-form-input name="street" type="street" [form]="form" [control]="form.controls.street"
        placeholder="Enter Street" label="Street">
      </cashaa-form-input>
    </div>

    <div class="col-12 col-sm-6">
      <cashaa-form-input name="city" type="city" [form]="form" [control]="form.controls.city" placeholder="Enter City"
        label="City">
      </cashaa-form-input>
    </div>
    <div class="col-12 col-sm-6 mt-4">
      <cashaa-form-input name="postCode" type="postCode" [form]="form" [control]="form.controls.postCode"
        placeholder="Enter Zip/Postal Code" label="Zip/Postal Code">
      </cashaa-form-input>
    </div>

  </div>
  <div class="txt_centre pt-2">
    <button [disabled]="form.invalid" mdbBtn (click)="onFormSubmit()" block="true" type="button"
      class="w-25 rounded-sm position-relative btn btn_color waves-effect waves-light btn-block" mdbWavesEffect>
      Save
    </button>
  </div>
</form>