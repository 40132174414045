export interface IStakeholders {
    id?: string;
    firstName: string;
    lastName: string;
    dateOfBirth?: string;
    email: string;
    phone: string;
    type: string;
    percentageShares: number;
    isMainApplicant: boolean;
    homeAddressLine1: string;
    homeAddressCountryCode: string;
    homeAddressPostCode: string;
    homeAddressCity: string;
    businessContactId?: string;
    businessKYCDetailsId?: string;
}

export interface ICreateBusinessKYC {
    // accountType: string;
    legalBusinessName: string;
    businessEmail: string;
    businessPhone: string;
    industryCode: number;
    businessType: number;
    incorporationDate: Date;
    registration: string;
    isRegulated: boolean;
    tradingAddressLine1: string;
    tradingAddressCountryCode: string;
    tradingAddressPostCode: string;
    tradingAddressCity: string;
    registeredAddressLine1: string;
    registeredAddressCountryCode: string;
    registeredAddressPostCode: string;
    registeredAddressCity: string;
    stakeholdersList: IStakeholders[];
}

export class CreateBusinessKYC implements ICreateBusinessKYC {
    constructor(
        // public accountType: string,
        public legalBusinessName: string,
        public businessEmail: string,
        public businessPhone: string,
        public industryCode: number,
        public businessType: number,
        public incorporationDate: Date,
        public registration: string,
        public isRegulated: boolean,
        public tradingAddressLine1: string,
        public tradingAddressCountryCode: string,
        public tradingAddressPostCode: string,
        public tradingAddressCity: string,
        public registeredAddressLine1: string,
        public registeredAddressCountryCode: string,
        public registeredAddressPostCode: string,
        public registeredAddressCity: string,
        public stakeholdersList: IStakeholders[],
    ) { }
}


