import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { stat } from 'fs';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { RECIPIENT_DELETED_SUCCESS, UNABLE_TO_PERFORM_ERROR, USER_EMAIL_PATH, USER_NAME_ID, VERIFICATION_MESG } from 'src/app/app.constants';
import { NetworkType } from 'src/app/core/enums/network-type-enum';
import { IRecipient, RecipientDelete } from 'src/app/core/models/fIat/recipients';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { FiatService } from 'src/app/fiat/services/fiat.service';
import { CommonSavedData } from 'src/app/shared/services/commonSavedData.service';
import { RecipientService } from '../services/recipient.service';

@Component({
  selector: 'cashaa-recipient',
  templateUrl: './recipient.component.html',
  styleUrls: ['./recipient.component.scss']
})
export class RecipientComponent implements OnInit, OnDestroy {

  @Input() data: IRecipient;
  timerFlag = {};
  timerStart = {};
  timerTime = {};
  disableBtn = {};
  verificationClickCount = {};
  loader: boolean = false;
  sub: Subscription;
  fiatCurrencies = ["USD", "GBP", "EUR", "AUSD", "SGD", "AUD", "CNH", "CAD", "CHF", "NZD", "JPY"];
  constructor(private fiatService: FiatService,
    private toastrService: ToastrService,
    private recipientService: RecipientService,
    private modalService: ModalService,
    private commonSavedData: CommonSavedData) { }

  ngOnInit(): void {
  }

  getNameInitials(): string {
    if (this.data) {
      let splitName = this.data.name.trim().split(" ");
      switch (splitName.length) {
        case 1:
          return (splitName[0][0].trim() + splitName[0][1]).toUpperCase().trim();
        case 2:
          return (splitName[0][0].trim() + splitName[1][0]).toUpperCase().trim();
        case 3:
          return (splitName[0][0].trim() + splitName[2][0]).toUpperCase().trim();
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
          return (splitName[0][0].trim() + splitName[1][0]).toUpperCase().trim();
      }
    } else {
      return "";
    }
  }

  getVerificaionLink(id, curr, i) {
    this.fiatService.verifyRecipient(id, curr).subscribe(
      (flag) => {
        if (flag.message) {
          this.timerTime[i] = 60;
          this.timerFlag[i] = true;
          this.timerStart[i] = true;
          if (isNaN(this.verificationClickCount[i])) {
            this.verificationClickCount[i] = 1;
          } else {
            this.verificationClickCount[i]++;
          }
          this.toastrService.success(VERIFICATION_MESG);
        }
      },
      (err) => {
        if (err.error.message) {
          this.toastrService.error(err.error.message);
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
      }
    );
  }

  timerExpire(i): void {
    if (this.verificationClickCount[i] < 3) {
      this.timerFlag[i] = false;
      this.timerStart[i] = false;
    } else {
      this.disableBtn[i] = true;
      this.timerStart[i] = false;
    }
  }

  deleteRecipient(): void {
    this.modalService.openConfirmationModal("Do you want to delete the recipient?");
    this.sub = this.commonSavedData.getConfirmationBoxStatus().subscribe((status: boolean) => {
      if (status) {
        this.loader = true;
        let recipientDelete = new RecipientDelete(this.data.id);
        this.recipientService.deleteRecipient(recipientDelete).subscribe((res) => {
          this.loader = false;
          if (res.message === "success") {
            this.recipientService.emitDeleteRecipientFlag(true);
            this.toastrService.success(RECIPIENT_DELETED_SUCCESS);
          } else {
            this.toastrService.error("Please try again!");
          }
        }, (err) => {
          if (err.error.message) {
            this.toastrService.error(err.error.message);
          } else {
            this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
          }
          this.loader = false;
        });
      }
      this.sub.unsubscribe();
    });
  }

  getCurrency(): string {
    if (this.data.currency === "AUSD") return "USD";
    if (this.data.currency === "DOG") return "Dogecoin (DOGE)";
    if (this.data.currency === "BCH") return "Bitcoin Cash (BCH)";
    if (this.data.currency === "XRP") return "Ripple (XRP)";
    if (this.data.currency === "LTC") return "Litecoin (LTC)";
    if (this.data.currency === "BTC") return "Bitcoin (BTC)";
    else return this.data.currency;
  }

  emitRecipient(): void {
    return this.recipientService.emitSendMoneyRecipient(this.data);
  }

  getLabel(): string {
    if (this.data.network == 0 || this.data.network == null) {
      if (this.data.currency === 'CAS' || this.data.currency === 'BNB') {
        return "BNB Beacon Chain (BEP2)"
      } else {
        return this.getCurrency();
      }
    } else {
      return NetworkType[this.data.network];
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      // this.sub.unsubscribe();
    }
  }

  updateMemo(data, networkTypeLable) {
    // const name = sessionStorage.getItem(USER_NAME_ID);
    // const email = sessionStorage.getItem(USER_EMAIL_PATH);
    this.modalService.openUpdateMemoModal(data.currency, data.currencyId, data.accountNumber, data.id, data.network, networkTypeLable);
  }
}
