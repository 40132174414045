import { Component, EventEmitter, Output, OnInit, Input } from "@angular/core"
import { FormBuilder, FormGroup } from "@angular/forms";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";

@Component({
  selector: "cashaa-other-details",
  templateUrl: "./other-details.component.html",
  styleUrls: ["./other-details.component.scss"],
})
export class OtherDetailsComponent implements OnInit {
  loading = false;
  @Output() goBack = new EventEmitter();
  @Output() onAddingPersonalDetails = new EventEmitter();
  otherDetailsForm: FormGroup;

  constructor(private fb: FormBuilder) { 
    this.otherDetailsForm = this.fb.group({
      name: ['', [ValidatorUtility.Required]],
      email: ['', [ValidatorUtility.Required, ValidatorUtility.EmailValidator]],
      mobileNo: ['', [ValidatorUtility.Required, ValidatorUtility.mobilenumber]],
      title: ['', [ValidatorUtility.Required]],
      companyName: ['', [ValidatorUtility.Required]]
    });
  }

  ngOnInit(): void {

  }

  onSubmit(){

  }

  goToPreviousPage() {
    this.goBack.emit();
  }

  continue() {
    console.log(';;;;;;;;;;;;;;')
    this.onAddingPersonalDetails.emit(this.otherDetailsForm);
  }
}