import { Component, OnInit } from "@angular/core";
import { USER_TYPE } from "src/app/app.constants";

@Component({
  selector: "cashaa-settings-home",
  templateUrl: "./settings-home.component.html",
  styleUrls: ["./settings-home.component.scss"],
})
export class SettingsHomeComponent implements OnInit {
  user;
  constructor() {}

  ngOnInit(): void {
    this.user = sessionStorage.getItem(USER_TYPE);
  }
}
