<cashaa-sidenav>
  <section class="mh-100">
    <div class="bg-white p-4">
      <h4 class="font-weight-bold sm-profile-heading-mobile-2">Stake & Earn Timeline</h4>
    </div>
  </section>

  <h2 class="heading pb-2">Congratulations!</h2>
  <h6 *ngIf="isWorldWide" class="subtext">You have successfully staked, you will be awarded with a 30%
    APR.</h6>
  <h6 *ngIf="!isWorldWide" class="subtext">You have successfully staked 10,000 CAS, you will be awarded with a 30% APR.
  </h6>
  <h6 class="subtext">Bonus rewards will be paid out weekly, every Monday for the 6 month staking period.</h6>
  <h6 *ngIf="usdNoKyc" class="subtext" style="font-style: italic;">Complete KYC to stake and receive the bonus rewards.
  </h6>

  <div class="p-4 text-center" [hidden]="!isLoading">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
  </div>

  <section class="activity-box px-3 mb-2 staking" *ngIf="!isLoading && !isNormalStaking">
    <div class="details">
      <mdb-accordion [multiple]="false">
        <mdb-accordion-item>
          <mdb-accordion-item-body class="px-4 position-relative sm-custom-stepper">
            <div class="px-5 ml-5 pt-2">
              <div class="row">
                <div class="col-sm">
                  <!-- Vertical Steppers -->
                  <div class="row mt-1">
                    <div class="col-md-12">
                      <!-- Stepers Wrapper -->
                      <ul class="stepper stepper-vertical green-stepper">
                        <!-- First Step -->
                        <li class="success successNext">
                          <a class="sm-default-cursor">
                            <span class="date">{{ ledger?.created | date: "MMM d, yyyy" }}</span>
                            <div class='sm-width-50'><span class="circle text-danger"></span></div>
                            <span class="label">You setup your staking</span>
                          </a>
                        </li>

                        <li class="success successNext">
                          <a class="sm-default-cursor">
                            <span class="date">{{ ledger?.created | date: "MMM d, yyyy" }}</span>
                            <div class='sm-width-50'><span class="circle"></span>
                            </div>
                            <span class="label">You used your cashaa balance</span>
                          </a>
                        </li>
                        <li class="success successNext">
                          <a class="sm-default-cursor">
                            <span class="date">{{ ledger?.created | date: "MMM d, yyyy" }}</span>
                            <div class="sm-width-50">
                              <span class="circle"></span>
                              <div *ngIf="ledger?.islocked || !isDotEnable" class="dot">
                              </div>
                            </div>
                            <span class="label">Locked
                            </span>
                          </a>
                        </li>

                        <!-- iteration loop Step  -->
                        <li *ngFor="let monday of mondayList"
                          [ngClass]="{'success':monday.isSuccess,'successNext':monday.isSuccessNext }">
                          <!--Section Title -->
                          <a *ngIf="monday.text!='no'" class="sm-default-cursor">
                            <span class="date">{{ monday.date | date: "MMM d, yyyy"
                              }}</span>
                            <div class='sm-width-50'>
                              <span class="circle"></span>
                              <div *ngIf="monday.dot" class="dot"></div>
                            </div>
                            <span class="label">{{monday.text}}</span>
                          </a>
                        </li>
                      </ul>
                      <!-- /.Stepers Wrapper -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mdb-accordion-item-body>
        </mdb-accordion-item>
      </mdb-accordion>
    </div>
  </section>

  <section class="activity-box px-3 mb-2 normal-staking" *ngIf="!isLoading && isNormalStaking">
    <div class="details">
      <mdb-accordion [multiple]="false">
        <mdb-accordion-item>
          <mdb-accordion-item-body class="border-top border-light px-4 position-relative sm-custom-stepper">
            <div class="px-5 ml-5 pt-2">
              <div class="row">
                <div class="col-sm">
                  <!-- Vertical Steppers -->
                  <div class="row mt-1">
                    <div class="col-md-12">
                      <!-- Stepers Wrapper -->
                      <ul class="stepper stepper-vertical green-stepper">
                        <!-- First Step -->
                        <li class="success successNext">
                          <a class="sm-default-cursor">
                            <span class="date">{{ ledger?.startDate | date: "MMM d, yyyy" }}</span>
                            <div class='sm-width-50'><span class="circle text-danger"></span></div>
                            <span class="label">You setup your staking</span>
                          </a>
                        </li>

                        <li [ngClass]="{'success':isSuccessClass(2),'successNext':isSuccessClass(3)}">
                          <a class="sm-default-cursor">
                            <span class="date">{{ ledger?.startDate | date: "MMM d, yyyy" }}</span>
                            <div class='sm-width-50'><span class="circle"></span>
                            </div>
                            <span class="label">You used your cashaa balance</span>
                          </a>
                        </li>
                        <li [ngClass]="{'success':isSuccessClass(3),'successNext':isSuccessClass(4)}">
                          <a class="sm-default-cursor">
                            <span class="date">{{ ledger?.startDate | date: "MMM d, yyyy" }}</span>
                            <div class="sm-width-50">
                              <span class="circle"></span>
                              <div *ngIf="ledger?.islocked || !isDotEnable" class="dot">
                              </div>
                            </div>
                            <span class="label">Locked
                            </span>
                          </a>
                        </li>

                        <li [ngClass]="{'success':isSuccessClass(4) }">
                          <!--Section Title -->
                          <a class="sm-default-cursor">
                            <span class="date">{{ ledger?.endDate | date: "MMM d, yyyy"
                              }}</span>
                            <div class='sm-width-50'>
                              <span class="circle"></span>
                              <div *ngIf="ledger.withdrawConfirm && ledger.status===2" class="dot"></div>
                              <div *ngIf="!ledger.islocked" class="dot">
                              </div>
                            </div>
                            <span [ngClass]="{'text-success':ledger.withdrawConfirm && ledger.status===2}"
                              class="label">Unlocked</span>
                          </a>
                        </li>
                      </ul>
                      <!-- /.Stepers Wrapper -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mdb-accordion-item-body>
        </mdb-accordion-item>
      </mdb-accordion>
    </div>
  </section>

  <!-- LEDGER LIST -->
</cashaa-sidenav>