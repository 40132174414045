import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";
import { Signup } from "src/app/core/models/signup";
import { AccountService } from "../sevices/account.service";
import {
  ACCOUNT_TYPE,
  APP_ROUTES,
  RegisterType,
  UNABLE_TO_PERFORM_ERROR,
} from "src/app/app.constants";
import { Observable } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Title, Meta } from '@angular/platform-browser';  
import { environment } from "src/environments/environment";

@Component({
  selector: "cashaa-account-signup",
  templateUrl: "./account-signup.component.html",
  styleUrls: ["./account-signup.component.scss"],
})
export class AccountSignupComponent implements OnInit {
  /** Signup form */
  form: FormGroup;

  /** isSigningIn */
  isSigningUp = false;

  isCountryDisable = false;

  /** error message when failed */
  errorMessage: string;

  passwordInvalid: boolean;
  /**
   * Inject services
   * @param domainService for domain values
   * @param fb form builder
   */
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private accountService: AccountService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,  
    private metaTagService: Meta  
    
  ) {
    this.createForm();
  }

  /**
   * Call http services
   */
  ngOnInit(): void {
    this.accountService.setDisableCross(false);
    this.activatedRoute.queryParams.subscribe(params => {
      if(params.referralCode) {
      this.form.get('referralId').setValue(params.referralCode);
      this.form.get('referralId').disable();
      }
    })
    this.titleService.setTitle("CASHAA SIGN UP");  
    this.metaTagService.addTag({ name: 'description', content: 'Welcome to one account with old and new money' });
    this.metaTagService.addTag({ name: 'image', content: `${location.origin}/assets/media/logo.svg`});
  }

  /** create signup form */
  createForm(): void {
    this.form = this.fb.group({
      email: ["", [ValidatorUtility.Required]],
      password: ["", [ValidatorUtility.Required]],
      country: ["", ValidatorUtility.Required],
      accountType: ["", ValidatorUtility.Required],
      referralId : [""]
    });

    this.form.controls.accountType.valueChanges.subscribe((val) => {
      if (val === ACCOUNT_TYPE.PERSONAL && this.form.value?.country.label === 'United States') {
        this.isCountryDisable = true;
        this.errorMessage = 'Users in the USA are not allowed to create personal accounts. Change your account type to business.';
      } else {
        this.errorMessage = undefined;
        this.isCountryDisable = false;
      }
    })
  }

  /**
   * On Signup form submit
   */
  onSignupSubmit(): void {
    this.isSigningUp = true;
    this.errorMessage = undefined;
    const data = this.form.value;
    const signupData = new Signup(
      data.email,
      data.password,
      data.country.value === "-1" ? null : data.country.value,
      RegisterType.web,
      data.referralId
    );

    let observable$: Observable<{ statusCode: number; message: string }>;
    if (data.accountType === ACCOUNT_TYPE.PERSONAL) {
      observable$ = this.accountService.createPersonalAccount(signupData);
    } else {
      observable$ = this.accountService.createBusinessAccount(signupData);
    }

    observable$.subscribe(
      () => {
        this.isSigningUp = false;
        this.router.navigateByUrl(APP_ROUTES.MESG_BOX + "?source=sign-up");
      },
      (err) => {
        this.isSigningUp = false;
        if (err.error.message) {
          this.errorMessage = err.error.message;
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
      }
    );
  }

  passwordValid(event) {
    this.passwordInvalid = event;
  }

  countryChanged(event) {
    if (this.form.value?.accountType === ACCOUNT_TYPE.PERSONAL && event?.option?.label === 'United States') {
      this.errorMessage = 'Users in the USA are not allowed to create personal accounts. Change your account type to business.';
      this.isCountryDisable = true;
    } else {
        this.isCountryDisable = false;
        this.errorMessage = undefined;
    }
  }

}
