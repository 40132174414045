import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { WithdrawService } from "../services/withdraw.service";
import { Router } from "@angular/router";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";
import { AddressWhitelist } from "src/app/core/models/address-whitelist";
import { ICurrencyList } from "src/app/core/models/currency";
import { IFormSelect } from "src/app/core/models/form-select";
import { UNABLE_TO_PERFORM_ERROR } from "src/app/app.constants";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "cashaa-withdraw-address-whitelist",
  templateUrl: "./withdraw-address-whitelist.component.html",
  styleUrls: ["./withdraw-address-whitelist.component.scss"],
})
export class WithdrawAddressWhitelistComponent implements OnInit {
  /** Form Group */
  createAddressWhitelistForm: FormGroup;
  cryptoCurrencyList: ICurrencyList;
  /** form select values */
  options: IFormSelect[] = [];
  /**  */
  errorMessage: string;

  constructor(
    private addressWhitelistService: WithdrawService,
    private fb: FormBuilder,
    private routes: Router,
    private toastrService: ToastrService
  ) {
    this.createAddressWhitelistForm = this.fb.group({
      currency: ["", [ValidatorUtility.Required]],
      address: ["", [ValidatorUtility.Required]],
      addressLabel: [
        "",
        [ValidatorUtility.Required],
      ],
      password: [""],
    });
  }

  ngOnInit(): void {
    this.getCryptoCurrency();
  }

  getCryptoCurrency(): void {
    this.addressWhitelistService.getCryptoCurrency().subscribe((res) => {
      this.cryptoCurrencyList = res;
      this.options = res.currencies.map((currency) => {
        return {
          value: currency.id,
          label: currency.symbol,
        };
      });
    });
  }

  onFormSubmit(): void {
    this.errorMessage = undefined;
    const data = this.createAddressWhitelistForm.value;

    const addressWhitelistData = new AddressWhitelist(
      data.currency.value,
      data.address,
      data.addressLabel,
      data.password,
      undefined
    );

    this.addressWhitelistService
      .addressWhitelist(addressWhitelistData)
      .subscribe(
        (res) => {
          this.addressWhitelistService.emitAddressWhitelistData(
            addressWhitelistData
          );

          this.createAddressWhitelistForm.reset();
        },

        (err) => {
          if (err.error.message) {
            this.toastrService.error(err.error.message);
            this.errorMessage = err.error.message;
          } else {
            this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
          }
        }
      );
  }
}
