import { FormGroup, ValidatorFn } from "@angular/forms";
import { USER_NAME_ID } from "src/app/app.constants";

/**
 * Validator utitlity for various
 * validations
 */
export class ValidatorUtility {
  constructor() { }

  /**
   * Get error message
   * @param validatorName validator name
   * @param validatorValue validator value
   */
  static GetValidatorErrorMessage(
    validatorName: string,
    validatorValue?: any
  ): string {
    const config = {
      required: "Please fill out this field",
      invalidEmailAddress: "Invalid email address",
      invalidPassword:
        "Password should contain one lowercase, one uppercase, one special character, one number.",
      invalidNumber: "Must be a numeric Value",
      invalidSwiftOrBic: "Invalid SWIFT or BIC Code",
      invalidIban: "Invalid IBAN",
      tagmaxlength: `Maximum length Must be 8`,
      tagminlength: `Minlength length Must be 8`,
      mobilemaxlength: `Maximum length Must be 10`,
      mobileminlength: `Invalid Phone Number`,
      max50: `Max words allowed is 50`,
      postalcode: `Minlength length Must be 4`,
      minlength: `Min length Must be ${validatorValue.requiredLength}`,
      maxlength: `Max length Must be ${validatorValue.requiredLength}`,
      min: `Min Value Must be ${validatorValue.min}`,
      max: `Max Value Must be ${validatorValue.max}`,
      isNumberExist: `This Numer is Already registered`,
      mustMatch: "Password must match",
      invalidUrl: "Url is invalid",
      invalidAddress: "Invalid Address",
      invalidFullName: `The sender name should match the registered KYC details.`,
      invalidIPAddress: `Invalid IP Address`,
      invalidLetter: `Must be Alphabet only`,
      invalidAlphanumeric: 'Must be Alphanumeric'

    };

    return config[validatorName];
  }

  /**
   * Required feild Validator
   * @param control form control
   */
  static Required(control): { [key: string]: boolean } | null {
    // RFC 2822 compliant regex
    if (control === null || control.value === null || control.value === "") {
      return {
        required: true,
      };
    } else if (control.value !== null || control.value.match(/\S/)) {
      return null;
    } else {
      return {
        required: true,
      };
    }
  }
  /**
   * Required feild Validator
   * @param control form control
   */
  static MaxForConvert(max: number): ValidatorFn {
    return (control): { [key: string]: boolean } => {
      if (control.value === ".") {
        return null;
      } else if (control.value) {
        let value = parseFloat(control.value.replaceAll(",", ""));
        if (value <= max) {
          return null;
        } else {
          return {
            max: true,
          };
        }
      }
    };
  }

  /**
   * Required feild Validator
   * @param control form control
   */
  static MinForConvert(min: number): ValidatorFn {
    return (control): { [key: string]: boolean } => {
      if (control.value === ".") {
        return null;
      } else if (control.value) {
        let value = parseFloat(control.value.replaceAll(",", ""));
        if (value >= min) {
          return null;
        } else {
          return {
            min: true,
          };
        }
      }
    };
  }

  /**
   * Only number validator
   * @param control form control
   */
  static OnlyNumberRequired(control): { [key: string]: boolean } | null {
    const pattern = /^([0-9 +][0-9 +]*)$/;
    // RFC 2822 compliant regex
    if (control.value != null && pattern.test(control.value)) {
      return null;
    } else if (!control.value) {
      return null;
    } else {
      return {
        invalidNumber: true,
      };
    }
  }

  /**
 * Only alphabet and number validator
 * @param control form control
 */
  static OnlyAphaNumericRequired(control): { [key: string]: boolean } | null {
    const pattern = /^([a-zA-Z0-9]*)$/;
    // RFC 2822 compliant regex
    if (control.value != null && pattern.test(control.value)) {
      return null;
    } else if (!control.value) {
      return null;
    } else {
      return {
        invalidAlphanumeric: true,
      };
    }
  }

  static ipValidation(control): { [key: string]: boolean } | null {
    const pattern = /^([0-9 .][0-9 .]*)$/;
    // RFC 2822 compliant regex
    if (control.value != null && pattern.test(control.value)) {
      return null;
    } else if (!control.value) {
      return null;
    } else {
      return {
        invalidNumber: true,
      };
    }
  }

  /**
   * Only number validator
   * @param control form control
   */
  static OnlyNumberRequiredWithFormat(
    control
  ): { [key: string]: boolean } | null {
    const pattern = /^([0-9][0-9]*)$/;
    // RFC 2822 compliant regex
    if (
      control.value != null &&
      pattern.test(control.value.replaceAll(",", ""))
    ) {
      return null;
    } else if (control.value != null && !control.value.replaceAll(",", "")) {
      return null;
    } else {
      return {
        invalidNumber: true,
      };
    }
  }

  /**
   * Only number validator
   * @param control form control
   */
  static IbanRegexGBP(control): { [key: string]: boolean } | null {
    const pattern = /^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/;

    // RFC 2822 compliant regex
    if (control.value != null && pattern.test(control.value)) {
      return null;
    } else {
      return {
        invalidIban: true,
      };
    }
  }
  /**
   * Only number validator
   * @param control form control
   */
  static IbanRegexEUR(control): { [key: string]: boolean } | null {
    const pattern = /^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/;
    // RFC 2822 compliant regex
    if (control.value != null && pattern.test(control.value)) {
      return null;
    } else {
      return {
        invalidIban: true,
      };
    }
  }

  /**
   * Only number validator
   * @param control form control
   */
  static swiftOrBicRegex(control): { [key: string]: boolean } | null {
    const pattern = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
    // RFC 2822 compliant regex
    if (control.value != null && pattern.test(control.value)) {
      return null;
    } else {
      return {
        invalidSwiftOrBic: true,
      };
    }
  }

  /**
   * Only number validator
   * @param control form control
   */
  static postalcode(control): { [key: string]: boolean } | null {
    if (control.value && control.value.length >= 4) {
      return null;
    } else {
      return {
        postalcode: true,
      };
    }
  }

  /**
   * Only number validator
   * @param control form control
   */
  static convertCas(control): { [key: string]: boolean } | null {
    if (control.value === ".") {
      return null;
    } else if (control.value) {
      let val = parseFloat(control.value.replaceAll(",", ""));
      if (val != null && val % 10 === 0) {
        return null;
      } else {
        return {
          covertCasMultiplicative: true,
        };
      }
    }
  }

  /**
   * Only number validator
   * @param control form control
   */
  static mobilenumber(control): { [key: string]: boolean } | null {
    if (
      control.value &&
      control.value.match(/^([+]\d{2})?\d{3,13}$/)
    ) {
      return null;
    } else {
      return {
        mobileminlength: true,
      };
    }
  }
  /**
   * Only number validator
   * @param control form control
   */
  static max50(control): { [key: string]: boolean } | null {
    if (control.value != null && control.value.length <= 50) {
      return null;
    } else {
      return {
        max50: true,
      };
    }
  }
  /**
   * Email Validator
   * @param control form control
   */
  static EmailValidator(control): { [key: string]: boolean } | null {
    // RFC 2822 compliant regex
    if (
      control.value &&
      control.value.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9-_]+\.[a-zA-Z.]{2,}$/)
    ) {
      return null;
    } else if (!control.value) {
      return null;
    } else {
      return {
        invalidEmailAddress: true,
      };
    }
  }

  static BitcoinAddressValidator(control): { [key: string]: boolean } | null {
    // RFC 2822 compliant regex
    if (
      control.value &&
      control.value.match(/^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/)
    ) {
      return null;
    } else if (!control.value) {
      return null;
    } else {
      return {
        invalidAddress: true,
      };
    }
  }

  /**
   * Password Validator
   * @param control form control
   */
  static PasswordValidator(control): { [key: string]: boolean } | null {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (
      control.value &&
      control.value.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{6,100}$/
      )
    ) {
      return null;
    } else {
      return {
        invalidPassword: true,
      };
    }
  }

  static AddressCASValidator(control): { [key: string]: boolean } | null {
    if (control.value && control.value.startsWith("bnb")) {
      return null;
    } else if (!control.value) {
      return null;
    } else {
      return {
        invalidAddress: true,
      };
    }
  }

  static AddressETHUSDTValidator(control): { [key: string]: boolean } | null {
    if (control.value && control.value.startsWith("0x")) {
      return null;
    } else if (!control.value) {
      return null;
    } else {
      return {
        invalidAddress: true,
      };
    }
  }

  /**
   * Validator to check whether password
   * and confirm password match
   * @param controlName password
   * @param matchingControlName confirm password
   */
  static confirmPasswordValidator(
    controlName: string,
    matchingControlName: string
  ): ValidatorFn {
    return (form: FormGroup) => {
      const control = form.get(controlName);
      const matchingControl = form.get(matchingControlName);

      // return if another validator has already found an error on the matchingControl
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return null;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  /**
   * Url Validator
   * @param control form control
   */
  static UrlValidator(control): { [key: string]: boolean } | null {
    if (
      control.value && control.value.match(
        /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
      )
    ) {
      return null;
    } else if (!control.value) {
      return null;
    } else {
      return {
        invalidUrl: true,
      };
    }
  }

  static fullNameValidator(control): { [key: string]: boolean } | null {
    const name = sessionStorage.getItem(USER_NAME_ID);
    if (!control.value || control.value.match(name)) {
      return null;
    } else {
      return {
        invalidFullName: true
      }
    }
  }

  static validateIPAddress(control): { [key: string]: boolean } | null {
    if (control.value && control.value.match(/^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/)) {
      return null;
    } else if (!control.value) {
      return null;
    } else {
      return {
        invalidIPAddress: true
      }
    }
  }

  static letterValidator(control): { [key: string]: boolean } | null {
    if (!control.value || control.value.match(/[a-zA-Z]/)) {
      return null;
    } else {
      return {
        invalidLetter: true
      }
    }
  }
}
