import { Component, OnInit } from "@angular/core";
import { AccountService } from "../sevices/account.service";
import { ActivatedRoute, Router } from "@angular/router";
import { WhitelistDevice } from "src/app/core/models/whitelist-device";
import { APP_ROUTES, UNABLE_TO_PERFORM_ERROR } from "src/app/app.constants";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "cashaa-account-whitelist-device",
  templateUrl: "./account-whitelist-device.component.html",
  styleUrls: ["./account-whitelist-device.component.scss"],
})
export class AccountWhitelistDeviceComponent implements OnInit {
  /** Is whitelisting device in progresss */
  isInProgress = true;

  /** Error message if any */
  errorMessage?: string;

  constructor(
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    const deviceId = this.route.snapshot.paramMap.get("id");
    this.checkDeviceIsWhitelisted(deviceId);
  }

  /**
   * Check device is whitelisted or not
   */
  checkDeviceIsWhitelisted(deviceId: string): void {
    this.accountService
      .whitelistDevice(new WhitelistDevice(deviceId))
      .subscribe(
        () => {
          this.isInProgress = false;
          this.router.navigateByUrl(APP_ROUTES.SIGN_IN);
        },
        (err) => {
          this.isInProgress = false;
          if (err.error.message) {
            this.toastrService.error(err.error.message);
            this.errorMessage = err.error.message;
          } else {
            this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
          }
        }
      );
  }
}
