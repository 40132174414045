<div *ngIf="form" [formGroup]="form" class="position-relative text-left sm-input"
  [ngClass]="{'sm-setting-width sm-setting-width-phone':isSetting}">
  <label *ngIf="label" class="heading sm-label"
    [ngClass]=" getErrorMessage() ? 'sm-label-red' : ''">{{ label }} <span *ngIf="isRequired" class="text-danger">*</span>
    <button *ngIf="infoMesg" type="button" class="sm-tooltip" mdbTooltip={{infoMesg}} placement="top">
      <mdb-icon class="pl-2 info-circle" title="" fas icon="info-circle"></mdb-icon>
    </button>
  </label>
  <input [maxLength]="maxLength ? maxLength : '100'" [pattern]="pattern" [id]="id ? id+'fileInput' : name" mdbInput
    [type]="isPasswordType ? (showPassword ? 'text' : 'password') : type" *ngIf="!control?.disabled || !onlyValue"
    [formControlName]="name" class="form-control sm-input-height" [ngClass]="{
      'error-border': (control?.invalid && (control?.dirty || control?.touched) || (email && emailFocus && isEmailError)),
      '': getErrorMessage()?.length > 50
    }" [placeholder]="placeholder" (focusout)="focusOut($event)" (focus)="focusin()"
    (keyup)="transformNumberKeyUp($event)" accept="application/pdf,image/x-png,image/jpeg" />

  <label *ngIf="isFile" [for]="id+'fileInput'" [ngClass]="{'file-padding':isBrokerFile}">Choose a file</label>
  <p *ngIf="control?.disabled && onlyValue" class="font-weight-bolder text-primary-dark">
    {{ control?.value }}
  </p>
  <mdb-error
    *ngIf="!isFile && (control?.invalid && (control?.dirty || control?.touched) || (email && emailFocus && isEmailError))"
    class="hasLabel">
    {{ (email && emailFocus && isEmailError) ? getEmailErrorMesg() : getErrorMessage() }}
  </mdb-error>

  <i *ngIf="isPasswordType && eye" (click)="toggleShowPassword()" class="fa fa-eye position-absolute"
    [ngClass]="{ 'text-primary-light': showPassword }"></i>
</div>