<cashaa-sidenav>
    <section class="mh-100">
        <div class="bg-white p-4 sm-media-padding">
            <div class="d-flex sm-phone-display-none mr-3" style="height: 47px;">
                    <button routerLink="/repay" mdbBtn type="button" class="cursor-pointer btn-sm app-primary-btn">Back</button>
            </div>
        </div>
    </section>

    <!--  -->
    <div class="text_cntnt table_main_section" style="padding: 24px;">
        <h5 style="color: #1531AA;">Loan interest</h5>
        <p class="" style="color: #3E4B5B;">Interest charged daily will automatically be deducted from your saving wallet in the currency of the loan availed. In case of unavailability of balance, the interest amount will accrue to your outstanding loan amount</p>


        <table class="table table-bordered border-primary align-middle">
            <thead>
                <tr class="">
                    <!-- <th scope="col"></th> -->
                    <th scope="col">Date</th>
                    <th scope="col">Transaction ID</th>
                    <th scope="col">Amount</th>
                </tr>
            </thead>
            <tbody class="">
                <tr class="" *ngFor="let intLoan of interestonLoan">
                    <td>{{intLoan?.date | date : 'dd MMMM, yyyy'}}</td>
                    <td>{{intLoan?.id}}</td>
                    <td><img src="../../../assets/icons/{{intLoan?.currencySymbol}}.png" width="25px" alt="{{intLoan?.currencySymbol}}"> &nbsp;{{intLoan?.amount | number: '0.0-9'}}</td>

                </tr>
            </tbody>
        </table>
    </div>

    

</cashaa-sidenav>
<div class="d-flex row p-4 mr-2" *ngIf="!loading">
    <div class="col-md-6 p-0">
        <span class="content" style="font-weight: 600;">{{pageIndex}}-{{pageSize}} of {{length}}</span>
    </div>
    <div class="col-md-6 p-0" style="text-align-last: end;">
        <span class="content mr-2" style="font-weight: 600;">The page you're on {{pageIndex}}</span>
        <mdb-icon (click)="previous()" class="arrow-icon arrow-left mr-2" fas icon="chevron-left"></mdb-icon>
        <mdb-icon (click)="next()" class="arrow-icon arrow-right" fas icon="chevron-right"></mdb-icon>
    </div>
</div>