<cashaa-sidenav>
    <section class="mh-100">
        <div class="bg-white p-4">
             <div class="d-flex mr-3" style="height: 48px;">
                <button (click)="onGoBack()" mdbBtn type="button" class="btn-sm app-primary-btn ml-5">Back</button>
             </div>
        </div>
    </section>
        
        <div class="p-4 h-100">
            <div class="mx-auto stepper w-phone-100 w-75">
                <mdb-stepper #stepper [linear]="true" class="w-100" id="cred-line">
                    <mdb-step name="Borrow" class="step">
                        <cashaa-borrow (onConfirm)="confirm($event)"></cashaa-borrow>
                    </mdb-step>
                    <mdb-step name="Security Verification" class="step">
                        <cashaa-review-security-verification (goback)="onGoBack()" (onContinue)="continue($event)" [currencyId]="currencyId" [loanAmount]="loanAmount">
                        </cashaa-review-security-verification>
                    </mdb-step>
                    <mdb-step name="Confirm" class="step">
                        <cashaa-confirm></cashaa-confirm>
                    </mdb-step>
                </mdb-stepper>
            </div>
        </div>
  
</cashaa-sidenav>