import { Component, OnInit, ViewChild } from "@angular/core";
import { TradeService } from "../services/trade.service";
import { TRADE_MODE } from "src/app/app.constants";
import { ITradeChange } from "src/app/core/models/trade";
import { TabsetComponent, TabDirective } from "ng-uikit-pro-standard";

@Component({
  selector: "cashaa-trade-buy-sell-tabs",
  templateUrl: "./trade-buy-sell-tabs.component.html",
  styleUrls: ["./trade-buy-sell-tabs.component.scss"],
})
export class TradeBuySellTabsComponent implements OnInit {
  /** shown tab */
  shownTab = 0;

  constructor(private tradeService: TradeService) {}

  ngOnInit(): void {}

  /**
   * On tab change
   */
  onTabChange(event: any): void {
    this.shownTab = event && event.activeTabIndex ? event.activeTabIndex : 0;
  }
}
