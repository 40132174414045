import { IFormSelect } from "./../../core/models/form-select";
import { DomainService } from "src/app/core/services/domain/domain.service";
import {
  USER_ID,
  USER_FEE_MODE,
  USER_FEE_PERCENT,
  UNABLE_TO_PERFORM_ERROR,
  FEE_MODE_UPDATED,
  INTEREST_MODE_CREATED,
  USER_INTEREST_MODE,
} from "./../../app.constants";
import { FormGroup } from "@angular/forms";
import { SettingsService } from "./../services/settings.service";
import { FormBuilder } from "@angular/forms";
import { ValidatorUtility } from "./../../core/utility/validator.utility";
import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute } from "@angular/router";
import { IInterestMode } from "src/app/core/models/interest-mode";

@Component({
  selector: 'cashaa-setting-interest-choice',
  templateUrl: './setting-interest-choice.component.html',
  styleUrls: ['./setting-interest-choice.component.scss']
})
export class SettingInterestChoiceComponent implements OnInit {

  /** is opened */
  isOpen = false;

  /** show form or not */
  showForm = false;

  /** is some call in progress */
  isInProgress = false;

  /** form group */
  form: FormGroup;
  interestMode?: IFormSelect[];
  interestModelist = [];
  /** selected fee mode */
  selectedinterestMode: any;
  /** user user fee mode address */
  userInterestMode: string = sessionStorage.getItem(USER_INTEREST_MODE);
  userId = sessionStorage.getItem(USER_ID);

  errorMessage: string;

  isCollapsed = true;
  isChecked : boolean;

  constructor(
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private domainService: DomainService,
    private toastrService: ToastrService,
    private activateRoute: ActivatedRoute
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.activateRoute.queryParams.subscribe((params) => {
      const setting2FA = params["step"];
      if (setting2FA === "interestmode") {
        this.isCollapsed = false;
        this.isOpen = true;
      }
    });
   this.getUserinterestMode();
    this.createForm();
  }
  //
  /**
   * create new form
   */
  createForm(): void {
    this.form = this.fb.group({
      interestChoiceId: [this.userInterestMode, ValidatorUtility.Required],
    });
  }
  
  onInterestChoiceFormSubmit(): void {
    this.isInProgress = true;
    const inKindChoiceId = this.interestModelist.filter(item => item.choiceType == 1).map( obj => obj.id);
    const inCasChoiceId = this.interestModelist.filter(item => item.choiceType == 2).map( obj => obj.id);
    if(this.isChecked) {
      this.form.controls["interestChoiceId"].setValue(inKindChoiceId.toString());
    } else {
      this.form.controls["interestChoiceId"].setValue(inCasChoiceId.toString());
    }
    this.isChecked = !this.isChecked;
    this.settingsService.createInterestChoice(this.form.value).subscribe(
      (res) => {
        this.showForm = false;
        this.isInProgress = false;
        // this.userInterestMode = this.interestMode.filter((data) => data.value === this.form.value.interestChoiceId)[0].label;
        this.userInterestMode = res.userInterestMode;
        sessionStorage.setItem(USER_INTEREST_MODE, res.userInterestMode);
        this.toastrService.success(INTEREST_MODE_CREATED);
      },
      (err) => {
        this.isChecked = !this.isChecked;
        this.isInProgress = false;
        if (err.error.message) {
          this.toastrService.error(err.error.message);
          this.errorMessage = err.error.message;
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
      }
    );
  }

  getUserinterestMode() {
    this.domainService.getUserInterestModeList().subscribe((resData) => {
   this.interestModelist = resData;
      this.interestMode = resData.map((d) => {
        if (!this.userInterestMode && d.choiceType === 1) {
          this.userInterestMode = d.interestChoiceName;
        }
        return {
          value: d.id,
          label: d.interestChoiceName,
        };
      });
    this.userInterestMode === 'InKind'? this.isChecked = false: this.isChecked = true;
    });
  }

}
