export interface ICreateBusinessUser {
    firstName?: string;
    lastName?: string;
    email?: string;
    sourceOfFunds?: string;
    emailConfirmed?: boolean;
    emailVerificationCode?: string;
    mobileNumber?: string;
    mobileNumberConfirmed?: boolean;
    dateOfBirth?: Date;
    countryId?: string;
    houseNameNumber?: string;
    street?: string;
    city?: string;
    postCode?: string;
    kycStatus?: number;
}

export class CreateBusinessUser implements ICreateBusinessUser {
    constructor(
        public firstName?: string,
        public lastName?: string,
        public email?: string,
        public sourceOfFunds?: string,
        public emailConfirmed?: boolean,
        public emailVerificationCode?: string,
        public mobileNumber?: string,
        public mobileNumberConfirmed?: boolean,
        public dateOfBirth?: Date,
        public countryId?: string,
        public houseNameNumber?: string,
        public street?: string,
        public city?: string,
        public postCode?: string,
        public kycStatus?: number
    ) { }
}
