import { Injectable } from "@angular/core";
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
} from "@angular/router";
import { UserType } from "../../enums/userType";
import {
  APP_ROUTES,
  AUTH_TOKEN_PATH,
  USER_NAME_PATH,
  USER_EMAIL_PATH,
  USER_ID,
  USER_TYPE,
  USER_NAME_ID,
  USER_FEE_MODE,
  USER_FEE_PERCENT,
  SEESION_FLAG,
  SEESION_OBJECT,
  USER_TWO_FACTOR_CODE,
  USER_INTEREST_MODE,
  USER_COUNTRY_CODE,
  USER_REFERRAL_CODE,
  IS_CAS_SWAP_Enabled,
} from "src/app/app.constants";
@Injectable({
  providedIn: "root",
})
export class AuthGuardService implements CanActivate {
  /**
   * Construct this component
   * @param router Router
   */
  constructor(private router: Router) {}

  /**
   * Can given route be activated or not
   * @param next next handler
   * @param state current state of the route
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let sessionFlag = localStorage.getItem(SEESION_FLAG);

    if (
      sessionStorage.getItem(AUTH_TOKEN_PATH) != null &&
      sessionFlag === "true"
    ) {
      let tempObj: any = {};
      tempObj.token = sessionStorage.getItem(AUTH_TOKEN_PATH);
      tempObj.name = sessionStorage.getItem(USER_NAME_PATH);
      tempObj.email = sessionStorage.getItem(USER_EMAIL_PATH);
      tempObj.cashaaUserId = sessionStorage.getItem(USER_ID);
      tempObj.userType = sessionStorage.getItem(USER_TYPE).toString();
      tempObj.userName = sessionStorage.getItem(USER_NAME_ID);
      tempObj.userFeeMode = sessionStorage.getItem(USER_FEE_MODE);
      tempObj.conversionFee = sessionStorage.getItem(USER_FEE_PERCENT);
      tempObj.twofactorcode = sessionStorage.getItem(USER_TWO_FACTOR_CODE);
      tempObj.userCountryCode =  sessionStorage.getItem(USER_COUNTRY_CODE);
      tempObj.isCASSWAPEnabled =  sessionStorage.getItem(IS_CAS_SWAP_Enabled);
      localStorage.setItem(SEESION_OBJECT, JSON.stringify(tempObj));
      return true;
    } else if (
      localStorage.getItem(SEESION_OBJECT) != null &&
      sessionFlag === "true"
    ) {
      let res = JSON.parse(localStorage.getItem(SEESION_OBJECT));
      sessionStorage.setItem(AUTH_TOKEN_PATH, res.token);
      sessionStorage.setItem(USER_NAME_PATH, res.name);
      sessionStorage.setItem(USER_EMAIL_PATH, res.email);
      sessionStorage.setItem(USER_ID, res.cashaaUserId);
      sessionStorage.setItem(USER_TYPE, res.userType.toString());
      sessionStorage.setItem(USER_NAME_ID, res.userName);
      sessionStorage.setItem(USER_FEE_MODE, res.userFeeMode);
      sessionStorage.setItem(USER_INTEREST_MODE, res.userInterestMode);
      sessionStorage.setItem(USER_FEE_PERCENT, res.conversionFee);
      sessionStorage.setItem(USER_TWO_FACTOR_CODE, res.twofactorcode);
      sessionStorage.setItem(USER_COUNTRY_CODE, res.userCountryCode);
      sessionStorage.setItem(USER_REFERRAL_CODE, res.referalCode);
      sessionStorage.setItem(IS_CAS_SWAP_Enabled, res.isCASSWAPEnabled);
      return true;
    } else {
      // this.router.navigateByUrl(APP_ROUTES.UNAUTHORIZED);
      this.router.navigate(['/account/signin'], { queryParams: { returnUrl: state.url }});
      return false;
    }
  }

  /**
   * can activate the child or not
   * @param next next route
   * @param state current state
   */
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(next, state);
  }
}
