<section class="py-5 w-75 mx-auto fiat-flow-amount">
  <div *ngIf="!hideMainPage && !loader">
    <h4 *ngIf="sendCurrencySymbol !== 'INR'" class="heading mb-4 text-center">
      How much would you like to exchange?
    </h4>
    <h4 *ngIf="sendCurrencySymbol === 'INR'" class="heading mb-4 text-center">
      How much would you like to buy w/ INR?
    </h4>
    <p class="red-text mb-1 font-weight-normal" *ngIf="isMinSendValueError && !form.controls.senderAmount.hasError('max')">
      Min {{minAmount}} {{sendCurrencySymbol}} amount will exchange
    </p>
    <!-- (changed)="resetCurrency()" -->
    <div class="d-flex justify-content-center mb-4" *ngIf="currencyWiseNetworkList">
      <cashaa-form-select-transfer-currency (sendCurrencyChangeEvent)="onsendCurrencyChangeEvent($event)" [form]="form"
        class="width-100" [amountControl]="form.controls.senderAmount" [isSendModule]="false" [isConvertModule]="true"
        [isSendInput]="true" [currencyControl]="form.controls.sendercurrencyId" [controlCurrency]="sendCurrencySymbol"
        label="Exchange" [noAccountCurrency]="true" amountControlName="senderAmount"
        currencyControlName="sendercurrencyId" type="send" showBalance="true" [isSendConvert]="true"
        [recieveCurrencySymbol]="receiveCurrencySymbol" [currencyMap]="currencyMap" >
      </cashaa-form-select-transfer-currency>
    </div>
    <div class="mb-4 position-relative pl-5" *ngIf="sendCurrencySymbol !== 'INR' && receiveCurrencySymbol !== 'INR'">
      <div class="d-flex align-items-center text-primary-dark">
        <p class="font-weight-bold ml-2 mr-1 mb-0">
          {{!(selectedfeeamount <= 0 || selectedfeeamount> 0) ? 0 : selectedfeeamount | number:
            getDecimalNumber(checkFeeMode() == 1 ? casSymbol : getCurrencyName(sendCurrencySymbol)) }}
            {{
            checkFeeMode() == 1 ? casSymbol : getCurrencyName(sendCurrencySymbol)
            }}
        </p>
        <!--[routerLink]="['/settings']" [queryParams]="{step:'fee'}-->
        <p class="font-weight-bold ml-2 mb-0 p-0">Fee</p>
        <br>
        <span class="red-text mb-1 font-weight-normal sm-insf-bal" *ngIf="iscasfee"> Insufficient CAS balance</span>
        <span class="mb-0 p-2 font-weight-normal ng-star-inserted" *ngIf="!iscasfee && userFeeMode==='InCashaa'"> You have
          {{casFeeCheckBalance}} CAS
          balance</span>
      </div>
      <div class="text-gray mt-4">
        <p *ngIf="actulaconvertedamount > 0" class="font-weight-bolder ml-2 mr-3 mb-0">
          {{ actulaconvertedamount | number: getDecimalNumber(sendCurrencySymbol) }} {{
          getCurrencyName(sendCurrencySymbol) }} Amount
          we'll
          exchange
        </p>

        <p *ngIf="actulaconvertedamount <= 0 || !(actulaconvertedamount <= 0 || actulaconvertedamount > 0)"
          class="exc-amt ml-2 mr-3 mb-0">
          0 {{ getCurrencyName(sendCurrencySymbol) }} Amount we'll exchange
        </p>
      </div>
      <div class="text-primary-light mt-4 d-flex">
        <p class="heading ml-2 mr-3 mb-0">
          {{ rate | number: "0.2-10" }} {{getCurrencyName(rateSymbol)}}
          <!-- 1.88649 -->
        </p>
        <span class="text-gray ml-1 font-weight-bolder"> Rate </span>
      </div>
      <span class="line-left">
        <mdb-icon class="line-icon first-icon" fas icon="minus"> </mdb-icon>
        <mdb-icon class="line-icon second-icon" fas icon="equals"></mdb-icon>
        <mdb-icon class="line-icon third-icon" fas icon="times"></mdb-icon>
      </span>
    </div>
    <p class="red-text mb-1 font-weight-normal" *ngIf="isMinReceiveValueError">
      <!-- {{receiveCurrencySymbol}} amount should not be less than {{minAmount}} -->
      Min {{sendCurrencySymbol === 'CAS' ? minCASAmount : (minAmount)}} {{receiveCurrencySymbol}} amount will exchange
    </p>
    <div class="d-flex justify-content-center mb-5">
      <!-- (changed)="resetCurrency()" -->
      <cashaa-form-select-transfer-currency [isConvertModule]="true"
        (reciveCurrencyChangeEvent)="onreciveCurrencyChangeEvent($event)" class="width-100" [form]="form"
        [amountControl]="form.controls.receiverAmount" [isSendModule]="false" [controlCurrency]="receiveCurrencySymbol"
        [currencyControl]="form.controls.receivercurrencyId" [noAccountCurrency]="true" label="To"
        amountControlName="receiverAmount" currencyControlName="receivercurrencyId" type="receive"
        [sendCurrencySymbol]="sendCurrencySymbol" [currencyMap]="currencyMap" [isRecieveConvert]="true"
        >
      </cashaa-form-select-transfer-currency>
    </div>
    <div *ngIf="sendCurrencySymbol === 'INR'" class="mb-5">
      <cashaa-form-select [form]="networkForm" [control]="networkForm.controls.networkType" name="networkType" label="Network Type"
      [options]="networkTypeOptions" (changed)="networkTypeChanges($event)">
    </cashaa-form-select>
    </div>
    <div *ngIf="sendCurrencySymbol === 'INR'" class="term-condition mb-3">
      <mdb-checkbox [(ngModel)]="isAddressGenerated" [disabled]="!walletAddress">
        <p class="font-weight-normal">I have generated the destination crypto address in my Cashaa account.</p>
      </mdb-checkbox>
      <div *ngIf="!walletAddress" class="generate-address" (click)="generateAddress()">Generate Address</div>
      <mdb-checkbox [(ngModel)]="thirdPartyConditionsAccepted">
        <p class="font-weight-normal">This exchange will be executed on our partner app</p>
      </mdb-checkbox>
      <mdb-checkbox [(ngModel)]="isKYCRedoWithDigiLocker">
        <p class="font-weight-normal">I agree to redo KYC with DigiLocker app.</p>
      </mdb-checkbox>
      <mdb-checkbox [(ngModel)]="termsAndConditionsAccepted">
        <a class="p-0" target="_blank" href="https://cashaa.com/term-condition">Terms & Conditions</a> 
      </mdb-checkbox>
       
    </div>
    <div class="width-100">

      <button *ngIf="sendCurrencySymbol !== 'INR' && receiveCurrencySymbol !== 'INR'" [disabled]="isMinSendValueError || isMinReceiveValueError || rate == 0 || form.invalid || isInvalid || iscasfee || checkValues()" mdbBtn block="true"
        type="button"
        class="rounded-sm position-relative btn btn-secondary sm-custom-sec waves-effect waves-light btn-block"
        mdbWavesEffect (click)="onContinue()">
        Get Quote
      </button>
      <button *ngIf="sendCurrencySymbol === 'INR' || receiveCurrencySymbol === 'INR'"  mdbBtn block="true"
      type="button" [disabled]="!thirdPartyConditionsAccepted || !termsAndConditionsAccepted || form.invalid || !isAddressGenerated || !isKYCRedoWithDigiLocker || form.controls.senderAmount.value <= 0 || isMinSendValueError "
      class="rounded-sm position-relative btn btn-secondary sm-custom-sec waves-effect waves-light btn-block"
      mdbWavesEffect (click)="onGetProcess()">
      Buy
    </button>
   
    <div *ngIf="receiveCurrencySymbol === 'INR'" class="text-danger text-center mt-2">Currently we are not exchanging to INR</div>
    </div>
  </div>
  <div class="p-4 text-center" [hidden]="!loader">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
  </div>
  <iframe *ngIf="hideMainPage" [src]="srcUrl|safe" frameborder="0" allowfullscreen
  style="position: absolute;
  top: 0px;
  left: 45px;
  width: 87.5%;
  height: 100%;"></iframe>

</section>

