<section class="activity-box px-3">
  <!-- <div class="date p-2">
   {{ ledger.created | date : "EEEE, MMMM d, y"}}
   </div> -->
  <div class="details">
    <mdb-accordion [multiple]="false" aria-multiselectable="false">
      <mdb-accordion-item class="no-collase ledger-list-item">
        <mdb-accordion-item-head class="px-2 py-2" mdbWavesEffect>
          <div class="d-flex flex-row px-2 align-items-center">
            <div class="px-2 circle">
              <img src="../../../assets/images/Deposited.png" />
            </div>
            <div class="status-recieved ml-2 transaction-detail">
              From
              <span *ngIf="isCrypto()" class="text-primary-dark">
                <a target="_blank"
                   [attr.href]="getRef()"
                   class="text-primary-dark" target="_blank">{{ledger.fromAddress}}</a>
              </span>
              <span *ngIf="isFiatAndAccount()" class="text-primary-dark">
                <!-- {{ledger.fullName}}  -->
                {{ledger.fromAddress}}
              </span>
              <br />
              <span class="text-gray">{{ getType(ledger.ledgerType) }}</span>
            </div>
            <div class="amount ml-auto mr-4">
              <ng-container *ngIf="ledger.currency === 'BTC';else ETH">
                <b>{{ ledger.amount | number: "0.0-8" }} {{ ledger.currency }}</b>
                <br />
                <span class="text-gray">
                  {{ ledger.amount + ledger.networkFee | number: "0.0-8" }}
                  {{ getCurrencyName(ledger.currency) }}
                </span>
              </ng-container>
              <ng-template #ETH>
                <ng-container *ngIf="ledger.currency === 'ETH'; else BNB">
                  <b>{{ ledger.amount | number: "0.0-6" }} {{ ledger.currency }}</b>
                  <br>
                  <span class="text-gray">
                    {{ ledger.amount + ledger.networkFee | number: "0.0-6" }}
                    {{ getCurrencyName(ledger.currency) }}
                  </span>
                </ng-container>
              </ng-template>
              <ng-template #BNB>
                <ng-container *ngIf="ledger.currency === 'BNB'; else others">
                  <b>{{ ledger.amount | number: "0.0-5" }} {{ ledger.currency }}</b>
                  <br>
                  <span class="text-gray">
                    {{ ledger.amount + ledger.networkFee | number: "0.0-5" }}
                    {{ getCurrencyName(ledger.currency) }}
                  </span>
                </ng-container>
              </ng-template>
              <ng-template #others>
                <b>{{ ledger.amount | number: "0.0-4" }} {{ ledger.currency }}</b>
                <br>
                <span class="text-gray">
                  {{ ledger.amount + ledger.networkFee | number: "0.0-4" }}
                  {{ getCurrencyName(ledger.currency) }}
                </span>
              </ng-template>
            </div>
          </div>
        </mdb-accordion-item-head>
        <mdb-accordion-item-body class="px-4 position-relative">
          <div class="container px-4 pt-2">
            <div class="row">
              <div class="col-sm">
                To <br />
                <b class="text-primary" *ngIf="isCrypto()" class="font-weight-bolder text-primary">
                  {{
 ledger.toAddress
                  }}
                </b>
                <b class="text-primary" *ngIf="isFiatAndAccount()">
                  {{
"Your
                  "+ledger.currency+" account"
                  }}
                </b>
              </div>
              <div *ngIf="isCrypto()" class="col-sm">
                Transaction Hash <br />
                <div class="w-75">
                  <a target="_blank" [attr.href]="getRef()" class="font-weight-bolder text-primary" target="_blank" *ngIf="!isTransactionIdExistMigration()">{{ledger.transactionId}}</a>
                  <span class="font-weight-bolder text-primary" *ngIf="isTransactionIdExistMigration()">{{ledger.transactionId}}</span>
                </div>
              </div>

              <div *ngIf="isFiatAndAccount()" class="col-sm">
                {{isFiatAndAccount() ?'Reference':'Memo'}} <br />
                <b class="text-primary">{{ ledger.transactionData }}</b>
              </div>
            </div>
            <div class="row mt-3">
              <div *ngIf="isFiatAndAccount()"
                   class="col-sm">
                Transfer Number <br />
                <b class="text-primary">{{ ledger.transactionId }}</b>
              </div>
              <div *ngIf="isFiatAndAccount() && ledger.convertfee > 0" class="col-sm">
                Fee <br />
                <b class="text-primary">{{ledger.convertfee}} {{ ledger.feeCurrency}}</b>
              </div>
            </div>
          </div>
          <div class="btn-group mx-4 position-absolute no-arrow details-button" mdbDropdown placement="left">
            <mdb-icon fas icon="ellipsis-h" classInside="dropdown-toggle" mdbDropdownToggle class="waves-effect"
                      mdbWavesEffect></mdb-icon>
            <div class="dropdown-menu dropdown-primary">
              <a class="dropdown-item" (click)="openDetailBox()">View Received details</a>
            </div>
          </div>
        </mdb-accordion-item-body>
      </mdb-accordion-item>
    </mdb-accordion>
  </div>
</section>

