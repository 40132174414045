<section class="my-5 py-5 text-center" style="width: 450px;margin: auto;">
  <h3 class="font-weight-bold text-dark-gray mb-5">
    All done! We sent your invites
  </h3>
  <p class="font-weight-bolder mb-3">
    Tell your friends to keep an eye on their inbox. Their invite to <br />
    Cashaa, including their free transfers, should be with them shortly
  </p>
  <div class="d-flex">
    <button mdbBtn block="true" type="button" outline="true" class="mr-3" color="primary" mdbWavesEffect
      routerLink="/activity/list">
      Done
    </button>

    <button mdbBtn block="true" type="button"
      class="rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block font-weight-normal"
      mdbWavesEffect routerLink="/invite/link">
      Send more invites
    </button>
  </div>
</section>