import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpHeaders,
} from "@angular/common/http";
import {
  AUTH_TOKEN_PATH,
  APP_ROUTES,
  USER_NAME_PATH,
  USER_EMAIL_PATH,
  USER_ID,
  USER_TYPE,
  USER_NAME_ID,
  USER_FEE_MODE,
  USER_FEE_PERCENT,
  SEESION_OBJECT,
  SEESION_FLAG,
  USER_INTEREST_MODE,
  USER_COUNTRY_CODE,
  USER_REFERRAL_CODE,
} from "src/app/app.constants";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    let idToken = sessionStorage.getItem(AUTH_TOKEN_PATH);
    let sessionFlag = localStorage.getItem(SEESION_FLAG);

    if (!idToken && sessionFlag === "true") {
      let res = JSON.parse(localStorage.getItem(SEESION_OBJECT));
      sessionStorage.setItem(AUTH_TOKEN_PATH, res.token);
      sessionStorage.setItem(USER_NAME_PATH, res.name);
      sessionStorage.setItem(USER_EMAIL_PATH, res.email);
      sessionStorage.setItem(USER_ID, res.cashaaUserId);
      sessionStorage.setItem(USER_TYPE, res.userType.toString());
      sessionStorage.setItem(USER_NAME_ID, res.userName);
      sessionStorage.setItem(USER_FEE_MODE, res.userFeeMode);
      sessionStorage.setItem(USER_INTEREST_MODE, res.userInterestMode);
      sessionStorage.setItem(USER_FEE_PERCENT, res.conversionFee);
      sessionStorage.setItem(USER_COUNTRY_CODE, res.userCountryCode);
      sessionStorage.setItem(USER_REFERRAL_CODE, res.referalCode);
      idToken = res.token;
    }

    if (idToken && sessionFlag === "true") {
      const headers = new HttpHeaders({
        "Authorization": "Bearer " + idToken ,
        "header-sign-key": environment.HEADER_SIGN_KEY
      })
      // let cloned = req.clone({
      //   headers: req.headers.set("Authorization", "Bearer " + idToken),
      // });
      const cloned = req.clone({
        headers
      });
      
      return next.handle(cloned).pipe(
        catchError((err) => {
          if (err.status === 401) {
            this.router.navigateByUrl("unauthorized");
          }
          return throwError(err);
        })
      );
    } else {
      return next.handle(req).pipe(
        catchError((err) => {
          if (err.status === 401) {
            this.router.navigateByUrl(APP_ROUTES.UNAUTHORIZED);
          }
          return throwError(err);
        })
      );
    }
  }
}
