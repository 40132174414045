<div class="mb-5 text-align-center">
  <mdb-spinner *ngIf="loader" spinnerColor="blue"></mdb-spinner>
</div>
<form [formGroup]="form" class="my-4">

  <div class="row mb-4">
    <!-- <p>{{companyDetails|json}}</p> -->
    <!-- <div class="col-12 col-sm-6">
      <cashaa-form-select-accounttype
        source="businessplan"
        name="accountType"
        type="accountType"
        [form]="form"
        [control]="form.controls.accountType"
        placeholder="Enter Account Type"
        label="Account type"
      >
      </cashaa-form-select-accounttype>
    </div> -->
    <div class="col-12 col-sm-6">
      <cashaa-form-input name="legalBusinessName" type="legalBusinessName" [form]="form"
        [control]="form.controls.legalBusinessName" placeholder="Enter Legal Business Name" label="Business Name">
      </cashaa-form-input>
    </div>

    <div class="col-12 col-sm-6">
      <cashaa-form-input name="businessEmail" type="businessEmail" [form]="form" [control]="form.controls.businessEmail"
        placeholder="Enter Business Email" label="Business Email" type="email">
      </cashaa-form-input>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-12 col-sm-6">
      <cashaa-form-input name="businessPhone" type="businessPhone" [form]="form" [control]="form.controls.businessPhone"
        placeholder="Enter Business Phone" label="Business Phone">
      </cashaa-form-input>
    </div>
    <div class="col-12 col-sm-6">
      <cashaa-form-select [form]="form" [control]="form.controls.industryCode" name="industryCode" label="Industry Code"
        placeholder="Select Industry Code" [options]="industryCodeType"></cashaa-form-select>

    </div>
  </div>
  <div class="row mb-4">
    <div class="col-12 col-sm-6 mb-4">
      <cashaa-form-select [form]="form" [control]="form.controls.businessType" name="businessType" label="Business Type"
        placeholder="Select Business Type" [options]="companyType"></cashaa-form-select>
    </div>
    <div class="col-12 col-sm-6">
      <label class="heading">Business Incorporation Date</label>
      <mdb-date-picker *ngIf="companyDetails" [inline]="true" [selDate]="companyDetails.incorporationDate"
        name="incorporationDate" [options]="myDatePickerOptions" [placeholder]="'Enter Business Incorporation Date'"
        (dateChanged)="setFormControl($event)">
      </mdb-date-picker>

      <mdb-date-picker *ngIf="!companyDetails" [inline]="true" name="incorporationDate"
        [placeholder]="'Enter Busniness Incorporation Date'" [options]="myDatePickerOptions"
        (dateChanged)="setFormControl($event)">
      </mdb-date-picker>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-12 col-sm-6">
      <cashaa-form-input name="registration" type="registration" [form]="form" [control]="form.controls.registration"
        placeholder="Enter Business registration" label="Business Registration">
      </cashaa-form-input>
    </div>
    <div class="col-12 col-sm-6">
      <div>
        <label class="heading">Is your business regulated?
        </label>
      </div>

      <div class="mb-4 d-flex">
        <div class="form-check mr-3 pl-0">
          <input type="radio" formControlName="isRegulated" value="true" class="form-check-input" id="true"
            name="isRegulated" mdbInput value="true" />
          <label class="form-check-label font-weight-bolder" for="true">Yes</label>
        </div>
        <div class="form-check">
          <input type="radio" value="false" class="form-check-input" id="false" formControlName="isRegulated"
            name="isRegulated" mdbInput value="false" />
          <label class="form-check-label font-weight-bolder" for="false">No</label>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-12 col-sm-6">
      <cashaa-form-input name="tradingAddressLine1" type="tradingAddressLine1" [form]="form"
        [control]="form.controls.tradingAddressLine1" placeholder="Enter Trading Address Line 1"
        label="Trading Address Line 1">
      </cashaa-form-input>
    </div>
    <div class="col-12 col-sm-6">
      <cashaa-form-input name="tradingAddressCountryCode" type="tradingAddressCountryCode" [form]="form"
        [control]="form.controls.tradingAddressCountryCode" placeholder="Enter Trading Address Country Code"
        label="Trading Address Country Code" infoMesg="UK/US/EU">
      </cashaa-form-input>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-12 col-sm-6">
      <cashaa-form-input name="tradingAddressPostCode" type="tradingAddressPostCode" [form]="form"
        [control]="form.controls.tradingAddressPostCode" placeholder="Enter Trading Address Zip / Postal Code"
        label="Trading Address Zip / Postal Code">
      </cashaa-form-input>
    </div>
    <div class="col-12 col-sm-6">
      <cashaa-form-input name="tradingAddressCity" type="tradingAddressCity" [form]="form"
        [control]="form.controls.tradingAddressCity" placeholder="Enter Trading Address City"
        label="Trading Address City">
      </cashaa-form-input>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-12 col-sm-6">
      <cashaa-form-input name="registeredAddressLine1" type="registeredAddressLine1" [form]="form"
        [control]="form.controls.registeredAddressLine1" placeholder="Enter Trading Registered Address Line 1"
        label="Trading Registered Address Line 1">
      </cashaa-form-input>
    </div>
    <div class="col-12 col-sm-6">
      <cashaa-form-input name="registeredAddressCountryCode" type="registeredAddressCountryCode" [form]="form"
        [control]="form.controls.registeredAddressCountryCode" placeholder="Enter Registered Address Country Code"
        label="Registered Address Country Code" infoMesg="UK/US/EU">
      </cashaa-form-input>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-12 col-sm-6">
      <cashaa-form-input name="registeredAddressPostCode" type="registeredAddressPostCode" [form]="form"
        [control]="form.controls.registeredAddressPostCode" placeholder="Enter Registered Address Zip / Postal Code"
        label="Registered Address Zip / Postal Code">
      </cashaa-form-input>
    </div>
    <div class="col-12 col-sm-6">
      <cashaa-form-input name="registeredAddressCity" type="registeredAddressCity" [form]="form"
        [control]="form.controls.registeredAddressCity" placeholder="Enter Registered Address City"
        label="Registered Address City">
      </cashaa-form-input>
    </div>
  </div>
  <!-- <p>{{companyDetails|json}}</p> -->
  <!-- <div class="row mb-4">
    <div class="col-12 col-sm-4">
      <cashaa-form-select-country name="countryId" type="countryId" [form]="form" [control]="form.controls.countryId"
        placeholder="Enter Country Id" label="Country">
      </cashaa-form-select-country>
    </div>
    <div class="col-12 col-sm-4">
      <cashaa-form-select-phonecode name="countryCode" type="countryCode" [form]="form"
        [control]="form.controls.countryCode" placeholder="Enter Country Code" label="Country code">
      </cashaa-form-select-phonecode>
    </div>
    <div class="col-12 col-sm-4">
      <cashaa-form-input name="businessWebsiteLink" type="businessWebsiteLink" [form]="form"
        [control]="form.controls.businessWebsiteLink" placeholder="Enter business website link" label="Website link">
      </cashaa-form-input>
    </div>
  </div> -->

  <!-- <div class="mb-4">
    <cashaa-form-textarea name="businessDetails" type="text" [form]="form" [control]="form.controls.businessDetails"
      placeholder="Enter Business Details " label="Business details (in about 500 words)">
    </cashaa-form-textarea>
  </div>

  <div>
    <label class="font-weight-bolder">Is a politically exposed person part of your Business?
    </label>
  </div>

  <div class="mb-4 d-flex">
    <div class="form-check mr-3 pl-0">
      <input type="radio" formControlName="politicallyExposed" class="form-check-input" id="true"
        name="politicallyExposed" mdbInput value="true" />
      <label class="form-check-label font-weight-bolder" for="true">true</label>
    </div>
    <div class="form-check">
      <input type="radio" formControlName="politicallyExposed" class="form-check-input" id="false"
        name="politicallyExposed" mdbInput value="false" />
      <label class="form-check-label font-weight-bolder" for="false">false</label>
    </div>
  </div> -->
  <div class="txt_centre pt-2">
    <button mdbBtn (click)="onFormSubmit()" block="true" type="button"
      class="w-25 rounded-sm position-relative btn btn_color waves-effect waves-light btn-block" mdbWavesEffect
      [disabled]="form.invalid">
      Save & Next
    </button>
  </div>
</form>