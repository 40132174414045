<div class="row">
  <div class="col-4">
    <button
      type="button"
      class="py-2 rounded-sm btn btn-block btn-ins waves-effect waves-light"
    >
      <i class="fab fa-facebook-f left fa-2x"></i>
    </button>
  </div>
  <div class="col-4">
    <button
      outline="true"
      type="button"
      class="py-2 rounded-sm btn btn-block waves-effect waves-light google-button"
    >
      <img src="../../../../assets/media/google.svg" />
    </button>
  </div>
  <div class="col-4">
    <button
      type="button"
      outline="true"
      class="py-2 rounded-sm btn btn-block waves-effect waves-light"
    >
      <i class="fab fa-apple left fa-2x"></i>
    </button>
  </div>
</div>
