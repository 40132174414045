<div class="d-flex justify-content-center">
  <mdb-spinner spinnerColor="blue" *ngIf="loading"></mdb-spinner>
  <h4 *ngIf="errorMessage" class="text-center">{{ errorMessage }}</h4>

  <div *ngIf="isKycDone" class="text-center">
    <img src="../../../../assets/images/kyc_completed.png.png"/>
    <h3 class="text-center font-weight-bold">KYC Completed</h3>
  </div>

  <div *ngIf="createKycDone" class="text-center">
    <img src="../../../../assets/images/kyc_in_progess.png"/>
    <h3 class="text-center font-weight-bold">Manual KYC document verification will take from 24 to 72
      hrs to get processed</h3>
  </div>
</div>