import { ModalService } from "src/app/core/services/modal/modal.service";
import { Component, OnInit, Input } from "@angular/core";
import { ILedgerList } from "src/app/core/models/activity/recent-transaction-list";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import {
  TRANSACTION_SUCCESS_CANCELLED,
  UNABLE_TO_PERFORM_ERROR,
  VERIFICATION_MESG,
} from "src/app/app.constants";
import { FiatService } from "src/app/fiat/services/fiat.service";

@Component({
  selector: "cashaa-activity-balance-weekly-reward",
  templateUrl: "./activity-balance-weekly-reward.component.html",
  styleUrls: ["./activity-balance-weekly-reward.component.scss"],
})
export class ActivityBalanceWeeklyRewardComponent implements OnInit {
  @Input() ledger: ILedgerList;

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {}

  openDetailBox(): void {
    this.modalService.openActivityTransferDetail(this.ledger);
  }
}
