import { CurrencyType } from '../enums/currencyType';

export interface IFiatAccountRequestModel {
    currencySymbol: string;
}




export class FiatAccountRequestModel implements IFiatAccountRequestModel {
    constructor(
        public currencySymbol: string,

    ) { }
}