<div class="modal-body font-weight-bolder text-dark">
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span style="font-size: 35px;" aria-hidden="true">×</span>
  </button>
  <div class="p-4 text-center" [hidden]="!loader">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
  </div>
  <div class="position-relative sm-font-family sm-form-padding" *ngIf="!loader">
    <!-- <div class="earn-plan-bg">
      <img src="../../../assets/images/earn_plan_bg.png">
    </div> -->

    <h4 class="text-primary-dark text-initial font-weight-600 mt-3 pr-4 pl-4">Interest Breakdown</h4>
    <!-- <div class="col-md-12 text-center mb-4 mt-3"> -->
    <div class="row p-4 m-auto">
      <!-- <div class="row text-initial"> -->
      <!-- <div class="col-12"> -->
      <!-- <h6 class="font-weight-bolder mt-3 mb-3"> -->
      <!-- <div class="row"> -->
      <img class="icon" [src]="'../../../../assets/icons/' + getCurrencyName(symbol) + '.png'" alt="">

      <!-- <div> -->
      <span class="col-10 main-span mt-2">
        <span *ngIf="(!isFixedDepositEnable || isFixedAndEarnEnable) && currentPlanUserDetail">{{getCurrencyName(currentPlanUserDetail?.symbol)}}</span>
        <span *ngIf="isFixedDepositEnable">{{getCurrencyName(symbol)}}</span> Savings Wallet Balance
        <div class="currency-amount">
          <span class="text-primary-dark font-weight-bold"
                *ngIf="(!isFixedDepositEnable || isFixedAndEarnEnable) && currentPlanUserDetail">{{getCurrencyName(currentPlanUserDetail.symbol)}}</span>
          <span class="text-primary-dark font-weight-bold"
                *ngIf="isFixedDepositEnable">{{getCurrencyName(symbol)}}</span>
          <span class="text-primary-dark font-weight-bold pl-1">{{balance | number: "0.0-4" }}</span>
        </div>
      </span>
      <!-- </div> -->
      <!-- </div> -->
      <!-- </h6>/ -->
      <!-- </div> -->
      <!-- </div> -->
      <!-- </div> -->
    </div>
    <div [ngClass]="{'d-flex justify-content-center pl-4 pr-4': isFixedDepositEnable || isEarnModuleNotActive, 'row display_flex_nowrap m-auto': isFixedAndEarnEnable}">
      <div [ngClass]="{'col-md-8': isFixedDepositEnable, 'col-md-6': isFixedAndEarnEnable}"
           *ngIf="(isFixedDepositEnable || isFixedAndEarnEnable) && !isEarnModuleNotActive">
        <div class="intrest_bank-box left-box font-weight-bolder">
          <!-- <div class="gray_circle_sec"> -->
          <p class="m-2 font-weight-bold" *ngIf="balance === 0 && isFiatCurrency">
            Deposit or buy {{symbol}} and start earning
          </p>
          <p class="m-2 font-weight-bold" *ngIf="balance === 0 && !isFiatCurrency">
            Deposit or buy {{symbol}} coin and start earning
          </p>
          <!-- <h1>{{getMinCasDepositRate()}}{{getMinCasDepositRate() === 0
            ?'':'%'}}</h1>-->
          <!-- </div> -->
          <div class="gray_bg-sec mb-3">
            <p class="m-2 text-initial">You will earn</p>
            <!-- <p class="m-2">Earn up to <span class="green_text">{{getMaxCasDepositRate()}}% </span>interest with Fixed
              Term Deposits</p> -->
            <div *ngIf="userType =='4' && symbol !=='CAS'" class=" d-flex mx-2 mb-n2">
              <p class="perc-circle mr-1">
                {{
getRate() === 0 ? 'NIL' :
                getRate()
                }}{{getRate() === 0 ? '' : '%'}}
              </p><span class="font-weight-600">Flex Term</span>
            </div>
            <div class="sub-unsub my-2 d-flex" *ngIf="userType =='4'">
            <button class="app-primary-btn w-100 p-1" *ngIf="selectedCurrency[0].isFlexSubscribed" (click)="unsub()">Unsubscribe</button>
            <button class="subscribe-btn" *ngIf="!selectedCurrency[0].isFlexSubscribed" (click)="subcribe()">Subscribe</button>
            </div>
            
            <div class="d-flex mx-2 mb-n2">
              <p class="perc-circle mr-1">
                {{getMinCasDepositRate()}}{{getMinCasDepositRate() === 0 ?'':'%'}}
              </p><span class="font-weight-600">Fixed term</span>
            </div>
            <!-- <a (click)="redirectDepositInterest()">
              <p class="m-0 btn-app position-relative alignment tp">
                Fixed Term <i class="fas fa-angle-right"></i></p>
            </a> -->
          </div>
          <p *ngIf="balance === 0" class="m-1 text-center fixed-term">
            Earn up to <span class="green_text font-weight-bold">{{getMaxCasDepositLessThanEqualRate()}}% </span>interest with Fixed
            Term Deposits
          </p>
          <div class="d-flex">
            <button *ngIf="balance === 0" class="app-primary-btn btn-sm w-100 mb-2"
                    (click)="deposit()">
              Deposit
            </button>
          </div>
          <button *ngIf="balance !== 0" class="app-primary-btn w-100 mb-2 btn-sm" (click)="redirectDepositInterest()">
            Fixed
            term
          </button>
          <p *ngIf="balance !== 0" class="m-1 text-center fixed-term">
            Earn up to <span class="green_text font-weight-bold">{{getMaxCasDepositLessThanEqualRate()}}% </span>interest with Fixed
            Term Deposits
          </p>
          <button *ngIf="balance !== 0 && getMaxCasDepositRate() > 0 " class="app-primary-btn w-100 mb-2 btn-sm" (click)="redirectDepositInterestForLongTerm()">Cashaa Long Income Plan</button>
          <p *ngIf="getMaxCasDepositRate() > 0" class="long-term m-1 text-center">
            Earn up to <span class="green_text font-weight-bold">{{getMaxCasDepositRate()}}% </span>interest with 3 year Fixed
            Term Deposits
          </p>


          <!-- <div class="wight_bg-sec text-center">

          </div> -->
        </div>
      </div>
      <div class="col-md-6" *ngIf="(!isFixedDepositEnable || isFixedAndEarnEnable) || isEarnModuleNotActive">
        <div class="intrest_bank-box right-box text-initial">
          <p class="m-2">Loyalty Level</p>
          <div class="text-primary-dark font-weight-bolder">

            <h4 class="mb-2 font-weight-600 p-2">
              <img class="plan-icon" src="../../../assets/images/plan/{{userCurrentPlan.name}}.svg"
                   alt="">{{userCurrentPlan.name}}
            </h4>
          </div>
          <button class="app-tertiary-btn btn-sm w-100" (click)="redirectPlans()" *ngIf="!upgradeDisable; else onlyIcon">
            Upgrade to {{upgradePlanName}} Level <i class="fas fa-angle-right"></i>
          </button>
          <ng-template #onlyIcon>
            <button class="app-tertiary-btn w-100" (click)="redirectPlans()">
              Diamond Plan
              <i class="fas fa-angle-right"></i>
            </button>
          </ng-template>
        </div>
        <div class="intrest_bank-box right-box mt-3" *ngIf="userInterestMode === 'InKind' && userType == '4'">
          <div class="wight_bg-sec text-initial">
            <p class="mb-2">Interest Options</p>
            <h6 class="font-weight-600">Earn in Cas</h6>
            <a [routerLink]="['/settings']" [queryParams]="{step:'interestmode'}" class="m-0 text-primary-dark"
               (click)="modalRef.hide()">
              <p class="m-0 text-primary-dark">
                Get <span *ngIf="currentPlanUserDetail" class="green_text font-weight-600">{{currentPlanUserDetail.casRate}}% </span>
                <span *ngIf="!currentPlanUserDetail" class="green_text font-weight-600">NIL&nbsp;</span>Bonus
                <i class="fas fa-angle-right"></i>
              </p>
            </a>
            <!-- <button class="app-tertiary-btn btn-sm w-100" (click)="redirectToSetting()">
            Boost your earings<i class="fas fa-angle-right"></i>
            </button> -->
          </div>
        </div>
        <div class="intrest_bank-box right-box mt-3" *ngIf="userInterestMode === 'InKind'&& userType !== '4'">
          <div class="wight_bg-sec text-initial">
            <p class="mb-2">Interest Options</p>
            <h6 class="font-weight-600">Earn in Cas</h6>
            <a [routerLink]="['/settings']" [queryParams]="{step:'interestmode'}" class="m-0 text-primary-dark"
               (click)="modalRef.hide()">
              <p class="m-0 text-primary-dark">
                Get <span class="green_text font-weight-600">{{userCurrentPlan.casBonus}}% </span>Bonus
                <i class="fas fa-angle-right"></i>
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row pl-3 pr-3 mt-2 mb-2">
      <div class="col-md-12 pt-2">
        <p class="font-size-10 m-0 mt-1">
          *FLEX Interest Rate is he current internest rate compounding dally on lyour FLEX Terms balance.
          It is compised of the Savings Interest Rate of your Loyalty level and the 2% bouns rate when you opt-in
          to receive the interest in CAS Tokens
        </p>
      </div>
    </div> -->
  </div>
</div>
