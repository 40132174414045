import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ICurrency } from "src/app/core/models/currency";
import { IFormSelect } from "src/app/core/models/form-select";
import { DomainService } from "src/app/core/services/domain/domain.service";
import { CurrencyType } from "src/app/core/enums/currencyType";
import { Observable, Subject } from "rxjs";
import { startWith, map } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "cashaa-form-select-currency-pair",
  templateUrl: "./form-select-currency-pair.component.html",
  styleUrls: ["./form-select-currency-pair.component.scss"],
})
export class FormSelectCurrencyPairComponent implements OnInit {
 /** Placeholder for sourcetype fiatCurrency,cryptoCurrency,allCurrency */
 @Input() source: string;

 /** Placeholder for input */
 @Input() placeholder = "Select currency";

 /** input form group */
 @Input() form: FormGroup;

 /** form control */
 @Input() control: FormControl;

 /** name of control */
 @Input() name: string;

 /** Form select values */
 @Input() options: any[] = [];

 @Input() label = "";

 @Input() setCurrency;
 @Input() isFDActive;


 @Input() set initialValue(data) {
   if (data) {
     this.getCurrencyPairList(data);
   }
 }

 @Output() changed = new EventEmitter<ICurrency>();

 currencies: ICurrency[] = [];

 searchText = new Subject();

 filteredOptions: Observable<any[]>;

 optionIcon: string;
//  @Input() ('fromCurrencyId') set(val) {

//  }

 constructor(private domainService: DomainService, private activateRoute: ActivatedRoute) {}

 ngOnInit(): void {
  console.log(this.options);
   this.activateRoute.queryParams.subscribe((params) => {
     this.setCurrency = params["cur"];
   });
 }

 filter(value: string): any[] {
   const filterValue = value.toLowerCase();
   return this.options.filter((option: any) =>
     option.label.toLowerCase().includes(filterValue)
   );
 }

 /**
  * Get Currency list
  */
 getCurrencyPairList(data?): void {
   this.domainService.getCurrencyPairListById(data).subscribe((list) => {
     this.options = list.map((currencies) => {
       return {
         value: currencies.toCurrencyId,
         label: currencies.toSymbol,
         icon: `../../../../assets/icons/${currencies.toSymbol}.png`,
         symbol : currencies.toSymbol,
         currencyType: currencies.currencyType,
       };
     });
     this.options.push({
      value: list[0].currencyId,
      label: list[0].fromSymbol,
      icon: `../../../../assets/icons/${list[0].fromSymbol}.png`,
      symbol: list[0].fromSymbol,
      currencyType: list[0].currencyType
    })
     this.filteredOptions = this.searchText.pipe(
       startWith(""),
       map((value: string) => {
         return this.filter(value);
       })
     );

     if (this.setCurrency || data) {
       let cur;
       if (this.setCurrency) {
         cur = this.options.filter((c) => c.label === this.setCurrency);
       } else {
         cur = this.options.filter((c) => c.value === data);
       }
       setTimeout(() => {
         if (this.options.length > 0) {
           this.form.controls[this.name].patchValue(cur[0], {
             emitEvent: false,
           });
           this.control.setValue(cur[0]);
           this.onCurrencyChange(cur[0]);
         }
       });
     }
   });
 }

 onCurrencyChange(value: IFormSelect): void {
  console.log(value);
   const cur = this.options.filter((c) => c.symbol === value.label);
   if (cur.length > 0) {
     this.changed.emit(cur[0]);
   }
   this.selectedImg(
     this.options.find((option: IFormSelect) => {
       return option.label === value.label;
     })
   );
 }

 selectedImg(v: IFormSelect) {
   if (v.icon) {
     this.optionIcon = v.icon;
   }
 }
}
