import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IMyOptions } from "ng-uikit-pro-standard";
import { ToastrService } from "ngx-toastr";
import { UNABLE_TO_PERFORM_ERROR } from "src/app/app.constants";
import { ICurrency } from "src/app/core/models/currency";
import { IFormSelect } from "src/app/core/models/form-select";
import { ICustomer } from "src/app/core/models/recipient/recipient-add-usd";
import { ModalService } from "src/app/core/services/modal/modal.service";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";
import { RecipientService } from "src/app/recipient/services/recipient.service";
import { SubSink } from "subsink";
import { CustomerService } from "../services/customer.service";

const customerType = require("../../../assets/json/customerType.json");
const addressdocumentTypes = require("../../../assets/json/addressProofDocumentType.json");
const identitydocumentTypes = require("../../../assets/json/identityProofDocumentType.json");

@Component({
  selector: "cashaa-add-cutomer",
  templateUrl: "./add-customer.component.html",
  styleUrls: ["./add-customer.component.scss"],
})
export class AddCustomerComponent implements OnInit {
  public customerDetail: ICustomer[] = [];
  loading: boolean = false;
  subsink = new SubSink();
  constructor(
    private customerService: CustomerService,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.loadCustomerDetails();
    this.updateCustomerView();
    this.subsink.add(this.customerService.getDeleteRecipientFlagEmitter().subscribe((res) => {
      if (res) {
        this.loadCustomerDetails();
      }
    }));
  }

  updateCustomerView() {
    this.customerService.getCustomerAdd().subscribe((data: boolean) => {
      if (data) {
        this.loadCustomerDetails();
      }
    });
  }

  loadCustomerDetails(): void {
    this.loading = true;
    this.customerService.getAllCustomers().subscribe(
      (customerData: ICustomer[]) => {
        this.customerDetail = customerData;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        if (err.error.message) {
          this.toastrService.error(err.error.message);
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
      }
    );
  }


  ngOnDestroy() {
    this.subsink.unsubscribe();
  }


}
