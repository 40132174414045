import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cashaa-profile-personal',
  templateUrl: './profile-personal.component.html',
  styleUrls: ['./profile-personal.component.scss']
})
export class ProfilePersonalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
