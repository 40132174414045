import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TradeBuySellTabsComponent } from "./trade-buy-sell-tabs/trade-buy-sell-tabs.component";
import { TradeBuyFormComponent } from "./trade-buy-form/trade-buy-form.component";
import { TradeSellFormComponent } from "./trade-sell-form/trade-sell-form.component";
import { TradeRoutingModule } from "./trade-routing.module";
import { MDBBootstrapModulesPro } from "ng-uikit-pro-standard";
import { TradeHomeComponent } from "./trade-home/trade-home.component";
import { SharedModule } from "../shared/shared.module";
import { TradeBuySellConfirmationComponent } from "./trade-buy-sell-confirmation/trade-buy-sell-confirmation.component";
import { FormsModule } from "@angular/forms";
import { ToastrModule } from "ngx-toastr";

@NgModule({
  declarations: [
    TradeBuySellTabsComponent,
    TradeBuyFormComponent,
    TradeBuyFormComponent,
    TradeSellFormComponent,
    TradeHomeComponent,
    TradeBuySellConfirmationComponent,
  ],
  imports: [
    CommonModule,
    TradeRoutingModule,
    FormsModule,
    MDBBootstrapModulesPro.forRoot(),
    ToastrModule.forRoot(),
    SharedModule,
  ],
})
export class TradeModule {}
