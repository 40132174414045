import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { IFormSelect } from "src/app/core/models/form-select";
import { DomainService } from "src/app/core/services/domain/domain.service";

@Component({
  selector: "cashaa-form-input-director-role",
  templateUrl: "./form-input-director-role.component.html",
  styleUrls: ["./form-input-director-role.component.scss"],
})
export class FormInputDirectorRoleComponent implements OnInit {
  /** Placeholder for input */
  @Input() source: string;

  /** Placeholder for input */
  @Input() placeholder = "Director Roles";

  /** input form group */
  @Input() form: FormGroup;

  /** form control */
  @Input() control: FormControl;

  /** name of control */
  @Input() name: string;

  @Input() label = "";

  /** form select values */
  options: IFormSelect[] = [];

  constructor(private domainService: DomainService) {}

  ngOnInit(): void {
    this.getDirectorRoleList();
  }

  /***Get list of available directors*/

  getDirectorRoleList(): void {
    this.domainService.getRoles().subscribe((list) => {
      this.options = list.directorRolesList.map((role) => {
        return {
          value: role.id,
          label: role.role,
        };
      });
    });
  }
}
