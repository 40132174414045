import { IUpgradeSubscriptionRequest } from "./../../core/models/account-upgrade/upgrade-subscription-request";
import { BehaviorSubject } from "rxjs";
import { Observable } from "rxjs";
import { SubscriptionPlans, UserSubscriptionPlan } from "./../../core/models/account-upgrade/subscription-plan-response";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AccountUpgradeService {
  private apiUrl = environment.api;

  private usdFeePayment = new BehaviorSubject<boolean | undefined>(undefined);

  constructor(private http: HttpClient) {}
  /**
   * Get the user subscription plan details as per user role/type
   * @ param RequestData
   */
  getSubscriptionPlan(RequestData): Observable<SubscriptionPlans[]> {
    return this.http.get<SubscriptionPlans[]>(
      `${this.apiUrl}/subscriptionplans/${RequestData}`
    );
  }

  getUserActionSubscriptionPlan(): Observable<UserSubscriptionPlan> {
    return this.http.get<UserSubscriptionPlan>(
      `${this.apiUrl}/subscriptions/getusersubscriptionplan`
    );
  }
  /**
   * Get the user subscription plan details as per user role/type
   * @ param RequestData
   */
  checkPremierStatus(): Observable<{ statusCode: number; message: string }> {
    return this.http.get<{ statusCode: number; message: string }>(
      `${this.apiUrl}/subscriptions/premiumStatus`
    );
  }

  /**
   * Subscription plan upgradation
   * @ param upgradePlanRequestdata
   */
  upgradeSubscriptionPlan(
    upgradePlanRequestdata: IUpgradeSubscriptionRequest
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${this.apiUrl}/subscriptions/usd-onboarding-fee-pay`,
      upgradePlanRequestdata
    );
  }
  /**
   * Subscription plan upgradation
   * @ param upgradePlanRequestdata
   */
  createStakingPersonal(
    upgradePlanRequestdata: IUpgradeSubscriptionRequest
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${this.apiUrl}/subscriptions/create-personal-world-wide-staking`,
      upgradePlanRequestdata
    );
  }

  /**
   * Subscription plan upgradation
   * @ param upgradePlanRequestdata
   */
  usdStatus(): Observable<{ status: number }> {
    return this.http.get<{ status: number }>(
      `${this.apiUrl}/subscriptions/usd-onboarding-status`
    );
  }
  /**
   * Subscription plan upgradation
   * @ param upgradePlanRequestdata
   */
  userKycStatus(): Observable<{ status: number }> {
    return this.http.get<{ status: number }>(
      `${this.apiUrl}/kyc/user-kyc-status`
    );
  }

  /**
   * Get subscription plan id data emitter
   */
  getusdFeePaymentEmitter(): Observable<boolean> {
    return this.usdFeePayment.asObservable();
  }

  /**
   * Emit subscription plan id data
   */
  usdFeePaymentEmitter(data: boolean): void {
    this.usdFeePayment.next(data);
  }
}
