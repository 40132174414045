import { APP_ROUTES, SUBSCRIPTION_BUSINESS_PLAN, SUBSCRIPTION_PERSONAL_PLAN } from "src/app/app.constants";
import { ActivatedRoute, Router } from "@angular/router";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";
import { FormBuilder } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { SubscriptionPlans, UserSubscriptionPlan } from "./../../core/models/account-upgrade/subscription-plan-response";
import { AccountUpgradeService } from "./../services/account-upgrade.service";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ModalService } from "src/app/core/services/modal/modal.service";
import { SubSink } from "subsink";
import { USER_TYPE } from "src/app/app.constants";
import { ActivityService } from "src/app/activity/services/activity.service";
import { KycService } from "src/app/kyc/services/kyc.service";
import { IPaymentFeesDetail, IUpgradePayment } from "src/app/core/models/kyc-us-personal/us-personal";

@Component({
  selector: "cashaa-account-upgrade-subscription",
  templateUrl: "./account-upgrade-subscription.component.html",
  styleUrls: ["./account-upgrade-subscription.component.scss"],
})
export class AccountUpgradeSubscriptionComponent implements OnInit, OnDestroy {
  upgradeForm: FormGroup;
  subscriptionPlanResponse: SubscriptionPlans[];
  userType: string;
  isBrokerBtnVisible: boolean = false;
  isUSBtnVisible: boolean = false;
  isReserveBtnVisible: boolean = false;
  isUKBtnVisible: boolean = false;
  isEuroBtnVisible: boolean = false;
  subsink = new SubSink();
  loading: boolean;
  usdButtonText: string;
  usdButtonDisable: boolean;
  usdStatus: number;
  currentPlanDetails: UserSubscriptionPlan;
  cryptoButtonText: string;
  isPremuimPlan: boolean = false;
  premuimFeesDetails:IPaymentFeesDetail
  constructor(
    private fb: FormBuilder,
    private upgardeService: AccountUpgradeService,
    private modalService: ModalService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private activityService: ActivityService,
    private kycService: KycService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params["highlight"]) {
        this.modalService.openPersonalModal(false);
      } else if (params["highlightBusiness"]) {
        this.modalService.openPersonalModal(true);
        this.isUSBtnVisible = true;
      }
    });

    this.userType = sessionStorage.getItem(USER_TYPE);
    this.getUsdStatus();
    this.getActionSubscriptionPlan();

    this.kycService.getPremuimFeesDetail().subscribe(
      (res) => {
        if (res) {
          this.premuimFeesDetails = res;
        }
      }
    );
  }

  createForm(): void {
    this.upgradeForm = this.fb.group({});
  }

  /**
   * Get the user subscription plan
   */
  getSubscriptionPlan(): void {
    this.upgardeService.getSubscriptionPlan(4).subscribe((list) => {
      this.subscriptionPlanResponse = list;
    });
  }

  getUsdStatus(): void {
    this.loading = true;
    this.usdButtonDisable = false;
    let filterData = {
      activityType: "14",
      fromDate: "",
      toDate: "",
      symbol: [],
      searchText: "",
    };

    this.activityService.getRecentTransactions(filterData).subscribe((res) => {
      if (res.length !== 0) {
        this.usdButtonText = "";
        this.usdButtonDisable = true;
        this.loading = false;
      } else {
        this.upgardeService.usdStatus().subscribe(
          (flag) => {
            this.usdStatus = flag.status;
            switch (flag.status) {
              case 1:
                this.usdButtonText =
                  this.userType == "4" ? "Apply" : "Get Usd Account";
                this.cryptoButtonText = "Complete KYC"
                break;

              case 2:
                this.usdButtonText = "Update KYC Details";
                this.cryptoButtonText = "Complete KYC"
                break;

              case 3:
                this.usdButtonText = "KYC in Progress";
                this.cryptoButtonText = "KYC in Progress"
                break;

              case 4:
                this.usdButtonText = "Update KYC Details";
                this.cryptoButtonText = "KYC Failed"
                break;

              case 5:
                this.usdButtonText = "KYC in Progress";
                this.cryptoButtonText = "Verified"
                break;

              case 6:
                this.usdButtonText = "Account Creation in Progress";
                this.cryptoButtonText = "Verified"
                this.usdButtonDisable = true;
                break;

              case 7:
                this.usdButtonText = "";
                this.cryptoButtonText = "Verified"
                this.usdButtonDisable = true;
                break;
            }
            this.loading = false;
          },
          (err) => {
            this.loading = false;
          }
        );
      }
    });
  }

  // tslint:disable-next-line: typedef
  onSubmitPremier(flag: boolean, plan?: string) {
    if (this.usdStatus !== 1 && !flag) {
      this.router.navigateByUrl("/kyc/US/personal");
    } else if ((this.usdStatus !== 1 && flag) || (flag && plan === "Crypto")) {
      this.router.navigateByUrl(`/kyc/business?plan=${plan}`);
    } else {
      if (plan) {
        this.router.navigateByUrl(`/kyc/business?plan=${plan}`);
      } else {
        this.modalService.openPersonalModal(flag);
      }
    }
  }

  getBroker(): void {
    this.router.navigateByUrl('/payment');
  }

  showBrokenButton(): void {
    if (this.isBrokerBtnVisible) {
      this.isBrokerBtnVisible = false;
    } else {
      this.isBrokerBtnVisible = true;
    }
  }

  showUKButton(): void {
    if (this.isUKBtnVisible) {
      this.isUKBtnVisible = false;
    } else {
      this.isUKBtnVisible = true;
    }
  }

  showUSButton(flag): void {
    this.isUSBtnVisible = flag;
  }
  showReserveButton(): void {
    if (this.isReserveBtnVisible) {
      this.isReserveBtnVisible = false;
    } else {
      this.isReserveBtnVisible = true;
    }
  }

  showEuroButton(): void {
    if (this.isEuroBtnVisible) {
      this.isEuroBtnVisible = false;
    } else {
      this.isEuroBtnVisible = true;
    }
  }

  ngOnDestroy() {
    this.subsink.unsubscribe();
  }

  getActionSubscriptionPlan() {
    this.upgardeService.getUserActionSubscriptionPlan().subscribe((data: UserSubscriptionPlan) => {
      if (data) {
        this.currentPlanDetails = data;
        if (this.userType === '4') {
          if (SUBSCRIPTION_PERSONAL_PLAN.PREMIUM === this.currentPlanDetails.planName) {
            this.isPremuimPlan = true;
          }
        } else {
          if (SUBSCRIPTION_BUSINESS_PLAN.PREMIUM === this.currentPlanDetails.planName) {
            this.isPremuimPlan = true;
          }
        }
      }
    });
  }


}
