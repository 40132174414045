import { ValidatorUtility } from "./../../../core/utility/validator.utility";
import { UserService } from "./../../../core/services/user/user.service";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import {
  TRANSFER_TYPE,
  CURRENCY_TYPE,
  WALLET_CURRENCY,
} from "src/app/app.constants";
import { CurrencyType } from "src/app/core/enums/currencyType";
import { DomainService } from "src/app/core/services/domain/domain.service";
import { ICurrency } from "../../../core/models/currency";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ConvertService } from "src/app/convert/services/convert.service";
import { SubSink } from "subsink";
import { CommonSavedData } from "../../services/commonSavedData.service";
import { DecimalPipe } from "@angular/common";
import { eventNames } from "process";
import { Router } from "@angular/router";
import { IUserBalance } from "src/app/core/models/user-balance";

@Component({
  selector: 'cashaa-form-select-plan',
  templateUrl: './form-select-plan.component.html',
  styleUrls: ['./form-select-plan.component.scss']
})
export class FormSelectPlanComponent implements OnInit {

  @Output() sendCurrencyChangeEvent = new EventEmitter<ICurrency>();

  @Output() reciveCurrencyChangeEvent = new EventEmitter<ICurrency>();

  @Output() minSendAmount = new EventEmitter<number>();

  @Input() label = "Amount";

  /** disable type */
  @Input() disable = false;

  /** transfer type */
  @Input() type = TRANSFER_TYPE.SEND;

  /** currency type */
  @Input() source = CURRENCY_TYPE.ALL;

  /** input form group */
  @Input() form: FormGroup;

  /** currency control */
  @Input() currencyControl: FormControl;

  /** amount control */
  @Input() amountControl: FormControl;

  /** name of control */
  @Input() currencyControlName: string;

  /** name of control */
  @Input() amountControlName: string;

  /** show balance */
  @Input() showBalance = false;

  @Input() noInput = false;

  @Input() noAccountCurrency = false;

  @Input() isSendModule: boolean;

  @Input() isConvertModule: boolean;

  @Input() isSendInput: boolean;

  @Input() isRecipientComponent: boolean = false;

  @Input() set initialValue(data) {
    if (data) {
      this.initValFlag = true;
      this.getCurrencyList(data);
    }
  }

  @Input() isGBPActive: boolean;

  initValFlag: boolean = false;

  controlCurrencyVal;

  fiatCurrencies = ["USD", "GBP", "EUR", "AUSD", "CUSD", "CGBP", "CEUR", "SGD", "CSGD", "AUD", "CAUD", "CNH", "CCNH",
    "CAD", "CCAD", "CHF", "CCHF", "NZD", "CNZD", "JPY", "CJPY"];

  validKeyCodes = [
    48,
    49,
    50,
    51,
    52,
    53,
    54,
    55,
    56,
    57,
    8,
    46,
    96,
    97,
    98,
    99,
    100,
    101,
    102,
    103,
    104,
    105,
    110,
    190,
  ];

  subsink = new SubSink();

  @Input() set controlCurrency(data) {
    this.controlCurrencyVal = data;
    if (this.showBalance) {
      this.attachValidatorsToControl(true, data);
    } else {
      this.attachValidatorsToControl(false, data);
    }
  }

  /** select different */
  // @Input() different = false;

  /** on currency change */
  @Output() changed = new EventEmitter<any>();

  /** selected currency */
  selectedPlan;

  /** is dropdown opened */
  isDropdownOpened = false;

  /** all currencies */
  currencies: ICurrency[] = [];

  /** Original currencies without filter */
  originalCurrencies: ICurrency[] = [];

  originalCurrentCurrencies: ICurrency[] = [];

  searchPlan = [];

  /** search term */
  searchTerm = "";

  CURRENCY_TYPE = CURRENCY_TYPE;

  // defaultCurrency: ICurrency;

  balance = 0;
  @Input() send: boolean = true;

  @Input() module: string;

  @Input() set sendCurrencySymbol(value) {
    this.sendCurrencySymb = value;
    this.currencies = this.originalCurrencies.filter((data) => this.currencyMap.get(value).includes(data.symbol) || (data.symbol === value && data.currencyType === 1));
    if (this.controlCurrencyVal === 'DOG') {
      this.attachValidatorsToControl(true, this.controlCurrencyVal);
    }
  }

  @Input() set recieveCurrencySymbol(value) {
    if (value !== 'CAS') {
      this.recivedCurrencySymb = value;
      this.currencies = this.originalCurrencies.filter((data) => this.currencyMap.get(value).includes(data.symbol) || (data.symbol === value && data.currencyType === 1));
    }

    if (this.controlCurrencyVal === 'DOG') {
      this.attachValidatorsToControl(true, this.controlCurrencyVal);
    }
  }


  @Input() currencyMap: Map<string, string[]>;

  @Input() isSendConvert;

  @Input() isRecieveConvert;
  @Input() minAmount;
  @Input() addAddress;

  balanceDetail: IUserBalance;

  sendCurrencySymb;

  recivedCurrencySymb;
  @Input() dropdownInfoList;

  constructor(
    private domainService: DomainService,
    private userService: UserService,
    private commonSavedData: CommonSavedData,
    private decimalNumber: DecimalPipe,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (!this.initValFlag) {
      this.getCurrencyList();
      this.listenToCurrencyChange();
    } else {
      this.initValFlag = false;
    }
  }

  /**
   * get user balance
   */
  getUserBalance(): void {
    this.userService.getUserBalance().subscribe((balance) => {
      const found = balance.filter(
        (b) => {
       //   this.selectedPlan.rate == 2.5? this.selectedPlan.rate = 10 : this.selectedPlan.rate = this.selectedPlan.rate;
          if (this.module === "depositInterest" && this.selectedPlan.currencyType === 2 && this.selectedPlan.symbol.charAt(0) === 'C') {
            if (b.currencyType === this.selectedPlan.symbol.slice(1, this.selectedPlan.symbol.length)) {
              return b;
            }
          } else {
            if (b.currencyType === this.selectedPlan.symbol) {
              return b;
            }
          }
        }
      );
      if (found.length > 0) {
        this.balance = found[0].available;
        this.balanceDetail = found[0];
        this.minSendAmount.emit(this.balanceDetail.minSendAmount);
        if (this.amountControl) {
          this.attachValidatorsToControl(true);
        }
      }
    });
  }

  /**
   * Get Currency list
   */
  getCurrencyList(data?): void {
    const currencyType =
      this.source === CURRENCY_TYPE.ALL
        ? CurrencyType.all
        : this.source === CURRENCY_TYPE.WALLET || this.source === "deposit"
          ? CurrencyType.Fiat
          : CurrencyType.Crypto;


    this.subsink.add(
      this.domainService.getCurrenciesList(currencyType).subscribe((list) => {
        if (this.source === "deposit") {
          this.currencies = this.originalCurrencies = list.currencies.filter(
            item => item.isDepositActive === true
          );
        } else if (this.isSendModule && this.module != "depositInterest") {
          const excludeCurrencies = ["GBP"];
          this.currencies = this.originalCurrencies = list.currencies.filter(
            (cur) => !excludeCurrencies.includes(cur.symbol)
          );
        } else {
          this.currencies = this.originalCurrencies = list.currencies;
        }

        if (this.module === "depositInterest") {
          this.currencies = this.currencies.filter(item => item.isFDActive === true);
          this.currencies = this.currencies.map(data => {
            if (data.currencyType === 2) {
              data.symbol = "C".concat(data.symbol);
            }
            return data;
          });
        }

        if (currencyType === CurrencyType.Fiat) {
          this.domainService.emitFiatCurrency(this.currencies);
        }
        if (data) {
          this.setCurrencyValue(data);
        } else {
          this.setCurrencyValue(this.currencyControl.value);
        }

        if (this.showBalance && this.selectedPlan) {
          this.getUserBalance();
        } else if (!this.showBalance) {
          this.attachValidatorsToControl();
        }
        if (this.sendCurrencySymb) {
          this.currencies = this.originalCurrencies.filter((data) => this.currencyMap.get(this.sendCurrencySymb).includes(data.symbol));
        }

      })
    );

  }

  attachValidatorsToControl(isSend?: boolean, curr?) {
    if (!this.noInput) {
      let currency: string = this.selectedPlan?.symbol
        ? this.selectedPlan?.symbol
        : curr;
      this.amountControl.clearValidators();
      this.form.updateValueAndValidity();
      let validatorsArr = [ValidatorUtility.Required];
      if (isSend) {
        if (this.amountControlName == "senderAmount") {
          validatorsArr.push(ValidatorUtility.MaxForConvert(this.balance));
        }

        if (this.module === 'depositInterest') {
          
          validatorsArr.push(ValidatorUtility.MinForConvert(this.balanceDetail?.minFDAmount));
        }
      }
      this.amountControl.setValidators(validatorsArr);
      this.form.updateValueAndValidity();
    }
  }

  /**
   * Listen to control change
   */
  listenToCurrencyChange(): void {
    this.subsink.add(
      this.currencyControl.valueChanges.subscribe((value) => {
        if (this.currencies.length > 0 && value) {
          this.setCurrencyValue(value);
        }
      })
    );
  }
  setCurrencyValue(currencyId: string): void {
    this.commonSavedData.setCurrencies(this.currencies);
    const found = this.originalCurrencies.filter((c) => c.id === currencyId);
    if (found.length > 0) {
      this.selectedPlan = found[0];
      if (this.type === TRANSFER_TYPE.SEND) {
        this.sendCurrencyChangeEvent.emit(this.selectedPlan);
      } else {
        this.reciveCurrencyChangeEvent.emit(this.selectedPlan);
      }

      if (this.showBalance && this.selectedPlan) {
        this.getUserBalance();
      } else if (!this.showBalance) {
        this.attachValidatorsToControl();
      }
    }
  }

  /**
   * on currency selection
   *
   */
  onPlanSelection(item): void {
    this.isDropdownOpened = false;
    this.selectedPlan = item;
    this.changed.emit(this.selectedPlan);
  }

  /**
   * On search of currency
   */
  onSearch(event: Event): void {
    event.stopPropagation();
    this.searchPlan = this.dropdownInfoList.filter((c) => {
      if (c.planName && this.searchTerm) {
        return (
          c.planName
            .toLocaleLowerCase()
            .indexOf(this.searchTerm.toLocaleLowerCase()) !== -1
        );
      } else {
        return true;
      }
    });
  }

  /**
   * get all account currencies
   */
  getWalletCurrencies(): ICurrency[] {
    let currencies: ICurrency[] = [];
    if (this.searchTerm.length > 0) {
      currencies = this.searchPlan;
    } else {
      currencies = this.currencies;
    }

    if (this.isSendConvert) {
      let val = [...this.currencyMap.keys()];
      return currencies
        .filter((c) => c.currencyType === 2 && val.includes(c.symbol));
    } else if (this.module === "depositInterest") {
      return currencies.filter((c) =>  c.currencyType === 2 && c.isFDActive === true);
      // .map(data => {
      //   if (data.currencyType === 2) {
      //     data.symbol = "C".concat(data.symbol);
      //   }
      //   return data;
      // });
    } else if (this.isSendModule) {
      return currencies.filter((c) => c.currencyType === 2 && c.isSendActive === true);
    } else if (this.isRecipientComponent) {
      if(this.addAddress === 'cryptoAddress') {
      return currencies.filter(item => item.currencyType === 1);
      } else {
        return currencies.filter(item => item.currencyType === 2 && item.isSendActive === true);
      }
    } else {
      return currencies
        .filter((c) => c.currencyType === 2)
        .map((curr) => {
          if (this.isRecipientComponent && curr.name.endsWith("Wallet")) {
            curr.name = curr.name.substring(0, curr.name.lastIndexOf(" "));
          }
          return curr;
        });
    }
  }

  /**
   * get all account currencies
   */
  getWithoutAccountCurrencies(): ICurrency[] {
    let currencies: ICurrency[] = [];
    if (this.searchTerm.length > 0) {
      currencies = [...this.searchPlan];
    } else {
      currencies = [...this.currencies];
    }

    if (this.isConvertModule) {
      if (this.isSendInput) {
        if (this.isSendConvert) {
          return currencies.filter(
            (c) => c.currencyType === 1 && [...this.currencyMap.keys()].includes(c.symbol)
          );
        } else {
          return currencies.filter(
            (c) =>  c.currencyType === 1 && c.symbol !== "CAS"
          );
        }
      } else {
        return currencies.filter(
          (c) =>  c.currencyType === 1
        );
      }
    } else if ((this.isSendModule || this.isRecipientComponent) && this.module !== "depositInterest") {
      return currencies.filter(
        (c) =>  c.currencyType === 1 && c.isSendActive === true
      );
    } else {
      return currencies.filter(
        (c) => {
          if (this.module === "depositInterest") {
            return  c.currencyType === 1 && c.isFDActive === true; 
          } else {
            return  c.currencyType === 1;
          }
        }
      );
    }
  }

  /**
   * On input
   */
  onInput(value: string): void {
    const newValue = parseInt(value);
    this.amountControl.setValue(newValue);
  }

  numberOnly(event, flag) {
    let keyCode = event.keyCode;
    if (this.validKeyCodes.indexOf(keyCode) >= 0) {
      if (this.fiatCurrencies.includes(this.selectedPlan.symbol)) {
        let actualValue = event.target.value.substr(
          0,
          event.target.value.indexOf(".") + 3
        );
        let val = event.target.value;
        if (actualValue === "0.00") {
          if (event.keyCode === 190 || event.keyCode === 110) {
            this.amountControl.setValue(".");
          } else {
            event.target.value = String.fromCharCode(keyCode);
            this.amountControl.setValue(event.target.value);
          }
        } else if (
          val != null &&
          val.indexOf(".") > -1 &&
          val.split(".")[1].length > 2
        ) {
          event.target.value = actualValue;
          this.amountControl.setValue(event.target.value);
        }
      } else if (
        this.selectedPlan.symbol === "CAS" ||
        this.selectedPlan.symbol === "BNB"
      ) {
        if (this.isSendModule) {
          let actualValue = event.target.value.substr(
            0,
            event.target.value.indexOf(".") + 5
          );
          let val = event.target.value;
          if (actualValue === "0.0000") {
            if (event.keyCode === 190 || event.keyCode === 110) {
              this.amountControl.setValue(".");
            } else {
              event.target.value = String.fromCharCode(keyCode);
              this.amountControl.setValue(event.target.value);
            }
          } else if (
            val != null &&
            val.indexOf(".") > -1 &&
            val.split(".")[1].length > 4
          ) {
            event.target.value = actualValue;
            this.amountControl.setValue(event.target.value);
          }
        }
      } else {
        let actualValue = event.target.value.substr(
          0,
          event.target.value.indexOf(".") + 5
        );
        let val = event.target.value;
        if (actualValue === "0.0000") {
          if (event.keyCode === 190 || event.keyCode === 110) {
            this.amountControl.setValue(".");
          } else {
            event.target.value = String.fromCharCode(keyCode);
            this.amountControl.setValue(event.target.value);
          }
        } else if (
          val != null &&
          val.indexOf(".") > -1 &&
          val.split(".")[1].length > 4
        ) {
          event.target.value = actualValue;
          this.amountControl.setValue(event.target.value);
        }
      }
    } else if (flag) {
      event = event.toString();
      if (
        (this.selectedPlan.symbol === "CAS" ||
          this.selectedPlan.symbol === "BNB") &&
        !this.isSendModule
      ) {
        this.amountControl.setValue(event);
      } else if (this.fiatCurrencies.includes(this.selectedPlan.symbol)) {
        let actualValue =
          event.indexOf(".") === -1
            ? event
            : event.substr(0, event.indexOf(".") + 3);
        let val = event;
        if (actualValue === "0.00") {
          this.amountControl.setValue(event);
        } else if (val != null) {
          event = actualValue;
          this.amountControl.setValue(event);
        }
      } else {
        let actualValue =
          event.indexOf(".") === -1
            ? event
            : event.substr(0, event.indexOf(".") + 5);
        let val = event;
        if (actualValue === "0.0000") {
          this.amountControl.setValue(event);
        } else if (val != null) {
          event = actualValue;
          this.amountControl.setValue(event);
        }
      }
    } else {
      return false;
    }
    if (
      event.keyCode !== 190 &&
      event.keyCode !== 110 &&
      this.amountControl.value
    ) {
      let decimalPoint =
        "0." +
        (this.amountControl.value.split(".")[1]
          ? this.amountControl.value.split(".")[1].length
          : "0") +
        "-4";
      this.amountControl.setValue(
        this.decimalNumber.transform(
          this.amountControl.value.replaceAll(",", ""),
          decimalPoint
        )
      );
    }
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  patchBalance(balance) {
    this.numberOnly(balance, true);
  }
  getCurrencyName(curr): string {
    if (curr === "AUSD") return "USD";
    if (curr === "DOG") return "DOGE";
    else return curr;
  }

  getMinDog(): number {
    let validCurrencies = [... this.fiatCurrencies];
    validCurrencies.push('USDT');
    validCurrencies.push('BNB');
    if (this.isConvertModule) {
      if (validCurrencies.includes(this.sendCurrencySymb) || validCurrencies.includes(this.recivedCurrencySymb)) {
        return 1;
      } else if ('BTC' === this.sendCurrencySymb || 'BTC' === this.recivedCurrencySymb || 'ETH' === this.sendCurrencySymb || 'ETH' === this.recivedCurrencySymb) {
        return 100;
      }
    }
  }

  depositBalance() {
    if (this.fiatCurrencies.includes(this.selectedPlan.symbol)) {
      this.router.navigateByUrl(`/deposit?cur=${this.selectedPlan.symbol}`);
    } else {
      this.router.navigateByUrl(`/wallet?c=${this.selectedPlan.symbol}#${this.selectedPlan.symbol}`);
    }
  }

}
