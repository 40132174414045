<div class="text-center sm-width-phone-700 sm-width-phone-400-height" [hidden]="!showLoadingIndicator">
    <mdb-spinner spinnerColor="blue" class="sm-loader-center"></mdb-spinner>
</div>
<div [hidden]="showLoadingIndicator">
    <section
        class="p-5 wrapper w-100 d-flex justify-content-center flex-column position-relative mx-auto sm-width-phone-700 sm-width-phone-400-height">
        <img class="position-absolute logo" src="../../../assets/media/logo-dark.svg" />

        <h2 class="mb-4 text-center font-weight-bolder">Status</h2>
        <mdb-icon far icon="check-circle" class="text-center green-text mb-4" size="10x" *ngIf="!errorMessage">
        </mdb-icon>
        <mdb-icon fas icon="times-circle" class="text-center red-text mb-4" size="10x" *ngIf="errorMessage"></mdb-icon>
        <div style="text-align: center">
            <h3 class="font-weight-bold">{{isVerified}}<br></h3>
        </div>
        <div class="p-5" class="center-btn mt-4">
            <button mdbBtn block="true" type="button"
                class="sm-width-ph-confirmation w-25 btn-sm btn-primary btn btn-height" mdbWavesEffect
                (click)="onContinue()">
                Continue to your Account <i class="fas fa-arrow-circle-right pl-2" style="font-size: 1rem;"></i>
            </button>
        </div>
    </section>

</div>