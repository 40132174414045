<div>
  <div class="d-flex flex-wrap">
    <div class="mr-1 mr-md-3 mb-3">
      <cashaa-currency-box
        [currency]="'CAS'"
        [convert]="fromCurrency"
        [selected]="toCurrency == 'CAS'"
        [reset]="resetCurrencyPrices"
        (clicked)="onToCurrencyChange($event)"
      >
      </cashaa-currency-box>
    </div>
    <div class="mr-1 mr-md-3 mb-3">
      <cashaa-currency-box
        [currency]="'BTC'"
        [convert]="fromCurrency"
        [selected]="toCurrency == 'BTC'"
        [reset]="resetCurrencyPrices"
        (clicked)="onToCurrencyChange($event)"
      >
      </cashaa-currency-box>
    </div>
    <div class="mr-1 mr-md-3 mb-3">
      <cashaa-currency-box
        [currency]="'ETH'"
        [convert]="fromCurrency"
        [selected]="toCurrency == 'ETH'"
        [reset]="resetCurrencyPrices"
        (clicked)="onToCurrencyChange($event)"
      >
      </cashaa-currency-box>
    </div>
    <div class="mr-1 mr-md-3 mb-3">
      <cashaa-currency-box
        [currency]="'USDT'"
        [convert]="fromCurrency"
        [selected]="toCurrency == 'USDT'"
        [reset]="resetCurrencyPrices"
        (clicked)="onToCurrencyChange($event)"
      >
      </cashaa-currency-box>
    </div>
  </div>
  <h6 class="font-weight-bold my-4">
    Select Payment Method
  </h6>
  <div class="mb-5">
    <cashaa-form-select-balance
      [type]="'wallet'"
      [mode]="'buy'"
      (changed)="onFromCurrencyChange($event)"
    ></cashaa-form-select-balance>
  </div>
  <h6 class="font-weight-bold mb-3">
    Amount
  </h6>
  <div class="d-flex align-items-center">
    <div class="flex-1">
      <cashaa-form-input-currency
        [currency]="fromCurrency"
        [form]="form"
        [control]="form?.controls?.fromAmount"
        (changed)="onFromCurrencyAmountChange($event)"
        name="fromAmount"
      ></cashaa-form-input-currency>
    </div>
    <mdb-icon fas icon="exchange-alt" class="exchange-arrow"></mdb-icon>
    <div class="flex-1">
      <cashaa-form-input-currency
        [currency]="toCurrency"
        [form]="form"
        [control]="form?.controls?.toAmount"
        (changed)="onToCurrencyAmountChange($event)"
        name="toAmount"
      ></cashaa-form-input-currency>
    </div>
  </div>
</div>
