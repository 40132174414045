<form [formGroup]="form" (ngSubmit)="onSubmit(form.value)" class="my-4 sm-custom-height">
  <div formArrayName="documents" *ngFor="let item of documents.controls; let i = index">
    <div class="row mb-4">
      <div class="col-12 col-sm-4">
        <div [formGroupName]="i">
          <div class="position-relative text-left sm-input">
            <label class="text-dark font-weight-bolder sm-label">Document Type</label>
            <input mdbInput class="form-control sm-input-height" type="text" disabled="true"
              value="{{documentType[i].label}}">
          </div>
          <cashaa-form-select [form]="item" [disable]="true" [control]="item.get('docType')" name="docType"
            label="Document Type" placeholder="Select Document Type" [options]="documentType"
            (changed)="selectChange($event)" style="display: none;">
          </cashaa-form-select>
        </div>
      </div>
      <div class="col-12 col-sm-4 mobile-mt-5">
        <div [formGroupName]="i">
          <cashaa-form-input name="docIdentifier" type="text" [form]="item" [control]="item.get('docIdentifier')"
            placeholder="Enter Document Identifier" label="Document Identifier">
          </cashaa-form-input>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12 col-sm-4">
        <div [formGroupName]="i">
          <label class="text-dark font-weight-normal">Document Expiry Date</label>
          <mdb-date-picker [inline]="true" name="docExpiryDate" [placeholder]="'Document Expiry Date'"
            [options]="myDatePickerOptions" formControlName="docExpiryDate" class="datepicker-white"></mdb-date-picker>
        </div>
      </div>
    </div>
    <hr class="pb-4" />
  </div>
  <button mdbBtn block="true" type="submit"
    class="w-25 rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block" mdbWavesEffect
    [disabled]="form.invalid || isSameDocument">
    Submit
  </button>
</form>