import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { WithdrawAddressWhitelistComponent } from "./withdraw-address-whitelist/withdraw-address-whitelist.component";
import { WithdrawFundComponent } from "./withdraw-fund/withdraw-fund.component";
import { AuthGuardService } from "./../core/services/auth-guard/auth-guard.service";

const routes: Routes = [{
  //chweck
  path: "withdraw",
  canActivate: [AuthGuardService],
  children: [
    {
      path: "address-whitelist",
      component: WithdrawAddressWhitelistComponent,
      canActivate: [AuthGuardService],
    },
    {
      path: "fund",
      component: WithdrawFundComponent,
      canActivate: [AuthGuardService],
    },
  ],
},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WithdrawRoutingModule { }
