import { Router } from "@angular/router";
import {
  Component,
  OnInit,
  Input,
  ElementRef,
  QueryList,
  ViewChildren,
  EventEmitter,
  Output,
} from "@angular/core";

@Component({
  selector: "cashaa-google-auth-boxes",
  templateUrl: "./google-auth-boxes.component.html",
  styleUrls: ["./google-auth-boxes.component.scss"],
})
export class GoogleAuthBoxesComponent implements OnInit {
  code: string[] = ["", "", "", "", "", ""];
  @ViewChildren("inputs") inputs: QueryList<ElementRef>;
  allCodeEntered = false;

  @Input() set finalCode(value) {
    if (value === "") {
      this.code = ["", "", "", "", "", ""];
    }
  }
  @Output() updateCode = new EventEmitter<string>();
  @Output() removeAuthBoxesError = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  removeError(): void {
    this.removeAuthBoxesError.emit(true);
  }

  /**
   * Check if google input authenticator
   * code is filled in all 6 boxes
   */
  checkIfCodeIsFilled(idx: number): void {
    if (idx >= 0 && idx < 5 && this.code[idx]) {
      this.inputs.toArray()[idx + 1].nativeElement.focus();
    }

    this.allCodeEntered = true;
    this.code.forEach((val) => {
      if (val === null || val.length === 0) {
        this.allCodeEntered = false;
      }
    });
    if (this.code[5] && idx === -1) {
      this.inputs.toArray()[5].nativeElement.focus();
    }
    this.updateCode.emit(this.code.join(""));
  }

  /**
   * On code paste automatically
   * fill all 6 boxes
   */
  onCodePaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData("text");

    if (pastedText && pastedText.length === 6) {
      this.code = pastedText.split("");
      this.checkIfCodeIsFilled(-1);
    }
    this.updateCode.emit(this.code.join(""));
  }

  removeBack(event: any, index: number) {
    const keyCode = event.keyCode;
    if (keyCode === 8) {
      this.inputs.toArray()[index].nativeElement.focus();
    }
    this.updateCode.emit(this.code.join(""));
  }
}
