import { BKYCStakeholderDocuments } from "./../../core/models/kyc-business-stakeholders-doc";
import { IBusinessDocList } from "./../../core/models/kyc-business-document-list";
import { IBusinessKycDetails } from "./../../core/models/business-account/business-key-detail-list";
import { IAPIEnumLoad, IFile } from "./../../core/models/Lerex/enum-load";
import { ICreateKYC, IDeleteApplicationDoc } from "./../../core/models/create-kyc";
import { ICustomerEmailConfermation } from "./../../core/models/kyc-customer-email-verification-model";
import { Injectable } from "@angular/core";

import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { IPayBrokerFee } from "./../../core/models/pay-fee";
import { Observable, BehaviorSubject } from "rxjs";
import { IDirectorVerification } from "../../core/models/director-verification-link";
import {
  CreateBusinessKYC,
  IStakeholders,
} from "../../core/models/create-business-kyc";
import { CreateKYC } from "../../core/models/create-kyc";
import { environment } from "src/environments/environment";
import { ICreateBusinessUser } from "../../core/models/business-account/kyc-business-user";
import { IKycBusinessUser } from "src/app/core/models/kyc-business-user-model";
import { EmailVerificationRequest } from "src/app/core/models/kyc-verification-request-model";
import { IPaymentFeesDetail, IUpgradePayment, IUSPersonal } from "src/app/core/models/kyc-us-personal/us-personal";
@Injectable({
  providedIn: "root",
})
export class KycService {
  /** KYC data emitter */
  private createKycDataEmitter = new BehaviorSubject<CreateKYC | undefined>(
    undefined
  );

  private createBusinessKycDataEmitter = new BehaviorSubject<
    CreateBusinessKYC | undefined
  >(undefined);

  private createDirectorBusinessKycDataEmitter = new BehaviorSubject<
    Array<IStakeholders> | undefined
  >(undefined);

  private kycUsPersonalMobileCode = new BehaviorSubject<string>(undefined);

  private kycUsPersonalObj = new BehaviorSubject<IUSPersonal>(undefined);


  constructor(private http: HttpClient) {}

  createKyc(createKycData: CreateKYC): Observable<{ message: string }> {
    return this.http.post<any>(`${environment.api}/kyc/create`, createKycData);
  }

  /*** Get KYC data emitter***/
  getcreateKycDataEmitter(): Observable<CreateKYC> {
    return this.createKycDataEmitter.asObservable();
  }

  /**
   * Emit KYC data
   */
  emitCreateKycData(data: CreateKYC): void {
    this.createKycDataEmitter.next(data);
  }

  createBusinessKyc(
    createBusinessKycData: CreateBusinessKYC
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${environment.api}/kyc/createBusinessKyc`,
      createBusinessKycData
    );
  }

  payPremiumFees(payment: IUpgradePayment): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(`${environment.api}/subscriptions/upgradeFee`, payment);
  }

  getPremuimFeesDetail(): Observable<IPaymentFeesDetail> {
    return this.http.get<IPaymentFeesDetail>(`${environment.api}/subscriptions/getupgradeplanfee`);
  }


  /*** Get Business KYC data emitter***/
  getcreateBusinessKycDataEmitter(): Observable<CreateBusinessKYC> {
    return this.createBusinessKycDataEmitter.asObservable();
  }

  /**
   * Emit Business KYC data
   */
  emitBusinessCreateKycData(data: CreateBusinessKYC): void {
    this.createBusinessKycDataEmitter.next(data);
  }

  emitKycUsPersonalMobileCode(data: string): void {
    this.kycUsPersonalMobileCode.next(data);
  }

  getKycUsPersonalMobileCode(): Observable<string> {
    return this.kycUsPersonalMobileCode.asObservable();
  }

  emitKycUsPersonal(data: IUSPersonal): void {
    this.kycUsPersonalObj.next(data);
  }

  getKycUsPersonal(): Observable<IUSPersonal> {
    return this.kycUsPersonalObj.asObservable();
  }

  /*** Get Director Business KYC data emitter***/
  getcreateBusinessKycDirectorDataEmitter(): Observable<IStakeholders[]> {
    return this.createDirectorBusinessKycDataEmitter.asObservable();
  }

  /**
   * Emit Director Business KYC data
   */
  emitBusinessCreateKycDirectorData(data: IStakeholders[]): void {
    this.createDirectorBusinessKycDataEmitter.next(data);
  }

  getKYCDirectorDetails(): Observable<IStakeholders[]> {
    return this.http.get<IStakeholders[]>(
      `${environment.api}/kyc/getkycdirectorsdetails`
    );
  }

  getBusinessDocuments(businessId: string): Observable<IBusinessDocList[]> {
    return this.http.get<IBusinessDocList[]>(
      `${environment.api}/kyc/getbusinessdocuments?BusinessKYCDetailId=${businessId}`
    );
  }

  getStakeholderDocuments(
    businessKYCStakeholderId: string
  ): Observable<BKYCStakeholderDocuments[]> {
    return this.http.get<BKYCStakeholderDocuments[]>(
      `${environment.api}/kyc/getstakeholderdocument?businessKYCStakeholderId=${businessKYCStakeholderId}`
    );
  }

  getBusinessKycDetails(): Observable<IBusinessKycDetails> {
    return this.http.get<IBusinessKycDetails>(
      `${environment.api}/kyc/getBusinesskycdetails`
    );
  }
  /**
   * Get business user details
   *
   * @param {IDirectorVerification} data
   * @returns {Observable<string>}
   *
   * @memberOf KycService
   */
  getBusinessUserDetails(): Observable<IKycBusinessUser> {
    return this.http.get<IKycBusinessUser>(
      `${environment.api}/kyc/getbusinessusers`
    );
  }

  sendVerificationLink(
    data: IDirectorVerification
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${environment.api}/kyc/sendverificationlink`,
      data
    );
  }
  // Verify business User email
  verifyBusinessUserEmail(
    data: EmailVerificationRequest
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${environment.api}/kyc/verifybusinessuseremail`,
      data
    );
  }
  // Customer email verification

  verifyCustomerEmail(
    data: ICustomerEmailConfermation
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${environment.api}/broker/emailverification`,
      data
    );
  }

  // load KYC details from database
  getKycDetails(): Observable<ICreateKYC> {
    return this.http.get<ICreateKYC>(`${environment.api}/kyc/getkycdetails`);
  }
  // Get this list of Enum Values
  // Load all Lerex API Industry code
  // 1
  getIndustryCode(): Observable<IAPIEnumLoad[]> {
    return this.http.get<IAPIEnumLoad[]>(`${environment.api}/kyc/industrycode`);
  }
  // 2
  // Load all Lerex API Company code
  getCompanyCode(): Observable<IAPIEnumLoad[]> {
    return this.http.get<IAPIEnumLoad[]>(
      `${environment.api}/kyc/organizationtype`
    );
  }
  // 3
  // Load all Lerex API stackholders contact types code
  getContactType(): Observable<IAPIEnumLoad[]> {
    return this.http.get<IAPIEnumLoad[]>(`${environment.api}/kyc/contacttype`);
  }
  // 4
  // Load all Lerex API Industry code
  getBusinessDocumentTypes(type: number): Observable<IAPIEnumLoad[]> {
    return this.http.get<IAPIEnumLoad[]>(
      `${environment.api}/kyc/businessdocumenttypes?accountDocumentType=${type}`
    );
  }
  // Uplaod Director files
  createBusinessKycContact(
    contactData: FormData
  ): Observable<HttpResponse<{ statusCode: number; message: string }>> {
    return this.http.post<any>(
      `${environment.api}/kyc/uploadkycdocument`,
      contactData
    );
  }
  // Uplaod business Contact files
  uplaodBusinessKycContactDocument(
    contactData: FormData
  ): Observable<HttpResponse<{ statusCode: number; message: string }>> {
    return this.http.post<any>(
      `${environment.api}/kyc/uploadkyccontactdocument`,
      contactData
    );
  }
  //uploadpersonalkycdocument
  // Uplaod personal kyc file files
  uplaodPersonalKycDocument(
    contactData: FormData
  ): Observable<HttpResponse<{ statusCode: number; message: string }>> {
    return this.http.post<any>(
      `${environment.api}/kyc/uploadpersonalkycdocument`,
      contactData
    );
  }

  /**
   *
   * @param businessUser get source of business account fund
   */
  getSourceOfFund(): Observable<IAPIEnumLoad[]> {
    return this.http.get<IAPIEnumLoad[]>(
      `${environment.api}/kyc/getsourceoffunds`
    );
  }

  /**
   * Create a business user to the Lerex
   */
  createBusinessUser(
    businessUser: ICreateBusinessUser
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${environment.api}/kyc/createbusinessuser`,
      businessUser
    );
  }

  createUSPersoanlKYC(
    data: IUSPersonal
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${environment.api}/kyc/us-kyc-personal`,
      data
    );
  }

  getUSPersonalKYC(): Observable<IUSPersonal> {
    return this.http.get<IUSPersonal>(
      `${environment.api}/kyc/us-kyc-personal-details`
    );
  }

  payBrokerFee(feedata: IPayBrokerFee): Observable<any> {
    return this.http.post(`${environment.api}/kyc/feepayment`, feedata);
  }

  getFeeStatus():Observable<{status:boolean}> {
    return this.http.get<{status:boolean}>(
      `${environment.api}/broker/feestatus`
    );
  }

  deleteDocument(docId: string): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>
    (`${environment.api}/kyc/delete-director-doc?documentId=${docId}`, null);
  } 

  deleteBusinessDocument(docId: string): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>
    (`${environment.api}/kyc/delete-business-doc?documentId=${docId}`, null);
  }

  uploadApplicationFormDoc(applicationData: FormData):Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>
    (`${environment.api}/kyc/uploadkycdocument`, applicationData);
  }

  getApplicationFormDoc():Observable<any[]> {
    return this.http.get<any[]>(`${environment.api}/kyc/getbusinessapplicationfile`);
  }

  deleteApplicationFormDoc(deleteDoc: IDeleteApplicationDoc): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>
    (`${environment.api}/kyc/deletebusinessapplicationfile`, deleteDoc);
  }
}
