import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { IUserBalance } from "src/app/core/models/user-balance";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ReceiveFundRequest, ReceiveFundResponse, IReceiveFundResponse } from "src/app/core/models/receive-fund";

@Injectable({
  providedIn: "root"
})
export class ReceiveService {

  constructor(private http: HttpClient) { }

  createReceiveFund(receiveFundData: ReceiveFundRequest): Observable<IReceiveFundResponse> {
    return this.http.post<IReceiveFundResponse>(`${environment.api}/wallets/create`, receiveFundData



    );
  }
}
