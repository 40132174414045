import { AccountUpgradeRoutingModule } from "./account-upgrade-routing.module";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { MDBBootstrapModulesPro } from "ng-uikit-pro-standard";
import { SharedModule } from "./../shared/shared.module";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AccountUpgradeSubscriptionComponent } from "./account-upgrade-subscription/account-upgrade-subscription.component";
import { UpgradeAccountPremierStakingComponent } from "./upgrade-account-premier-staking/upgrade-account-premier-staking.component";
import { ToastrModule } from "ngx-toastr";
import { ReserveModalComponent } from "./reserve-modal/reserve-modal.component";
import { StakingHomeComponent } from "./staking-home/staking-home.component";
import { StakingTimelineComponent } from "./staking-timeline/staking-timeline.component";

@NgModule({
  declarations: [
    AccountUpgradeSubscriptionComponent,
    UpgradeAccountPremierStakingComponent,
    ReserveModalComponent,
    StakingHomeComponent,
    StakingTimelineComponent,
  ],
  imports: [
    CommonModule,
    AccountUpgradeRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    MDBBootstrapModulesPro.forRoot(),
    ToastrModule.forRoot(),
  ],
})
export class AccountUpgradeModule {}
