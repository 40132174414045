<!-- <cashaa-sidenav>
    <section class="mh-100">
      <div class="bg-white p-4">
        <div class="sm-customer-btn-padding-mobile">
          <h4 class="font-weight-bold">Add New Beneficiary/Customer</h4>
          <button mdbBtn type="button" outline="true" mdbWavesEffect class="sm-btn-input-hover btn-border btn-sm"
            (click)="addCustomer()">
            Add Beneficiary/Customer
          </button>
        </div>
      </div>
    </section>
    <div class="d-flex justify-content-between p-4">
      <div class="border border-primary rounded-lg p-4 bg-white" style="width: 100%;">
        <cashaa-customer-details (customer)="editCustomer($event)"></cashaa-customer-details>
      </div>
    </div>
  </cashaa-sidenav> -->


<cashaa-sidenav>
    <section class="mh-100">
        <div class="bg-white p-4">
            <!-- <div class="bg-white"> -->
                <div class="sm-customer-btn-padding-mobile">
                    <!-- <h6 class="font-weight-bold">Add New Beneficiary/Customer</h6> -->
                    <button mdbBtn type="button" outline="true" mdbWavesEffect
                        class="sm-btn-input-hover btn-border btn-sm" (click)="addCustomer()">
                        Add Beneficiary/Customer
                    </button>
                </div>
            <!-- </div> -->
        </div>
    </section>
    <cashaa-add-cutomer></cashaa-add-cutomer>
</cashaa-sidenav>