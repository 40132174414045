import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { IRecipient } from "src/app/core/models/fIat/recipients";
import { NEW_RECIPIENT_TYPE } from "src/app/app.constants";
import { ToastrService } from "ngx-toastr";
import { ModalService } from "src/app/core/services/modal/modal.service";
import { NetworkType } from "src/app/core/enums/network-type-enum";

@Component({
  selector: "cashaa-recipient-box",
  templateUrl: "./recipient-box.component.html",
  styleUrls: ["./recipient-box.component.scss"],
})
export class RecipientBoxComponent implements OnInit {
  /** Recipient */
  @Input() recipient: IRecipient;

  /** is New recipient */
  @Input() isNew = false;

  /** if new, then its type */
  @Input() type = NEW_RECIPIENT_TYPE.MYSELF;

  /** on click */
  @Output() clicked = new EventEmitter<void>();

  /**
   * get initials of user
   *
   */
  get initials(): string {
    if (this.recipient) {
      const fullName = this.recipient?.name.split(" ");
      return `${fullName[0] ? fullName[0][0] : ""}${
        fullName[1] ? fullName[1][0] : ""
        }`;
    }
    return "";
  }
  copied = false;
  constructor(private toastrService: ToastrService, private modalService: ModalService) { }

  ngOnInit(): void { }

  /**
   * On recipient clicked
   *
   */
  onRecipientClick(): void {
    if(this.recipient?.isCashaaAddress && !this.recipient?.memo && this.recipient?.network === 0) {
    } else {
      this.clicked.emit();
    } 
  }
  copyAddress(): void {
    this.copied = true;
    navigator.clipboard.writeText(this.recipient.accountNumber);
    this.toastrService.toastrConfig.preventDuplicates = true;
    this.toastrService.success("Text copied to clipboard");
    setTimeout(() => (this.copied = false), 300);
  }

  updateMemo(data, netwrkLabel) {
    this.modalService.openUpdateMemoModal(data.currency, data.currencyId,data.accountNumber, data.id, data.network, netwrkLabel);
  }
  getLabel(): string {
    if (this.recipient.network == 0 || this.recipient.network == null) {
      if (this.recipient.currency === 'CAS' || this.recipient.currency === 'BNB') {
        return "BNB Beacon Chain (BEP2)"
      } else {
        return this.getCurrency();
      } 
    } else {
      return NetworkType[this.recipient.network];
    }
  }

  getCurrency() : string {
    if (this.recipient.currency === "AUSD") return "USD";
    if (this.recipient.currency === "DOG") return "DOGE";
    else return this.recipient.currency;
  }
}
