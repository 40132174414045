import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IMyOptions, MDBModalRef } from "ng-uikit-pro-standard";
import { ToastrService } from "ngx-toastr";
import {
  CUSTOMER_ADDED_SUCCESS,
  UNABLE_TO_PERFORM_ERROR,
} from "src/app/app.constants";
import { ICurrency } from "src/app/core/models/currency";
import { IFormSelect } from "src/app/core/models/form-select";
import { ICustomer } from "src/app/core/models/recipient/recipient-add-usd";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";
import { CustomerService } from "../services/customer.service";

const addressdocumentTypes = require("../../../assets/json/addressProofDocumentType.json");
const identitydocumentTypes = require("../../../assets/json/identityProofDocumentType.json");

@Component({
  selector: "cashaa-add-cutomer-modal",
  templateUrl: "./add-customer-modal.component.html",
  styleUrls: ["./add-customer-modal.component.scss"],
})
export class AddCustomerModalComponent {
  public form: FormGroup;
  isInProgress = false;
  public currency: IFormSelect[] = [];
  public loading: boolean = false;
  public isFormEdit: boolean;
  public identityfile: File;
  public addressfile: File;
  public selfiefile: File;
  public addressProofOptions: IFormSelect[] = [];
  public identityProofOptions: IFormSelect[] = [];
  public selectedCustomer: ICustomer;
  currentCur: ICurrency;
  isLoading: boolean = false;
  customer;
  myDatePickerOptions: IMyOptions = {
    minYear: 1900,
    maxYear: 2099,
  };
  setInitialCurrencyValue;
  constructor(
    private fb: FormBuilder,
    private customerService: CustomerService,
    public modalRef: MDBModalRef,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.setInitialCurrencyValue = "b87668f2-cb17-48a8-2535-08d7b39928d1";
    this.isLoading = true;
    this.createForm();
    this.addressProofOptions = addressdocumentTypes;
    this.identityProofOptions = identitydocumentTypes;
    if (this.customer) {
      this.editCustomer(this.customer);
    } else {
      setTimeout(() => {
        this.isLoading = false;
      });
    }
    this.form.valueChanges.subscribe((v) => {
    
      for (let control in this.form.controls) {
        if (this.form.controls[control].invalid) {
          
        }
      }
    });
  }

  onSubmit(customerData: ICustomer): void {
    this.isInProgress = true;
    if (this.isFormEdit) {
      const formData = new FormData();
      formData.append("CustomerId", this.selectedCustomer.customerId);
      formData.append("selfie", this.selfiefile);
      formData.append("identityProof", this.identityfile);
      formData.append("addressProof", this.addressfile);
      // formData.append("AccountNumber", customerData.accountNumber);
      // formData.append("CustomerName", customerData.customerName);
      // formData.append("BankCode", customerData.bankCode);
      // formData.append("BankName", customerData.bankName);
      // formData.append("DOB", customerData.dob.toString());
      // formData.append("UserType", customerData.userType.toString());
      // formData.append("Email", customerData.email);
      // formData.append("Phone", customerData.phone);
      // formData.append("RoutingNumber", customerData.routingNumber);
      // formData.append("GoogleDriveUrl", customerData.googleDriveUrl);
      // formData.append("CurrencyId", customerData.currencyId);
      formData.append(
        "identityDocumentType",
        customerData.identityDocumentType
      );
      formData.append("addressDocumentType", customerData.addressDocumentType);
      this.customerService.editCustomer(formData).subscribe(
        (res: any) => {
          if (res.status) {
            this.form.patchValue(
              {
                selfie: "",
                addressProof: "",
                identityProof: "",
              },
              {
                emitEvent: false,
              }
            );
            this.toastrService.success(CUSTOMER_ADDED_SUCCESS);
            this.isInProgress = false;
            this.form.reset();
          }
        },
        (err) => {
          this.isInProgress = false;
          if (err.error.message) {
            this.toastrService.error(err.error.message);
          } else {
            this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
          }
        }
      );
    } else {
      // customerData.currencyId = this.currentCur.id;
      this.customerService.createCustomer(customerData).subscribe(
        (id: { statusCode: number; message: string }) => {
          if (id.message !== undefined) {
            this.modalRef.hide();
            this.isInProgress = false;
            this.form.reset();
            this.customerService.emitLoadingStatus(true);
            this.customerService.emitCustomerAdd(true);
            this.form.patchValue(
              {
                selfie: "",
                addressProof: "",
                identityProof: "",
              },
              {
                emitEvent: false,
              }
            );
          }
        },
        (err) => {
          this.isInProgress = false;
          if (err.error.message) {
            this.toastrService.error(err.error.message);
          } else {
            this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
          }
        }
      );
    }
  }

  createForm(): void {
    this.form = this.fb.group({
      currencyId: ["", [ValidatorUtility.Required]],
      customerName: ["", [ValidatorUtility.Required, Validators.maxLength(50)]],
      accountNumber: [
        "",
        [ValidatorUtility.Required, Validators.maxLength(20)],
      ],
      bankCode: ["", [ValidatorUtility.Required]],
      bankName: ["", [ValidatorUtility.Required]],
      email: ["", [ValidatorUtility.Required]],
      phone: ["", [ValidatorUtility.Required]],
      dob: ["", [Validators.required]],
      routingNumber: [""],
      googleDriveLink: [""],
      identityProof: [""],
      addressProof: [""],
      identityDocumentType: [""],
      addressDocumentType: [""],
      selfie: [""],
      userType: ["4", [Validators.required]],
    });
  }

  onFileChange(event, control): void {
    if (event.target.files.length > 0) {
      if (control === "selfie") {
        this.selfiefile = event.target.files[0];
      } else if (control === "addressProof") {
        this.addressfile = event.target.files[0];
      } else if (control === "identityProof") {
        this.identityfile = event.target.files[0];
      }
      const file = event.target.files[0];
      this.selfiefile = event.target.files[0];
      this.form.patchValue({
        control: file,
      });
    }
  }

  onFromDateChange(data: any): void {}

  editCustomer(data: any) {
    this.isFormEdit = data.isUploadKyc;
    this.selectedCustomer = data.selectedCustomer;
    this.setInitialCurrencyValue = data.selectedCustomer.currencyId;
    this.form.get("userType").setValue(data.selectedCustomer.userType);
    this.form.get("customerName").setValue(data.selectedCustomer.customerName);
    this.form.get("bankCode").setValue(data.selectedCustomer.bankCode);
    this.form.get("dob").setValue(data.selectedCustomer.dob);
    this.form.get("accountNumber").setValue(data.selectedCustomer.accountNumber);
    this.form.get("phone").setValue(data.selectedCustomer.phone);
    this.form.get("routingNumber").setValue(data.selectedCustomer.routingNumber);
    this.form.get("email").setValue(data.selectedCustomer.email);
    this.form.get("bankName").setValue(data.selectedCustomer.bankName);
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  }

  onCurrencyChange(cur: ICurrency): void {
    if (cur.symbol === "USD") {
      this.form.controls["routingNumber"].setValidators(Validators.required);
    } else {
      this.form.controls["routingNumber"].clearValidators();
    }
    this.form.controls["routingNumber"].updateValueAndValidity();
    this.form.updateValueAndValidity({ onlySelf: true });

    this.currentCur = cur;
    this.form.patchValue(
      {
        currencyId: cur.id,
      },
      {
        emitEvent: false,
      }
    );
  }
}
