import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { HelpdeskStatus } from 'src/app/core/enums/help-desk-enum';
import { LedgerType } from 'src/app/core/enums/ledgerType';
import { ICreateHelpDesk, IHelpDeskCategories, IHelpDeskTicket } from 'src/app/core/models/create-helpdesk';
import { IFormSelect } from 'src/app/core/models/form-select';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { ValidatorUtility } from 'src/app/core/utility/validator.utility';
import { HelpdeskTicketService } from '../services/helpdesk-ticket.service';

@Component({
  selector: 'cashaa-helpdesk-home-ticket',
  templateUrl: './helpdesk-home-ticket.component.html',
  styleUrls: ['./helpdesk-home-ticket.component.scss']
})
export class HelpdeskHomeTicketComponent implements OnInit {

  /** Form Group */
  createHelpdeskTicket: FormGroup;
  ticketDetail: IHelpDeskTicket[] = [];
  helpCategories: IFormSelect[] = [];
  loading: boolean = false;
  
  constructor(
    private helpdeskService: HelpdeskTicketService,
    private activateRoute: ActivatedRoute,
    private modalService: ModalService,
    private mdbModalService: MDBModalService
  ) {
  }

  ngOnInit(): void {
    this.helpdeskService.getHelpDeskCatagories().subscribe((catagories :IHelpDeskCategories[]) => {
      this.helpCategories = catagories.map((catagory) => {
        if (catagory.active) {
          return {
            value :catagory.id,
            label : catagory.categoryName
          }
        }
      })

    });
    this.loadHelpDeskData();
  }

  showTicketCreate() {
    this.modalService.openTicketCreateModal();
  }

  loadHelpDeskData (): void {
    this.helpdeskService.getAllHelpDeskTickets().subscribe((helpDeskData: IHelpDeskTicket[]) => {
      this.ticketDetail = helpDeskData;
      this.loading = true;
    });
  }
  

  getStatusName(status: number): string {
    return HelpdeskStatus[status];
  }


}
