import { Component, OnInit } from '@angular/core';
import { ActivityService } from '../services/activity.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { IRecentTransactionList, ILedgerList } from 'src/app/core/models/activity/recent-transaction-list';
import { Router } from '@angular/router';

@Component({
  selector: 'cashaa-activity-welcome',
  templateUrl: './activity-welcome.component.html',
  styleUrls: ['./activity-welcome.component.scss']
})
export class ActivityWelcomeComponent implements OnInit {



  constructor(private router: Router) {

  }

  ngOnInit(): void {

  }

  goToCrypto(): void {
    this.router.navigateByUrl(`/wallet?crypto=true`);
  }



}
