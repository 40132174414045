import { SharedModule } from "./../shared/shared.module";
import { MDBBootstrapModulesPro } from "ng-uikit-pro-standard";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ConvertHomeComponent } from "./convert-home/convert-home.component";
import { ConvertReviewComponent } from "./convert-review/convert-review.component";
import { ConvertRoutingModule } from "./convert-routing.module";
import { ConvertAmountComponent } from "./convert-amount/convert-amount.component";
import { ConvertCompletedComponent } from "./convert-completed/convert-completed.component";
import { ToastrModule } from "ngx-toastr";

@NgModule({
  declarations: [
    ConvertHomeComponent,
    ConvertReviewComponent,
    ConvertAmountComponent,
    ConvertCompletedComponent,
  ],
  imports: [
    CommonModule,
    ConvertRoutingModule,
    SharedModule,
    FormsModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    MDBBootstrapModulesPro.forRoot(),
  ],
})
export class ConvertModule {}
