import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { APIData, IAPIData, IApiListRespose, IIPList } from 'src/app/core/models/api-manager/api-manager';
import { IFeature } from 'src/app/core/models/api-manager/feature';
import { ValidatorUtility } from 'src/app/core/utility/validator.utility';
import { ApiManagerService } from '../services/api-manager.service';

@Component({
  selector: 'cashaa-api-manager-create',
  templateUrl: './api-manager-create.component.html',
  styleUrls: ['./api-manager-create.component.scss']
})
export class ApiManagerCreateComponent implements OnInit {

  form: FormGroup;
  featureList: IFeature[];
  loading: boolean = false;
  @Output()
  continue = new EventEmitter<IAPIData>();
  @Output()
  featureData = new EventEmitter<IFeature[]>();
  updatedData: IApiListRespose;

  constructor(private fb: FormBuilder, private apiManagerService: ApiManagerService,
    private activateRoute: ActivatedRoute, private toasterService: ToastrService) {
    this.form = this.fb.group({
      apiName: ["", ValidatorUtility.Required],
      features: this.fb.array([]),
      ipAddress: this.fb.array([])
    });

    this.loading = true;

    // of(this.apiManagerService.getFeatureList().subscribe((data: IFeature[]) => {
    //   this.loading = false;
    //   this.featureList = data;
    //   this.addCheckboxData();
    // }));
  }

  ngOnInit(): void {
    this.activateRoute.queryParams.subscribe((params) => {
      let id = params["id"];
      if (id) {
        this.apiManagerService.getAPIData().subscribe((data: IApiListRespose) => {
          if (data) {
            this.updatedData = data;
            this.loadData();
            this.loadFeatureData(true);
          } else {
            this.apiManagerService.getAPIDataById(id).subscribe((data) => {
              if (data) {
                this.updatedData = data;
                this.loadData();
                this.loadFeatureData(true);
              }
            }, (err: HttpErrorResponse) => {
              this.toasterService.error(err.error.message);
            });
          }
        });
      } else {
        this.addItem();
        this.loadFeatureData(false);
      }
    });
  }

  get ipAddressFormArray(): FormArray {
    return this.form.controls.ipAddress as FormArray;
  }

  get featuresFormArray(): FormArray {
    return this.form.controls.features as FormArray;
  }

  addCheckboxData(): void {
    this.featureList.forEach(() => this.featuresFormArray.push(new FormControl(false)));
  }

  addIPAddressData(data?: IIPList): FormGroup {
    return this.fb.group({
      ip: [data?.ip ? data.ip : "", [ValidatorUtility.Required, ValidatorUtility.validateIPAddress]],
      label: [data?.label ? data.label : "", ValidatorUtility.Required]
    });
  }

  addItem(data?: IIPList): void {
    this.ipAddressFormArray.push(this.addIPAddressData(data));
  }

  removeItem(index: number) {
    this.ipAddressFormArray.removeAt(index);
  }

  onFormSubmit(): void {
    let formData = this.form.value;
    let feature: [] = formData.features;
    let index = 0;
    let features: string[] = [];
    feature.forEach((data) => {
      if (data) {
        features.push(this.featureList[index].id);
      }
      index++;
    });
    let apiData: IAPIData = new APIData(formData.apiName, formData.ipAddress as IIPList[], features, this.updatedData?.id);
    this.continue.next(apiData);
    this.featureData.next(this.featureList);
  }

  loadData(): void {
    this.form.controls.apiName.setValue(this.updatedData.apiName);
    this.updatedData.ipList.forEach(data => {
      this.addItem(data);
    });
  }

  loadFeatureData(isEdit: boolean): void {
    this.loading = true;
    this.apiManagerService.getFeatureList().subscribe((data: IFeature[]) => {
      this.loading = false;
      this.featureList = data;
      if (!isEdit) {
        this.addCheckboxData();
      } else {
        this.featureList.forEach(data => {
          let searchData = this.updatedData.apiFeatureTypeList.filter((feature) => feature.id.toLocaleLowerCase() === data.id)[0];
          if (searchData) {
            this.featuresFormArray.push(new FormControl(true));
          } else {
            this.featuresFormArray.push(new FormControl(false));
          }
        });
      }
    });
  }

}
