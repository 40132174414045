
<div class="row p-3">
  <div class="col-1-offset">
     <img class="mx-3 flag-icon mt-2 flag-icon mt-2" [src]="'../../../../assets/icons/' + currency + '.png'" />
  </div>
 
  <div class="text-center bal-align col-offset">
    <h6 class="text-dark text-initial font-weight-bolder m-auto" *ngIf="currency === 'USDT'|| currency === 'USDC'; else nonStable">
      {{ currency }} {{ balance | number: "0.0-2" }}
      
      <br>
      ${{userBalanceInUSD | number: "0.0-2"}}
    </h6>
   
    <ng-template #nonStable>
      <h6 class="text-dark text-initial font-weight-bolder m-auto">
        {{ currency }} {{ balance | number: "0.0-4" }}
        <br>
        ${{userBalanceInUSD | number: "0.0-4"}}
      </h6>
    </ng-template>
  </div>
  <div *ngIf="!isEarn" class="my-auto col text-center">
    <h6 *ngIf="creditBalanceInUSD != 0 || item.creditLine != 0" class="text-dark font-weight-bolder m-auto">
     ${{creditBalanceInUSD}}
    </h6>
    <h6 *ngIf="creditBalanceInUSD==0 && item.creditLine == 0" class="text-dark font-weight-bolder m-auto">
     N/A
     </h6>
  </div>
  <div *ngIf="!isEarn" class="my-auto col text-center">
    <h6 *ngIf="creditBalanceInUSD != 0 || item.creditLine != 0" class="text-dark font-weight-bolder m-auto">
      {{ item.creditLine }}%
    </h6>
    <h6 *ngIf="creditBalanceInUSD==0 && item.creditLine == 0" class="text-dark font-weight-bolder m-auto">
      N/A
    </h6>
  </div>
  <div *ngIf="!isEarn" class="my-auto col text-center">
    <h6 *ngIf="item.interestRate>0" class="text-dark font-weight-bolder m-auto">
      {{ item.interestRate }}%
    </h6>
    <h6 *ngIf="item.interestRate==0" class="text-dark font-weight-bolder m-auto">
      N/A
    </h6>
  </div>
  <button [style.visibility]="item.isFDActive && isEarn ? 'visible' : 'hidden'" mdbBtn type="button" class="ml-auto btn-sm earn-upto"
  (click)="openEarnPlan($event)">Earn
  up to
  {{rate}}%</button>
    <button *ngIf="item.currencynature === 2 && isDepositActive"
    [style.marginLeft]="isDepositActive ? '6' : '-30px'" type="submit" (click)="
                  getCryptoAccountDetails($event, item, idx);
                  selectedCrypto == idx
                    ? (selectedCrypto = -1)
                    : (selectedCrypto = idx);" mdbBtn type="button" class="btn-sm app-primary-btn">
      Deposit</button>
    <button *ngIf="item.currencynature === 1 && isDepositActive" type="submit" (click)="
                getCryptoAccountDetails($event, item, idx);
                selectedCrypto == idx
                  ? (selectedCrypto = -1)
                  : (selectedCrypto = idx);" mdbBtn type="button" class="btn-sm app-primary-btn">
    Deposit</button>
  <button *ngIf="isDepositActive" mdbBtn type="button" class="btn-sm app-secondary-btn"
    (click)="onConvertClick(item.symbol)">Exchange</button>
    <!-- <button  *ngIf="isDepositActive && item.currencynature === 2; else nonBankingFiat" mdbBtn type="button" class="btn-sm app-secondary-btn"
    (click)="onConvertClick(item.symbol)">Exchange</button> -->
    <!-- <ng-template #nonBankingFiat> -->
      <button  *ngIf="!isDepositActive"  mdbBtn type="button" class="btn-sm app-secondary-btn"
      (click)="onBuyClick(item.symbol)">Buy</button>
      <button  *ngIf="!isDepositActive" mdbBtn type="button" class="btn-sm app-secondary-btn"
      (click)="onConvertClick(item.symbol)">Sell</button>
    <!-- </ng-template> -->
  <button [disabled]="!isSendActive" mdbBtn type="button" class="btn-sm app-tertiary-btn"
    (click)="onSendClick(item.symbol)">Withdraw</button>
</div>