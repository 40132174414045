<section class=" bg-white d-flex justify-content-center position-relative sm-width-phone-700">
  <img class="position-absolute logo" src="../../../assets/media/logo-dark.svg" />

  <div class="position-absolute close text-primary-light" (click)="backRoute()">
    <mdb-icon fas icon="times"></mdb-icon>
  </div>
</section>

<div class="stepper sm-width-phone-700">
  <router-outlet></router-outlet>
</div>