<section
  class="wrapper bg-white d-flex justify-content-center position-relative sm-width-phone-700 sm-width-phone-800-height">
  <img class="position-absolute logo" src="../../../assets/media/logo-dark.svg" />

  <div class="position-absolute close text-primary-light" (click)="backRoute()">
    <mdb-icon fas icon="times"></mdb-icon>
  </div>

  <div class="w-stepper-60 deposit-navigation w-phone-100" [hidden]="completed">
    <mdb-stepper #stepper [linear]="true" class="w-60">
      <mdb-step name="Amount" class="step">
        <cashaa-deposit-amount (continue)="continueAmount($event)" (hideAddSender)="hideSender($event)"></cashaa-deposit-amount>
      </mdb-step>
      <!-- <mdb-step name="Payment" class="step">
        <cashaa-deposit-transfer-type (continue)="continue()" (back)="back()" (completed)="complete()">
        </cashaa-deposit-transfer-type>
      </mdb-step> -->
      <mdb-step name="Sender" class="step" *ngIf="hideSenderStepper">
        <cashaa-deposit-recipient (continue)="continue()" (back)="back()" (resetAll)="reset()">
        </cashaa-deposit-recipient>
      </mdb-step>
      <mdb-step name="Bank Details" class="step">
        <cashaa-deposit-detail (continue)="continue()" (back)="back()" (resetAll)="reset()"></cashaa-deposit-detail>
      </mdb-step>
      <mdb-step name="Transfer Details" class="step">
        <cashaa-deposit-transfer-number (continue)="continue()" (completed)="complete()" (resetAll)="reset()">
        </cashaa-deposit-transfer-number>
      </mdb-step>
    </mdb-stepper>
  </div>
  <div *ngIf="completed" class="p-5 d-flex  flex-column mx-auto sm-custom-pt">
    <mdb-icon far icon="check-circle" class="text-center green-text mt-4 mb-2" size="10x"></mdb-icon>
    <h5 class="font-weight-bold mb-2">
      All done! we're just waiting for your money now
    </h5>
    <button mdbBtn block="true" type="button" rounded="true"
      class="w-auto rounded-sm position-relative btn btn-primary waves-light btn-block" mdbWavesEffect
      (click)="reset()">
      New Deposit
    </button>
  </div>
</section>