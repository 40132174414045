import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { ICountry } from "src/app/core/models/country";
import { DomainService } from "src/app/core/services/domain/domain.service";
import { IFormSelect } from "src/app/core/models/form-select";
import { Subject, Observable } from "rxjs";
import { startWith, map } from "rxjs/operators";

@Component({
  selector: "cashaa-form-select-country",
  templateUrl: "./form-select-country.component.html",
  styleUrls: ["./form-select-country.component.scss"],
})
export class FormSelectCountryComponent implements OnInit {
  /** Placeholder for input */
  @Input() placeholder;

  /** input form group */
  @Input() form: FormGroup;

  /** form control */
  @Input() control: FormControl;

  /** name of control */
  @Input() name: string;

  @Input() label = "";

  @Input() onlyValue = false;

  @Input() isRequired = false;

  @Input() set initialValue(data) {
    if (data) {
      this.initValFlag = true;
      this.getCountriesList(data);
    }
  }

  initValFlag: boolean = false;

  @Output() changed = new EventEmitter<any>();

  countries: ICountry[] = [];

  /** form select values */
  options: IFormSelect[] = [];

  searchText = new Subject();

  filteredOptions: Observable<any[]>;

  constructor(private domainService: DomainService) {}

  ngOnInit(): void {
    if (!this.initValFlag) {
      this.getCountriesList();
    } else {
      this.initValFlag = false;
    }
  }

  filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option: any) =>
      option.label.toLowerCase().includes(filterValue)
    );
  }

  /**
   * Get list of available countries
   */
  getCountriesList(setInitialValue?): void {
    this.domainService.getCountriesList().subscribe((list) => {
      this.countries = list.countries;
      this.options = list.countries.map((country) => {
        return {
          value: country.id,
          label: country.name,
        };
      });
      this.addOtherOption();

      this.filteredOptions = this.searchText.pipe(
        startWith(""),
        map((value: string) => {
          return this.filter(value);
        })
      );

      if (setInitialValue) {
        const cur = this.options.filter((c) => c.value === setInitialValue);

        setTimeout(() => {
          if (this.options.length > 0 && cur[0]) {
            this.form.controls[this.name].patchValue(cur[0], {
              emitEvent: false,
            });
            this.control.setValue(cur[0]);
            this.onCountryChange(cur[0]);
          }
        });
      }
    });
  }

  getCountriesCode(): void {
    this.domainService.getCountriesList().subscribe((list) => {
      this.options = list.countries.map((country) => {
        return {
          value: country.countryCode,
          label: `${country.countryCode}(${country.phoneCode})`,
        };
      });
      this.addOtherOption();
    });
  }

  /**
   * Add other options in the list
   */
  addOtherOption(): void {
    this.options.push({
      value: "-1",
      label: "Other",
    });
  }

  onCountryChange(value: IFormSelect): void {
    const cur = this.options.filter((c) => c.value === value.value);
    const country = this.countries.filter((c) => c.id === value.value);
    if (cur.length > 0) {
      this.changed.emit({ option: cur[0], country: country[0] });
    }
  }
}
