<section class="sm-height">
  <label *ngIf="label" class="heading mb-0">{{label}}</label>
  <div class="btn-group sm-width sm-height-div" mdbDropdown [isDisabled]="disable ? true : false">
    <a mdbDropdownToggle mdbBtn class="dropdown-toggle dropdown waves-light black-text text-left d-flex sm-a-align"
      type="button">
      <span class="sm-placeholder" *ngIf="!this.selectedValue">Select option</span>
      <ng-template [ngTemplateOutlet]="information"></ng-template>

      <mdb-icon class="sm-mdb-icon-right chevron-down" fas icon="chevron-down">
      </mdb-icon>
    </a>
    <div class="dropdown-menu m-0 p-3 sm-width-div">
      <ng-template [ngTemplateOutlet]="currencyList"></ng-template>
    </div>
  </div>
</section>
<ng-template #information>
  <h6 class="font-weight-bolder mb-0 mr-3 normal-font-weight pl-2">
    {{ selectedValue?.label }}
  </h6>
</ng-template>
<ng-template #currencyList>
  <div class="d-flex align-items-center">
    <mdb-icon fas icon="search" aria-hidden="true"></mdb-icon>
    <input class="form-control form-control-sm ml-3 w-75 search-input" type="text" placeholder="Search"
      aria-label="Search" mdbInput [(ngModel)]="searchText" (input)="onSearch($event)"
      (click)="$event.stopPropagation()" />
  </div>
  <div>
    <hr />
    <div class="p-0 d-flex align-items-center dropdown-item" (click)="onValueChange(currency)"
      *ngFor="let currency of filteredOptions">
      <h6 class="mb-0 ">{{ currency?.label }}</h6>
    </div>
  </div>
</ng-template>