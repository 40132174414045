<h5 class="heading text-center mt-5">Your ticket has been confirmed and we have received your payment</h5>
<div class="bg-white p-4 ticket-history my-5">
<div class="row">
    <div class="col text-center">
        <h6 class="heading">Date</h6>
    </div>
    <div class="border-right"></div>
    <div class="col text-center">
        <h6 class="heading">Ticket Type</h6>
    </div>
    <div class="border-right"></div>
    <div class="col text-center">
        <h6 class="heading">Reference Number</h6>
    </div>
    <div class="border-right"></div>
    <div class="col text-center">
        <h6 class="heading">Amount</h6>
    </div>
    <div class="border-right"></div>
    <div class="col text-center">
        <h6 class="heading">Payment</h6>
    </div>
</div>
    <div class="row">
        <!-- <ng-container *ngFor="let info of ticketDetails"> -->
                <div class="col text-center font-weight-600">{{ticketDetails?.created | date: 'dd-MM-yyyy'}}</div>
                <div class="col text-center font-weight-600">{{ticketDetails?.eventSubscription}}</div>
                <div class="col text-center font-weight-600">{{ticketDetails?.ticketReferance}}</div>
                <div class="col text-center font-weight-600">{{ticketDetails?.amount}} {{ticketDetails?.symbol}}</div>
                <div class="col text-center font-weight-600">{{ticketDetails?.isPaymentSuccess}}</div>
        <!-- </ng-container> -->
    </div>
   
</div>


<div class="bg-white p-4">
    <h5 class="heading">Deposit History</h5>
    <table class="table table-bordered border-primary align-middle">
        <thead style="background: #E7ECF9">
            <tr class="">
                <th scope="col">Date</th>
                <th scope="col">Amount</th>
                <th scope="col">Transaction Id</th>
                <th scope="col">From Address</th>
                <th scope="col">To Address</th>
                <th scope="col">Status</th>
            </tr>
        </thead>
        <tbody class="">
            <tr class="" *ngFor="let info of depositHistoryData">
                <td>{{info?.created | date: 'dd-MM-yyyy'}}</td>
                <td>{{info?.amount}} {{info?.symbol}}</td>
                <td title="{{info?.transactionId}}" mdbTooltip="Copy transaction id" (click)="copyTransactionID(info)">{{(info?.transactionId | slice: 0:6)
                    + "....." + (info?.transactionId | slice : info?.transactionId.length - 6)}}</td>
                <td title="{{info?.fromAddress}}" mdbTooltip="Copy from address" (click)="copyFromAddress(info)">{{(info?.fromAddress | slice: 0:6)
                 + "....." + (info?.fromAddress | slice : info?.fromAddress.length - 6)}}</td>
                 <td title="{{info?.toAddress}}" mdbTooltip="Copy to address" (click)="copyToAddress(info)">{{(info?.toAddress | slice: 0:6)
                 + "....." + (info?.toAddress | slice : info?.toAddress.length - 6)}}</td>
                <td title="{{info?.status}}">{{info?.status}}</td>
            </tr>
        </tbody>
    </table>
</div>
