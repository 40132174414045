<div>
  <section
      class="p-5 wrapper w-100 d-flex justify-content-center flex-column position-relative mx-auto sm-width-phone-700 sm-width-phone-400-height">
      <mdb-icon fab icon="searchengin" class="text-center search-icon"></mdb-icon>
      <h1 class="mb-4 text-center not-found">404</h1>
      <h4 class="mb-4 text-center font-weight-normal text-dark-red">Sorry, we can't find the page you were looking for!</h4>
      <div class="p-5" class="center-btn mt-4">
          <button mdbBtn block="true" type="button"
              class="sm-width-ph-confirmation w-25 btn-sm btn-primary btn btn-height" mdbWavesEffect
              (click) = "getRoute()">
              Cashaa Home Page <i class="fas fa-arrow-circle-right pl-2" style="font-size: 1rem;"></i>
          </button>
      </div>
  </section>
</div>
