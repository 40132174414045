import { Component, EventEmitter, Output, OnInit, Input } from "@angular/core"
import { EventFeeService } from "../services/event-fee.service";

@Component({
  selector: "cashaa-confirm",
  templateUrl: "./confirm.component.html",
  styleUrls: ["./confirm.component.scss"],
})
export class ConfirmComponent implements OnInit {
  loading = false;
  @Output() onConfirm = new EventEmitter();
  @Output() goBack = new EventEmitter();
  @Input() confirmedTicketDetails;
  ticketDetails;
  currentDate = new Date();

  constructor() { }

  ngOnInit(): void {

  }

  goToPreviousPage() {
    this.goBack.emit();
  }

  continue() {
    this.onConfirm.emit();
  }
}