import { Component, Input } from '@angular/core';
import { ILedgerList } from 'src/app/core/models/activity/recent-transaction-list';


@Component({
  selector: 'cashaa-activity-rolling-reserve',
  templateUrl: './activity-rolling-reserve.component.html',
  styleUrls: ['./activity-rolling-reserve.component.scss']
})
export class ActivityRollingReserveComponent {
  @Input() ledger: ILedgerList;

  getCurrencyName(curr: string): string {
    if (curr === "AUSD") return "USD";
    if (curr === 'DOG') return 'DOGE';
    else return curr;
  }

  getSum(a, b): number {
    return a+b;
  }

}


