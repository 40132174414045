import { ActivityBalanceTransferDetailComponent } from "./../../../activity/activity-balance-transfer-detail/activity-balance-transfer-detail.component";
import { RecipientAddBankaccountComponent } from "./../../../recipient/recipient-add-bankaccount/recipient-add-bankaccount.component";
import { Injectable } from "@angular/core";
import { MDBModalService } from "ng-uikit-pro-standard";
import { MessageBoxComponent } from "src/app/shared/components/message-box/message-box.component";
import { RecipientAddComponent } from "src/app/recipient/recipient-add/recipient-add.component";
import { ProfileBusinessEditComponent } from "src/app/profile/profile-business-edit/profile-business-edit.component";

import { FiatFlowPayAuthApprovalComponent } from "src/app/fiat/fiat-flow-pay-auth-approval/fiat-flow-pay-auth-approval.component";
import { FiatFlowPayApproveComponent } from "src/app/fiat/fiat-flow-pay-approve/fiat-flow-pay-approve.component";
import { ActivityBalanceAttentionCancelTrasnferComponent } from "src/app/activity/activity-balance-attention-cancel-transfer/activity-balance-attention-cancel-transfer.component";
import { FiatFlowRecipientAddBusinessComponent } from "src/app/fiat/fiat-flow-recipient-add-business/fiat-flow-recipient-add-business.component";
import { HelpdeskCreateTicketComponent } from "src/app/helpdesk/helpdesk-create-ticket/helpdesk-create-ticket.component";
import { AddCustomerModalComponent } from "src/app/customer/add-customer-modal/add-customer-modal.component";
import { ReserveModalComponent } from "src/app/account-upgrade/reserve-modal/reserve-modal.component";
import { ICustomer } from "../../models/recipient/recipient-add-usd";
import { ConfirmationBoxComponent } from "src/app/shared/components/confirmation-box/confirmation-box.component";
import { ApManagerDeleteApiConfirmComponent } from "src/app/api-manager/ap-manager-delete-api-confirm/ap-manager-delete-api-confirm.component";
import { WarningMemoBoxComponent } from "src/app/shared/components/warning-memo-box/warning-memo-box.component";
import { EarnPlanDetailsComponent } from "src/app/user-plan/earn-plan-details/earn-plan-details.component";
import { IUserPlan } from "../../models/user-plan/user-detail";
import { AccountUpgradeService } from "src/app/account-upgrade/services/account-upgrade.service";
import { UserSubscriptionPlan } from "../../models/account-upgrade/subscription-plan-response";
import { TermsAndConditionsComponent } from "src/app/shared/components/terms-and-conditions/terms-and-conditions.component";
import { UpdateMemoComponent } from "src/app/recipient/update-memo/update-memo.component";
import { CreditLineComponent } from "src/app/wallet/credit-line/credit-line.component";
import { CancelFeeComponent } from "src/app/activity/cancel-fee/cancel-fee.component";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  constructor(private mdbModalService: MDBModalService, private upgradeService: AccountUpgradeService) { }

  /**
   * Open Login Component in a
   * Model
   */
  openMessageModal(title: string = "", body: string = ""): void {
    this.mdbModalService.show(MessageBoxComponent, {
      ignoreBackdropClick: true,
      data: {
        title,
        body,
      },
      class: "modal-dialog-centered",
    });
  }

  /**
   * Open Login Component in a
   * Model
   */
  openRecipientAddModal(
    name?: string,
    email?: string,
    symbol?: string,
    beneficiarytype?: string,
    addressType?: string
  ): void {
    this.upgradeService.getUserActionSubscriptionPlan().subscribe((data: UserSubscriptionPlan) => {
      if (data) {
        this.mdbModalService.show(RecipientAddComponent, {
          class: "sm-modal-width",
          ignoreBackdropClick: true,
          data: {
            userEmail: data.email,
            userName: data.name,
            symbol: symbol,
            beneficiarytype: beneficiarytype,
            currentUserPlanDetail: data,
            addressType: addressType
          },
        });
      }
    });

  }

  openConfirmationModal(confirmBoxMesg: string): void {
    this.mdbModalService.show(ConfirmationBoxComponent, {
      class: "sm-modal-width-box",
      ignoreBackdropClick: true,
      data: {
        mesg: confirmBoxMesg
      }
    });
  }

  openWarningModal(currency: string): void {
    this.mdbModalService.show(WarningMemoBoxComponent, {
      class: "sm-modal-width-warning",
      ignoreBackdropClick: true,
      data: {
        currency: currency
      }
    });
  }

  openRecipientBrokerModal(beneficiarytype: string): void {
    this.upgradeService.getUserActionSubscriptionPlan().subscribe((data: UserSubscriptionPlan) => {
      if (data) {
        this.mdbModalService.show(RecipientAddComponent, {
          class: "sm-modal-width",
          ignoreBackdropClick: true,
          data: {
            beneficiarytype: beneficiarytype,
          },
        });
      }
    });
  }
  /**
   * Open Login Component in a
   * Model
   */
  openPersonalModal(
    isBusiness?,
    isStaking?,
    stakingHeading?,
    stakingBody?
  ): void {
    this.mdbModalService.show(ReserveModalComponent, {
      class: "sm-modal-width",
      ignoreBackdropClick: true,
      data: {
        isBusiness: isBusiness,
        isStaking: isStaking,
        stakingHeading: stakingHeading,
        stakingBody: stakingBody,
      },
    });
  }

  openCustomerAddModal(customer?): void {
    this.mdbModalService.show(AddCustomerModalComponent, {
      class: "sm-modal-width",
      ignoreBackdropClick: true,
      data: {
        customer: customer,
      },
    });
  }

  openCustomerEditModal(customer: {
    selectedCustomer: ICustomer;
    isUploadKyc: boolean;
  }): void {
    this.mdbModalService.show(RecipientAddComponent, {
      class: "sm-modal-width",
      ignoreBackdropClick: true,
      data: {
        customer: customer,
        userEmail: customer.selectedCustomer.email,
        userName: customer.selectedCustomer.firstName,
      },
    });
  }

  openTicketCreateModal(ledgerType?: number): void {
    this.mdbModalService.show(HelpdeskCreateTicketComponent, {
      ignoreBackdropClick: true,
      data: {
        selectedLedgerType: ledgerType,
      },
    });
  }

  /**
   * Open Bank Recipient Component in a
   * Model
   */
  openBankRecipientAddModal(): void {
    this.mdbModalService.show(RecipientAddBankaccountComponent, {
      ignoreBackdropClick: true,
    });
  }

  /**
   * Open Login Component in a
   * Model
   */
  attentionCancelTransfer(): void {
    this.mdbModalService.show(ActivityBalanceAttentionCancelTrasnferComponent, {
      ignoreBackdropClick: true,
    });
  }

  /**
   * Open business profile modal
   */
  openBusinessProfileModal(): void {
    this.mdbModalService.show(ProfileBusinessEditComponent, {
      class: "profileModal",
      ignoreBackdropClick: true,
    });
  }

  /**
   * Open send moeny password conformation model
   */
  openSendMoneySecurityConfirmation(): void {
    this.mdbModalService.show(FiatFlowPayAuthApprovalComponent, {
      ignoreBackdropClick: true,
    });
  }

  /**
   * Open send moeny password conformation model
   */
  openRecipentSkipRequiredPopup(): void {
    this.mdbModalService.show(FiatFlowPayApproveComponent, {
      ignoreBackdropClick: true,
    });
  }
  /**
   * Open send moeny password conformation model
   */
  openSendMoneyApprove(): void {
    this.mdbModalService.show(FiatFlowPayApproveComponent, {
      ignoreBackdropClick: true,
    });
  }

  /**
   * Open send moeny password conformation model
   */
  openSendMoneyAddRecipientBusiness(symbol?): void {
    this.mdbModalService.show(FiatFlowRecipientAddBusinessComponent, {
      ignoreBackdropClick: true,
      data: {
        symbol: symbol,
      },
    });
  }

  openActivityTransferDetail(data: any): void {
    this.mdbModalService.show(ActivityBalanceTransferDetailComponent, {
      ignoreBackdropClick: true,
      data: {
        detail: data,
      },
    });
  }

  confirmDeleteAPI(id: string): void {
    this.mdbModalService.show(ApManagerDeleteApiConfirmComponent, {
      ignoreBackdropClick: true,
      data: {
        apiId: id,
      }
    });
  }

  openEarnPlanDetailPopup(plans: IUserPlan[], diamondPlan: IUserPlan, balance: number, id: string, symbol: string, casDepositRates: number[], isFixedDepositEnable: boolean, isFixedAndEarnEnable: boolean,
    maxDepositRate: number[], isEarnModuleNotActive: boolean, casDepositRatesLessThan365: number[], casDepositRatesGreaterThan365: number[], minFlexAmount: number): void {
    this.mdbModalService.show(EarnPlanDetailsComponent, {
      class: "sm-modal-earn-width-box",
      ignoreBackdropClick: true,
      data: {
        userPlanDetailByCurrency: plans,
        diamondPlan: diamondPlan,
        balance: balance,
        currencyId: id,
        symbol: symbol,
        casDepositRates: casDepositRates,
        isFixedDepositEnable: isFixedDepositEnable,
        isFixedAndEarnEnable: isFixedAndEarnEnable,
        maxInterestModeRate: maxDepositRate,
        isEarnModuleNotActive: isEarnModuleNotActive,
        casDepositRatesLessThan365: casDepositRatesLessThan365,
        casDepositRatesGreaterThan365: casDepositRatesGreaterThan365,
        minFlexAmount: minFlexAmount

      }
    });
  }

  openTermsConditionPopup(content, ...imgSrc): void {
    this.mdbModalService.show(TermsAndConditionsComponent, {
      backdrop: false,
      ignoreBackdropClick: true,
      data: {
        info: content,
        imgSrc: imgSrc
      }
    });
  }
  /**
  * Open Update Memo in a
  * Model
  */
  openUpdateMemoModal(
    currency,
    currencyId,
    accountNumber,
    recipientId,
    networkTypeValue,
    networkTypeLabel
  ): void {
    this.mdbModalService.show(UpdateMemoComponent, {
      class: "sm-modal-width",
      ignoreBackdropClick: true,
      data: {
        currency,
        currencyId,
        accountNumber,
        recipientId,
        networkTypeValue,
        networkTypeLabel
      },
    })

  }

  /**
* Open Credit line
* Model
*/
  // openCreditLineModal(totalBalanceInUSD, totalCreditInUSD
  //   ): void {
  //         this.mdbModalService.show(CreditLineComponent, {
  //           class: "sm-modal-width",
  //           ignoreBackdropClick: true,
  //           data: {
  //             totalBalanceInUSD,
  //              totalCreditInUSD
  //           },
  //       })

  //   }

  showCancelFeeInfo(depositInterestId
  ): void {
    this.mdbModalService.show(CancelFeeComponent, {
      class: "sm-modal-width",
      ignoreBackdropClick: true,
      data: {
        depositInterestId
      },
    })
  }
}
