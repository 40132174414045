import { Router } from "@angular/router";
import { Recipient } from "./../../core/models/fIat/recipients";
import { ITransferFees } from "./../../core/models/transfer-types";
import { DomainService } from "./../../core/services/domain/domain.service";
import { ICurrency } from "./../../core/models/currency";
import { FiatFlow } from "./../../core/models/fIat/fiat-flow";
import { FiatService } from "./../services/fiat.service";
import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { APP_ROUTES, USER_FEE_MODE } from "../../app.constants";
import { interval } from "rxjs";
import { LedgerType } from "src/app/core/enums/ledgerType";
import { ModalService } from "src/app/core/services/modal/modal.service";
import { OnDestroy } from "@angular/core";

@Component({
  selector: "cashaa-fiat-completed",
  templateUrl: "./fiat-completed.component.html",
  styleUrls: ["./fiat-completed.component.scss"],
})
export class FiatCompletedComponent implements OnInit, OnDestroy {
  /** on continue click, emit event */
  @Output() continue = new EventEmitter<void>();

  timer: any;

  // @Input() set transId(data) {
  //   if (data) {
  //     this.timer = interval(2000).subscribe((val) => {
  //       this.fiatService.isCryptoSendVerified(data).subscribe((flag) => {
  //         if (flag.message === "success") {
  //           this.loading = false;
  //           this.isVerified = true;
  //           this.timer.unsubscribe();
  //         }
  //       });
  //     });
  //   }
  // }

  /** FiatFlow data */
  fiatFlowData?: FiatFlow;

  // hold pay data request
  fromcurrencyObject: ICurrency;

  /** fee amount object */
  feeAmountObject: ITransferFees;

  /** fiat recipient data */
  fiatRecipientData?: Recipient;
  /** feemode variable */
  userFeeMode = sessionStorage.getItem(USER_FEE_MODE);
  _casCurrecnyId = "43796AC1-8F4E-4875-2533-08D7B39928D1";
  casFee = 0;
  // isVerified: boolean;
  isVerified = true;
  loading = false;
  fiatCurrencies = ["USD", "GBP", "EUR", "AUSD", "SGD", "AUD", "CNH", "CAD", "CHF", "NZD", "JPY"];

  constructor(
    private fiatService: FiatService,
    private domainService: DomainService,
    private router: Router,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    // this.loading = true;

    this.getFiatFlowData();
    this.getRecipientData();
  }

  ngOnDestroy() {
    if (this.timer) {
      this.timer.unsubscribe();
    }
  }

  getFiatFlowData(): void {
    this.fiatService.getfiatFlowPaydDataEmitter().subscribe((fiatFlowData) => {
      if (fiatFlowData) {
       // this.loading = false;
        this.fiatFlowData = fiatFlowData;
        this.getFromCurrency(this.fiatFlowData);
        this.getFee(this.fiatFlowData);
      }
    },
      err => {
        //this.loading = false;
      }
    );

  }

  getDecimalNumber(symbol) {
    let toFix = "0";
    if (symbol === "CAS" || symbol === "BNB") {
      toFix = "";
    } else if (this.fiatCurrencies.includes(symbol)) {
      toFix = "0.2-2";
    } else {
      toFix = "0.4";
    }
    return toFix;
  }

  getRecipientData(): void {
    this.fiatService.getemitRecipientdData().subscribe((fiatRecipientdData) => {
      if (fiatRecipientdData) {
        //this.loading = false;
        this.fiatRecipientData = fiatRecipientdData;
      }
    },
      err => {
        //this.loading = false;
      });
  }

  /**
   * Get from currency
   */
  getFromCurrency(fiatFlow: FiatFlow): void {
    this.fromcurrencyObject = fiatFlow.fromcurrencyObject;
  }

  /**
   * Get fee data
   */
  getFee(fiatFlow: FiatFlow): void {
    this.feeAmountObject = fiatFlow.feeAmountObject;
    if (this.userFeeMode !== "InNative") {
      this.casFee = this.feeAmountObject.sendFee / this.feeAmountObject.casRate;
    }
  }

  /**
   * On continue click
   */
  onContinue(): void {
    this.router.navigateByUrl(APP_ROUTES.ACTIVITY_LIST);
  }

  helpDeskSend(): void {
    this.modalService.openTicketCreateModal(LedgerType.sent);
  }
}
