<cashaa-sidenav>
  <section class="mh-100">
    <div class="bg-white p-5" style="min-height: 70px;">
      <!-- <h4 class="font-weight-bold sm-profile-heading-mobile">KYC Verification</h4> -->
    </div>
  </section>
  <div class="p-4">
    <div class="mx-auto stepper w-phone-100 w-90" *ngIf="!loading">
      <mdb-stepper #stepper [linear]="true">
        <!-- <mdb-step name="Payment" class="step" *ngIf="plan==='Broker' && isPaymentTabActive">
          <cashaa-kyc-busines-application-status (continue)="onStatusContinue()">
          </cashaa-kyc-busines-application-status>
        </mdb-step> -->
        <mdb-step name="Basic Information" title="Basic" class="step">
          <cashaa-kyc-business-information (continue)="onInformationContinue($event)">
          </cashaa-kyc-business-information>
        </mdb-step>
        <mdb-step name="Director Contact" class="step">
          <div>
            <cashaa-kyc-business-director-detail [informationData]="informationData"
              (continue)="onDirectorContinue($event)"></cashaa-kyc-business-director-detail>
          </div>
        </mdb-step>
        <mdb-step name="Upload Business Doc" class="step">
          <cashaa-kyc-business-checklist [informationData]="informationData" [directorData]="directorData"
            (continue)="onChecklistContinue(businessId)"></cashaa-kyc-business-checklist>
        </mdb-step>
        <mdb-step name="Application Form" class="step" *ngIf="isActive()">
          <cashaa-kyc-business-application-form [plan]="plan" [businessId]="businessId"
            (continue)="onApplicationFormContinue()">
          </cashaa-kyc-business-application-form>
        </mdb-step>

        <mdb-step name="Upload Director Doc" class="step">
          <cashaa-kyc-upload-contact-doc (continue)="onUploadDirectorDocContinue()" [directorData]="directorData">
          </cashaa-kyc-upload-contact-doc>
        </mdb-step>

        <mdb-step name="Business User" class="step">
          <div class="sm-div-height">
            <cashaa-kyc-business-user-add (continue)="onCreateBusinessUserContinue()" plan="Broker">
            </cashaa-kyc-business-user-add>
          </div>
        </mdb-step>

        <mdb-step name="Status" class="step">
          <cashaa-kyc-status [kycCompleteStatus]="kycCompleteStatus">
          </cashaa-kyc-status>
        </mdb-step>
      </mdb-stepper>
    </div>
  </div>
</cashaa-sidenav>