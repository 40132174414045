<div class="btn-group currency-dropdown" mdbDropdown>
  <a
    mdbDropdownToggle
    mdbBtn
    class="dropdown-toggle dropdown waves-light black-text text-left"
    type="button"
    mdbWavesEffect
    (click)="isDropdownOpened = !isDropdownOpened"
  >
    <ng-template
      [ngTemplateOutlet]="information"
      [ngTemplateOutletContext]="{ balance: selectedBalance }"
    >
    </ng-template>

    <mdb-icon
      class="position-absolute arrow"
      fas
      [icon]="'long-arrow-alt-' + (isDropdownOpened ? 'up' : 'down')"
    >
    </mdb-icon>
  </a>
  <div class="dropdown-menu">
    <a
      class="dropdown-item dropdown black-text text-left"
      (click)="onBalanceSelected(balance)"
      *ngFor="let balance of balances"
    >
      <ng-template
        [ngTemplateOutlet]="information"
        [ngTemplateOutletContext]="{ balance: balance }"
      ></ng-template>
    </a>
  </div>
</div>

<ng-template #information let-balance="balance">
  <div>
    <img
      [src]="'../../../../assets/icons/' + balance?.currencyType + '.png'"
      class="icon mr-2"
    />
    <ng-container *ngIf="mode == 'buy' && type == 'wallet' && !isMobile"
      >Pay with {{ balance?.currencyType }} Wallet
    </ng-container>
    <ng-container *ngIf="mode == 'sell' && type == 'wallet' && !isMobile"
      >Receive in {{ balance?.currencyType }} Wallet
    </ng-container>
    <ng-container *ngIf="(mode == 'sell' && type == 'crypto') || isMobile">{{
      balance?.currencyType
    }}</ng-container>
  </div>
  <div>{{ balance?.available }} {{ balance?.currencyType }}</div>
</ng-template>
