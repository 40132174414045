import { WithdrawConfirmation } from "./../../core/models/withdraw-fund";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { AddressWhitelist } from "src/app/core/models/address-whitelist";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { CreateWithdrawRequest } from "src/app/core/models/withdraw-fund";
import {
  WithdrawFundRequest,
  IWithdrawFundResponse,
} from "src/app/core/models/withdraw-fund";
import { ICurrencyList } from "../../core/models/currency";

@Injectable({
  providedIn: "root",
})
export class WithdrawService {
  /** Address Whitelist data emitter */
  private adressWhitelistDataEmitter = new BehaviorSubject<
    AddressWhitelist | undefined
  >(undefined);

  /** Withdraw Address data emitter */
  private withdrawAddressDataEmitter = new BehaviorSubject<string | undefined>(
    undefined
  );

  constructor(private http: HttpClient) {}

  /**
   * get Crypto currencies for network dropdown
   */
  getCryptoCurrency(): Observable<ICurrencyList> {
    return this.http.get<ICurrencyList>(`${environment.api}/currencies/crypto`);
  }

  addressWhitelist(
    addressWhitelistData: AddressWhitelist
  ): Observable<HttpResponse<{ statusCode: number; message: string }>> {
    return this.http.post<any>(
      `${environment.api}/WithdrawAddress/Create`,
      addressWhitelistData
    );
  }

  /*** Get Address Whitelist data emitter***/
  getAddressWhitelistDataEmitter(): Observable<AddressWhitelist> {
    return this.adressWhitelistDataEmitter.asObservable();
  }

  /**
   * Emit AddressWhitelist data
   */
  emitAddressWhitelistData(data: AddressWhitelist): void {
    this.adressWhitelistDataEmitter.next(data);
  }

  createWithdrawFund(
    withdrawFundData: WithdrawFundRequest
  ): Observable<IWithdrawFundResponse> {
    return this.http.post<IWithdrawFundResponse>(
      `${environment.api}/wallets/create`,
      withdrawFundData
    );
  }

  createWithdrawRequest(
    withdrawRequestData: CreateWithdrawRequest
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${environment.api}/send/cryptosend`,
      withdrawRequestData
    );
  }

  /*** Get Withdraw Fund data emitter***/
  getWithdrawAddressSymbolEmitter(): Observable<string> {
    return this.withdrawAddressDataEmitter.asObservable();
  }

  /**
   * Emit Withdraw Address data
   */
  emitWithdrawAddressSymbolEmitter(data: string): void {
    this.withdrawAddressDataEmitter.next(data);
  }

  /** withraw request confirmation */

  withdrawConfirmation(
    withdrawConfirmationData: WithdrawConfirmation
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${environment.api}/activity/confirmation`,
      withdrawConfirmationData
    );
  }

  withdrawAddressConfirmation(
    withdrawConfirmationData: WithdrawConfirmation
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${environment.api}/activity/confirmation`,
      withdrawConfirmationData
    );
  }
}

//WithdrawAddressConfirmation
