<div *ngIf="form" class="position-relative" (paste)="onCodePaste($event)">
  <label *ngIf="label" class="text-dark font-weight-normal">{{ label }}</label>
  <div class="d-flex">
    <input #inputfirst mdbInput type="text" onKeyPress="if(this.value.length==2) return false;"
      class="form-control mr-1" placeholder="40" [(ngModel)]="input1" (input)="onInputChange(1)" />
    <input #inputsecond mdbInput type="text" onKeyPress="if(this.value.length==2) return false;"
      class="form-control mr-1" placeholder="30" [(ngModel)]="input2" (input)="onInputChange(2)" />
    <input #inputthird mdbInput type="text" onKeyPress="if(this.value.length==2) return false;" class="form-control"
      placeholder="20" [(ngModel)]="input3" (input)="onInputChange(3)" />
  </div>
  <mdb-error *ngIf="control?.invalid && (control?.dirty || control?.touched)">{{
    getErrorMessage()
    }}</mdb-error>
</div>