import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CANCEL_AUTO_RENEWAL_SUCCESS, UNABLE_TO_PERFORM_ERROR } from 'src/app/app.constants';
import { RateType } from 'src/app/core/enums/rateTypeEnum';
import { ILedgerList } from 'src/app/core/models/activity/recent-transaction-list';
import { ICancelAutoRenewal } from 'src/app/core/models/deposit-interest/deposit-interest';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { DepositInterestService } from 'src/app/deposit-interest/service/deposit-interest.service';
import { CommonSavedData } from 'src/app/shared/services/commonSavedData.service';


@Component({
  selector: 'cashaa-activity-deposit-interest',
  templateUrl: './activity-deposit-interest.component.html',
  styleUrls: ['./activity-deposit-interest.component.scss']
})
export class ActivityDepositInterestComponent implements OnInit {
  @Input() ledger: ILedgerList;
  rateType = RateType;
  loader: boolean = false;
  sub: Subscription;
  isFDPrematureWithdraw: boolean;

  constructor(private modalService: ModalService, private commonSavedData: CommonSavedData,
    private toastrService: ToastrService, private depositInterestService: DepositInterestService, private userService: UserService) { }

  ngOnInit(): void {
    this.getUserPlanDetails();
  }

  getCurrencyName(curr: string): string {
    if (curr === "AUSD") return "USD";
    if (curr === 'DOG') return 'DOGE';
    else return curr;
  }

  help() {
    this.modalService.openTicketCreateModal(this.ledger.ledgerType);
  }

  isEndDateReached(): boolean {
    return new Date(this.ledger.endDate).setHours(0, 0, 0, 0) <= new Date().getHours();
  }

  setAutoRenewal(): void {
    console.log(this.ledger.isAutoRenew)

    if (this.ledger.isAutoRenew) {
      this.modalService.openConfirmationModal("Do you want to enable the auto renewal?");
    } else {
      this.modalService.openConfirmationModal("Do you want to cancel the auto renewal?");
    }

    this.sub = this.commonSavedData.getConfirmationBoxStatus().subscribe((status: boolean) => {
      if (status) {
        // this.loader = true;
        const cancel: ICancelAutoRenewal = {
          id: this.ledger.depositInterestId
        }
        this.depositInterestService.cancelAutoRenewal(cancel).subscribe((res) => {
          // this.loader = false;
          if (res.statusCode === 200) {
            this.toastrService.success(CANCEL_AUTO_RENEWAL_SUCCESS);
            // this.ledger.isAutoRenew;
          } else {
            this.toastrService.error("Please try again!");
          }
        }, (err) => {
          if (err.error.message) {
            this.toastrService.error(err.error.message);
          } else {
            this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
          }
          // this.loader = false;
        });
      } else {
        this.ledger.isAutoRenew = !this.ledger.isAutoRenew;
      }
      this.sub.unsubscribe();
    });
  }
  cancelFixedDeposit() {

    //  let content = '';
    //   content = `<div class="font-weight-bold heading my-2">Fee for premature withdrawals</div>
    //                <div class="fee" *ngFor="let ${info} of ${this.cancelFeeDetails}">

    //                     <div class="d-flex border p-2">
    //                         <div class="percentage">
    //                             <h6 class="font-weight-bold">${this.cancelFeeDetails[0]}%</h6>
    //                           </div>
    //                          <div class="ml-3 desc">
    //                           <small class="heading mb-0">${info}</small>
    //                           <div class="description">Any interest given will be also adjusted from principle</div>
    //                           </div>
    //                     </div>
    //                     <div class="d-flex border p-2">
    //                         <div class="percentage">
    //                             <h6 class="font-weight-bold">15%</h6>
    //                           </div>
    //                          <div class="ml-3 desc">
    //                           <small class="heading mb-0">Second Year</small>
    //                           <div class="description">Any interest given will be also adjusted from principle</div>
    //                           </div>
    //                     </div>
    //                     <div class="d-flex border p-2">
    //                         <div class="percentage">
    //                             <h6 class="font-weight-bold">10%</h6>
    //                           </div>
    //                          <div class="ml-3 desc">
    //                           <small class="heading mb-0">Third Year</small>
    //                           <div class="description">Any interest given will be also adjusted from principle</div>
    //                           </div>
    //                     </div>
    //                 </div>`
    //  this.modalService.openConfirmationModal(`Do you really want to cancel this fixed-term deposit? 
    //  Please note - <b>${this.cancelFee.toFixed(2)} ${this.ledger.currency}</b> will be deducted for the premature cancellation.</br>
    //  ${content}`);
    this.modalService.showCancelFeeInfo(this.ledger.depositInterestId)
    // this.sub = this.commonSavedData.getConfirmationBoxStatus().subscribe((status: boolean) => {
    //   if (status) {
    //     this.loader = true;
    //     const cancel: ICancelAutoRenewal = {
    //       id: this.ledger.depositInterestId
    //     }
    //     this.depositInterestService.cancelFixedDeposit(cancel).subscribe((res) => {
    //       this.loader = false;
    //       if (res.statusCode === 200) {
    //         this.toastrService.success(CANCEL_AUTO_RENEWAL_SUCCESS);
    //         this.ledger.isActive = false;
    //         window.location.reload();
    //       } else {
    //         this.toastrService.error("Please try again!");
    //       }
    //     }, (err) => {
    //       if (err.error.message) {
    //         this.toastrService.error(err.error.message);
    //       } else {
    //         this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
    //       }
    //       this.loader = false;
    //     });
    //   }
    //   this.sub.unsubscribe();
    // });
  }

  // 

  viewStatement(ledger, rateType) {
    sessionStorage.setItem('ledger', JSON.stringify(ledger));
    sessionStorage.setItem('rateType', JSON.stringify(rateType));
    // this.router.navigateByUrl('/statement');
  }

  getUserPlanDetails() {
    this.userService.getUserPlanDetail().subscribe(res => {
      console.log(res);
      this.isFDPrematureWithdraw = res.isFDPrematureWithdraw;
    }, err => {

    })
  }

}
