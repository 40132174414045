import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ActivityHomeComponent } from "./activity-home/activity-home.component";
import { ActivityRoutingModule } from "./activity-routing.module";
import { SharedModule } from "../shared/shared.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MDBBootstrapModulesPro } from "ng-uikit-pro-standard";
import { ActivityBalanceListComponent } from "./activity-balance-list/activity-balance-list.component";
import { ActivityBalanceRecievedComponent } from "./activity-balance-recieved/activity-balance-recieved.component";
import { ActivityBalanceTransferComponent } from "./activity-balance-transfer/activity-balance-transfer.component";
import { ActivityWelcomeComponent } from "./activity-welcome/activity-welcome.component";
import { ActivityBalanceAttentionComponent } from "./activity-balance-attention/activity-balance-attention.component";
import { ActivityBalanceAttentionCancelTrasnferComponent } from "./activity-balance-attention-cancel-transfer/activity-balance-attention-cancel-transfer.component";
import { ActivityBalanceCancelledComponent } from "./activity-balance-cancelled/activity-balance-cancelled.component";
import { ActivityBalanceConvertedComponent } from "./activity-balance-converted/activity-balance-converted.component";
import { ActivityBalanceSpentComponent } from "./activity-balance-spent/activity-balance-spent.component";
import { ActivityBalanceWithdrawnComponent } from "./activity-balance-withdrawn/activity-balance-withdrawn.component";
import { ActivityBalanceTransferDetailComponent } from "./activity-balance-transfer-detail/activity-balance-transfer-detail.component";
import { ToastrModule } from "ngx-toastr";
import { ActivityBalanceUsdStakingComponent } from "./activity-balance-usd-staking/activity-balance-usd-staking.component";
import { ActivityBalanceOnboardingFeeComponent } from "./activity-balance-onboarding-fee/activity-balance-onboarding-fee.component";
import { ActivityBalanceWeeklyRewardComponent } from "./activity-balance-weekly-reward/activity-balance-weekly-reward.component";
import { ActivityDepositInterestComponent } from './activity-deposit-interest/activity-deposit-interest.component';
import { CancelFeeComponent } from './cancel-fee/cancel-fee.component';
import { ActivityCreditAvailedComponent } from "./activity-credit-availed/activity-credit-availed.component";
import { ActivityCreditRepaidComponent } from "./activity-credit-repaid/activity-credit-repaid.component";
import { ActivityCreditInterestComponent } from "./activity-credit-interest/activity-credit-interest.component";
import { ActivityCreditLockedComponent } from "./activity-credit-locked/activity-credit-locked.component";
import { ActivityCreditLockedReleasedComponent } from "./activity-credit-locked-released/activity-credit-locked-released.component";
import { ActivityRollingReserveComponent } from "./activity-rolling-reserve/activity-rolling-reserve.component";
import { ActivityStakingRollingReserveComponent } from "./activity-staking-rolling-reserve/activity-staking-rolling-reserve.component";

@NgModule({
  declarations: [
    ActivityHomeComponent,
    ActivityBalanceListComponent,
    ActivityBalanceRecievedComponent,
    ActivityWelcomeComponent,
    ActivityBalanceTransferComponent,
    ActivityBalanceAttentionComponent,
    ActivityBalanceAttentionCancelTrasnferComponent,
    ActivityBalanceCancelledComponent,
    ActivityBalanceConvertedComponent,
    ActivityBalanceSpentComponent,
    ActivityBalanceWithdrawnComponent,
    ActivityBalanceTransferDetailComponent,
    ActivityBalanceUsdStakingComponent,
    ActivityBalanceOnboardingFeeComponent,
    ActivityBalanceWeeklyRewardComponent,
    ActivityDepositInterestComponent,
    ActivityCreditAvailedComponent,
    ActivityCreditRepaidComponent,
    CancelFeeComponent,
    ActivityCreditInterestComponent,
    ActivityCreditLockedComponent,
    ActivityCreditLockedReleasedComponent,
    ActivityRollingReserveComponent,
    ActivityStakingRollingReserveComponent
  ],
  imports: [
    CommonModule,
    ActivityRoutingModule,
    SharedModule,
    ToastrModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
    FormsModule
  ],
})
export class ActivityModule {}
