<section class="p-5 w-75 mx-auto" *ngIf="resposeData">
    <h3 class="text-primary-dark font-weight-bolder mb-5 text-center">
        Review details of your API Manager
    </h3>
    <div class="details px-4 py-2">
        <div class="mb-4 mt-2">
            <div class="font-weight-bold text-gray ml-5">API Name</div>
            <div class="font-weight-bolder border details link ml-5 bg-grey mt-2 p-2" style="width: 35%;">
                {{resposeData.apiName}}
            </div>
        </div>
        <div class="mb-4 mt-2">
            <div class="font-weight-bold text-gray ml-5">API Key</div>
            <div class="font-weight-bolder border details link ml-5 bg-grey mt-2 p-2" style="width: 35%;">
                {{resposeData.apiKey}}
            </div>
        </div>
        <div class="mb-4 mt-2">
            <div class="font-weight-bold text-gray ml-5">Secret Key</div>
            <div class="font-weight-bolder border details link ml-5 bg-grey mt-2 p-2" style="width: 35%;">
                {{resposeData.secretKey}}
                <i mdbTooltip="Copy 2FA secret key" placement="top" class="fas fa-clone ml-2"
                [ngClass]="copied ? 'text-primary' : ''" (click)="copyAddress()"></i>
            </div>
        </div>
        <div class="mb-4 mt-2">
            <div class="font-weight-bold text-gray ml-5">API Restrictions</div>
            <div class="d-inline-flex">
                <div class="font-weight-bolder border details link ml-5 bg-grey mt-2 p-2">
                    General
                </div>
            </div>
            <div *ngFor="let item of resposeData.apiFeatureTypeList" class="d-inline-flex">
                <div class="font-weight-bolder border details link ml-5 bg-grey mt-2 p-2">
                    {{item.name}}
                </div>
            </div>
        </div>
        <div class="mb-4 mt-2">
            <div class="font-weight-bold text-gray ml-5">IP Address</div>
            <div *ngFor="let item of resposeData.ipList" class="d-inline-flex">
                <div class="font-weight-bolder border details link ml-5 bg-grey mt-2 p-2">
                    {{item.ip}}
                </div>
            </div>
        </div>
    </div>

    <div class="conditions d-flex mt-4 p-3 mb-4">
        <mdb-checkbox [(ngModel)]="conditionsAccepted">
            <span class="font-weight-normal">I accept the
                <a target="_blank" href="https://cashaa.com/term-condition" style="padding: 0px;">Terms of
                    Use</a></span>
        </mdb-checkbox>
    </div>

    <button [disabled]="!conditionsAccepted" mdbBtn block="true" type="button"
        class="w-100 rounded-sm position-relative btn btn-secondary sm-custom-sec waves-effect waves-light btn-block"
        mdbWavesEffect (click)="onFormSubmit()">
        <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Confirm
    </button>
</section>