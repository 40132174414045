export enum HelpdeskStatus
    {
        /// <summary>
        /// The open
        /// </summary>
        Open = 1,
        /// <summary>
        /// The on hold
        /// </summary>
        OnHold = 2,
        /// <summary>
        /// The initiated
        /// </summary>
        Initiated = 3,
        /// <summary>
        /// The awaiting
        /// </summary>
        Awaiting = 4,
        /// <summary>
        /// The completed
        /// </summary>
        Completed = 5
    }
