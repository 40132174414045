<div class="modal-header position-relative">
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true" style="font-size: 35px">×</span>
  </button>
  <h4 class="modal-title w-100 text-primary font-weight-bolder sm-custom-font mt-3" id="myModalLabel">
    Edit Your Profile
  </h4>
</div>
<div class="p-4 text-center" [hidden]="!loading">
  <mdb-spinner spinnerColor="blue"></mdb-spinner>
</div>
<div class="modal-body">
  <form [formGroup]="businessProfileForm" class="sm-font p-3" [hidden]="loading">
    <h5 class="font-weight-normal text-primary mt-3">Business Profile</h5>
    <p class="mt-2 text-dark font-weight-normal sm-font-note font-size-12">
      *Please note we cannot offer our services to unregistered charities from
      the UK
    </p>
    <hr />
    <div class="row mb-4">
      <div class="col-6">
        <cashaa-form-input name="companyName" type="text" [form]="businessProfileForm"
          [control]="businessProfileForm.controls.companyName" placeholder="" label="Registered Business name">
        </cashaa-form-input>
      </div>
      <div class="col-6">
        <cashaa-form-select name="companyType" type="text" [form]="businessProfileForm" [options]="businessCompany"
          [control]="businessProfileForm.controls.companyType" placeholder="Select Company Type" label="Company type">
        </cashaa-form-select>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-6">
        <cashaa-form-input name="website" type="text" placeholder="" [form]="businessProfileForm"
          [control]="businessProfileForm.controls.website" label="Website or online presence">
        </cashaa-form-input>
      </div>
      <div class="col-6">
        <cashaa-form-input name="registrationNumber" type="text" placeholder="" [form]="businessProfileForm"
          [control]="businessProfileForm.controls.registrationNumber" label="Registration number">
        </cashaa-form-input>
      </div>
    </div>

    <!-- WHAT BANK DO -->
    <h5 class="font-weight-normal text-primary mt-4">What does your bank do?</h5>
    <hr />

    <div class="row mb-4">
      <div class="col-6">
        <cashaa-form-input name="category" type="text" [form]="businessProfileForm"
          [control]="businessProfileForm.controls.category" label="Category">
        </cashaa-form-input>
      </div>
      <div class="col-6">
        <cashaa-form-input name="subcategory" type="text" [form]="businessProfileForm"
          [control]="businessProfileForm.controls.subcategory" placeholder="" label="Sub Category">
        </cashaa-form-input>
      </div>
    </div>

    <!-- Address -->
    <!-- WHAT BANK DO -->
    <h5 class="font-weight-normal text-primary mt-4">Business Address</h5>
    <hr />

    <div class="row mb-4">
      <div class="col-6">
        <cashaa-form-input name="addressLine1" type="addressLine1" [form]="businessProfileForm"
          [control]="businessProfileForm.controls.addressLine1" label="Address 1"></cashaa-form-input>
      </div>
      <div class="col-6">
        <cashaa-form-input name="addressLine2" type="addressLine2" [form]="businessProfileForm"
          [control]="businessProfileForm.controls.addressLine2" label="Address 2"></cashaa-form-input>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-6">
        <cashaa-form-input name="city" type="city" placeholder="" [form]="businessProfileForm"
          [control]="businessProfileForm.controls.city" label="City">
        </cashaa-form-input>
      </div>
      <div class="col-6">
        <cashaa-form-select-country [initialValue]="initialValueCountry" name="countryId" type="countryId"
          [form]="businessProfileForm" [control]="businessProfileForm.controls.countryId" label="Country">
        </cashaa-form-select-country>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-6">
        <cashaa-form-input name="postalCode" type="postalCode" placeholder="" [form]="businessProfileForm"
          [control]="businessProfileForm.controls.postalCode" label="Enter postal code">
        </cashaa-form-input>
      </div>
      <div class="col-6">
        <cashaa-form-input name="phoneNumber" type="phoneNumber" placeholder="" [form]="businessProfileForm"
          [control]="businessProfileForm.controls.phoneNumber" label="Phone Number">
        </cashaa-form-input>
      </div>
    </div>

    <p class="mb-4 text-dark font-weight-bold font-size-12">
      *By clicking continue, you confirm that you are an authorized representative of
      CASHAA TECHNOLOGIES LTD and have an authorization to make payments on the behalf of CASHAA
      TECHNOLOGIES LTD's
    </p>

    <button mdbBtn [disabled]="businessProfileForm.invalid" (click)="onFormSubmit()" block="true" type="button"
      class="rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block" style="font-size: 18px;"
      mdbWavesEffect>
      Submit
    </button>
    <mdb-progress-bar *ngIf="isInProgress" class="progress primary-color-dark position-absolute" mode="indeterminate">
    </mdb-progress-bar>
  </form>
</div>