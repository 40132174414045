export interface ISignup {
  email: string;
  password: string;
  countryId: string;
  registerType: number;
  referralId?: string;
}

export class Signup implements ISignup {
  constructor(
    public email: string,
    public password: string,
    public countryId: string,
    public registerType: number,
    public referralId?: string
  ) {}
}
