<div class="modal-header position-relative sm-form-title">
  <button type="button" class="close pull-right sm-close" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">×</span>
  </button>
  <h3 class="modal-title w-100 text-primary font-weight-bolder" id="myModalLabel">Add Beneficiary/Customer</h3>
</div>
<div class="modal-body">
  <div class="p-4 text-center" [hidden]="!isLoading">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
  </div>

  <form [formGroup]="form" class="position-relative sm-font-family sm-form-padding" [hidden]="isLoading">
    <div class="mb-4">
      <cashaa-form-select-transfer-currency [initialValue]="setInitialCurrencyValue"
        (sendCurrencyChangeEvent)="onCurrencyChange($event)" [noInput]='true' [form]="form" class="width-100"
        [currencyControl]="form.controls.currencyId" label="Currency" currencyControlName="currency" source="wallet">
      </cashaa-form-select-transfer-currency>
    </div>

    <div class="row mb-4">
      <div class="form-check col-5 sm-userType-boxes">
        <input type="radio" formControlName="userType" class="form-check-input" id="personalAccount" name="userType"
          mdbInput value="4" />
        <label class="form-check-label" for="personalAccount">Personal</label>
      </div>
      <div class="form-check col-5 sm-userType-boxes">
        <input type="radio" formControlName="userType" class="form-check-input" id="businessAccount" name="userType"
          mdbInput value="5" />
        <label class="form-check-label" for="businessAccount">Business</label>
      </div>
    </div>

    <div class="mb-4">
      <cashaa-form-input name="email" type="email" text="100" [form]="form" [control]="form.controls.email"
        label="Email (optional)">
      </cashaa-form-input>
    </div>

    <div class="mb-4">
      <cashaa-form-input name="customerName" type="text" [form]="form" [control]="form.controls.customerName"
        placeholder="Enter the Name" label="Name">
      </cashaa-form-input>
    </div>
    <div class="mb-4">
      <cashaa-form-input name="accountNumber" type="text" [form]="form" [control]="form.controls.accountNumber"
        placeholder="Enter the Account Number" label="Account Number">
      </cashaa-form-input>
    </div>
    <div class="mb-4">
      <cashaa-form-input name="bankCode" type="text" [form]="form" [control]="form.controls.bankCode"
        placeholder="Enter the Code" label="Swift/Sort code/IFSC code">
      </cashaa-form-input>
    </div>
    <div class="mb-4" *ngIf="currentCur && currentCur.symbol === 'USD'">
      <cashaa-form-input name="routingNumber" type="text" [form]="form" [control]="form.controls.routingNumber"
        placeholder="Enter the Routing Number" label="Routing Number">
      </cashaa-form-input>
    </div>
    <div class="mb-4">
      <cashaa-form-input name="bankName" type="text" [form]="form" [control]="form.controls.bankName"
        placeholder="Enter the Bank Name" label="Bank Name">
      </cashaa-form-input>
    </div>
    <!-- <div class="mb-4">
      <cashaa-form-input name="email" type="email" [form]="form" [control]="form.controls.email"
        placeholder="Enter the Email" label="Email Address">
      </cashaa-form-input>
    </div> -->
    <div class="mb-4">
      <cashaa-form-input name="phone" type="text" [form]="form" [control]="form.controls.phone"
        placeholder="Enter the Phone Number" label="Phone">
      </cashaa-form-input>
    </div>
    <div class="mb-4">
      <label class="heading">Date of birth</label>
      <mdb-date-picker [inline]="true" name="dob" [placeholder]="'Date of birth'" formControlName="dob"
        class="datepicker-white" [options]="myDatePickerOptions"></mdb-date-picker>
    </div>
    <div class="mb-4" *ngIf="form.get('userType').value === 5">
      <cashaa-form-input name="googleDriveLink" type="text" [form]="form" [control]="form.controls.googleDriveLink"
        placeholder="Enter the Google Drive Link" label="Google Drive Link">
      </cashaa-form-input>
    </div>
    <div class="mb-4" *ngIf="form.get('userType').value === 5 && isFormEdit">
      <cashaa-form-input name="identityProof" type="text" [form]="form" [control]="form.controls.identityProof"
        placeholder="Enter the Address Proof Link" label="Address Proof Link">
      </cashaa-form-input>
    </div>
    <div class="mb-4" *ngIf="form.get('userType').value === 5 && isFormEdit">
      <cashaa-form-input name="addressProof" type="text" [form]="form" [control]="form.controls.addressProof"
        placeholder="Enter the Address Proof Link" label="Address Proof Link">
      </cashaa-form-input>
    </div>
    <div class="mb-4" *ngIf="form.get('userType').value === 4 && isFormEdit">
      <label>Document
        Type</label>
      <cashaa-form-select [form]="form" [control]="form.controls.identityDocumentType" name="identityDocumentType"
        placeholder="Select document type" [options]="identityProofOptions"></cashaa-form-select>
    </div>
    <div class="mb-4" *ngIf="form.get('userType').value === 4 && isFormEdit">
      <label>Identity Document</label>
      <cashaa-form-input name="identityProof" id="identityProof" isFile="true" type="file" [form]="form"
        [control]="form.controls.identityProof" (change)="onFileChange($event,'identityProof')"></cashaa-form-input>
    </div>
    <div class="mb-4" *ngIf="form.get('userType').value === 4 && isFormEdit">
      <label>Document
        Type</label>
      <cashaa-form-select [form]="form" [control]="form.controls.addressDocumentType" name="addressDocumentType"
        placeholder="Select document type" [options]="addressProofOptions"></cashaa-form-select>
    </div>
    <div class="mb-4" *ngIf="form.get('userType').value === 4 && isFormEdit">
      <label>Address Proof Document</label>
      <cashaa-form-input name="addressProof" id="addressProof" isFile="true" type="file" [form]="form"
        [control]="form.controls.addressProof" (change)="onFileChange($event,'addressProof')"></cashaa-form-input>
    </div>
    <div class="mb-4" *ngIf="isFormEdit">
      <label>Upload Selfie</label>
      <cashaa-form-input name="selfie" id="selfie" isFile="true" type="file" [form]="form"
        [control]="form.controls.selfie" (change)="onFileChange($event,'selfie')"></cashaa-form-input>
    </div>

    <div class="mt-4 btn-center">
      <button mdbBtn block="true" type="button" (click)="onSubmit(form.value)"
        class="rounded-sm position-relative btn btn-primary btn-block sm-font" mdbWavesEffect [disabled]="form.invalid">
        Add Account
      </button>
    </div>
  </form>

  <!-- Progress bar -->
  <mdb-progress-bar *ngIf="isInProgress" class="progress primary-color-dark position-absolute" mode="indeterminate">
  </mdb-progress-bar>
</div>