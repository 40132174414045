import { Component, OnInit, ViewChild } from '@angular/core';
import { MdbStepperComponent } from 'ng-uikit-pro-standard';
import { ICreateDepositPlan } from 'src/app/core/models/deposit-interest/deposit-interest';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';

@Component({
  selector: 'cashaa-deposit-interest-home',
  templateUrl: './deposit-interest-home.component.html',
  styleUrls: ['./deposit-interest-home.component.scss']
})
export class DepositInterestHomeComponent implements OnInit {
  @ViewChild("stepper", { static: true }) stepper: MdbStepperComponent;
  constructor(private navigationService: NavigationService) { }
  createPlan: ICreateDepositPlan;
  ngOnInit(): void {
  }

  onAmount(event: ICreateDepositPlan): void {
    this.createPlan = event;
    this.stepper.next();
  }

  backRoute(): void {
    this.navigationService.back();
  }

}
