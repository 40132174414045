<!-- <cashaa-sidenav>
    <section class="mh-100">
        <div class="bg-white p-4">
            <div class="sm-recipient-btn-padding-mobile">

            </div>
        </div>
    </section>
</cashaa-sidenav> -->
<cashaa-sidenav>
  <section class="mh-100">
    <div class="bg-white p-4">
      <div class="d-flex sm-phone-display-none mr-3" style="height: 47px;">
        <h6 class="heading my-auto">Hold CAS to boost your rewards.</h6>
      </div>
    </div>
  </section>
  <!-- <section class="p-4 sm-width-phone-700 rt-sec" *ngIf="!loading"> -->
  <section class="p-4 sm-width-phone-700" *ngIf="!loading">
    <div class="row">
      <div class="col-md-12">
        <p class="font-weight-normal">
          Besides crypto, Cashaa users can also earn a fixed interest rate on their fiat deposits, including in USD and
          EUR balances. As a result, you will be able to earn market-beating interest rates on both crypto and fiat
          deposits - helping you put your idle
          funds to work.
        </p>
      </div>
    </div>
    <!---- Pricing Panel start ---->
    <div class="container-fluid">
      <div class="row mb-4">
        <div class="col-3">
          <div class="row pt-4">
            <div class="text-initial font-weight-400">
              <h4 class="heading1 text-primary font-weight-bold">
                {{userCurrentPlan.currentPortfolioPercent}}%
              </h4>
            </div>
            <div class="pt-1 text-initial font-weight-normal">
              <p style="max-width: 150px;" class="pt-2 font-weight-normal">of your portfolio is in CAS tokens</p>
              Your Loyalty Level is based on the ratio of CAS tokens to the other assets in your account
            </div>
          </div>
        </div>
        <div class="col" [ngClass]="{'sub-hover': isBase}">
          <div class="row m-auto pt-4">
            <div class="pricingTable-header m-auto">
              <img class="plan-icon" src="../../../assets/images/plan/Base.svg" alt="">
              <span class="text-primary-dark font-weight-bold">Base</span>
            </div>
          </div>
          <h5 class="text-center plan-name-desc my-2 font-14-reg">{{basePlans[0]?.planNameDescription}}</h5>
          <div class="row mt-3">
            <button *ngIf="isBase" mdbBtn type="button" class="btn-sm app-primary-btn m-auto">
              Active <i class="fa fa-check-circle"></i>
            </button>
            <button *ngIf="!isBase" mdbBtn type="button" class="btn-sm app-secondary-btn m-auto">
              Unlocked <i class="fa fa-check-circle"></i>
            </button>
          </div>
        </div>
        <div class="col" [ngClass]="{'sub-hover': isSilver}">
          <div class="row m-auto pt-4">
            <div class="pricingTable-header m-auto">
              <img class="plan-icon" src="../../../assets/images/plan/Silver.svg" alt="">
              <span class="text-primary-dark font-weight-bold">Silver</span>
            </div>
          </div>
          <h5 class="text-center plan-name-desc my-2 font-14-reg font-14-reg">{{silverPlans[0]?.planNameDescription}}
          </h5>
          <div class="row mt-3">
            <button *ngIf="isSilver" mdbBtn type="button" class="btn-sm app-primary-btn m-auto ">
              Active <i class="fa fa-check-circle"></i>
            </button>
            <button *ngIf="!isSilver && !isBase" mdbBtn type="button" class="btn-sm app-secondary-btn m-auto">
              Unlocked <i class="fa fa-check-circle"></i>
            </button>
            <button *ngIf="isBase && userCurrentPlan.amountForAllPlan[0] > 0" mdbBtn type="button" class="btn-sm app-secondary-btn m-auto" (click)="goToAccount()">
              Upgrade
              <i class="fa fa-star" aria-hidden="true"></i>
            </button>
            <span *ngIf="isBase && userCurrentPlan.amountForAllPlan[0] > 0; else deposit"
              class="m-auto p-3 text-center">
              Add <b class="font-weight-bold">{{userCurrentPlan.amountForAllPlan[0] | number}} </b>CAS to
              upgrade
            </span>
            <ng-template #deposit>
              <span *ngIf="isBase && userCurrentPlan.amountForAllPlan[0] == 0" class="m-auto p-3 text-center">
                <a class="link-color" (click)="goToCrypto()">Deposit</a> 
              </span>
              <span *ngIf="isBase && userCurrentPlan.amountForAllPlan[0] > 0" class="m-auto p-3 text-center">
                <a class="link-color" (click)="goToCrypto()">Deposit</a> to
                upgrade
              </span>
            </ng-template>

            <!-- <p class="m-auto mt-2" *ngIf="isSilver">Active</p> -->
            <!-- <a *ngIf="isBase" mdbBtn type="button" class=" rounded-pill text-primary-dark bg-white m-auto"
                size="md" mdbWavesEffect (click)="goToAccount()">
                <span>Add {{userCurrentPlan.amountForAllPlan[0] | number}} </span>CAS <i
                    class="fas fa-angle-right text-primary"></i>

            </a> -->
          </div>
        </div>
        <div class="col" [ngClass]="{'sub-hover': isGold}">
          <div class="row m-auto pt-4">
            <div class="pricingTable-header m-auto">
              <img class="plan-icon" src="../../../assets/images/plan/Gold.svg" alt="">
              <span class="text-primary-dark font-weight-bold">Gold</span>
            </div>
          </div>
          <h5 class="text-center plan-name-desc my-2 font-14-reg">{{goldPlans[0]?.planNameDescription}}</h5>
          <div class="row mt-3">
            <button *ngIf="isGold" mdbBtn type="button" class="btn-sm app-primary-btn m-auto">
              Active <i class="fa fa-check-circle"></i>
            </button>

            <button *ngIf="!isBase && !isSilver && !isGold" mdbBtn type="button"
              class="btn-sm app-secondary-btn m-auto">
              Unlocked <i class="fa fa-check-circle"></i>
            </button>

            <button *ngIf="(isSilver || isBase) && (userCurrentPlan.amountForAllPlan[1] > 0 || userCurrentPlan.amountForAllPlan[0]>0)" mdbBtn type="button" class="btn-sm app-secondary-btn m-auto"
              (click)="goToAccount()">
              Upgrade
              <i class="fa fa-star" aria-hidden="true"></i>
            </button>
            <span *ngIf="(isSilver || isBase) && (userCurrentPlan.amountForAllPlan[1] > 0 || userCurrentPlan.amountForAllPlan[0]>0) ; else deposit"
              class="m-auto p-3 text-center">
              Add
              <b class="font-weight-bold">
                {{
                (isBase ? userCurrentPlan.amountForAllPlan[1] : userCurrentPlan.amountForAllPlan[0]) |number
                }}
              </b> CAS to
              upgrade
            </span>
            <ng-template #deposit>
              <span *ngIf="(isSilver || isBase) && (userCurrentPlan.amountForAllPlan[1] == 0 || userCurrentPlan.amountForAllPlan[0] == 0)" class="m-auto p-3 text-center">
                <a class="link-color" (click)="goToCrypto()">Deposit</a>
              </span>
              <span *ngIf="(isSilver || isBase) && (userCurrentPlan.amountForAllPlan[1] > 0 || userCurrentPlan.amountForAllPlan[0] > 0)" class="m-auto p-3 text-center">
                <a class="link-color" (click)="goToCrypto()">Deposit</a> to
                upgrade
              </span>
            </ng-template>
          </div>
          <!-- </div> -->
        </div>
        <div class="col" [ngClass]="{'sub-hover': isDiamond}">
          <div class="row m-auto pt-4">
            <div class="pricingTable-header m-auto">
              <img class="plan-icon" src="../../../assets/images/plan/Diamond.svg" alt="">
              <span class="text-primary-dark font-weight-bold">Diamond</span>
            </div>
          </div>
          <h5 class="text-center plan-name-desc my-2 font-14-reg">{{daimondPlans[0]?.planNameDescription}}</h5>
          <div class="row mt-3">
            <button *ngIf="isDiamond" mdbBtn type="button" class="btn-sm app-primary-btn m-auto ">
              Active <i class="fa fa-check-circle"></i>
            </button>

            <button *ngIf="!isDiamond && !isBase && isSilver && isGold" mdbBtn type="button"
              class="btn-sm app-secondary-btn m-auto">
              Unlocked <i class="fa fa-check-circle"></i>
            </button>

            <button *ngIf="(isGold || isSilver || isBase) && (userCurrentPlan.amountForAllPlan[2] > 0 || userCurrentPlan.amountForAllPlan[1] > 0 || userCurrentPlan.amountForAllPlan[0] > 0)" mdbBtn type="button" class="btn-sm app-secondary-btn m-auto"
              (click)="goToAccount()">
              Upgrade
              <i class="fa fa-star" aria-hidden="true"></i>
            </button>
            <span *ngIf="(isGold || isSilver || isBase) && (userCurrentPlan.amountForAllPlan[2] > 0 || userCurrentPlan.amountForAllPlan[1] > 0 || userCurrentPlan.amountForAllPlan[0] > 0)   ; else deposit"
              class="m-auto p-3 text-center">
              Add
              <b class="font-weight-bold">
                {{
                (isBase ? userCurrentPlan.amountForAllPlan[2] : isSilver ?
                userCurrentPlan.amountForAllPlan[1]:userCurrentPlan.amountForAllPlan[0]) |number
                }}
              </b> CAS to
              upgrade
            </span>
            <ng-template #deposit>
              <span *ngIf="(isGold || isSilver || isBase) && (userCurrentPlan.amountForAllPlan[2] == 0 || userCurrentPlan.amountForAllPlan[1] == 0 || userCurrentPlan.amountForAllPlan[0] == 0)" class="m-auto p-3 text-center">
                <a class="link-color" (click)="goToCrypto()">Deposit</a>
              </span>
              <span *ngIf="(isGold || isSilver || isBase) && (userCurrentPlan.amountForAllPlan[2] > 0 || userCurrentPlan.amountForAllPlan[1] > 0 || userCurrentPlan.amountForAllPlan[0] > 0)" class="m-auto p-3 text-center">
                <a class="link-color" (click)="goToCrypto()">Deposit</a> to
                upgrade
              </span>
            </ng-template>
          </div>
          <!-- <p class="font-weight-bold m-auto mt-2" *ngIf="isDiamond">Active</p>
              <a *ngIf="isGold || isSilver || isBase" mdbBtn type="button"
                  class="btn rounded-pill text-primary-dark bg-white m-auto" size="md" mdbWavesEffect
                  (click)="goToAccount()">
                  <span>Add {{(isBase ?
                      userCurrentPlan.amountForAllPlan[2] : isSilver ? userCurrentPlan.amountForAllPlan[1]:
                      userCurrentPlan.amountForAllPlan[0]) |
                      number}}
                  </span>CAS <i class="fas fa-angle-right text-primary"></i>
              </a> -->
        </div>
      </div>
    </div>

    <div class="container-fluid earn-benefits mt-4 mb-4">
      <div class="row">
        <img class="heading-img" src="../../../assets/images/plan/earn-benefit.svg" alt="">
        <span>
          <div class="pt-2 heading1">Earn Benefits </div>
          <div class="pb-2 heading2 font-14-reg">Interest Rate</div>
        </span>
      </div>
      <ng-container *ngIf="userType ==='4'">
        <div class="row bg-white p-3">
          <div class="col-3 app-txt-green font-16-bold">
            Flex Interest Rate
          </div>
        </div>
        <div class="row bg-white p-3">
          <div class="col-3 font-16-med">
            Cryptocurrencies
          </div>
          <div class="col text-center font-16-med" *ngFor="let max of cryptoCurrencyWithMaxRate">
            Up to <b>{{max.kindRate}}%</b>
          </div>
        </div>
        <div class="row align-items-center bg-white border-top p-3">
          <div class="col-3 font-16-med">
            Fiat Currencies
          </div>
          <div class="col text-center font-16-med" *ngFor="let max of fiatCurrencyWithMaxRate">
            Up to <b>{{max.kindRate}}%</b>
          </div>
        </div>
        <div class="row align-items-center bg-white border-top p-3">
          <div class="col-3 font-16-med">
            Stablecoins
          </div>
          <div class="col text-center font-16-med" *ngFor="let max of stableCurrencyWithMaxRate">
            Up to <b>{{max.kindRate}}%</b>
          </div>
        </div>
        <div class="row align-items-center bg-white border-top border-bottom p-3">
          <div class="col-3 font-16-med">
            Earn in CAS Bonus
          </div>
          <div class="col text-center app-txt-green font-16-med">
            <img class="casha_icon" src="../../../assets/images/cashaa-logo.png"> +{{planDetails[0].casEarnBonus}}%
          </div>
          <div class="col text-center app-txt-green font-16-med">
            <img class="casha_icon" src="../../../assets/images/cashaa-logo.png"> +{{planDetails[1].casEarnBonus}}%
          </div>
          <div class="col text-center app-txt-green font-16-med">
            <img class="casha_icon" src="../../../assets/images/cashaa-logo.png"> +{{planDetails[2].casEarnBonus}}%
          </div>
          <div class="col text-center app-txt-green font-16-med">
            <img class="casha_icon" src="../../../assets/images/cashaa-logo.png"> +{{planDetails[3].casEarnBonus}}%
          </div>
        </div>
      </ng-container>

      <div class="row bg-white p-3">
        <div class="col-3 app-txt-green mt-4 font-16-bold">
          Fixed Deposit Interest Rate
        </div>
      </div>
      <div class="row bg-white p-3">
        <div class="col-3 font-16-med">
          Cryptocurrencies
        </div>
        <div class="col text-center font-16-med ">
          Up to <b>{{cryptoFixedDepoistRate[0]}}%</b>
        </div>
        <div class="col text-center font-16-med ">
          Up to <b>{{cryptoFixedDepoistRate[1]}}%</b>
        </div>
        <div class="col text-center font-16-med ">
          Up to <b>{{cryptoFixedDepoistRate[2]}}%</b>
        </div>
        <div class="col text-center font-16-med ">
          Up to <b>{{cryptoFixedDepoistRate[3]}}%</b>
        </div>
      </div>
      <div class="row align-items-center bg-white border-top p-3">
        <div class="col-3 font-16-med ">
          Fiat Currencies
        </div>
        <div class="col text-center font-16-med ">
          Up to <b>{{fiatFixedDepoistRate[0]}}%</b>
        </div>
        <div class="col text-center font-16-med ">
          Up to <b>{{fiatFixedDepoistRate[1]}}%</b>
        </div>
        <div class="col text-center font-16-med ">
          Up to <b>{{fiatFixedDepoistRate[2]}}%</b>
        </div>
        <div class="col text-center font-16-med ">
          Up to <b>{{fiatFixedDepoistRate[3]}}%</b>
        </div>
      </div>
      <div class="row align-items-center bg-white border-top p-3">
        <div class="col-3 font-16-med ">
          Stablecoins
        </div>
        <div class="col text-center font-16-med ">
          Up to <b>{{stableFixedDepoistRate[0]}}%</b>
        </div>
        <div class="col text-center font-16-med ">
          Up to <b>{{stableFixedDepoistRate[1]}}%</b>
        </div>
        <div class="col text-center font-16-med ">
          Up to <b>{{stableFixedDepoistRate[2]}}%</b>
        </div>
        <div class="col text-center font-16-med ">
          Up to <b>{{stableFixedDepoistRate[3]}}%</b>
        </div>
      </div>
      <div class="row align-items-center bg-white border-top p-3">
        <div class="col-3 font-16-med ">
          Earn in CAS Bonus
        </div>
        <div class="col text-center app-txt-green">
          <img class="casha_icon" src="../../../assets/images/cashaa-logo.png"> +{{planDetails[0].casEarnBonus}}%
        </div>
        <div class="col text-center app-txt-green">
          <img class="casha_icon" src="../../../assets/images/cashaa-logo.png"> +{{planDetails[1].casEarnBonus}}%
        </div>
        <div class="col text-center app-txt-green">
          <img class="casha_icon" src="../../../assets/images/cashaa-logo.png"> +{{planDetails[2].casEarnBonus}}%
        </div>
        <div class="col text-center app-txt-green">
          <img class="casha_icon" src="../../../assets/images/cashaa-logo.png"> +{{planDetails[3].casEarnBonus}}%
        </div>
      </div>
      <!-- <div class="row align-items-center bg-white border-top p-3">
                <div class="col text-center" *ngFor="let earnBenefit of earnBenefits">
                </div>
            </div> -->
    </div>
    <!-- <div class="col text-center font-16-med ">
      Up to <b>{{maxStableCoinsRate}}%</b>
    </div>
    <div class="col text-center font-16-med ">
      Up to <b>{{maxStableCoinsRate}}%</b>
    </div>
    <div class="col text-center font-16-med ">
      Up to <b>{{maxStableCoinsRate}}%</b>
    </div>
    <div class="col text-center font-16-med ">
      Up to <b>{{maxStableCoinsRate}}%</b>
    </div> -->
    




    <div class="mt-2 mb-2">*Rates for certain assets may vary.</div>

    <div class="container-fluid additional-benefit mt-4 mb-4">
      <div class="row">
        <img class="heading-img" src="../../../assets/images/plan/additional-benefit.svg" alt="">
        <span>
          <div class="pt-2 heading1">Additional Benefits </div>
          <div class="pb-2 heading2 font-14-reg">Get the most out of CAS</div>
        </span>
      </div>
      <div class="row bg-white p-3">
        <div class="col-3 font-16-med">
          FD Premature Withdrawals
        </div>
        <ng-container *ngFor="let pd of planDetails">
          <div *ngIf="pd.isFDPrematureWithdraw" class="col text-center font-16-med">
            Yes
          </div>
          <div *ngIf="!pd.isFDPrematureWithdraw" class="col text-center font-16-med">
            No
          </div>
        </ng-container>
      </div>
      <div class="row bg-white p-3">
        <div class="col-3 font-16-med">
          FD Cancellation Fee Discount
        </div>
        <div class="col text-center font-16-med" *ngFor="let pd of planDetails">
          {{pd.fdCancelFeeDiscount}}%
        </div>
      </div>
      <div class="row bg-white p-3">
        <div class="col-3 font-16-med">
          Exchange Fee Discount
        </div>
        <div class="col text-center font-16-med" *ngFor="let pd of planDetails">
          {{pd.exchangeFeeDiscount}}%
        </div>
      </div>
      <div class="row bg-white p-3">
        <div class="col-3 font-16-med">
          Free Withdrawals Per Month
        </div>
        <div class="col text-center font-16-med" *ngFor="let pd of planDetails">
          {{pd.monthlyFreeCryptoWithdraw}}
        </div>
      </div>
      <div class="row align-items-center bg-white border-top p-3">
        <div class="col-3 font-16-med">
          Governance
        </div>
        <div class="col text-center" *ngFor="let addBenefit of additionalBenefits">
          <i class="app-txt-green fa fa-check-circle"></i>
        </div>
      </div>
      <!-- <div class="row align-items-center bg-white border-top p-3">
    <div class="col text-center" *ngFor="let earnBenefit of earnBenefits">
    </div>
</div> -->
    </div>
    <div class="container-fluid borrow-benefit mt-4 mb-3">
      <div class="row">
        <img class="heading-img" src="../../../assets/images/plan/borrow-benefit.svg" alt="">
        <span>
          <div class="pt-2 heading1">Borrow Benefits </div>
          <div class="pb-2 heading2 font-14-reg">Interest Rate</div>
        </span>
        <!-- <p class="m-auto">Coming Soon!</p> -->
      </div>

      <div class="row bg-white p-3">
        <div class="col-3 font-16-med">
          Regular Cash Loan
          <!-- Coming Soon! -->
        </div>
        <div class="col text-center font-16-med" *ngFor="let plan of planDetails">
          {{plan.borrowInterest}}%
        </div>
      </div>
      <div class="row align-items-center bg-white border-top p-3">
        <div class="col col-3 font-16-med">
          Consumer Loan Limit (0% interest)
        </div>
        <div class="col text-center font-16-med" *ngFor="let plan of planDetails">
          <div *ngIf="plan.name==='Base' && plan.borrowZeroInterest == 0; else asItIs">NA</div>
          <ng-template #asItIs>{{plan.borrowZeroInterest}}%</ng-template>
        </div>
      </div>

    </div>

    <div class="container-fluid mb-3 bg-white border">
      <div class="row">
        <div class="col-3 bg-lt-grey">
          <div class="p-2">
            <h4 class="heading1 text-primary font-weight-bold">
              {{userCurrentPlan.currentPortfolioPercent}}%
            </h4>
            <div class="font-weight-500 portfolio-cas-token font-16-med">
              of your portfolio is in <span>
                <img class="w-50" src="../../../assets/images/plan/{{userCurrentPlan.name}}.svg" alt="">
              </span>
            </div>
            <div class="pb-2 font-weight-500 font-16-med">CAS tokens</div>
          </div>

        </div>

        <!-- <div class="col m-auto text-center" *ngFor="let plan of allPlans"> -->
        <div class="col m-auto text-center">
          <!-- <a *ngIf="isBase"> Current <i class="app-txt-green fa fa-check-circle"></i></a> -->
          <button *ngIf="isBase" mdbBtn type="button" class="btn-sm app-primary-btn">
            Active <i class="fa fa-check-circle"></i>
          </button>
          <button *ngIf="!isBase" mdbBtn type="button" class="btn-sm app-secondary-btn m-auto">
            Unlocked <i class="fa fa-check-circle"></i>
          </button>
        </div>
        <div class="col m-auto text-center">
          <button *ngIf="isSilver" mdbBtn type="button" class="btn-sm app-primary-btn">
            Active <i class="fa fa-check-circle"></i>
          </button>
          <button *ngIf="isBase" mdbBtn type="button" class="btn-sm app-secondary-btn" (click)="goToAccount()">
            Upgrade
            <i class="fa fa-star" aria-hidden="true"></i>
          </button>
          <button *ngIf="!isSilver && !isBase" mdbBtn type="button" class="btn-sm app-secondary-btn m-auto">
            Unlocked <i class="fa fa-check-circle"></i>
          </button>
        </div>
        <div class="col m-auto text-center">
          <button *ngIf="isGold" mdbBtn type="button" class="btn-sm app-primary-btn">
            Active <i class="fa fa-check-circle"></i>
          </button>
          <button *ngIf="isSilver || isBase" mdbBtn type="button" class="btn-sm app-secondary-btn"
            (click)="goToAccount()">
            Upgrade
            <i class="fa fa-star" aria-hidden="true"></i>
          </button>
          <button *ngIf="!isGold && !isBase && !isSilver " class="btn-sm app-secondary-btn m-auto">
            Unlocked <i class="fa fa-check-circle"></i>
          </button>
        </div>
        <div class="col m-auto text-center">
          <button *ngIf="isDiamond" mdbBtn type="button" class="btn-sm app-primary-btn">
            Active <i class="fa fa-check-circle"></i>
          </button>
          <button *ngIf="!isDiamond && !isBase && isSilver && isGold " mdbBtn type="button"
            class="btn-sm app-secondary-btn m-auto">
            Unlocked <i class="fa fa-check-circle"></i>
          </button>
          <button *ngIf="isGold || isSilver || isBase" mdbBtn type="button" class="btn-sm app-secondary-btn"
            (click)="goToAccount()">
            Upgrade
            <i class="fa fa-star" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="container-fluid mt-4rem">
      <div class=" row p-2">
        <div class="col-6">
          <h6 class="pt-2 heading"> Upgrade your Loyalty Level </h6>
          <div class="pt-2 font-weight-normal">
            Hold more, earn more. Get the best rates on our earn products by holding more CAS in your
            portfolio. Pay less in transfer and exchange fees by paying in CAS.
          </div>
          <button mdbBtn type="button" class="mt-3 btn-sm app-primary-btn" (click)="goToAccount()">Buy CAS Token</button>
        </div>
        <div class="col-6">
          <img class="wallet-img w-50" src="../../../assets/images/plan/bit-wallet.png" alt="">
        </div>

      </div>
    </div>
  </section>
  <!-- </section> -->

  <div class="p-4 text-center" [hidden]="!loading">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
  </div>

</cashaa-sidenav>