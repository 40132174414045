<div class="text-center sm-width-phone-700 sm-width-phone-400-height" [hidden]="!showLoadingIndicator">
    <mdb-spinner spinnerColor="blue" class="sm-loader-center"></mdb-spinner>
</div>
<div [hidden]="showLoadingIndicator">
    <section
        class="p-5 wrapper w-100 d-flex justify-content-center flex-column position-relative mx-auto sm-width-phone-700 sm-width-phone-400-height">
        <img class="position-absolute logo" src="../../../assets/media/logo-dark.svg" />

        <!-- <h2 class="mb-4 text-center font-weight-bolder">Status</h2> -->
        
        <div style="text-align: center" *ngIf="!status">
            <mdb-icon fas icon="times-circle" class="text-center red-text mb-4" size="10x"></mdb-icon>
            
            <h3 class="font-weight-bold">Please try again!<br></h3>
        </div>
        <div style="text-align: center" *ngIf="status">
            <mdb-icon far icon="check-circle" class="text-center green-text mb-4" size="10x">
            </mdb-icon>
            <h3 class="font-weight-bold">Email successfully changed!<br></h3>
            <h5 class="font-weight-normal">Your new email is now active</h5>
        </div>
        <div class="p-5" class="center-btn mt-4">
            <button mdbBtn block="true" type="button"
                class="sm-width-ph-confirmation w-25 btn-sm btn-primary btn btn-height" mdbWavesEffect
                (click)="onContinue()">
                Cashaa Login Page <i class="fas fa-arrow-circle-right pl-2" style="font-size: 1rem;"></i>
            </button>
        </div>
    </section>
</div>