export enum BrokerStatus {
        /// <summary>
        /// All
        /// </summary>
        ALL = 0,
        /// <summary>
        /// The approved - verified customer
        /// </summary>
        APPROVED = 1,
        /// <summary>
        /// The pending - not verified customer
        /// </summary>
        PENDING = 2,
        // Rejected
        REJECTED = 3,
        // Partially Rejected
        PARTIALAPPROVED = 4,
}
