import { IDepositAmount, IDepositDetail } from "./../../core/models/deposit";
import { DepositService } from "./../services/deposit.service";
import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { AccountUpgradeService } from "src/app/account-upgrade/services/account-upgrade.service";
import { KycService } from "src/app/kyc/services/kyc.service";
import { USER_TYPE } from "src/app/app.constants";

@Component({
  selector: "cashaa-deposit-detail",
  templateUrl: "./deposit-detail.component.html",
  styleUrls: ["./deposit-detail.component.scss"],
})
export class DepositDetailComponent implements OnInit {
  @Output() continue = new EventEmitter<void>();

  @Output() back = new EventEmitter<void>();

  @Output() resetAll = new EventEmitter<void>();

  depositData: IDepositAmount;

  bankDetail: IDepositDetail;

  loader = false;

  isKycDone = false;

  userType = sessionStorage.getItem(USER_TYPE);

  constructor(private depositService: DepositService,
    private kycService: KycService,
    private upgardeService: AccountUpgradeService) {}

  ngOnInit(): void {
    this.depositService.getDepositAmountEmitter().subscribe((data) => {
      if (data && data.currency) {
        this.depositData = data;
        this.getBankDetail();
      }
    });
    if (this.userType === '4') {
      this.getKycDetail();
  } else {
    this.getBusinessKycDetail();
  }
  }

  getBankDetail(): void {
    // this.loader = true;
    this.depositService
      .getBankDetail(this.depositData.currency, this.depositData.recipientType)
      .subscribe((res) => {
        this.bankDetail = res;
        this.depositService.emitDepositDetails(this.bankDetail);
        this.loader = false;
      });
  }

  onContinue(): void {
    this.continue.emit();
  }

  onBack(): void {
    this.back.emit();
  }

  onReset(): void {
    this.resetAll.emit();
  }

  getKycDetail(): void {
    this.loader = true;
    this.kycService.getKycDetails().subscribe((res) => {
      if (res) {
          this.loader = false;
          if (res.verified) {
              this.isKycDone = true;
          } 
        }
      },(err) => {
        this.loader = false;
      });
  }

  getBusinessKycDetail(): void {
    this.upgardeService.usdStatus().subscribe(
      (flag) => {
        if (flag.status === 5) {
          this.isKycDone = true;
        } 
        this.loader = false;
      },
      (err) => {
        this.loader = false;
      }
    );
  }
}
