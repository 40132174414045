import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cashaa-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {

  
completed = false;
constructor(
 
  private router: Router,
  private activatedRoute: ActivatedRoute
) {
 
}

ngOnInit(): void {
 this.completed = true;
}

}
