<form [formGroup]="createWithdrawFund" class="my-4">

    <div class="flex-1">
        <cashaa-form-select-balance [type]="'crypto'" (changed)="OnCurrencySelect($event)">
        </cashaa-form-select-balance>
    </div>
    <br>

    <div class="mb-5">


        <cashaa-form-select-withdraw-address name="address" type="address" [form]="createWithdrawFund"
            [control]="createWithdrawFund.controls.address">
        </cashaa-form-select-withdraw-address>

        <!-- <cashaa-form-input name="address" type="address" [form]="createWithdrawFund"
            [control]="createWithdrawFund.controls.address" placeholder="Enter Address"></cashaa-form-input> -->

    </div>

    <div class="mb-5">
        <cashaa-form-input name="memo" type="memo" [form]="createWithdrawFund"
            [control]="createWithdrawFund.controls.memo" placeholder="Memo (optional)" *ngIf="show"></cashaa-form-input>
    </div>


    <div class="mb-5">
        <cashaa-form-input name="amountToTransfer" type="amountToTransfer" [form]="createWithdrawFund"
            [control]="createWithdrawFund.controls.amountToTransfer" placeholder="Amount to transfer">
        </cashaa-form-input>
        <!-- <a class="form-control" (click)=" checkBalance()" class="nav-link waves-light" mdbWavesEffect>Transfer max</a> -->



    </div>

    <div class="mb-5">
        <table>
            <!-- <tr aria-colspan="2">
                <td><a (click)="checkBalance()" class="nav-link waves-light" mdbWavesEffect>Transfer max</a></td>
            </tr> -->
            <tr>
                <td><label>Fee: </label></td>
                <td>value</td>
            </tr>
            <!-- <tr>
                <td><label>You will receive:: </label></td>
                <td></td>
                <td>value</td>
            </tr> -->
        </table>

    </div>





    <button mdbBtn type="button" (click)="onFormSubmit()" block="true"
        class="rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block" mdbWavesEffect>
        <i class="fa-lock fas position-absolute"></i>Continue

    </button>

</form>