<section class="activity-box px-3">
    <div class="details">
        <mdb-accordion [multiple]="false" aria-multiselectable="false">
            <mdb-accordion-item class="no-collase ledger-list-item">
                <mdb-accordion-item-head class="px-2 py-1" mdbWavesEffect>
                    <div class="d-flex flex-row px-2 align-items-center">
                        <div class="px-2 circle">
                            <img src="../../../assets/images/Collateral Locked.png" style="width: 25px;" />
                        </div>
                        <div class="status ml-2 transaction-detail">
                            <span class="text-primary-dark">{{ getCurrencyName(ledger?.currency) }}
                                <b class="text-primary">{{ ledger.transactionData }}</b></span>
                        </div>
                        <div class="amount ml-auto mr-4">
                            <b>{{ ledger.amount |number: "0.0-4" }}
                                {{ getCurrencyName(ledger?.currency) }}</b><br />
                        </div>
                    </div>
                </mdb-accordion-item-head>
                <mdb-accordion-item-body class="px-4 position-relative">
                    <div class="container px-4 pt-2">
                        <div class="row">
                            <div class="col-sm-6">
                                Collateral Currency <br />
                                <img src="../../../../assets/icons/{{ledger?.currency}}.png" alt="currency"
                                    class="icon">
                                <b class="text-custom-black ml-2 font-weight-bold">{{ledger?.currency}}</b>
                            </div>
                            <div class="col-sm-3">
                                Collateral Amount<br />
                                <b class="text-custom-black font-weight-bold">{{ ledger.amount
                                    }}&nbsp;{{ ledger?.feeCurrency}}</b>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-sm">
                                Date <br />
                                <b class="text-custom-black font-weight-bold">{{ ledger.created| date:'longDate'}}</b>
                            </div>
                            <div class="col-sm">
                                Loan Number <br />
                                <b class="text-custom-black font-weight-bold">{{ ledger.loanNumber}}</b>
                            </div>
                        </div>
                    </div>
                </mdb-accordion-item-body>
            </mdb-accordion-item>
        </mdb-accordion>
    </div>
</section>