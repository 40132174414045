<section class="activity-box px-3 mb-2">
    <div class="details">
        <mdb-accordion [multiple]="false" class="md-accordion">
            <mdb-accordion-item>
                <mdb-accordion-item-head mdbWavesEffect>
                    <div class="d-flex flex-row px-4 py-2 align-items-center">
                        <div class="ml-2 p-2 circle">
                            <img style="height: 25px;" src="../../../assets/images/Fees.png" />
                        </div>
                        <div class="status ml-4">
                            To
                            <span class="text-primary-dark font-weight-bold">USD Account Staking</span>
                            <br />
                            <span *ngIf="(!ledger.withdrawConfirm && ledger.status=== 1)" class="text-gray">
                Pending</span>
                            <span *ngIf="ledger.withdrawConfirm && ledger.status === 1" class="text-gray"> Processing</span>
                            <span *ngIf="this.ledger.status === 2 && this.ledger.withdrawConfirm" class="text-gray">
                Sent</span>
                        </div>
                        <div class="amount ml-auto mr-4">
                            <b>{{ ledger?.convertFromamount |number: "0.0-4" }}
                {{ ledger?.currency }} </b><br />
                        </div>
                    </div>
                </mdb-accordion-item-head>
                <mdb-accordion-item-body class="px-4 position-relative sm-custom-stepper">
                    <div class="px-5 ml-5 pt-2">
                        <div class="row">
                            <div class="col-sm">
                                <!-- Vertical Steppers -->
                                <div class="row mt-1">
                                    <div class="col-md-12">
                                        <!-- Stepers Wrapper -->
                                        <ul class="stepper stepper-vertical green-stepper">
                                            <!-- First Step -->
                                            <li class="success successNext">
                                                <a class="sm-default-cursor">
                                                    <span class="date">{{ ledger?.startDate | date: "MMM d, yyyy" }}</span>
                                                    <div class='sm-width-50'><span class="circle text-danger"></span></div>
                                                    <span class="label">You setup your staking</span>
                                                </a>
                                            </li>

                                            <li [ngClass]="{'success':isSuccessClass(2),'successNext':isSuccessClass(3) }">
                                                <a class="sm-default-cursor">
                                                    <span class="date">{{ ledger?.startDate | date: "MMM d, yyyy" }}</span>
                                                    <div class='sm-width-50'><span class="circle"></span>
                                                    </div>
                                                    <span class="label">You used your cashaa balance</span>
                                                </a>
                                            </li>
                                            <li [ngClass]="{'success':isSuccessClass(3),'successNext':isSuccessClass(4)}">
                                                <a class="sm-default-cursor">
                                                    <span class="date">{{ ledger?.startDate | date: "MMM d, yyyy" }}</span>
                                                    <div class="sm-width-50">
                                                        <span class="circle"></span>
                                                        <div *ngIf="ledger.islocked" class="dot">
                                                        </div>
                                                    </div>
                                                    <span class="label">Locked
                          </span>
                                                </a>
                                            </li>

                                            <!-- Second Step  -->
                                            <li [ngClass]="{'success':isSuccessClass(4) }">
                                                <!--Section Title -->
                                                <a class="sm-default-cursor">
                                                    <span class="date">{{ ledger?.endDate | date: "MMM d, yyyy"
                            }}</span>
                                                    <div class='sm-width-50'>
                                                        <span class="circle"></span>
                                                        <div *ngIf="ledger.withdrawConfirm && ledger.status===2" class="dot"></div>
                                                        <div *ngIf="!ledger.islocked" class="dot">
                                                        </div>
                                                    </div>
                                                    <span [ngClass]="{'text-success':ledger.withdrawConfirm && ledger.status===2}" class="label">Unlocked</span>
                                                </a>
                                            </li>
                                            <li class="conclusion ml-2">
                                                <span *ngIf="ledger.ledgerType === 11" class="label">We sent {{ ledger?.convertFromamount |
                          number: "0.0-4" }}
                          {{ ledger?.currency }}</span>
                                            </li>
                                        </ul>
                                        <!-- /.Stepers Wrapper -->
                                        <div class="ml-7 pl-5">
                                            <button type="button" class="btn btn-outline-dark rounded" (click)="help()">
                        Need any help?
                      </button>
                                        </div>
                                    </div>
                                    <div class="col-md-12"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mdb-accordion-item-body>
            </mdb-accordion-item>
        </mdb-accordion>
    </div>
</section>