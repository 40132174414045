<div class="sm-currency-select position-relative">
  <label *ngIf="label" class="heading">{{label}} <span *ngIf="isRequired" class="text-danger">*</span></label>
  <mdb-select-2 placeholder="Select Country" (selected)="onCountryChange($event)" outline="true" [formControl]="control"
    [dropdownClass]="'sm-currency-select'" class="sm-text-indent sm-currency-select">
    <i class="fas fa-search sm-search-icon" aria-hidden="true"></i>
    <mdb-select-filter [ngModel]="searchText | async" (ngModelChange)="searchText.next($event)" placeholder="Search...">
    </mdb-select-filter>
    <mdb-select-option *ngFor="let option of filteredOptions | async" [value]="option">{{ option.label }}
    </mdb-select-option>
  </mdb-select-2>
</div>