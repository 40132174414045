import { IBKYCStakeholderDocuments } from "./../../core/models/kyc-business-stakeholders-doc";
import { IStakeholders } from "./../../core/models/create-business-kyc";
import { KycService } from "./../services/kyc.service";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ValidatorUtility } from "../../core/utility/validator.utility";
import { IFormSelect } from "../../core/models/form-select";
import { FILE_DELETE_MESG, FILE_SUCCESS_MESG, UNABLE_TO_PERFORM_ERROR } from "src/app/app.constants";
import { ToastrService } from "ngx-toastr";
import { FileUpload, IAPIEnumLoad, IFile } from "src/app/core/models/Lerex/enum-load";
import { ActivatedRoute } from "@angular/router";
import { AccountDocumentType } from "src/app/core/enums/accountDocumentTypeEnum";
import { SubSink } from "subsink";
import { DomainService } from "src/app/core/services/domain/domain.service";
import { ModalService } from "src/app/core/services/modal/modal.service";
import { Subscription } from "rxjs";
import { CommonSavedData } from "src/app/shared/services/commonSavedData.service";

@Component({
  selector: "cashaa-kyc-upload-contact-doc",
  templateUrl: "./kyc-upload-contact-doc.component.html",
  styleUrls: ["./kyc-upload-contact-doc.component.scss"],
})
export class KycUploadContactDocComponent implements OnInit, OnDestroy {
  form: FormGroup;
  documentTypes?: IFile[];
  errorMessage = "";
  ContactSelector?: IFormSelect[];
  loader = false;
  fileActionLoader = false;
  shareexceeded: boolean;
  contactList: IStakeholders;
  @Input() set directorData(data) {
    if (data) {
        this.ContactSelector = this.getList(data);
    }
  }
  StakeholderDocuments: IBKYCStakeholderDocuments[];
  StakeholderId?: string;
  contactfile: File;
  totalDocUploaded = 0;
  isFileUploadValid = false;
  fileCount : number = 0;
  plan: string;
  /** on continue click, emit event */
  @Output() continue = new EventEmitter<any>();
  documentMap = new Map<string, IFile>();
  subSink = new SubSink();
  sub: Subscription;
  constructor(
    private fb: FormBuilder,
    private kycService: KycService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private domainService: DomainService,
    private modalService: ModalService,
    private commonSavedData: CommonSavedData
  ) {
    this.createForm();
    this.shareexceeded = false;
  }
  ngOnInit(): void {
    this.subSink.add(this.activatedRoute.queryParams.subscribe(params => {
      this.plan = params['plan'];
    }));
    this.getDocumentsType();
    this.getKYCDirectorDetails();
    this.subSink.add(this.domainService.getKycUploadFile().subscribe(data=> {
      if (data && data.docType === "Contact") {
        this.onFileChange(data);
      }
    }));
  }
  getKYCDirectorDetails(): void {
    this.kycService.getKYCDirectorDetails().subscribe((list) => {
      this.ContactSelector = this.getList(list);
    });
  }

  getList(list: IStakeholders[]): IFormSelect[] {
    if (list[0].id.length === 0) {
        this.getKYCDirectorDetails();
    } else {
      return list.map((d) => {
        return {
          value: d.id,
          label: d.firstName + " " + d.lastName + " ( " + d.email + " )",
        };
      });
    }
    
  }
  
  get directorKycuploadList(): FormArray {
    return this.form.get("directorKycuploadList") as FormArray;
  }

  createItem(): FormGroup {
    return this.fb.group({
      documentType: ["", [ValidatorUtility.Required]],
      directors: ["", [ValidatorUtility.Required]],
    });
  }
  /**
   * Add item in array
   */
  addItem(): void {
    if (this.directorKycuploadList.value.length > 9 || this.form.invalid) {
      return;
    }
    this.directorKycuploadList.push(this.createItem());
  }

  /**
   * Remove item from array
   */
  removeItem(index: number): void {
    this.directorKycuploadList.removeAt(index);
  }

  createForm(): void {
    this.form = this.fb.group({
      directors: ["", ValidatorUtility.Required],
    });
  }
  /**
   * On File change
   */

  onContactchange(event: any) {
    this.fileCount = 0;
    this.isFileUploadValid = false;
    this.getBusinessStekholdersDocuments(event.value);
  }

  onFileChange(docValue: IFile): void {
    this.contactfile = docValue.file;
    this.onFormSubmit(docValue);
  }

  getDocumentsType() {
    this.kycService.getBusinessDocumentTypes(AccountDocumentType.Director).subscribe((resData) => {
      this.documentTypes = resData.map((data)=> {
        let fileData = new FileUpload(data);
        fileData.docType = "Contact";
        this.documentMap.set(data.value.toString(), fileData);
        return fileData;
      });
    });
  }

  getBusinessStekholdersDocuments(businessKYCStakeholderId: string): void {
    this.loader = true;
    this.kycService.getStakeholderDocuments(businessKYCStakeholderId).subscribe(
      (res) => {
        this.kycService.getBusinessDocumentTypes(AccountDocumentType.Director).subscribe((resData) => {
          this.documentTypes = resData.map((data)=> {
            let fileData = new FileUpload(data);
            fileData.docType = "Contact";
            this.documentMap.set(data.value.toString(), fileData);
            return fileData;
          });
          this.StakeholderDocuments = res;
          this.StakeholderDocuments.forEach((data)=> {
          let doc =  this.documentMap.get(data.documentType);
          if (doc) {
              doc.fileId = data.documentId;
              doc.isDeletedValid = true;
              doc.fileName = data.name;
              ++this.fileCount;
          }
          })
          this.loader = false;
          this.isFileUploadValid = true;
        });
        
      },
      (err) => {
        this.loader = false;
      }
    );
  }

  onFormSubmit(docValue: IFile): void {
    const formData = new FormData();
    formData.append("File", this.contactfile);
    formData.append("documentType", docValue.apiData.value.toString());
    formData.append("BusinessKYCStakeholdersId", this.form.value.directors);
    formData.append("DocFor", "Contact");

    this.errorMessage = undefined;
    docValue.fileActionLoader = true;
    const data = this.form.value;

    this.kycService.uplaodBusinessKycContactDocument(formData).subscribe(
      (res: any) => {
        docValue.fileActionLoader = false;
        this.toastrService.success(FILE_SUCCESS_MESG);
        docValue.isDeletedValid = true;
        docValue.fileId = res.message;
         ++this.fileCount;
      },
      (err) => {
        if (err.error.message) {
          this.toastrService.error(err.error.message);
          this.errorMessage = err.error.message;
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
        docValue.fileActionLoader = false;
      }
    );
  }
  onSubmit() {
    this.continue.emit();
  }

  onFileDelete(event, item:IFile) {
    this.modalService.openConfirmationModal("Do you want to delete the file?");
    this.sub = this.commonSavedData.getConfirmationBoxStatus().subscribe((status: boolean) => {
      if (status) {
          item.fileActionLoader = true;
          this.kycService.deleteDocument(item.fileId).subscribe((res: any) => {
            item.fileActionLoader = false;
            if (res.message === "success") {
              item.isDeletedValid = false;
              --this.fileCount;
                this.toastrService.success(FILE_DELETE_MESG);
            } else {
              this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
            }
        },
        (err) => {
          item.fileActionLoader = false;
          if (err.error.message) {
            this.toastrService.error(err.error.message);
            this.errorMessage = err.error.message;
          } else {
            this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
          }
        });
        }
      this.sub.unsubscribe();
    });
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }
}
