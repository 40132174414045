<section
  class="wrapper bg-white d-flex justify-content-center position-relative sm-width-phone-700 sm-width-phone-800-height">
  <img class="position-absolute logo" src="../../../assets/media/logo-dark.svg" />

  <div class="position-absolute close text-primary-light" (click)="backRoute()">
    <mdb-icon fas icon="times"></mdb-icon>
  </div>

  <div class="w-stepper-60 w-phone-100" [hidden]="completed">
    <mdb-stepper #stepper [linear]="true" class="w-60 sm-convert-mdbStepper-mob">
      <mdb-step name="Amount" class="step" [stepForm]="amountForm">
        <cashaa-convert-amount [inView]="activeStep == 0" (continue)="onAmountFormContinue()"></cashaa-convert-amount>
      </mdb-step>
      <mdb-step name="Confirm" class="step">
        <cashaa-convert-review [inView]="activeStep == 1" (goback)="onGoBack($event)"
          (completed)="convertCompleted($event)"></cashaa-convert-review>
      </mdb-step>
    </mdb-stepper>
  </div>
  <div class="w-25 sm-width-phone-500" *ngIf="completed">
    <cashaa-convert-completed (continue)="false"></cashaa-convert-completed>
  </div>
</section>