<section class="p-5 w-65 mx-auto fiat-flow-amount">
  <h4 class="heading mb-4 text-center">
    How much do you want to deposit?
  </h4>
  <!-- <div class="mb-4">
    <cashaa-form-select-currency [form]="form" name="currency" [control]="form.controls.currency" source="fiatCurrency"
      [setCurrency]="setCurrency" label="Currency" (changed)="onCurrencyChange($event)"></cashaa-form-select-currency>
  </div> -->

  <div class="mb-4">
    <cashaa-form-select-transfer-currency [initialValue]="initialValue" [noInput]='true'
      [noAccountCurrency]='true' (sendCurrencyChangeEvent)="onCurrencyChange($event)" (changed)="onCurrencyChange($event)" [form]="form" class="width-100"
      [currencyControl]="form.controls.currency" [controlCurrency]="setCurrency" label="Currency"
      currencyControlName="currency" source={{currencySource}}>
    </cashaa-form-select-transfer-currency>
  </div>
  <form [formGroup]="form">
    <div class="row mb-2">
      <div class="form-check col-5 sm-userType-boxes">
        <input type="radio" formControlName="recipientType" class="form-check-input" id="self" name="recipientType"
          mdbInput value="1" />
        <label class="form-check-label" for="self">Self</label>
        <button type="button" class="sm-tooltip" mdbTooltip="Own account" placement="top">
          <mdb-icon class="pl-1 info-circle" title="" fas icon="info-circle"></mdb-icon>
        </button>
      </div>
      <div class="form-check col-5 sm-userType-boxes">
        <input type="radio" formControlName="recipientType" class="form-check-input" id="others" name="recipientType"
          mdbInput value="2" />
        <label class="form-check-label" for="others">Others</label>
        <button type="button" class="sm-tooltip" mdbTooltip="Third-party bank account" placement="top">
          <mdb-icon class="pl-1 info-circle" title="" fas icon="info-circle"></mdb-icon>
        </button>
      </div>
    </div>
    <span *ngIf="!isPremiumPlan" class="font-weight-normal pb-4">*Do you want to receive money from someone else? </span>
    <a [routerLink]="['/subscription/plan']" *ngIf="!isPremiumPlan" class="p-0 pb-4">
      <span class="mt-2 font-weight-normal">Click here to upgrade.</span>
    </a>
    <div class="row mb-2 pt-2" *ngIf="currentCur?.symbol == 'USD'">
      <!-- <h3>Fee Type</h3> -->
      <div class="form-check col-5 sm-userType-boxes">
        <input type="radio" formControlName="deliveryMechanism" class="form-check-input" id="ACH" name="deliveryMechanism"
          mdbInput value="2" />
        <label class="form-check-label" for="ACH">ACH/Wire
        </label>
        <mdb-icon fas icon="info-circle" class="ml-1 info-circle"
        mdbTooltip="ACH/Wire transfers can take up to 2-5 working days. For faster payment use Wire transfers."
        placement="right"></mdb-icon>
      </div>
      <div class="form-check col-5 sm-userType-boxes">
        <input type="radio" formControlName="deliveryMechanism" class="form-check-input" id="SWIFT" name="deliveryMechanism"
          mdbInput value="3" />
        <label class="form-check-label disable" for="SWIFT">SWIFT
        </label>
        <mdb-icon fas icon="info-circle" class="ml-1 info-circle" 
        mdbTooltip="Wire transfers within the USA usually settle the same day, from outside the USA can take up to 3-5 working days."
        placement="right"></mdb-icon>
      </div>
    </div>
  </form>
  


  <div class="mb-4">
    <cashaa-form-input [form]="form" name="amount" [control]="form.controls.amount" [transformNumber]="true"
      label="How much do you want to deposit?">
    </cashaa-form-input>
  </div>
  <div>
    <mdb-error style="display: block;position: relative; top: -7px;" class="mt-1 mb-3" *ngIf="errorMessage">{{ errorMessage
    }}
  </mdb-error>
  </div>
  
  <div *ngIf="form?.controls?.amount?.value" class="mb-4 mx-1">
    <div class="d-flex justify-content-between" *ngIf="fee > 0">
      <p class="font-weight-normal small">
        Fee
        <mdb-icon fas icon="info-circle" class="ml-1 info-circle"
          mdbTooltip="Fees listed are what our bank charges us. Other banks used during the transfer may charge additional fees and are out of our control"
          placement="right"></mdb-icon>
      </p>
      <p class="font-weight-bolder small">
        <span *ngIf="currentCur?.symbol == 'GBP'">&pound;</span><span *ngIf="currentCur?.symbol == 'EUR'">&euro;</span><span *ngIf="currentCur?.symbol == 'USD'">$</span>
        {{ fee | number }}
      </p>
      
    </div>
    <div class="d-flex justify-content-between">
      <p class="font-weight-bold">Net amount you will deposit</p>
      <p class="font-weight-bold">
        <span *ngIf="currentCur?.symbol == 'GBP'">&pound;</span><span *ngIf="currentCur?.symbol == 'EUR'">&euro;</span><span *ngIf="currentCur?.symbol == 'USD'">$</span>
        {{ +form?.controls?.amount?.value.replaceAll(',','') + fee | number: "0.0-4" }}
      </p>
    </div>
  </div>

  <button [disabled]="form.invalid  || errorMessage || !isPremiumPlan || !getAmount() || (!form.controls.deliveryMechanism.value && currentCur?.symbol == 'USD')" mdbBtn block="true" type="button"
    class="btn-sm btn-primary btn btn-height" mdbWavesEffect (click)="onContinue()">
    Continue
  </button>
</section>