import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IApiListRespose } from 'src/app/core/models/api-manager/api-manager';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { ApiManagerService } from '../services/api-manager.service';

@Component({
  selector: 'cashaa-api-manager-data',
  templateUrl: './api-manager-data.component.html',
  styleUrls: ['./api-manager-data.component.scss']
})
export class ApiManagerDataComponent implements OnInit {
  loader;
  @Input()
  data: IApiListRespose;
  webhookUrlDetails = [];
  constructor(private modalService: ModalService, private router: Router, private apiManagerService: ApiManagerService) { }

  ngOnInit(): void {
    this.getWebhookURL();
  }

  deleteAPI(): void {
    this.modalService.confirmDeleteAPI(this.data.id);
  }
  editAPI() {
    this.apiManagerService.emitAPIData(this.data);
    this.router.navigateByUrl(`api-manager/edit?id=${this.data.id}`);
  }

  editWebhookURL(){
    this.apiManagerService.emitAPIData(this.data);
    this.router.navigateByUrl(`api-manager/edit?id=${this.data.id}`);
  }
  addWebhookURL() {
    this.router.navigateByUrl(`api-manager/add-webhook?id=${this.data.id}`);
  }

  getWebhookURL() {
    this.apiManagerService.getWebhookURL(this.data.id).subscribe((data) => {
     this.webhookUrlDetails.push(data);
     console.log(this.webhookUrlDetails);
   }, err => {
  
   });
  }

  updateDomain() {
    this.router.navigateByUrl(`api-manager/update-domain?id=${this.data.id}`);
  }

  verifyDomain() {
    this.router.navigateByUrl(`api-manager/verify-domain`);
  }

  getDomainStatus() {
    this.router.navigateByUrl(`api-manager/domain-status`);
  }
}
