import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ApiManagerRoutingModule } from './api-manager-routing.module';
import { ApiManagerHomeComponent } from './api-manager-home/api-manager-home.component';
import { ApiManagerCreateComponent } from './api-manager-create/api-manager-create.component';
import { ApiManagerSecurityVerificationComponent } from './api-manager-security-verification/api-manager-security-verification.component';
import { ApiManagerConfirmComponent } from './api-manager-confirm/api-manager-confirm.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { ApiManagerOperationsComponent } from './api-manager-operations/api-manager-operations.component';
import { ApiManagerDataComponent } from './api-manager-data/api-manager-data.component';
import { ApManagerDeleteApiConfirmComponent } from './ap-manager-delete-api-confirm/ap-manager-delete-api-confirm.component';
import { AddWebhookURLComponent } from './add-webhook-url/add-webhook-url.component';
import { UpdateDomainComponent } from './update-domain/update-domain.component';


@NgModule({
  declarations: [ApiManagerHomeComponent, ApiManagerCreateComponent, ApiManagerSecurityVerificationComponent, ApiManagerConfirmComponent, ApiManagerOperationsComponent, ApiManagerDataComponent, ApManagerDeleteApiConfirmComponent,
    AddWebhookURLComponent, UpdateDomainComponent],
  imports: [
    CommonModule,
    ApiManagerRoutingModule,
    ReactiveFormsModule,
    MDBBootstrapModulesPro.forRoot(),
    SharedModule,
    FormsModule
  ]
})
export class ApiManagerModule { }
