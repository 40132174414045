import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { ToastrService } from 'ngx-toastr';
import { CANCEL_AUTO_RENEWAL_SUCCESS, UNABLE_TO_PERFORM_ERROR } from 'src/app/app.constants';
import { ICancelAutoRenewal } from 'src/app/core/models/deposit-interest/deposit-interest';
import { DepositInterestService } from 'src/app/deposit-interest/service/deposit-interest.service';

@Component({
  selector: 'cashaa-cancel-fee',
  templateUrl: './cancel-fee.component.html',
  styleUrls: ['./cancel-fee.component.scss']
})
export class CancelFeeComponent implements OnInit {
  cancelFeeDetails;
  cancelFee;
  depositInterestId;
  currency;
  isLoading = false;
  constructor(public modalRef: MDBModalRef, private depositInterestService: DepositInterestService, private toastrService: ToastrService) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.depositInterestService.getCancelFee(this.depositInterestId).subscribe((res: any) => {
      this.isLoading = false;
      console.log(res)
      this.cancelFee = res.cancelFee.toFixed(2);
      this.currency = res.currency;
      //  console.log(this.cancelFee)
      this.cancelFeeDetails = res.cancelFeeDetails;
      console.log('CancelFeeComponent:', this.cancelFeeDetails)
    }, err => {
      this.isLoading = false;
    });
  }

  confirm() {
    const cancel: ICancelAutoRenewal = {
      id: this.depositInterestId
    }
    this.isLoading = true;
    this.depositInterestService.cancelFixedDeposit(cancel).subscribe((res) => {
      this.isLoading = false;
      if (res.statusCode === 200) {
        this.toastrService.success(CANCEL_AUTO_RENEWAL_SUCCESS);
        // this.isActive = false;
        window.location.reload();
      } else {
        this.toastrService.error("Please try again!");
      }
    }, (err) => {
      if (err.error.message) {
        this.toastrService.error(err.error.message);
      } else {
        this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
      }
      this.isLoading = false;
    
  });
}

}
