export interface IChangePasswordRequest {
    CurrentPassword: string,
    NewPassword: string,

}

export class ChangePasswordRequest implements IChangePasswordRequest {
    constructor(
        public CurrentPassword: string,
        public NewPassword: string,

    ) { }
}