import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { IRecentTransactionList } from "src/app/core/models/activity/recent-transaction-list";
import { ISearchFilter } from "src/app/core/models/activity/search-filter";

@Injectable({
  providedIn: "root",
})
export class ActivityService {
  /** api url */
  private apiUrl = environment.api;

  private balanceRefresh = new BehaviorSubject<boolean>(undefined);

  constructor(private http: HttpClient) { }

  getRecentTransactions(filterModel: ISearchFilter): Observable<IRecentTransactionList[]> {
    return this.http.post<IRecentTransactionList[]>(`${this.apiUrl}/transactions/recent`, filterModel);
  }

  emitBalanceRefreshStatus(status: boolean) {
    this.balanceRefresh.next(status);
  }

  getBalanceStatus(): Observable<boolean> {
    return this.balanceRefresh.asObservable();
  }

  checkConsent() {
    return this.http.get(`${this.apiUrl}/account/get-user-consent`);
  }

  refreshToken(cashaaUserId) {
    return this.http.post(`${this.apiUrl}/account/refreshtoken`, cashaaUserId);
  }

  getLockedAmountByCurrency(currencyId) {
    return this.http.get(`${this.apiUrl}/activity/lockedamountbycurrency?currencyId=${currencyId}`);
  }

}
