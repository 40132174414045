import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalService } from "src/app/core/services/modal/modal.service";
import { AccountUpgradeService } from "../services/account-upgrade.service";

@Component({
  selector: "cashaa-staking-home",
  templateUrl: "./staking-home.component.html",
  styleUrls: ["./staking-home.component.scss"],
})
export class StakingHomeComponent implements OnInit {
  isLoading: boolean;
  constructor(
    private activateRoute: ActivatedRoute,
    private modalService: ModalService,
    private upgardeService: AccountUpgradeService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  addStaking() {
    this.isLoading = true;
    this.upgardeService.userKycStatus().subscribe(
      (flag) => {
        if (flag.status === 2) {
          this.modalService.openPersonalModal(
            false,
            true,
            "Stake 10,000 CAS & Earn 30% APR",
            "Confirm to stake 10,000 CAS. Your CAS will be locked for a 6 month term. You will receive a 30% APR, paid out weekly."
          );
        } else {
          this.router.navigateByUrl("/kyc/personal?from=staking");
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  addStakingUSD() {
    this.isLoading = true;

    this.upgardeService.usdStatus().subscribe(
      (flag) => {
        if (flag.status === 1) {
          this.modalService.openPersonalModal(
            true,
            true,
            "Stake to Open a USD Account & Earn 30% APR",
            "Confirm to stake 10,000 CAS for a USD account. Your CAS will be locked for a 6 month term. You will receive a 30% APR, paid out weekly."
          );
        } else {
          this.router.navigateByUrl("/kyc/US/personal?from=staking");
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }
}
