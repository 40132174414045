import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { IFormSelect } from "src/app/core/models/form-select";
import { DomainService } from "src/app/core/services/domain/domain.service";
import { TransferType } from "src/app/core/enums/transferType";
import { ITransferFees } from "src/app/core/models/transfer-types";
import { ConvertService } from "../../../convert/services/convert.service";

@Component({
  selector: "cashaa-form-select-transfer-type",
  templateUrl: "./form-select-transfer-type.component.html",
  styleUrls: ["./form-select-transfer-type.component.scss"],
})
export class FormSelectTransferTypeComponent implements OnInit, OnChanges {
  @Output() Onfeechangeevent = new EventEmitter<ITransferFees>();

  /** Placeholder for input */
  @Input() placeholder = "Select transfer type";

  /** input form group */
  @Input() form: FormGroup;

  @Input() selected: string;

  /** form control */
  @Input() control: FormControl;

  /** name of control */
  @Input() name: string;

  /** Form select values */
  @Input() options: IFormSelect[] = [];

  isDropdownOpened = false;

  /** transfer fees types */
  transferFees: ITransferFees[] = [];

  /** selected fee */
  selectedFee: ITransferFees;

  symbol: string;

  constructor(
    private domainService: DomainService,
    private convertService: ConvertService
  ) {}

  ngOnInit(): void {
    this.getTransferTypeList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.selected &&
      changes.selected.currentValue !== changes.selected.previousValue
    ) {
      const currency = this.transferFees.filter(
        (fee) => fee.currencyId === this.selected
      );
      if (currency.length > 0) {
        this.control.setValue(currency[0].transferFeeId);
      }
    }
  }

  /**
   * Get Transfer fess
   */
  getTransferTypeList(): void {
    // to get the emitted symbol from convert component
    this.convertService.getCurrencySymbolEmitter().subscribe((symbol) => {
      this.symbol = symbol;
    });
    this.domainService.getTransferTypeList().subscribe((list) => {
      this.transferFees = list.transferFeesList;
      if (this.transferFees.length > 0) {
        this.selectedFee = this.transferFees[0];
        if (this.symbol === "GBP") {
          this.selectedFee = this.transferFees[0];
        } else if (this.symbol === "EUR") {
          this.selectedFee = this.transferFees[1];
        }
        this.control.setValue(this.selectedFee.transferFeeId);
      }
    });
  }

  /**
   * on fee selection
   */
  onFeeTypeSelection(fee: ITransferFees): void {
    this.selectedFee = fee;
    this.isDropdownOpened = false;
    this.control.setValue(fee.transferFeeId);
    this.Onfeechangeevent.emit(fee);
  }
}
