import { ModalService } from 'src/app/core/services/modal/modal.service';
import { Component, OnInit, Input } from '@angular/core';
import { ILedgerList } from 'src/app/core/models/activity/recent-transaction-list';

@Component({
  selector: 'cashaa-activity-balance-spent',
  templateUrl: './activity-balance-spent.component.html',
  styleUrls: ['./activity-balance-spent.component.scss']
})
export class ActivityBalanceSpentComponent implements OnInit {

  @Input() ledger: ILedgerList;

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
  }

  help(): void {
    this.modalService.openTicketCreateModal();
  }
}
