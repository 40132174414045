<section class="activity-box px-3 mb-2">
    <div class="details">
        <mdb-accordion [multiple]="false" aria-multiselectable="false">
            <mdb-accordion-item class="no-collase">
                <mdb-accordion-item-head class="p-2" mdbWavesEffect>
                    <div class="d-flex flex-row px-4 py-2 align-items-center">
                        <div class="">
                            <small>{{ getNameInitials() }}</small>
                            <img [src]="'../../../assets/icons/' + data.currency + '.png'" class="mr-2 cur-img" />
                        </div>
                        <div class="status ml-4" style="width: 50%;">
                            <span *ngIf="data.currencyType !==1" class="text-primary-dark font-weight-bold">{{data.name}}</span>
                            <span *ngIf="data.currencyType ===1" class="text-primary-dark font-weight-bold">{{data.name}} <span class="font-weight-normal">({{getLabel()}})</span></span>
                            <br />
                            <span class="text-gray"> {{data.currency |uppercase}} account ending in
                                {{
                                data?.accountNumber?.substr(
                                data?.accountNumber?.length - 4
                                )
                                }}</span>
                            <span *ngIf="data?.isVerified" style="font-size: 0.75rem !important" class="green-text font-weight-bolder">&nbsp;(Verified)</span>
                        </div>
                    </div>
                </mdb-accordion-item-head>
                <mdb-accordion-item-body class="border-top border-light px-4 position-relative">
                    <div class="container px-4 pt-2" *ngIf="data.currencyType !==1">
                        <div class="row">
                            <div class="col-sm" >
                                Summary<br />
                                <b class="text-primary">{{data.currency |uppercase}} account ending in
                                    {{
                                    data?.accountNumber?.substr(
                                    data?.accountNumber?.length - 4
                                    )
                                    }}</b>
                            </div>

                            <div class="col-sm">
                                Account holder name <br />
                                <b class="text-primary">{{data.name}}</b>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-sm">
                                Account number<br />
                                <b class="text-primary">{{data.accountNumber}}</b>
                            </div>
                            <div class="col-sm" *ngIf="!fiatCurrencies.includes(data.currency)">
                                Network<br />
                                <b class="text-primary">{{getLabel()}}</b>
                            </div>
                        </div>
                    </div>
                    <div class="container px-4 pt-2" *ngIf="data.currencyType ===1">
                        <div class="row">
                            <div class="col-sm">
                                Account number<br />
                                <b class="text-primary">{{data.accountNumber}}</b>
                            </div>
                            <div class="col-sm" *ngIf="!fiatCurrencies.includes(data.currency)">
                                Network<br />
                                <b class="text-primary">{{getLabel()}}</b>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-sm">
                                Account holder name <br />
                                <b class="text-primary">{{data.name}}</b>
                            </div>
                            
                            <div class="col-sm" *ngIf="data.memo">
                                Memo<br />
                                <b class="text-primary">{{data.memo}}</b>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="d-flex bd-highlight">
                        <div *ngIf="data?.isVerified" class="bd-highlight">
                            <div *ngIf="data?.isCashaaAddress && data?.network === 0 && !data?.memo; else showUpdateBtn">
                                <button style="height: 46px;" (click)="updateMemo(data, getLabel())" class="app-primary-btn btn-sm font-weight-bold" mdbBtn type="button" outline="true" mdbWavesEffect>
                                    Update {{data.currency}} Memo
                                </button>
                            </div>
                            <ng-template #showUpdateBtn>
                              <button style="height: 46px;" routerLink="/fiat" [queryParams]="{ cur: data.currency, recipientType:data?.recipientType}" (click)="emitRecipient()" class="btn-secondary sm-custom-sec sm-btn-send" mdbBtn type="button" outline="true" mdbWavesEffect>
                                Send {{data.currency}}
                              </button>
                            </ng-template>
                        <!-- <button *ngIf="data?.recipientType ==='Others' || data?.recipientType ==='2'" style="height: 46px;" routerLink="/fiat" [queryParams]="{ cur: getCurrency(), recipientType:2}" (click)="emitRecipient()" class="btn-secondary sm-custom-sec sm-btn-send" mdbBtn type="button" outline="true" mdbWavesEffect>
                            Send money
                        </button> -->
                        <!-- <button style="height: 46px;" routerLink="/fiat" [queryParams]="{ cur: getCurrency()}" (click)="emitRecipient()" class="btn-secondary sm-custom-sec sm-btn-send" mdbBtn type="button" outline="true" mdbWavesEffect>
                            Send money
                        </button> -->
                        </div>
                        <div class="bd-highlight">
                            <button *ngIf="!data?.isVerified" style="height: 46px;" [disabled]="timerFlag[0] || disableBtn[0]" mdbBtn type="button" outline="true" mdbWavesEffect class="sm-btn-input-hover btn-border btn-sm" (click)="getVerificaionLink(data?.id, data?.currency, 0)">
                            Get Verification Link
                        </button>
                            <cashaa-timer *ngIf="!data?.isVerified" [time]="timerTime[0]" [start]="timerStart[0]" [noExpiry]="true" [isRecipient]="true" (expire)="timerExpire(0)">
                            </cashaa-timer>
                        </div>
                        <div class="ml-auto bd-highlight">
                            <button style="height: 46px;" (click)="deleteRecipient()" class="btn-border-delete btn-sm" mdbBtn type="button" outline="true" mdbWavesEffect>
                                <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"
                                 *ngIf="loader"></span>
                                Delete Recipient
                            </button>
                        </div>
                    </div>
                </mdb-accordion-item-body>
            </mdb-accordion-item>
        </mdb-accordion>
    </div>
</section>
