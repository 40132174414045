import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../shared/shared.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MDBBootstrapModulesPro } from "ng-uikit-pro-standard";
import { SettingsRoutingModule } from "./setting-routing.module";
import { SettingsHomeComponent } from "./settings-home/settings-home.component";
import { SettingsEmailComponent } from "./settings-email/settings-email.component";
import { SettingsPasswordComponent } from "./settings-password/settings-password.component";
import { SettingsTwoFactorAuthComponent } from "./settings-two-factor-auth/settings-two-factor-auth.component";
import { SettingsFeeComponent } from "./settings-fee/settings-fee.component";
import { ToastrModule } from "ngx-toastr";
import { SettingsSubscriptionPlanComponent } from "./settings-subscription-plan/settings-subscription-plan.component";
import { SettingEmailConfirmComponent } from './setting-email-confirm/setting-email-confirm.component';
import { SettingInterestChoiceComponent } from './setting-interest-choice/setting-interest-choice.component';
import { SettingCloseAccountComponent } from './setting-close-account/setting-close-account.component';

@NgModule({
  declarations: [
    SettingsHomeComponent,
    SettingsEmailComponent,
    SettingsPasswordComponent,
    SettingsTwoFactorAuthComponent,
    SettingsFeeComponent,
    SettingsSubscriptionPlanComponent,
    SettingEmailConfirmComponent,
    SettingInterestChoiceComponent,
    SettingCloseAccountComponent,
  ],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    FormsModule,
    MDBBootstrapModulesPro.forRoot(),
  ],
})
export class SettingsModule {}
