import { AuthGuardService } from "./../core/services/auth-guard/auth-guard.service";
import { ConvertHomeComponent } from "./convert-home/convert-home.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { UnderMaintenanceComponent } from "../shared/components/under-maintenance/under-maintenance.component";

const routes: Routes = [
  {
    path: "convert",
    component: ConvertHomeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "buy",
    component: ConvertHomeComponent,
    canActivate: [AuthGuardService],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ConvertRoutingModule {}
