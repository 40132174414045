<!-- <section
  class="px-5 text-center recipient-box m-4"
  (click)="onRecipientClick()"
>
  <div
    *ngIf="!isNew"
    class="my-2 py-2 text-primary-light font-weight-bolder d-flex justify-content-center align-items-center"
  >
    {{ initials }}
  </div>
  <div
    *ngIf="isNew"
    class="my-2 py-2 text-primary-light font-weight-bolder d-flex justify-content-center align-items-center"
  >
    <mdb-icon far icon="user-circle" class="mr-1"></mdb-icon>
   <div class="text-primary-dark font-weight-bold text-wrap" *ngIf="isNew">
      {{
        type == "myself"
          ? "Self"
          : type == "someone"
          ? "Someone else"
          : type == "addsender"
          ? "Add Sender"
          : type == "walletAddress"
          ? "Wallet Address"
          : "Business or charity"
      }}
    </div>
  
  </div>
  <p class="text-primary-dark font-weight-bold text-wrap" *ngIf="!isNew">
    {{ recipient?.name }}
  </p>
 
  <p class="font-weight-normal text-gray" [hidden]="isNew">
    {{ recipient?.currency }} Account
  </p>
  <p class="font-weight-normal text-gray" [hidden]="isNew">
    {{ recipient?.accountNumber.substr(0, 4)}} XXXX
    {{ recipient?.accountNumber?.substr(recipient?.accountNumber?.length - 4) }}
  </p>
</section> -->


<section *ngIf="!isNew"
  class="px-5 py-3 text-center recipient-box m-4 d-flex" [ngClass]="{'disable': recipient?.isCashaaAddress && !recipient?.memo && recipient?.network === 0}"
  (click)="onRecipientClick()">
  <div *ngIf="!isNew" class="text-primary-light font-weight-bolder initials mt-2">
    <div class="letter">{{ initials }}</div>
  </div>
  <div class="text-primary-dark font-weight-bold text-wrap p-2" *ngIf="!isNew">
    <b>{{ recipient?.name }}</b>
    <p class="font-weight-normal text-gray text-initial mb-0" [hidden]="isNew">
      {{ recipient?.currency }} Account
    </p>
    <!-- <p class="font-weight-normal text-gray text-initial mb-0" [hidden]="isNew">
      {{ recipient?.accountNumber.substr(0, 4)}} XXXX
      {{ recipient?.accountNumber?.substr(recipient?.accountNumber?.length - 4) }}
    </p> -->
  </div>
  <div *ngIf="!isNew && recipient?.accountNumber" class="ml-auto my-auto font-weight-normal">{{recipient?.accountNumber}} <span class="sm-input-group-text" *ngIf="recipient?.currencyType ===1"><i mdbTooltip="Copy Address" placement="top"
    class="fas fa-clone" style="cursor: pointer;" [ngClass]="copied ? 'text-primary' : ''" (click)="copyAddress();$event.stopPropagation()"></i></span>
    <p *ngIf="!isNew && recipient?.memo" class="font-weight-normal text-initial mb-0"><span class="font-weight-600">Memo: </span> {{recipient?.memo}}</p>
 <div *ngIf="recipient?.isCashaaAddress && !recipient?.memo && recipient?.network === 0" (click)="updateMemo(recipient, getLabel());$event.stopPropagation()" class="text-primary-light font-weight-600 mt-1">Update Memo</div>
  </div>
  
</section>

<section *ngIf="isNew" class="mx-4 recipient-box-new"  (click)="onRecipientClick()">

<div class="p-4 d-flex text-primary-light font-weight-bolder">
  <!-- <mdb-icon far icon="user-circle" class="mr-1"></mdb-icon> -->
  <div class="circle">
  <img src="../../../../assets/images/user.svg" alt=""  class="m-2"></div>
  
 <div class="text-primary-dark font-weight-bold text-wrap m-auto">
    {{
      type == "myself"
        ? "Self"
        : type == "someone"
        ? "Someone else"
        : type == "addsender"
        ? "Add Sender"
        : type == "walletAddress"
        ? "Add Wallet Address"
        : "Business or charity"
    }}
  </div>
  </div>
</section>
