import { ModalService } from "src/app/core/services/modal/modal.service";
import { Component, OnInit, Input } from "@angular/core";
import { ILedgerList } from "src/app/core/models/activity/recent-transaction-list";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import {
  TRANSACTION_SUCCESS_CANCELLED,
  UNABLE_TO_PERFORM_ERROR,
  VERIFICATION_MESG,
} from "src/app/app.constants";
import { FiatService } from "src/app/fiat/services/fiat.service";

@Component({
  selector: "cashaa-activity-balance-onboarding-fee",
  templateUrl: "./activity-balance-onboarding-fee.component.html",
  styleUrls: ["./activity-balance-onboarding-fee.component.scss"],
})
export class ActivityBalanceOnboardingFeeComponent implements OnInit {
  @Input() ledger: ILedgerList;

  constructor(
    private modalService: ModalService
  ) { }

  ngOnInit(): void { }

  openDetailBox(): void {
    this.modalService.openActivityTransferDetail(this.ledger);
  }

  getName(): string {
    if (this.ledger.ledgerType === 13) {
      return 'USD Account Onboarding Fee';
    } else if (this.ledger.ledgerType === 15) {
      return 'Broker Fee';
    } else if (this.ledger.ledgerType === 18) {
      return 'NFT Fee';
    } else if (this.ledger.ledgerType === 17) {
      return 'KYC Fee';
    } else if (this.ledger.ledgerType === 21) {
      return 'Service Charge';
    } else if (this.ledger.ledgerType === 24) {
      return 'Upgrade Fee';
    }
  }
}
