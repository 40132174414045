import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MdbStepperComponent } from 'ng-uikit-pro-standard';
import { IAPIData, IApiListRespose, ICreate } from 'src/app/core/models/api-manager/api-manager';
import { IFeature } from 'src/app/core/models/api-manager/feature';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';

@Component({
  selector: 'cashaa-api-manager-home',
  templateUrl: './api-manager-home.component.html',
  styleUrls: ['./api-manager-home.component.scss']
})
export class ApiManagerHomeComponent implements OnInit {
  @ViewChild("stepper", { static: true }) stepper: MdbStepperComponent;

  constructor(private navigationService: NavigationService, private activateRoute: ActivatedRoute, private location: Location) { }
  apiData: IAPIData;
  respose: IApiListRespose;
  createData: ICreate;
  features: IFeature[];
  isUpdated = false;
  ngOnInit(): void {
    this.activateRoute.queryParams.subscribe((params) => {
      let id = params["id"];
      if (id) {
        this.isUpdated = true;
      }
    });
  }

  onCreate(event: IAPIData): void {
    this.apiData = event;
    this.stepper.next();
  }

  onSecurity(event: IApiListRespose): void {
    this.respose = event;
    this.stepper.next();
  }

  featureData(event: IFeature[]): void {
    this.features = event;
  }

  backRoute(): void {
    this.navigationService.back();
  }

  goBack() {
    this.location.back();
  }
}
