<cashaa-sidenav>
    <section class="mh-100">
            <div class="bg-white p-4 sm-media-padding">
                <div class="d-flex sm-phone-display-none mr-3" style="height: 47px;">
                        <button (click)="goBack()" mdbBtn type="button" class="cursor-pointer btn-sm app-primary-btn">Back</button>
                </div>
            </div>
    </section>
    <div class="p-4 bg-white h-100">
        <div class="mx-auto stepper w-phone-100 w-90">
            <mdb-stepper #stepper [linear]="true" class="w-100">
                <mdb-step name="Create API" class="step">
                    <cashaa-api-manager-create (continue)="onCreate($event)" (featureData)="featureData($event)">
                    </cashaa-api-manager-create>
                </mdb-step>
                <mdb-step name="Security Verification" class="step">
                    <cashaa-api-manager-security-verification (continue)="onSecurity($event)" [apiData]="apiData">
                    </cashaa-api-manager-security-verification>
                </mdb-step>
                <mdb-step name="Confirm" class="step" *ngIf="!isUpdated">
                    <cashaa-api-manager-confirm [resposeData]="respose">
                    </cashaa-api-manager-confirm>
                </mdb-step>
            </mdb-stepper>
        </div>
    </div>
</cashaa-sidenav>