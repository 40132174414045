<cashaa-sidenav>
  <section class="mh-100">
    <div class="bg-white p-4" style="min-height: 70px;">
      <h4 class="font-weight-bold sm-profile-heading-mobile">Payment</h4>
    </div>
  </section>
  <div class="p-4 sm-width-phone-700">
    <div class="sm-payment-card w-phone-100" [hidden]="loader">
      <p class="font-weight-normal text-dark">
        Please pay the one time processing fee <strong>
          {{premuimFeesDetails?.feeInCAS | number}} {{premuimFeesDetails?.casFeeSymbol}}
          ({{premuimFeesDetails?.discountFeeInFiat}} {{premuimFeesDetails?.fiatFeeSymbol}}) or
          {{premuimFeesDetails?.feeInEUR| number}} {{premuimFeesDetails?.fiatFeeSymbol}}
        </strong> to initiate your application review process.
      </p>
      <form [formGroup]="form" class="my-4 d-flex">
        <label class="heading mt-4">Select Fees Type</label>
        <div class="mb-4 d-flex">
          <div class="form-check ml-2 mt-4">
            <input type="radio" formControlName="feesType" class="form-check-input" id="false" name="feesType" mdbInput
              value="cas" />
            <label class="form-check-label font-weight-bolder" for="false">{{premuimFeesDetails?.feeInCAS | number}}
              {{premuimFeesDetails?.casFeeSymbol}} ({{premuimFeesDetails?.discountFeeInFiat}} {{premuimFeesDetails?.fiatFeeSymbol}})
            </label>
            <!--<p class="font-weight-bolder text-red ml-4 pl-3">* 25% less</p>-->
          </div>
          <div class="form-check mt-4">
            <input type="radio" formControlName="feesType" class="form-check-input" id="true" name="feesType" mdbInput
              value="fiat" />
            <label class="form-check-label font-weight-bolder" for="true">{{premuimFeesDetails?.feeInEUR| number}}
              {{premuimFeesDetails?.fiatFeeSymbol}}
            </label>
          </div>
        </div>
      </form>

      <p *ngIf="showTransfer" class="font-weight-normal text-dark">
        <strong>Steps to pay the Fee using {{getCurrencySymbol()}}</strong>
      </p>
      <table class="w-100" *ngIf="showTransfer">
        <tr>
          <td class="w-20" style="vertical-align: top;white-space: nowrap;">
            <h6> Steps 1:</h6>
            <div class="vl-step1"></div>
          </td>
          <td class="w-80" style="vertical-align: top;" *ngIf="getCurrencySymbol()=== 'CAS'">
            <a routerLink="/convert" class="p-0 font-weight-600"> Click here</a> to Buy {{getCurrencySymbol()}}
          </td>
          <td class="w-80" style="vertical-align: top;" *ngIf="getCurrencySymbol() === premuimFeesDetails.fiatFeeSymbol">
            <a [routerLink]="['/buy']" [queryParams]="{c:getCurrencySymbol()}" class="p-0 font-weight-600"> Click here</a> to Buy
            {{getCurrencySymbol()}}
          </td>
        </tr>
        <br>
        <tr>
          <td style="vertical-align: top;white-space: nowrap;">
            <h6> Steps 2:</h6>
            <div [ngClass]="getCurrencySymbol() !== 'CAS'?'vl-eur':'vl'"></div>
          </td>
          <td style="vertical-align: top;">
            <span *ngIf="getCurrencySymbol() === 'CAS'">Deposit CAS<br></span>
            <span *ngIf="getCurrencySymbol() !== 'CAS'"><a [routerLink]="['/deposit']" class="p-0 font-weight-600"> Click here</a> to
              Deposit {{getCurrencySymbol()}}</span>
            <div class="input-group mb-3 sm-nowrap" *ngIf="getCurrencySymbol() === 'CAS'">
              <div class="sm-qr-box sm-padding-qr-box">
                <div class="sm-text-center">
                  <h4 class="sm-heading-text">Transfer Address</h4>
                </div>
                <div class="sm-qr-box-inner">
                  <qr-code [size]="200" [value]="accountDetailsCryptoData?.address"></qr-code>
                </div>
                <div class="sm-qr-input-box">
                  <span class="sm-spam-text sm-span-text-padding">{{accountDetailsCryptoData?.address }}</span>
                  <div class="input-group-append">
                    <span class="sm-input-group-text"><i mdbTooltip="Copy address" placement="top"
                        class="fas fa-clone sm-copy-color" [ngClass]="copied ? 'text-primary' : ''"
                        (click)="copyAddress()"></i></span>
                  </div>
                </div>
              </div>
              <div class="input-group mb-3 pl-4 sm-border-line">
                <div class="sm-qr-box">
                  <div class="sm-text-center">
                    <h4 class="sm-heading-text">MEMO</h4>
                  </div>
                  <div class="sm-qr-box-inner">
                    <qr-code [size]="200" [value]="accountDetailsCryptoData?.memo"></qr-code>
                  </div>
                  <div class="sm-qr-input-box">
                    <span class="sm-spam-text">{{accountDetailsCryptoData?.memo}}</span>
                    <div class="input-group-append">
                      <span class="sm-input-group-text"><i mdbTooltip="Copy memo" placement="top"
                          class="fas fa-clone sm-copy-color" [ngClass]="copiedMemo ? 'text-primary' : ''"
                          (click)="copyMemoAddress()"></i></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <br>
        <tr>
          <td style="white-space: nowrap;">
            <h6> Steps 3:</h6>
          </td>
          <td>
            <mdb-checkbox [disabled]="showTransfer" class="mb-3" (change)="isTickCheck($event)">
              <p class="font-weight-normal"> Deduct my fee <strong>(Available balance:
                  {{ getCurrencySymbol() === premuimFeesDetails?.fiatFeeSymbol ? balanceDataFiat?.currencyType : balanceData?.currencyType}}
                  {{ getCurrencySymbol() === premuimFeesDetails?.fiatFeeSymbol ? balanceDataFiat.available : balanceData?.available}} )</strong></p>
            </mdb-checkbox>
          </td>
        </tr>

      </table>
      <div *ngIf="!showTransfer && getCurrencySymbol()">
        <mdb-checkbox [disabled]="showTransfer" class="mb-3" (change)="isTickCheck($event)">
          <p class="font-weight-normal"> Deduct my fee <strong>(Available balance:
              {{ getCurrencySymbol() === premuimFeesDetails?.fiatFeeSymbol ? balanceDataFiat?.currencyType : balanceData?.currencyType}}
              {{ getCurrencySymbol() === premuimFeesDetails?.fiatFeeSymbol ? balanceDataFiat?.available : balanceData?.available}} )</strong></p>
        </mdb-checkbox>
      </div>
      <div class="mb-4 mt-4">
        <button mdbBtn (click)="payfee()" block="true" type="button"
          class="rounded-sm position-relative btn btn-primary waves-effect waves-light" mdbWavesEffect
          [disabled]="isDisable() || form.invalid || !isTick">
          Pay Fee
        </button>
      </div>

      <!-- <span class="font-weight-normal text-dark">Contact <a [routerLink]="" (click)="helpDeskRedirect()" class="p-0">help
          desk</a> for any question or
        query.</span><br /> -->

    </div>

    <div class="mb-5 text-center" [hidden]="!loader">
      <mdb-spinner spinnerColor="blue" class="sm-loader-center"></mdb-spinner>
    </div>
  </div>
</cashaa-sidenav>
