<div class="wrapper-box  p-md-5 p-3 rounded-lg bg-white animated fadeInLeft position-relative sm-width-phone-370">
  <h4 class="text-center text-primary font-weight-bold">Reset Password?</h4>
  <p class="text-center font-weight-normal">
    Enter the new password.
  </p>
  <form *ngIf="form" [formGroup]="form" class="my-4">
    <div class="mb-4">
      <cashaa-form-input name="password" type="password" [form]="form" [control]="form.controls.password"
        placeholder="New password"></cashaa-form-input>
      <cashaa-password-strength [passwordToCheck]="form.value.password" (passwordStrength)="passwordValid($event)">
      </cashaa-password-strength>
    </div>
    <div class="mb-4">
      <cashaa-form-input name="confirmPassword" type="password" [form]="form" [control]="form.controls.confirmPassword"
        placeholder="Confirm new password"></cashaa-form-input>
    </div>
    <div class="position-relative" *ngIf="errorMessage">
      <mdb-error class="mt-2">{{ errorMessage }}</mdb-error>
    </div>
    <button mdbBtn [disabled]="form.invalid || showLoadingIndicator" (click)="onResetPasswordSubmit()" block="true"
      type="button"
      class="rounded-sm position-relative btn btn-secondary sm-custom-sec waves-effect waves-light btn-block"
      mdbWavesEffect>
      <i class="fa-lock fas position-absolute"></i>Reset password
    </button>
  </form>
  <mdb-progress-bar *ngIf="showLoadingIndicator" class="progress primary-color-dark position-absolute"
    mode="indeterminate"></mdb-progress-bar>
</div>