import { WalletReadyComponent } from "./wallet-ready/wallet-ready.component";
import { WalletOpenComponent } from "./wallet-open/wallet-open.component";
import { WalletDashboardComponent } from "./wallet-dashboard/wallet-dashboard.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuardService } from "./../core/services/auth-guard/auth-guard.service";
import { WalletPageComponent } from "./wallet-page/wallet-page.component";
import { CurrencyAssetsComponent } from "./currency-assets/currency-assets.component";
import { RepayComponent } from "./repay/repay.component";
import { LoanInterestComponent } from "./loan-interest/loan-interest.component";
import { CreditLineComponent } from "./credit-line/credit-line.component";
import { LoanDetailsComponent } from "./loan-details/loan-details.component";
import { LoanRepaymentComponent } from "./loan-repayment/loan-repayment.component";
import { PortfolioHistoryComponent } from "./portfolio-history/portfolio-history.component";
const routes: Routes = [
  // {
  //   path: "wallet",
  //   component: WalletDashboardComponent,
  //   children: [],
  //   canActivate: [AuthGuardService],
  // },
  {
    path: "repay",
    component: RepayComponent,
    children: [],
    canActivate: [AuthGuardService],
  },
  {
    path: "loan-interest/:id",
    component: LoanInterestComponent,
    children: [],
    canActivate: [AuthGuardService],
  },
  {
    path: "loan-details/:id",
    component: LoanDetailsComponent,
    children: [],
    canActivate: [AuthGuardService],
  },
  {
    path: "loan-repayment/:id",
    component: LoanRepaymentComponent,
    children: [],
    canActivate: [AuthGuardService],
  },
  {
    path: "wallet",
    component: CurrencyAssetsComponent,
    children: [],
    canActivate: [AuthGuardService],
  },
  {
    path: "account-wallet",
    component: CurrencyAssetsComponent,
    children: [],
    canActivate: [AuthGuardService],
  },
  {
    path: "credit-line",
    component: CreditLineComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "wallet/open",
    component: WalletOpenComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "wallet/ready",
    component: WalletReadyComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "wallet/portfolio-history",
    component: PortfolioHistoryComponent,
    canActivate: [AuthGuardService],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class WalletRoutingModule {}
