<div class="d-flex justify-content-center">
    <div class="p-4 p-sm-5 wrapper-box position-relative border border-box mt-5">
        <h5 class="font-weight-bold mb-3" *ngIf="!isUpdated">Email Verification Code</h5>
        <div class="row google-code mb-4" (paste)="onCodePaste($event)" *ngIf="!isUpdated">
            <div class="col-2">
                <input type="number" maxlength="1" [(ngModel)]="code[0]" (input)="checkIfCodeIsFilled(0)" autofocus
                    #inputs />
            </div>
            <div class="col-2">
                <input type="number" maxlength="1" [(ngModel)]="code[1]" (input)="checkIfCodeIsFilled(1)" #inputs
                    (keyup)="removeBack($event, 0)" />
            </div>
            <div class="col-2">
                <input type="number" maxlength="1" [(ngModel)]="code[2]" (input)="checkIfCodeIsFilled(2)" #inputs
                    (keyup)="removeBack($event, 1)" />
            </div>
            <div class="col-2">
                <input type="number" maxlength="1" [(ngModel)]="code[3]" (input)="checkIfCodeIsFilled(3)" #inputs
                    (keyup)="removeBack($event, 2)" />
            </div>
            <div class="col-2">
                <input type="number" maxlength="1" [(ngModel)]="code[4]" (input)="checkIfCodeIsFilled(4)" #inputs
                    (keyup)="removeBack($event, 3)" />
            </div>
            <div class="col-2">
                <input type="number" maxlength="1" [(ngModel)]="code[5]" (input)="checkIfCodeIsFilled(5)" #inputs
                    (keyup)="removeBack($event, 4)" />
            </div>
        </div>
        <button *ngIf="!isUpdated" mdbBtn type="button" outline="true" mdbWavesEffect
            class="sm-btn-input-hover btn-border btn-sm btn-des mb-3" (click)="sendCode()"
            [disabled]="timerFlag[0] || disableBtn[0]">
            <span *ngIf="codeLoading" class="spinner-border spinner-border-sm mr-2" role="status"
                aria-hidden="true"></span>
            Send Code
        </button>
        <cashaa-timer [time]="timerTime[0]" [start]="timerStart[0]" [noExpiry]="true" [isRecipient]="true"
            (expire)="timerExpire(0)" *ngIf="!isUpdated">
        </cashaa-timer>
        <div *ngIf="twoFactor">
            <h5 class="font-weight-bold mb-3">2FA Code</h5>
            <div class="row google-code mb-4" (paste)="onCodePasteTwoFA($event)">
                <div class="col-2">
                    <input type="number" maxlength="1" [(ngModel)]="twoFAcode[0]" (input)="checkIfCodeIsFilledTwoFA(0)"
                        #twoFAInputs autofocus />
                </div>
                <div class="col-2">
                    <input type="number" maxlength="1" [(ngModel)]="twoFAcode[1]" (input)="checkIfCodeIsFilledTwoFA(1)"
                        #twoFAInputs (keyup)="removeBackTwoFA($event, 0)" />
                </div>
                <div class="col-2">
                    <input type="number" maxlength="1" [(ngModel)]="twoFAcode[2]" (input)="checkIfCodeIsFilledTwoFA(2)"
                        #twoFAInputs (keyup)="removeBackTwoFA($event, 1)" />
                </div>
                <div class="col-2">
                    <input type="number" maxlength="1" [(ngModel)]="twoFAcode[3]" (input)="checkIfCodeIsFilledTwoFA(3)"
                        #twoFAInputs (keyup)="removeBackTwoFA($event, 2)" />
                </div>
                <div class="col-2">
                    <input type="number" maxlength="1" [(ngModel)]="twoFAcode[4]" (input)="checkIfCodeIsFilledTwoFA(4)"
                        #twoFAInputs (keyup)="removeBackTwoFA($event, 3)" />
                </div>
                <div class="col-2">
                    <input type="number" maxlength="1" [(ngModel)]="twoFAcode[5]" (input)="checkIfCodeIsFilledTwoFA(5)"
                        #twoFAInputs (keyup)="removeBackTwoFA($event, 4)" />
                </div>
            </div>
        </div>

        <div class="font-weight-bold mb-3" *ngIf="!twoFactor">
            <span>
                Your google 2FA is not enabled
                <a [routerLink]="['/settings']" [queryParams]="{step:'2fa'}" class="p-0"> click here </a> to
                enable.</span>
        </div>
    </div>
</div>

<div class="ml-1 mt-4 sm-pos-right">
    <button mdbBtn (click)="onFormSubmit()" block="true" type="button"
        class="w-25 rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block" mdbWavesEffect
        [disabled]="((!isUpdated || !allCodeEntered) && !allCodeEnteredTwoFA)">
        <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Submit
    </button>
</div>