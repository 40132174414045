import { Component, OnInit, Input } from "@angular/core";
import { DomainService } from "src/app/core/services/domain/domain.service";
import { FormGroup, FormControl } from "@angular/forms";
import { IFormSelect } from "src/app/core/models/form-select";

@Component({
  selector: "cashaa-form-select-phonecode",
  templateUrl: "./form-select-phonecode.component.html",
  styleUrls: ["./form-select-phonecode.component.scss"],
})
export class FormSelectPhonecodeComponent implements OnInit {
  /** Placeholder for input */
  @Input() placeholder = "Country Code";

  /** input form group */
  @Input() form: FormGroup;

  /** form control */
  @Input() control: FormControl;

  /** name of control */
  @Input() name: string;

  @Input() label = "";

  /** form select values */
  options: IFormSelect[] = [];

  constructor(private domainService: DomainService) {}

  ngOnInit(): void {
    this.getCountriesCode();
  }

  getCountriesCode(): void {
    this.domainService.getCountriesList().subscribe((list) => {
      this.options = list.countries.map((country) => {
        return {
          value: country.id,
          label: `${country.countryCode}(${country.phoneCode})`,
        };
      });
      this.addOtherOption();
    });
  }

  /**
   * Add other options in the list
   */
  addOtherOption(): void {
    this.options.push({
      value: "-1",
      label: "Other",
    });
  }
}
