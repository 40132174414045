import { Component, OnInit } from "@angular/core";
import { FiatService } from "../services/fiat.service";
import { Pay, SkipBenificry } from "src/app/core/models/fIat/fiat-pay";
import { Recipient } from "src/app/core/models/fIat/recipients";
import { MDBModalRef } from "ng-uikit-pro-standard";
@Component({
  selector: "cashaa-fiat-flow-pay-approve",
  templateUrl: "./fiat-flow-pay-approve.component.html",
  styleUrls: ["./fiat-flow-pay-approve.component.scss"],
})
export class FiatFlowPayApproveComponent implements OnInit {
  /** fiat send ata */
  fiatdata?: Pay;
  symbol: string;

  /** fiat recipient data */
  fiatRecipientData?: Recipient;

  /** is some request in progress */
  isInProgress = false;

  constructor(
    private fiatService: FiatService,
    public modalRef: MDBModalRef
  ) { }

  ngOnInit(): void {
    this.getFiateData();
    this.getRecipientData();
  }

  /**
   * Get fiat data from previous requests
   *
   */
  getFiateData(): void {
    this.fiatService.getPayRequestData().subscribe((recipentsListData) => {
      if (recipentsListData) {
        this.fiatdata = recipentsListData;
      }
    });
  }

  /**
   * Get recipient data
   */
  getRecipientData(): void {
    this.fiatService.getemitRecipientdData().subscribe((fiatRecipientdData) => {
      if (fiatRecipientdData) {
        this.fiatRecipientData = fiatRecipientdData;
        this.symbol = fiatRecipientdData.currency;

      }
    });
  }

  /**
   * Skip approval requesst next time
   */
  skipApprovalRequest(): void {
    this.storeConfirmation(true);
  }

  /**
   * ALways requrie approval
   *
   */
  requireApprovalRequest(): void {
    this.modalRef.hide();
  }

  /**
   * Store confirmation given by use to
   * server
   */
  storeConfirmation(skipstatus: boolean): void {
    // this.fiatdata.isrecipientSkiped = skipstatus;
    
    const data = new SkipBenificry(this.fiatdata.fiatBeneficiaryId, skipstatus, this.symbol);

    this.fiatService.Skiprecipent(data).subscribe((success) => {
      this.modalRef.hide();
    });
  }
}
