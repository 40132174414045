import { CreateWithdrawRequest } from "./../../core/models/withdraw-fund";
import { WalletService } from "./../../wallet/services/wallet.service";
import { Component, ElementRef, OnInit, QueryList, ViewChildren } from "@angular/core";
import { FiatService } from "../services/fiat.service";
import { Router } from "@angular/router";
import { APP_ROUTES, UNABLE_TO_PERFORM_ERROR, USER_TWO_FACTOR_CODE } from "src/app/app.constants";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";
import { Recipient } from "src/app/core/models/fIat/recipients";
import { Pay } from "src/app/core/models/fIat/fiat-pay";
import { ModalService } from "src/app/core/services/modal/modal.service";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { WithdrawService } from "src/app/withdraw/services/withdraw.service";
import { ToastrService } from "ngx-toastr";
import { SubSink } from "subsink";
import { AccountService } from "src/app/account/sevices/account.service";
@Component({
  selector: "cashaa-fiat-flow-pay-auth-approval",
  templateUrl: "./fiat-flow-pay-auth-approval.component.html",
  styleUrls: ["./fiat-flow-pay-auth-approval.component.scss"],
})
export class FiatFlowPayAuthApprovalComponent implements OnInit {
  /** form  */
  authApprovalForm: FormGroup;

  /** pay flow data */
  payflowdata: Pay;

  /** is some request in progress */
  isInProgress = false;

  /** any error come */
  errorMessage = "";
  currecnyType: string;
  subSink = new SubSink();
  /** fiat recipient data */
  fiatRecipientData?: Recipient;

  isTwoFactorEnable = false;
  allCodeEntered = false;
  code: string[] = ["", "", "", "", "", ""];
  twoFACode: string = "";
  @ViewChildren("twoFAInputs") twoFAInputs: QueryList<ElementRef>;
  isTwoFAInProgress = false;
  constructor(
    private fiatService: FiatService,
    private fb: FormBuilder,
    private modalService: ModalService,
    public modalRef: MDBModalRef,
    private withdrawService: WithdrawService,
    private toastrService: ToastrService,
    private accountService: AccountService
  ) { }

  ngOnInit(): void {
    // this.createForm();
    this.getFiateData();
    this.checkTwoFactorEnable();
    this.subSink.add(
      this.fiatService
        .getemitRecipientdData()
        .subscribe((fiatRecipientdData) => {
          if (fiatRecipientdData) {
            this.fiatRecipientData = fiatRecipientdData;
          }
        })
    );
  }

  

  /**
   * Create empty form
   *
   */
  // createForm(): void {
  //   this.authApprovalForm = this.fb.group({
  //     password: ["", [ValidatorUtility.Required]],
  //   });
  // }

  /**
   * Get fiat data
   *
   */
  getFiateData(): void {
    this.fiatService.getPayRequestData().subscribe((recipentsListData) => {
      if (recipentsListData) {
        this.payflowdata = recipentsListData;
      }
    });
  }

  /**
   * on submission of form
   */
  onFormSubmit(): void {
    this.isInProgress = true;
    this.errorMessage = "";
    // get transfer currency type
    this.payflowdata["password"] = {"twoFA": this.twoFACode, "password": null};
    this.fiatService.getCurrencyType(this.payflowdata.fromCurrencyId).subscribe(
      (res) => {
        this.currecnyType = res.message;
        this.finalSubmit();
      },
      (err) => {
        if (err.error.message) {
          this.toastrService.error(err.error.message);
          this.errorMessage = err.error.message;
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
      }
    );
  }

  finalSubmit(): void {
    if (this.currecnyType === "Fiat") {
      if (this.payflowdata.isrecipientSkiped === false) {
        // this.payflowdata.password = this.authApprovalForm.value;
        this.payflowdata.password = {"twoFA":  this.twoFACode, "password": null};
        this.fiatService.sendPayRequest(this.payflowdata).subscribe(
          (senddataresponse) => {
            if (senddataresponse) {
              this.fiatService.setTransactionId(senddataresponse.message);
              this.isInProgress = false;
              this.modalRef.hide();
              this.modalService.openRecipentSkipRequiredPopup();
            }
          },
          (err) => {
            this.fiatService.setTransactionId(null);
            this.isInProgress = false;
            if (err.error.message) {
              this.toastrService.error(err.error.message);
              this.errorMessage = err.error.message;
            } else {
              this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
            }
          }
        );
      } else {
        // this.payflowdata.password = this.authApprovalForm.value;
        this.payflowdata.password = {"twoFA":  this.twoFACode, "password": null};
        this.fiatService.sendPayRequest(this.payflowdata).subscribe(
          (senddataresponse) => {
            this.isInProgress = false;
            if (senddataresponse) {
              this.fiatService.setTransactionId(senddataresponse.message);
              this.modalRef.hide();
              this.modalService.openRecipentSkipRequiredPopup();
            }
          },
          (err) => {
            this.fiatService.setTransactionId(null);
            this.isInProgress = false;
            if (err.error.message) {
              this.toastrService.error(err.error.message);
              this.errorMessage = err.error.message;
            } else {
              this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
            }
          }
        );
      }
    } else if (this.currecnyType === "Crypto") {
      if(!this.fiatRecipientData.memo) {
        this.fiatRecipientData.memo = "";
      }
      // this.payflowdata.password = this.authApprovalForm.value;
      this.payflowdata.password = {"twoFA":  this.twoFACode, "password": null};;
      const data = this.payflowdata;
      const withdrawRequestData = new CreateWithdrawRequest(
      //   data.currency,
      //   data.accountNumber,
      //   data.amount,
      //   data.remark,
      //  data.networkType
         data.currency,
          this.fiatRecipientData.id,
          data.amount,
          this.fiatRecipientData.memo,
          this.fiatRecipientData.network.toString().length > 0 ? this.fiatRecipientData.network.toString(): undefined,
          data.password,
          data.isrecipientSkiped
      );
      this.withdrawService.createWithdrawRequest(withdrawRequestData).subscribe(
        (res) => {
          this.fiatService.setTransactionId(res.message);
          this.isInProgress = false;
          this.modalRef.hide();
          this.modalService.openRecipentSkipRequiredPopup();
        },
        (err) => {
          this.fiatService.setTransactionId(null);
          this.isInProgress = false;
          if (err.error.message) {
            this.toastrService.error(err.error.message);
            this.errorMessage = err.error.message;
          } else {
            this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
          }
        }
      );
    }
  }

  checkTwoFactorEnable() {
    this.accountService.isTwoFactorEnabled().subscribe(res => {
      sessionStorage.setItem(USER_TWO_FACTOR_CODE, res.toString());
      this.isTwoFactorEnable = res;
    }, err => {

    })
  }

    /**
   * Check if google input authenticator
   * code is filled in all 6 boxes
   */
    checkIfTwoFACodeIsFilled(idx: number): void {
      if (idx >= 0 && idx < 5 && this.code[idx]) {
        this.twoFAInputs.toArray()[idx + 1].nativeElement.focus();
      }
  
      this.allCodeEntered = true;
      this.code.forEach((val) => {
        if (val === null || val.length === 0) {
          this.allCodeEntered = false;
        }
      });
      if (this.allCodeEntered) {
        this.isTwoFAInProgress = true;
        this.errorMessage = "";
        this.twoFACode = this.code.join("");
      }
    }
  /**
 * On code paste automatically
 * fill all 6 boxes
 */
  onTwoFACodePaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData("text");

    if (pastedText && pastedText.length === 6) {
      this.code = pastedText.split("");
      this.checkIfTwoFACodeIsFilled(-1);
    }
  }
    removeTwoFAOTPBack(event: any, index: number) {
      const keyCode = event.keyCode;
      if (keyCode === 8) {
        this.twoFAInputs.toArray()[index].nativeElement.focus();
      }
    }

    removeError(): void {
      if (this.errorMessage !== undefined) {
        this.errorMessage = undefined;
      }
    }

    hideModal(){
      this.modalRef.hide();
    }
}
