import { UserType } from "./../../../core/enums/userType";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  USER_NAME_PATH,
  APP_ROUTES,
  USER_TYPE,
  SEESION_FLAG,
  USER_NAME_ID,
  USER_EMAIL_PATH,
  IS_KYC_DONE_PERSONAL,
  SUBSCRIPTION_PERSONAL_PLAN,
  SUBSCRIPTION_BUSINESS_PLAN,
  USER_COUNTRY_CODE,
  USER_ID,
  IS_CAS_SWAP_Enabled,
} from "src/app/app.constants";
import { from } from "rxjs";
import { MDBModalService } from "ng-uikit-pro-standard";
import { ModalService } from "src/app/core/services/modal/modal.service";
import { KycService } from "src/app/kyc/services/kyc.service";
import { CommonSavedData } from "../../services/commonSavedData.service";
import { ActivityService } from "src/app/activity/services/activity.service";
import { AccountUpgradeService } from "src/app/account-upgrade/services/account-upgrade.service";
import { UserSubscriptionPlan } from "src/app/core/models/account-upgrade/subscription-plan-response";

@Component({
  selector: "cashaa-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
})

export class SidenavComponent implements OnInit {
  isBusiness = false;
  isBroker = false;
  isPersonal = false;
  isKycDone;
  stakingURL: string = APP_ROUTES.STAKING;
  activeStaking: boolean = false;
  isBasicPlan: boolean = false;
  isPremiumPlan = false;
  userType =  sessionStorage.getItem(USER_TYPE);
  userCountryCode = sessionStorage.getItem(USER_COUNTRY_CODE);
  constructor(
    private router: Router,
    private modalService: ModalService,
    private kycService: KycService,
    private commonSavedData: CommonSavedData,
    private activityService: ActivityService,
    private upgradeService: AccountUpgradeService
  ) {

  }
  username: string;
  isCASSWAPEnabled: boolean;
  ngOnInit(): void {
    this.userType = sessionStorage.getItem(USER_TYPE);
    sessionStorage.getItem(IS_CAS_SWAP_Enabled) == 'true'? this.isCASSWAPEnabled = true : this.isCASSWAPEnabled = false;
    if (+this.userType === UserType.Business) {
      this.isBusiness = true;
    }

    if (+this.userType === UserType.Broker) {
      this.isBroker = true;
    }

    if (+this.userType === UserType.Personal) {
      this.isPersonal = true;
    }
    this.isKycDone = sessionStorage.getItem(IS_KYC_DONE_PERSONAL);
    /*   this.username = sessionStorage.getItem(USER_NAME_PATH)
      ? sessionStorage.getItem(USER_NAME_PATH)
      : sessionStorage.getItem(USER_EMAIL_PATH); */
    this.username = sessionStorage.getItem(USER_EMAIL_PATH);
    if (this.isPersonal) {
      this.checkStakingUrl();
      this.kycDoneCheck();
    }

    this.upgradeService.getUserActionSubscriptionPlan().subscribe((data: UserSubscriptionPlan) => {
      if (data) {
        if (this.userType === '4') {
          if (SUBSCRIPTION_PERSONAL_PLAN.BASIC === data.planName) {
            this.isBasicPlan = true;
          }
          if (SUBSCRIPTION_PERSONAL_PLAN.PREMIUM === data.planName) {
            this.isPremiumPlan = true;
          }
        } else {
          if (SUBSCRIPTION_BUSINESS_PLAN.BASIC === data.planName) {
            this.isBasicPlan = true;
          }
          if (SUBSCRIPTION_BUSINESS_PLAN.PREMIUM === data.planName) {
            this.isPremiumPlan = true;
          }
        }
      }
    });
  }

  checkStakingUrl() {
    let filterData = {
      activityType: "14",
      fromDate: "",
      toDate: "",
      symbol: [],
      searchText: "",
    };
    this.activityService.getRecentTransactions(filterData).subscribe(
      (res) => {
        if (res.length !== 0) {
          this.stakingURL = APP_ROUTES.STAKING_TIMELINE;
          this.activeStaking = true;
          this.commonSavedData.setStakingUrl(this.stakingURL);
        } else {
          filterData.activityType = "11";
          this.activityService
            .getRecentTransactions(filterData)
            .subscribe((res) => {
              if (res.length !== 0) {
                this.stakingURL = APP_ROUTES.STAKING_TIMELINE;
                this.activeStaking = true;
                this.commonSavedData.setStakingUrl(this.stakingURL);
              } else {
                filterData.activityType = "";
                this.activityService
                  .getRecentTransactions(filterData)
                  .subscribe(
                    (res) => {
                      let flag = false;
                      res.forEach((dateRow) => {
                        dateRow.activitylist.forEach((row) => {
                          if (row.mondaylist !== null) {
                            flag = true;
                          }
                        });
                      });

                      if (flag) {
                        this.stakingURL = APP_ROUTES.STAKING_TIMELINE;
                        this.activeStaking = true;
                      } else {
                        this.stakingURL = APP_ROUTES.STAKING;
                      }
                      this.commonSavedData.setStakingUrl(this.stakingURL);
                    },
                    (err) => { }
                  );
              }
            });
        }
      },
      (err) => { }
    );
  }

  kycDoneCheck(flag?): void {
    if (!this.commonSavedData.getIsFirstKycCall() || flag) {
      this.isKycDone = sessionStorage.getItem(IS_KYC_DONE_PERSONAL);
      if (this.isKycDone === undefined || this.isKycDone === null) {
        this.kycService.getKycDetails().subscribe((res) => {
          if (res) {
            this.commonSavedData.setIsFirstKycCall(true);
            this.isKycDone = res.verified;
            if (this.isKycDone) {
              sessionStorage.setItem(IS_KYC_DONE_PERSONAL, this.isKycDone);
              if (flag) {
                this.router.navigateByUrl(APP_ROUTES.ACTIVITY_LIST);
              }
            }
          }
        });
      } else {
        this.isKycDone =
          sessionStorage.getItem(IS_KYC_DONE_PERSONAL) === "true";
      }
    }
  }
  goToRoute(route: string): void {
    this.router.navigateByUrl(route);
  }

  createHelpdeskTicket() {
    this.modalService.openTicketCreateModal();
  }

  /**
   * Logout out of account
   */
  logout(): void {
    this.activityService.refreshToken({'cashaaUserId': sessionStorage.getItem(USER_ID)}).subscribe(res => {
      if(res) {
        localStorage.clear();
    localStorage.setItem(SEESION_FLAG, "false");
    this.commonSavedData.setIsFirstKycCall(false);
    sessionStorage.clear();
    this.router.navigateByUrl(APP_ROUTES.SIGN_IN);
      }
    })
  }

  goToAccount(){
    this.router.navigateByUrl(`account-wallet?isBorrow=true`)
  }

  
}
