<form [formGroup]="createAddressWhitelistForm" class="my-4">
  <div>
    <cashaa-form-select name="currency" type="currency" [form]="createAddressWhitelistForm" [options]="options"
      [control]="createAddressWhitelistForm.controls.currency" placeholder="Enter Currency"></cashaa-form-select>
  </div>

  <div>
    <cashaa-form-input name="address" type="address" [form]="createAddressWhitelistForm"
      [control]="createAddressWhitelistForm.controls.addressLabel" placeholder="Receiving Address"></cashaa-form-input>
  </div>

  <div>
    <cashaa-form-input name="addressLabel" type="addressLabel" [form]="createAddressWhitelistForm"
      [control]="createAddressWhitelistForm.controls.address" placeholder="Email" type="email"></cashaa-form-input>
  </div>

  <div>
    <cashaa-form-input name="password" type="password" [form]="createAddressWhitelistForm"
      [control]="createAddressWhitelistForm.controls.password" placeholder="Enter Password"></cashaa-form-input>
  </div>

  <div class="position-relative" *ngIf="errorMessage">
    <mdb-error class="mt-2">{{ errorMessage }}</mdb-error>
  </div>

  <button mdbBtn (click)="onFormSubmit()" block="true" type="button"
    class="rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block" mdbWavesEffect>
    <i class="fa-lock fas position-absolute"></i>Whitelist Address
  </button>
</form>