import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UNABLE_TO_PERFORM_ERROR } from 'src/app/app.constants';
import { FileUpload, IFile } from 'src/app/core/models/Lerex/enum-load';
import { DomainService } from 'src/app/core/services/domain/domain.service';
import { ValidatorUtility } from 'src/app/core/utility/validator.utility';
import { KycService } from '../services/kyc.service';

@Component({
  selector: 'cashaa-kyc-personal-selfie-upload',
  templateUrl: './kyc-personal-selfie-upload.component.html',
  styleUrls: ['./kyc-personal-selfie-upload.component.scss']
})
export class KycPersonalSelfieUploadComponent implements OnInit {
  selfiefile: IFile = new FileUpload(null);
  form: FormGroup;
  identityFront: IFile;
  identityBack: IFile;
  addressfile: IFile;
  @Output() continue = new EventEmitter<any>();
  @Input() identityDocumentType;
  @Input() addressDocumentType;
  loader
  createKycDone
  completed
  constructor(private fb: FormBuilder, private domainService: DomainService, 
    private kycService: KycService, private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.selfiefile.docType = "SelfieFile";
    this.form = this.fb.group({
      selfie: ["", ValidatorUtility.Required],
    });

    this.domainService.getKycUploadFile().subscribe(data => {
      if (data.docType === "SelfieFile") {
        this.selfiefile = data;
        this.selfiefile.isDeletedValid = true;
      } else if (data.docType === "IdentityFront") {
        this.identityFront = data;
        this.identityFront.isDeletedValid = true;
      } else if (data.docType === "IdentityBack") {
        this.identityBack = data;
        this.identityBack.isDeletedValid = true;
      } else if (data.docType === "AddressFile") {
        this.addressfile = data;
        this.addressfile.isDeletedValid = true;
      }
    });
  }

  onDocumentSubmit(): void {
    const formData = new FormData();
    formData.append("selfie", this.selfiefile?.file);
    formData.append("identityfront", this.identityFront?.file);
    formData.append("identityback", this.identityBack?.file);
    formData.append("addressproof", this.addressfile?.file);
    formData.append("addressdocumentType", this.addressDocumentType);
    formData.append("identitydocumentType",this.identityDocumentType);
    this.loader = true;
    this.kycService.uplaodPersonalKycDocument(formData).subscribe(
      (res) => {
        this.loader = false;
        this.completed = true;
        this.toastrService.success("Documents Uploaded Successfully");
        this.createKycDone = true;
        this.continue.next(true);
      },
      (err) => {
        this.loader = false;
        if (err.error.message) {
          this.toastrService.error(err.error.message);
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
      }
    );
  }
}
