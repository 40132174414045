import { expressionType } from "@angular/compiler/src/output/output_ast";

export const CONTROLS = {
  TEXT: "text",
  NUMBER: "number",
  EMAIL: "email",
  PASSWORD: "password",
};

export const APP_ROUTES = {
  ACCOUNT_BASE: "/account",
  SIGN_IN: "/account/signin",
  MESG_BOX: "/account/mesg-box",
  SETTING_BASE: "/settings",
  SETTING_HOME: "/setting/home",
  SIGN_UP: "/account/signup",
  FORGET_PASSWORD: "/account/forget-password",
  TWO_FACTOR_AUTH: "/account/2fa",
  UNAUTHORIZED: "/unauthorized",
  FIAT_BASE: "/fiat",
  ACTIVITY_BASE: "/activity",
  RECIPIENT_BASE: "/recipient",
  ACTIVITY_LIST: "/activity/list",
  DIRECTOR_VERIFICATION: "kyc/director-verification",
  BUSINESS_ONBOARDING: "kyc/business-onboarding",
  BUSINESS_APPLICATION_STATUS: "kyc/application-status",
  WALLET_READY_TO_GO: "/wallet/ready",
  WALLET_CREDIT_LINE: "/credit-line",
  ACCOUNT_OPEN: "wallet/open",
  WALLET_HOME: "wallet",
  PREMIER_STACKING: "subscription/premier-staking",
  WITHDRAW_CONFIRMATION: "/withdraw-confirmation",
  PAYMENT_SUCCESS: "/payment-success",
  PAYMENT_FAIL: "/payment-fail",
  STAKING: "/subscription/staking",
  STAKING_TIMELINE: "/subscription/staking/timeline",
};

export const CRYPTO_CURRENCY = {
  BTC: "BTC",
  ETH: "ETH",
  USDT: "USDT",
  CAS: "CAS",
  BNB: "BNB",
  BCH: "BCH",
  LTC: "LTC",
  XRP: "XRP",
  ADA: "ADA",
  DOG: "DOG",
  EOS: "EOS",

};

export const CRYPTO_CURRENCY_NAMES = {
  BTC: "Bitcoin",
  CAS: "Cashaa",
  ETH: "Ethereum",
  USDT: "Tether",
};

export const CRYPTO_CURRENCY_MAX_VALUES = {
  BTC: 100,
  ETH: 4000,
  USDT: 100000,
  CAS: 10000000,
};

export const CRYPTO_CURRENCY_MIN_VALUES = {
  BTC: 0.01,
  ETH: 0.1,
  USDT: 100,
  CAS: 5000,
};

export const WALLET_CURRENCY = {
  INR: "INR",
  GBP: "GBP",
  EUR: "EUR",
  USD: "USD",
  AUSD: "AUSD",
};

export const ACCOUNT_TYPE = {
  BUSINESS: "b",
  PERSONAL: "p",
};

export const RATE_HUB = {
  HUB_NAME: "myHub",
  DATA_PATH: "sendMarketExchangeData",
};

export const CURRENCY_TYPE = {
  CRYPTO: "crypto",
  WALLET: "wallet",
  ALL: "all",
};

export const TRADE_MODE = {
  BUY: "buy",
  SELL: "sell",
};

export const TRANSFER_TYPE = {
  SEND: "send",
  RECEIVE: "receive",
};

export const NEW_RECIPIENT_TYPE = {
  MYSELF: "myself",
  SOMEONE: "someone",
  BUSINESS: "business",
};

export const SUBSCRIPTION_PERSONAL_PLAN = {
  BASIC: "Basic",
  PREMIUM: "Premium"
}

export const SUBSCRIPTION_BUSINESS_PLAN = {
  BASIC: "Business Basic",
  PREMIUM: "Business Premium"
}

export const WALLET_CURRENCY_NUM = {
  INR: 1,
  GBP: 2,
  EUR: 3,
  USD: 4,
  AUSD: 5,
  SGD: 18,//Singapore dollars
  AUD: 19,//Australian dollars
  CNH: 20,//Chinese Yuan Renminbi
  CAD: 21,//Canadian dollars
  CHF: 22,//Swiss francs
  NZD: 23,//New Zealand dollars
  JPY: 24,//Japanese yen
  MXN: 25,//Mexican pesos
  SHIB: 26//SHIBA INU
};

export const CRYPTO_CURRENCY_NUM = {
  BTC: 2,
  ETH: 3,
  BNB: 4,
  CAS: 5,
  USDT: 6,
  BCH: 7,
  LTC: 8,
  XRP: 9,
  ADA: 10,
  DOG: 11,
  EOS: 12,
  LINK: 16,
  UNI: 17,
  USDC: 18,
  BNB20: 14
};

export const RegisterType = {
  web: 1,
  android: 2,
  iOS: 3
}

export const UNABLE_TO_PERFORM_ERROR =
  "Unable to perform the operation because of some error.";
export const AUTH_TOKEN_PATH = "cashaa/authtoken";
export const USER_NAME_PATH = "cashaa/username";
export const USER_EMAIL_PATH = "cashaa/useremail";
export const IS_KYC_DONE_PERSONAL = "cashaa/iskycdonepersonal";
export const SYMBOL_FOR_MEMO = "CAS";
export const USER_ID = "cashaa/userid";
export const USER_TYPE = "cashaa/usertype";
export const USER_NAME_ID = "cashaa/usernameid";
export const USER_FEE_MODE = "cashaa/userfeemode";
export const USER_INTEREST_MODE = "cashaa/userinterestmode";
export const USER_FEE_PERCENT = "cashaa/userfeepercent";
export const USER_CAS_FEE_PERCENT = "cashaa/usercasfeepercent";
export const USER_PLAN = "cashaa/userplan";
export const USER_CAS_FEE_RATE = "cashaa/usercasfeerate";
export const USER_TWO_FACTOR_CODE = "cashaa/twofactorcode";
export const USER_COUNTRY_CODE = "cashaa/userCountryCode";
export const USER_REFERRAL_CODE = "cashaa/referralCode";
export const IS_CAS_SWAP_Enabled = "cashaa/isCASSWAPEnabled";
export const GET_ALL_HELPDESK_TICKETS =
  "helpdesktickets​/getallhelpdesktickets";
export const SEESION_FLAG = "cashaa/sessionFlag";
export const SEESION_OBJECT = "cashaa/sessionObject";
export const ENABLE_2FA = "2FA is successfully enabled.";
export const PASSWORD_MESG = "Password changed successfully.";
export const VERIFICATION_MESG =
  "Verification link sent successfully. Please check your email.";
export const RECIPIENT_MESG = "Recipient added successfully.";
export const RECIPIENT_EMAIL_MESG =
  "Recipient added successfully. Please verify address in your email.";
export const PROFILE_MESG = "Profile updated successfully.";
export const DATA_SUCCESS_MESG = "Data saved successfully.";
export const FILE_SUCCESS_MESG = "File uploaded successfully.";
export const FILE_DELETE_MESG = "File deleted successfully.";
export const FEE_SUCCESS_MESG = "Fee payment successful.";
export const FEE_ALREADY_PAID = "Fee already paid.";
export const TICKET_CREATED_SUCCESS =
  "New ticket created successfully. Please check your email.";
export const KYC_VERIFACTION_MESG = "KYC verification link sent successfully.";
export const CUSTOMER_ADDED_SUCCESS = "Customer added successfully.";
export const CUSTOMER_UPDATED_SUCCESS = "Customer updated successfully.";
export const FEE_MODE_UPDATED = "Fee mode successfully updated.";
export const INTEREST_MODE_CREATED = "Interest Mode successfully updated";
export const BUSINESS_UPGRADE_SUBSCRIPTION =
  "You have successfully staked for the USD account.";
export const PERSONAL_UPGRADE_SUBSCRIPTION =
  "You have successfully staked for the worldwide staking";
export const TRANSACTION_SUCCESS_CANCELLED =
  "Transaction successfully cancelled";
export const RECIPIENT_DELETED_SUCCESS = "Recipient delete successfully";
export const CUSTOMER_DELETED_SUCCESS = "Beneficiary delete successfully";
export const CANCEL_AUTO_RENEWAL_SUCCESS = "Auto Renewal Cancel Successfully";
export const APPLICATION_FORM_CORPORATE_ACCOUNT_GBP_EUR = "https://cashaa-cdn.cashaa.com/KYC_Business/GBP-EUR-Cashaa-Corporate-Account-opening.pdf";
export const APPLICATION_FORM_NDA_GBP_EUR = "https://cashaa-cdn.cashaa.com/KYC_Business/Mutual-non-disclosure-agreement-template.pdf";
export const APPLICATION_FORM_ACCOUNT_OPPENING_USD = "https://cashaa-cdn.cashaa.com/KYC_Business/USD-Cashaa-Account-Opening-Form-with-CDD.pdf";
export const DEPOSIT_INTEREST_SUCCESS = "Fixed Deposit Successfully Created";
export const DELETE_REFERRAL_SUCCESS = "Referral deleted successfully";
export const RESEND_REFERRAL_SUCCESS = "Resend referral successfully"
export const API_MANAGER_SEND_CODE = "Code sent successfully. Please check your email.";
export const SILVER_PLAN = "Silver";
export const GOLD_PLAN = "Gold";
export const DIAMOND_PLAN = "Diamond";
export const BASE_PLAN = 'Base';
export const MEMO_UPDATED = 'Memo updated successfully';
export const DEPOSIT_TIMING = "00:29";
export const TICKET_CREATED_TIME = "cashaa/ticketCreatedTime";
