import { KycService } from "src/app/kyc/services/kyc.service";
import { EmailVerificationRequest } from "./../../core/models/kyc-verification-request-model";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { UNABLE_TO_PERFORM_ERROR } from "src/app/app.constants";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "cashaa-kyc-verification-email",
  templateUrl: "./kyc-verification-email.component.html",
  styleUrls: ["./kyc-verification-email.component.scss"],
})
export class KycVerificationEmailComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private kycService: KycService,
    private toastrService: ToastrService
  ) {}
  form: FormGroup;
  bkyid: string;
  email: string;
  bType: string;
  response?: string;
  ngOnInit(): void {
    this.createForm();
    this.getqueryStringValue();
  }

  createForm(): void {
    this.form = this.fb.group({});
  }
  getqueryStringValue(): void {
    this.bkyid = this.route.snapshot.queryParamMap.get("id");
    this.email = this.route.snapshot.queryParamMap.get("email");
    this.bType = this.route.snapshot.queryParamMap.get("type");
    const data = new EmailVerificationRequest(
      this.bkyid,
      this.email,
      this.bType
    );

    this.kycService.verifyBusinessUserEmail(data).subscribe(
      (res) => {
        this.response = res.message;
      },
      (err) => {
        if (err.error.message) {
          this.toastrService.error(err.error.message);
          this.response = err.error.message;
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
      }
    );
  }
}
