import { Router } from "@angular/router";
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef, AfterViewInit } from "@angular/core";
import { ModalService } from "src/app/core/services/modal/modal.service";
import { UserService } from "src/app/core/services/user/user.service";
import { DepositInterestService } from "src/app/deposit-interest/service/deposit-interest.service";
import { IDepositInterestPlan } from "src/app/core/models/deposit-interest/deposit-interest";
import { USER_COUNTRY_CODE, USER_INTEREST_MODE, USER_TYPE } from "src/app/app.constants";
import { IUserPlan } from "src/app/core/models/user-plan/user-detail";
import { MDBModalService } from "ng-uikit-pro-standard";
import { ActivityService } from "src/app/activity/services/activity.service";

@Component({
  selector: "cashaa-balance-box",
  templateUrl: "./balance-box.component.html",
  styleUrls: ["./balance-box.component.scss"],
})
export class BalanceBoxComponent implements OnInit {

  /** Currency type */
  @Input() currency: string;

  @Input() currencyId: string;

  /** balance */
  @Input() balance = 0;

  @Input() isFDActive: boolean;
  @Input() creditInterest;
  @Input() isBorrowActive;
  diamondPlan: IUserPlan;
  rate: number;
  allCurrenciesPlan: IUserPlan[] = [];
  rateType: string = sessionStorage.getItem(USER_INTEREST_MODE);
  userType = sessionStorage.getItem(USER_TYPE);
  currencyDepositRate: number[] = [];
  maxInterestModeRate: number[] = [];
  currencyDepositRateLessThanEqual365: number[] = [];
  currencyDepositRateGreaterThan365: number[] = [];
  @Output() emitCurrency = new EventEmitter<any>();
  @Input() onHold: number;
  @Input() currencyNature: number;
  @Input() minFlexAmount;
  lockedInfo;
  constructor(private router: Router, private modalService: ModalService, private mdbModalService: MDBModalService
     ,private userService: UserService, private depositService: DepositInterestService,
     private activityService: ActivityService) { }

  ngOnInit(): void {

    // if (!this.isEarnAndFixedDepositActive()) {
    if (this.isFixedDepositEnable()) {
      this.loadFixedDepositRate();
    } else if (this.isFixedDepositAndEarnEnable()) {
      if (this.currency === 'CAS') {
        this.loadFixedDepositRate();
      } else {
        let rate: number;
        this.userService.getPlanDetailsByCurrencyId(this.currencyId).subscribe((data: IUserPlan[]) => {
          if (data.length > 0) {
            this.allCurrenciesPlan = data;
            this.diamondPlan = data.filter((plan: IUserPlan) => plan.loyaltyPlanName === 'Diamond')[0];
            rate = Math.trunc(this.diamondPlan.casRate) + Math.trunc(this.diamondPlan.kindRate);
          }
          // if (!this.isEarnModuleNotActive()) {
          this.depositService.getByDepositFDCurrency(this.currencyId).subscribe((data: IDepositInterestPlan[]) => {
            if (data) {
              const plans: IDepositInterestPlan[] = data.filter((plan) => plan.isActive && plan.isDefault);
              const plangreaterThan365: IDepositInterestPlan[] = data.filter((plan) => plan.isActive && plan.isDefault && plan.duration > 365);
              const planLessThanEqual365: IDepositInterestPlan[] = data.filter((plan) => plan.isActive && plan.isDefault && plan.duration <= 365);
              plans.forEach(plan => {
                if (plan.rateType == (this.rateType === 'InCas' ? 2 : 1)) {
                  this.maxInterestModeRate.push(plan.rate)
                }
                this.currencyDepositRate.push(plan.rate)
              });
              plangreaterThan365.forEach(plan => {
                this.currencyDepositRateGreaterThan365.push(plan.rate)
              });
              planLessThanEqual365.forEach(plan => {
                this.currencyDepositRateLessThanEqual365.push(plan.rate)
              });
              if (rate) {
                this.currencyDepositRate.push(rate);
              }
              if (this.currencyDepositRate.length > 0) {
                this.rate = Math.max(...this.currencyDepositRate);
              }
            }
          });
          // } else {
          //   this.rate = rate;;
          // }
        });
      }
    }
    // }
  }

  loadFixedDepositRate() {
    // if (!this.isEarnModuleNotActive()) {
    this.depositService.getByDepositFDCurrency(this.currencyId).subscribe((data: IDepositInterestPlan[]) => {
      if (data.length > 0) {
        const plans: IDepositInterestPlan[] = data.filter((plan) => plan.isActive && plan.isDefault);
        plans.forEach(plan => {
          if (plan.rateType == (this.rateType === 'InCas' ? 2 : 1)) {
            this.maxInterestModeRate.push(plan.rate);
          }
          this.currencyDepositRate.push(plan.rate);
        });
        if (this.currencyDepositRate.length > 0) {
          this.rate = Math.max(...this.currencyDepositRate);
        }
      }
    });
    // }

  }

  // goToCurrency(): void {
  //   if(this.currency === 'GBP' || this.currency === 'USD' || this.currency === 'EUR') {
  //     this.router.navigateByUrl(`/deposit?currency=${this.currency}`);
  //   } else {
  //     this.router.navigateByUrl(`/wallet?c=${this.currency}#${this.currency}`);
  //   }  
  // }

  goToAccount() {
    this.router.navigateByUrl(`/wallet?c=${this.currency}#${this.currency}`);
  }

  getCurrencyName(curr): string {
    if (curr === "AUSD") return "USD";
    if (curr === "DOG") return "DOGE";
    else return curr;
  }

  openEarnPlan(): void {
    if (this.currency === 'CAS') {
      this.router.navigateByUrl(`deposit-interest?c=${this.currency}`);
    } else {
      this.modalService.openEarnPlanDetailPopup(this.allCurrenciesPlan, this.diamondPlan, this.balance, this.currencyId, this.currency, this.currencyDepositRate,
        this.isFixedDepositEnable(), this.isFixedDepositAndEarnEnable(), this.maxInterestModeRate, false, this.currencyDepositRateLessThanEqual365, this.currencyDepositRateGreaterThan365, this.minFlexAmount);
    }

  }

  isEarnAndFixedDepositActive(): boolean {
    return sessionStorage.getItem(USER_COUNTRY_CODE) !== 'US';
  }

  isFixedDepositEnable(): boolean {
    // sessionStorage.getItem(USER_TYPE) !== '4' || this.currency === 'CAS'
    return this.currency === 'CAS';
  }

  isFixedDepositAndEarnEnable(): boolean {
    // return sessionStorage.getItem(USER_TYPE) === '4' &&  this.currency !== 'CAS'
    // const userType = sessionStorage.getItem(USER_TYPE);
    return (this.userType === '4' || this.userType === '5' || this.userType === '6') && this.currency !== 'CAS';
  }

  // isEarnModuleNotActive(): boolean {
  //   // return sessionStorage.getItem(USER_TYPE) === '4' && this.currency === 'DOG';
  // }

  isHide(): boolean {
    if (this.rate && this.rate > 0) {
      return false;
    } else {
      return true;
    }
  }
  selectCurrency(event) {
    const data = {
      currency: this.currency,
      isSelected: event.checked
    }
    this.emitCurrency.emit(data);
  }
  openBorrowFlow() {
    this.router.navigateByUrl(`credit-line?c=${this.currency}`);
  }

  open() {
    this.activityService.getLockedAmountByCurrency(this.currencyId).subscribe(res => {
      this.lockedInfo = res;
    })

  }
}
