export interface IDirectorVerification {
    directorId: string;

}


export class DirectorVerification implements IDirectorVerification {
    constructor(
        public directorId: string

    ) { }
}