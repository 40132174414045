import { Component, OnInit } from "@angular/core";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ValidatorUtility } from "src/app/core/utility/validator.utility";

@Component({
  selector: "cashaa-fiat-flow-recipient-add-business",
  templateUrl: "./fiat-flow-recipient-add-business.component.html",
  styleUrls: ["./fiat-flow-recipient-add-business.component.scss"],
})
export class FiatFlowRecipientAddBusinessComponent implements OnInit {
  /** form group */
  form: FormGroup;

  constructor(private fb: FormBuilder, public modalRef: MDBModalRef) {
    this.createEmptyForm();
  }

  ngOnInit(): void {}

  createEmptyForm(): void {
    this.form = this.fb.group({
      email: [""],
      name: [""],
      countryId: [""],
      city: [""],
      address: [""],
      zipcode: [""],
      state: [""],
      bankCountryId: [""],
      routingNumber: [""],
      accountNumber: [""],
      accountType: ["c"],
    });
  }

  /**
   * On submission of form
   */
  onFormSubmit(): void {}
}
