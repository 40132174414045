import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ActivityHomeComponent } from "./activity-home/activity-home.component";
import { ActivityWelcomeComponent } from "./activity-welcome/activity-welcome.component";
import { ActivityBalanceListComponent } from "./activity-balance-list/activity-balance-list.component";
import { ActivityBalanceRecievedComponent } from "./activity-balance-recieved/activity-balance-recieved.component";
import { ActivityBalanceTransferComponent } from "./activity-balance-transfer/activity-balance-transfer.component";
import { ActivityBalanceAttentionComponent } from "./activity-balance-attention/activity-balance-attention.component";
import { ActivityBalanceAttentionCancelTrasnferComponent } from "./activity-balance-attention-cancel-transfer/activity-balance-attention-cancel-transfer.component";
import { ActivityBalanceCancelledComponent } from "./activity-balance-cancelled/activity-balance-cancelled.component";
import { ActivityBalanceConvertedComponent } from "./activity-balance-converted/activity-balance-converted.component";
import { ActivityBalanceSpentComponent } from "./activity-balance-spent/activity-balance-spent.component";
import { ActivityBalanceWithdrawnComponent } from "./activity-balance-withdrawn/activity-balance-withdrawn.component";
import { AuthGuardService } from "./../core/services/auth-guard/auth-guard.service";
const routes: Routes = [
  {
    path: "activity",
    component: ActivityHomeComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "welcome",
        component: ActivityWelcomeComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "list",
        component: ActivityBalanceListComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "recieved",
        component: ActivityBalanceRecievedComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "transfer",
        component: ActivityBalanceTransferComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "attention",
        component: ActivityBalanceAttentionComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "attention/cancel-transfer",
        component: ActivityBalanceAttentionCancelTrasnferComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "cancelled",
        component: ActivityBalanceCancelledComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "converted",
        component: ActivityBalanceConvertedComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "spent",
        component: ActivityBalanceSpentComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "withdrawn",
        component: ActivityBalanceWithdrawnComponent,
        canActivate: [AuthGuardService],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class ActivityRoutingModule { }
