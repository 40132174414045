<section class="py-2 w-100 mx-auto">
  <h4 class="text-primary-dark font-weight-bolder mb-5 text-center">
    Who are you sending {{symbol}} to?
  </h4>
  <p class="m-4 font-weight-bolder text-primary-dark">Existing address
    <ng-container *ngFor="let nt of networkTypeOptions">
      <span *ngIf="nt.value === fiatFlowData.networkType">({{nt.label}})</span>
      </ng-container>
     </p>
  <!-- <hr /> -->

  <!-- <h6 *ngIf="fiatFlowData?.fromcurrencyObject.currencyType == '1'" class="heading m-4"></h6> -->
  <!-- <mdb-tabset *ngIf="fiatFlowData?.fromcurrencyObject.currencyType == '1'"
  [buttonClass]="'classic-tabs'"
  [contentClass]="'card'"
  class="classic-tabs"> -->
<!-- <ng-container *ngFor="let nt of networkTypeOptions"> -->
  <!-- <div *ngIf="nt.value === fiatFlowData.networkType">{{nt.label}}</div> -->
   <!-- <mdb-tab heading="{{nt.label}}" (select)="getRecipientList(symbol, nt.value, data.recipientType)" [disabled]="nt.value !== fiatFlowData.networkType"> -->
    <!-- <div class="row">
      <div class="col-12">
        <div
          class="d-flex justify-content-between mx-5 my-4 bdr"
          *ngFor="let item of acceptedList"
        >
          <div class="d-flex">
            <img
              class="icon-img bdr-right p-3"
              [src]="'../../../assets/images/deposit_' + item.currency + '.svg'"
            />
            <div class="text-center bdr-right p-3">
              <p class="font-weight-bolder mb-1">
                {{ item.depositDate | date: "MMM" }}
              </p>
              <p class="font-weight-normal mb-0">
                {{ item.depositDate | date: "dd" }}
              </p>
            </div>
            <div class="bdr-right p-3">
              <p class="font-weight-bolder mb-1">
                Received {{ item.currency }}
              </p>
              <p class="font-weight-normal mb-0">{{ item.transactionId }}</p>
            </div>
          </div>
          <p class="font-weight-bolder mb-1 p-3">
            +{{ item.amount | number: "0.2" }}
          </p>
        </div>
      </div>
    </div> -->
  <!-- </mdb-tab> -->
  <!-- <mdb-tab heading="BEP20 (Binance Chain)" (select)="getRecipientList(symbol, 2, data.recipientType)">    
  </mdb-tab> -->
  <!-- <mdb-tab heading="Polygon" (select)="getRecipientList(symbol, 3, data.recipientType)">

  </mdb-tab> -->
<!-- </ng-container> -->
<!-- </mdb-tabset> -->

  <div class="p-4 text-center" [hidden]="!loading">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
  </div>
  <div class=" justify-content-between mb-5">
    <h4 class="sm-align-font sm-custom-link" *ngIf="(recipients?.length===0 || !recipients) && !loading && symbol && fiatFlowData?.fromcurrencyObject.currencyType == '1'"
        (click)="openAddRecipientOther()">
      Add {{symbol}} Address
    </h4>
    <h4 class="sm-align-font sm-custom-link" *ngIf="(recipients?.length===0 || !recipients) && !loading && symbol
       && fiatFlowData?.fromcurrencyObject.currencyType == '2'"
        (click)="openAddRecipientOther()">
      Add Bank Account
    </h4>
    <cashaa-recipient-box *ngFor="let recipient of recipients" [recipient]="recipient"
                          (click)="selectRecipients(recipient)"></cashaa-recipient-box>
  </div>

  <p *ngIf="fiatFlowData?.fromcurrencyObject.currencyType == '2'" class="font-weight-bolder text-primary-dark m-4">New recipient</p>
  <p *ngIf="fiatFlowData?.fromcurrencyObject.currencyType == '1'" class="font-weight-bolder text-primary-dark m-4">New Address</p>
  <hr class="mx-4"/>
  <div class="d-flex justify-content-between flex-wrap" *ngIf="fiatFlowData?.fromcurrencyObject.currencyType == '2'">
    <cashaa-recipient-box [isNew]="true" type="myself" (click)="openAddRecipient()"></cashaa-recipient-box>
    <cashaa-recipient-box [isNew]="true" type="someone" (click)="openAddRecipientOther()"></cashaa-recipient-box>
    <!-- <cashaa-recipient-box [isNew]="true" type="business" (click)="openAddRecipientCharity()"></cashaa-recipient-box> -->
  </div>
  <div *ngIf="fiatFlowData?.fromcurrencyObject.currencyType == '1'">
    <cashaa-recipient-box [isNew]="true" type="walletAddress" (click)="openAddRecipient()"></cashaa-recipient-box>
  </div>
</section>
