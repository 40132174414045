import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
  OnDestroy,
} from "@angular/core";
import { AccountService } from "../sevices/account.service";
import { Signin } from "src/app/core/models/signin";
import { Signin2fa } from "src/app/core/models/signin-2fa";
import { UserService } from "src/app/core/services/user/user.service";
import * as Bowser from "bowser";
import { IpInfo } from "src/app/core/models/ipinfo";
import { AuthStatus } from "src/app/core/enums/authStatus";
import { APP_ROUTES } from "src/app/app.constants";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { SubSink } from "subsink";

@Component({
  selector: "cashaa-account-two-factor-authentication",
  templateUrl: "./account-two-factor-authentication.component.html",
  styleUrls: ["./account-two-factor-authentication.component.scss"],
})
export class AccountTwoFactorAuthenticationComponent
  implements OnInit, OnDestroy {
  /** google authentication code */
  code: string[] = ["", "", "", "", "", ""];

  /** is checking operation in progress */
  isInProgress = false;

  @ViewChildren("inputs") inputs: QueryList<ElementRef>;

  /** singin data */
  singinData?: Signin;
  /** error message when failed */
  errorMessage: string;
  subSink = new SubSink();
  constructor(
    private userService: UserService,
    private accountService: AccountService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.accountService.setDisableCross(false);
    this.subSink.add(
      this.accountService.getSigninDataEmitter().subscribe((singinData) => {
        if (singinData) {
          this.singinData = singinData;
        } else {
          this.router.navigateByUrl(APP_ROUTES.SIGN_IN);
        }
      })
    );
  }

  removeError(): void {
    if (this.errorMessage !== undefined) {
      this.errorMessage = undefined;
    }
  }

  /**
   * Check if google input authenticator
   * code is filled in all 6 boxes
   */
  checkIfCodeIsFilled(idx: number): void {
    if (idx >= 0 && idx < 5 && this.code[idx] != null) {
      this.inputs.toArray()[idx + 1].nativeElement.focus();
    }

    let allCodeEntered = true;
    this.code.forEach((val) => {
      if (val === null || val.length === 0) {
        allCodeEntered = false;
      }
    });

    if (allCodeEntered) {
      this.isInProgress = true;
      this.errorMessage = "";
      const code = this.code.join("");
      this.authenticateCode(code);
    }
  }

  /**
   * Authenticate 2fa code
   */
  authenticateCode(code: string): void {
    this.singinData.otp = code;

    this.accountService.signinAccount(this.singinData).subscribe(
      (res) => {
        if (res.authStatus === AuthStatus.Success) {
          this.router.navigateByUrl(APP_ROUTES.ACTIVITY_LIST);
        }
      },
      (err: HttpErrorResponse) => {
        this.isInProgress = false;
        this.errorMessage = err.error.message;
        this.code = ["", "", "", "", "", ""];
        this.singinData.otp = " ";
      }
    );
  }

  /**
   * On code paste automatically
   * fill all 6 boxes
   */
  onCodePaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData("text");

    if (pastedText && pastedText.length === 6) {
      this.code = pastedText.split("");
      this.checkIfCodeIsFilled(-1);
    }
  }

  removeBack(event: any, index: number) {
    const keyCode = event.keyCode;
    if (keyCode === 8) {
      this.inputs.toArray()[index].nativeElement.focus();
    }
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}
