export const environment = {
  production: true,
  api: "https://api.prod.cashaa.com/api/v1",
  rateWebsocket: "",
  ipInfo: "https://ipinfo.io/?token=de58bb1860f4f7",
  whiteListUserId: "34b15f2b-fdad-4c05-8a37-12538ae2e100,ace9e0a0-5365-432f-8540-f694eab92521,babd0381-f60d-4464-85d7-e0e36db3c768,acf0bbb5-558e-4be7-82da-e0273d2e7a20",
  CAS: "https://explorer.binance.org/tx/",
  BNB: "https://explorer.binance.org/tx/",
  BCH: "https://blockchair.com/bitcoin-cash/transaction/",
  XRP: "https://blockchair.com/ripple/transaction/",
  LTC: "https://blockchair.com/litecoin/transaction/",
  DOG: "https://blockchair.com/dogecoin/transaction/",
  ETH: "https://blockchair.com/ethereum/transaction/",
  BTC: "https://blockchair.com/bitcoin/transaction/",
  USDT: "https://blockchair.com/ethereum/transaction/",
  LINK: "https://etherscan.io/tx/",
  USDC: "https://etherscan.io/tx/",
  UNI: "https://etherscan.io/tx/",
  NETWORK_ERC20: "https://etherscan.io/tx/",
  NETWORK_BEP20: "https://bscscan.com/tx/",
  NETWORK_POLYGON: "https://polygonscan.com/tx/",
  NETWORK_TRC20: "https://tronscan.org/#/transaction/",
  HEADER_SIGN_KEY: "IntcIml2XCI6XCJXdU9wTU9wUFNYZTVXL3V3UnZGN2J3PT1cIixcInZhbHVlXCI6XCJTd21MNzkvbnY1WDlzNVIvR21COWI0cDRzQksrdS9aVXVuY09BK3Y1bkNZPVwiLFwibWFjXCI6XCJlZTA2ZGViYWI2NmNiY2UxMTg5ZTQ5OWVkZjdhZDgwZmYyYTU3NDc5Yzk0YmQ2N2RkNjljZTNlZDRhZTkxM2UzXCJ9Ig==",
  CASHAA_USER_ID_TRC_ENABLED: "4f99ef1e-a8f3-4db0-bc75-678a4fcac4db"
};
