import { PasswordType } from "./fIat/fiat-pay";

export interface IWithdrawFundRequest {
  currencyId: string;
  currency: string;
}

export class WithdrawFundRequest implements IWithdrawFundRequest {
  constructor(public currencyId: string, public currency: string) {}
}

export interface IWithdrawFundResponse {
  currencyId: string;
  address: string;
  currency: string;
  memo: string;
}
export class WithdrawFundResponse implements IWithdrawFundResponse {
  constructor(
    public currencyId: string,
    public address: string,
    public currency: string,
    public memo: string
  ) {}
}

export interface ICreateWithdrawRequest {
  currency: string;
  toAddress: string;
  memo?: string;
  amount: number;
  networkType?: string
}

export class CreateWithdrawRequest implements ICreateWithdrawRequest {
  constructor(
    public currency: string,
    public toAddress: string,
    public amount: number,
    public memo?: string,
    public networkType?: string,
    public password?: PasswordType,
    public isrecipientSkiped?: boolean
  ) {}
} 
export interface IWithdrawConfirmation {
  token: string;
}

export class WithdrawConfirmation implements IWithdrawConfirmation{
  constructor(public token: string) {}
}
