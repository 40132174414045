<cashaa-sidenav>
  <section class="mh-100">
    <div class="bg-white p-4">
      <div class="switch-sec ml-auto font-weight-normal mt-2 px-4"> Hide Zero Balance Assets <label class="switch"> 
        <input checked="true" type="checkbox" [(ngModel)]="hideZeroBalance" (click)="filterNonZeroBalance()"
        >
          <span class="slider round"></span> </label>
      </div>
   
      <div class="d-flex sm-phone-display-none mr-3" style="height: 12px;"></div>
    </div>
  </section>

  <div class="d-flex mx-5 mt-4 bg-white p-3"  *ngIf="localBankFiatCurrencyList.length > 0 && !loading && (!hideZeroBalance || isBalance)">
    <h6 class="heading">
     Assets
    </h6>
    <!-- <div class="col-lg-6 col-md-6 m-auto"> -->
  </div>
  <section  class="banks fiat-section pr-1 mx-5 sm-width-phone-700">
    <mdb-accordion *ngFor="let item of localBankFiatCurrencyList; let idx = index">
      <mdb-accordion-item>
        <account-box [idx]="idx" [currency]="item.currencyType" [balance]="item.available"
          [currencyId]="item.currencyId" [isDepositActive]="item.isDepositActive" [isSendActive]="item.isSendActive"
          [item]="item">
        </account-box>
        <mdb-accordion-item-body>
          <div class="px-5 mx-5">
            <mdb-tabset #staticTabs [buttonClass]="'nav md-pills pills-primary mb-2'" [justified]="true"
              class="recipientTab">
              <mdb-tab heading={{getTabHeaderData1(item.currencyType)}}>
                <div class="background px-5 py-4" *ngIf="!cryptoLoading && !accountDetailsCryptoData">
                  <h4 class="text-dark mb-4 font-weight-bolder">
                    Get your {{ getCurrencyName(item.currencyType) }} address
                  </h4>
                  <p class="text-dark font-weight-normal">
                    With {{ getCurrencyName(item.currencyType) }} address, people can send
                    {{ getCurrencyName(item.currencyType) }} to your account.
                  </p>
                  <div class="mt-4">
                    <button class="btn btn-outline-primary rounded text-primary btn-sm"
                      (click)="onAccountReadyPage(item, 'Crypto')">
                      Get address
                    </button>
                  </div>
                </div>
                <div class="px-5 mx-5 mt-3 text-center" *ngIf="cryptoLoading">
                  <mdb-spinner spinnerColor="blue"></mdb-spinner>
                </div>
                <div *ngIf="accountDetailsCryptoData">
                  <div class="py-4 pb-0">
                    <p class="font-weight-bold text-primary-dark">
                      Your Blockchain details to receive {{ item.currencyType }}
                    </p>
                    <!-- <p class="font-weight-normal text-dark">
                      Use your {{ item.currencyType }} address to receive
                      {{ item.currencyType }}.
                    </p> -->
                  </div>
                  <div class="row background px-5 py-4">
                    <div class="d-flex justify-content-center w-100">
                      <!-- <div class="w-75"> -->
                      <!-- <h5 class="text-dark mb-3 font-weight-bolder">
                          {{ item.currencyType }} Deposit
                        </h5> -->
                      <!-- </div> -->
                      <div class="f-shrink-1 p-1" style="background-color: white;"
                        *ngIf="item.currencyType === 'BNB' || item.currencyType==='CAS' || item.currencyType==='XRP'">
                        <span class="mb-3 font-weight-normal">
                          <mdb-icon far icon="question-circle"></mdb-icon> <a class="p-1" target="_blank"
                            routerLinkActive="customactive"
                            href="https://help.cashaa.com/knowledgebase/how-to-deposit-cryptocurrencies-to-your-cashaa-account/">How
                            to deposit?</a>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="mb-5">
                        <div class="input-group mb-3 sm-nowrap">
                          <div class="sm-qr-box sm-padding-qr-box">
                            <div class="sm-text-center">
                              <h4 class="sm-heading-text">Address</h4>
                            </div>
                            <div class="sm-qr-box-inner">
                              <qr-code [size]="200" [value]="accountDetailsCryptoData.address"></qr-code>
                            </div>
                            <div class="sm-qr-input-box">
                              <span class="sm-spam-text sm-span-text-padding">{{accountDetailsCryptoData.address
                                }}</span>
                              <div class="input-group-append">
                                <span class="sm-input-group-text"><i mdbTooltip="Copy address" placement="top"
                                    class="fas fa-clone sm-copy-color" [ngClass]="copied ? 'text-primary' : ''"
                                    (click)="copyAddress()"></i></span>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="item.currencyType == 'CAS' || item.currencyType == 'BNB' || item.currencyType == 'XRP'"
                            class="input-group mb-3 pl-4 sm-border-line">
                            <div class="sm-qr-box">
                              <div class="sm-text-center">
                                <h4 class="sm-heading-text">{{item.currencyType == 'XRP' ? 'TAG': 'MEMO' }}</h4>
                              </div>
                              <div class="sm-qr-box-inner">
                                <qr-code [size]="200" [value]="accountDetailsCryptoData.memo"></qr-code>
                              </div>
                              <div class="sm-qr-input-box">
                                <span class="sm-spam-text">{{accountDetailsCryptoData.memo}}</span>
                                <div class="input-group-append">
                                  <span class="sm-input-group-text"><i mdbTooltip="Copy memo" placement="top"
                                      class="fas fa-clone sm-copy-color" [ngClass]="copiedMemo ? 'text-primary' : ''"
                                      (click)="copyMemoAddress()"></i></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p class="text-dark font-weight-bolder">Important</p>
                      <ul class="text-dark font-weight-bolder ul-not">
                        <li>
                          Send only {{getContentData(item.currencyType)}} to this
                          address. Sending
                          any other coins or token to this address may result in the
                          loss of your deposit.
                        </li>
                        <li>
                          Coins will be deposited immediately after 3 network
                          confirmation.
                        </li>
                        <li>
                          After making a deposit, you can track its progress on the
                          reports.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </mdb-tab>
              <mdb-tab heading="BEP-20" *ngIf="!(item.currencyType === 'BTC' || item.currencyType === 'BNB')">
                <div class="background px-5 py-4" *ngIf="!cryptoLoading && !accountDetailsETHCryptoData">
                  <h4 class="text-dark mb-4 font-weight-bolder">
                    Get your {{ getCurrencyName(item.currencyType) }} address
                  </h4>
                  <p class="text-dark font-weight-normal">
                    With {{ getCurrencyName(item.currencyType) }} address, people can send
                    {{ getCurrencyName(item.currencyType) }} to your account.
                  </p>
                  <div class="mt-4">
                    <button class="btn btn-outline-primary rounded text-primary btn-sm"
                      (click)="onAccountReadyPage(item, 'Crypto', 'BEP-20')">
                      Get address
                    </button>
                  </div>
                </div>
                <div class="px-5 mx-5 mt-3 text-center" *ngIf="cryptoLoading">
                  <mdb-spinner spinnerColor="blue"></mdb-spinner>
                </div>
                <div *ngIf="accountDetailsETHCryptoData">
                  <div class="py-4 pb-0">
                    <p class="font-weight-bold text-primary-dark">
                      Your Blockchain details to receive {{ item.currencyType }}
                    </p>
                    <!-- <p class="font-weight-normal text-dark">
                      Use your {{ item.currencyType }} address to receive
                      {{ item.currencyType }}.
                    </p> -->
                  </div>
                  <div class="row background px-5 py-4">
                    <div class="d-flex justify-content-center w-100">
                      <!-- <div class="w-75">
                        <h5 class="text-dark mb-3 font-weight-bolder"> -->
                      <!-- {{ item.currencyType }} Deposit -->
                      <!-- </h5>
                      </div> -->
                      <div class="f-shrink-1 p-2" style="background-color: white;"
                        *ngIf="item.currencyType === 'BNB' || item.currencyType==='CAS' || item.currencyType==='XRP'">
                        <span class="mb-3 font-weight-normal">
                          <mdb-icon far icon="question-circle"></mdb-icon> <a class="p-1" target="_blank"
                            routerLinkActive="customactive"
                            href="https://help.cashaa.com/knowledgebase/how-to-deposit-cryptocurrencies-to-your-cashaa-account/">How
                            to deposit?</a>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="mb-5">
                        <div class="input-group mb-3 sm-nowrap">
                          <div class="sm-qr-box sm-padding-qr-box">
                            <div class="sm-text-center">
                              <h4 class="sm-heading-text">Address</h4>
                            </div>
                            <div class="sm-qr-box-inner">
                              <qr-code [size]="200" [value]="accountDetailsETHCryptoData.address"></qr-code>
                            </div>
                            <div class="sm-qr-input-box">
                              <span class="sm-spam-text sm-span-text-padding">{{accountDetailsETHCryptoData.address
                                }}</span>
                              <div class="input-group-append">
                                <span class="sm-input-group-text"><i mdbTooltip="Copy address" placement="top"
                                    class="fas fa-clone sm-copy-color" [ngClass]="copied ? 'text-primary' : ''"
                                    (click)="copyAddressETH()"></i></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p class="text-dark font-weight-bolder">Important</p>
                      <ul class="text-dark font-weight-bolder ul-not">
                        <li>
                          Send only {{item.currencyType}} (BEP-20) to this
                          address. Sending
                          any other coins or token to this address may result in the
                          loss of your deposit.
                        </li>
                        <li>
                          Coins will be deposited immediately after 3 network
                          confirmation.
                        </li>
                        <li>
                          After making a deposit, you can track its progress on the
                          reports.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </mdb-tab>
              <mdb-tab heading="Polygon" *ngIf="item.currencyType === 'CAS'">
                <div class="background px-5 py-4" *ngIf="!cryptoLoading && !accountDetailsETHCryptoData">
                  <h4 class="text-dark mb-4 font-weight-bolder">
                    Get your {{ getCurrencyName(item.currencyType) }} address
                  </h4>
                  <p class="text-dark font-weight-normal">
                    With {{ getCurrencyName(item.currencyType) }} address, people can send
                    {{ getCurrencyName(item.currencyType) }} to your account.
                  </p>
                  <div class="mt-4">
                    <button class="btn btn-outline-primary rounded text-primary btn-sm"
                      (click)="onAccountReadyPage(item, 'Crypto', 'BEP-20')">
                      Get address
                    </button>
                  </div>
                </div>
                <div class="px-5 mx-5 mt-3 text-center" *ngIf="cryptoLoading">
                  <mdb-spinner spinnerColor="blue"></mdb-spinner>
                </div>
                <div *ngIf="accountDetailsETHCryptoData">
                  <div class="py-4 pb-0">
                    <p class="font-weight-bold text-primary-dark">
                      Your Blockchain details to receive {{ item.currencyType }}
                    </p>
                    <!-- <p class="font-weight-normal text-dark">
                      Use your {{ item.currencyType }} address to receive
                      {{ item.currencyType }}.
                    </p> -->
                  </div>
                  <div class="row background px-5 py-4">
                    <div class="d-flex justify-content-center w-100">
                      <!-- <div class="w-75"> -->
                      <!-- <h5 class="text-dark mb-3 font-weight-bolder">
                          {{ item.currencyType }} Deposit
                        </h5> -->
                      <!-- </div> -->
                      <div class="f-shrink-1 p-2" style="background-color: white;"
                        *ngIf="item.currencyType === 'BNB' || item.currencyType==='CAS' || item.currencyType==='XRP'">
                        <span class="mb-3 font-weight-normal">
                          <mdb-icon far icon="question-circle"></mdb-icon> <a class="p-1" target="_blank"
                            routerLinkActive="customactive"
                            href="https://help.cashaa.com/knowledgebase/how-to-deposit-cryptocurrencies-to-your-cashaa-account/">How
                            to deposit?</a>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="mb-5">
                        <div class="input-group mb-3 sm-nowrap">
                          <div class="sm-qr-box sm-padding-qr-box">
                            <div class="sm-text-center">
                              <h4 class="sm-heading-text">Address</h4>
                            </div>
                            <div class="sm-qr-box-inner">
                              <qr-code [size]="200" [value]="accountDetailsETHCryptoData.address"></qr-code>
                            </div>
                            <div class="sm-qr-input-box">
                              <span class="sm-spam-text sm-span-text-padding">{{accountDetailsETHCryptoData.address
                                }}</span>
                              <div class="input-group-append">
                                <span class="sm-input-group-text"><i mdbTooltip="Copy address" placement="top"
                                    class="fas fa-clone sm-copy-color" [ngClass]="copied ? 'text-primary' : ''"
                                    (click)="copyAddressETH()"></i></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p class="text-dark font-weight-bolder">Important</p>
                      <ul class="text-dark font-weight-bolder ul-not">
                        <li>
                          Send only CAS (Polygon) to this
                          address. Sending
                          any other coins or token to this address may result in the
                          loss of your deposit.
                        </li>
                        <li>
                          Coins will be deposited immediately after 3 network
                          confirmation.
                        </li>
                        <li>
                          After making a deposit, you can track its progress on the
                          reports.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </mdb-tab>
            </mdb-tabset>
            <!-- <div class="d-flex">
              <div class="text-left mt-3">
                <span [ngClass]="{'sm-disable-span':item.available===0 || isCryptoDisable(item.currencyType)}"
                  [title]="getTootlTip(item.available,item.currencyType)">
                  <button [disabled]="item.available===0 || isCryptoDisable(item.currencyType)"
                    [ngClass]="{'sm-disable-btn':item.available===0 || isCryptoDisable(item.currencyType)}"
                    class="sm-btn-input-hover btn-border btn-sm btn rounded mr-2"
                    (click)="onConvertClick(item.currencyType)">
                    Convert
                  </button>
                </span>
                <span [ngClass]="{'sm-disable-span':item.available===0 || isCryptoSendDisable(item.currencyType)}"
                  [title]="getTootlTip(item.available,item.currencyType)">
                  <button [disabled]="item.available===0 || isCryptoSendDisable(item.currencyType)"
                    [ngClass]="{'sm-disable-btn':item.available===0 || isCryptoSendDisable(item.currencyType)}"
                    class="sm-btn-input-hover btn-border btn-sm btn rounded mr-2"
                    (click)="onSendClick(item.currencyType)">
                    Send {{ item.currencyType }}
                  </button>
                </span>
                <span *ngIf="isEarnAndFixedDepositActive() && ((item.currencyType === 'DOG' && userType === '4') || item.currencyType !== 'DOG') && 
                            (item.earnRate && item.earnRate > 0)" [ngClass]="{'sm-disable-span':item.available===0}"
                  [title]="getEarnTootlTip(item.available,item.currencyType)">
                  <button [disabled]="item.available===0" [ngClass]="{'sm-disable-btn':item.available===0}"
                    class="sm-btn-input-hover btn-border btn-sm btn rounded mr-2"
                    (click)="onDepositInterestClick(item)">
                    Earn up to {{item.earnRate}} %
                  </button>
                </span>
              </div>
            </div> -->
          </div>
        </mdb-accordion-item-body>
      </mdb-accordion-item>
    </mdb-accordion>
  </section>
  <!-- section for crypto currency starts here -->
  <!-- <div class="d-flex mx-5 bg-white p-3" *ngIf=" !loading && (!hideZeroBalance || isBalance)">
    <h6 class="heading" *ngIf="!loading">
      Cryptocurrency
    </h6>
    </div> -->
  <div *ngIf="!isBalance && !loading && hideZeroBalance" class="text-center mt-4">
   <h5 class="heading">Oops.. you have no Balance</h5>
   <img class="mb-4" src="../../../assets/images/zero-bal.png" alt="deposit">
   <!-- <button class="app-primary-btn d-block m-auto px-5" (click)="navigateToDeposite()">Deposit</button> -->
  </div>
 
  <section *ngIf=" !loading && (!hideZeroBalance || isBalance)"  #cryptoSection class="banks crypto-section pr-1 mx-5 mb-5 sm-width-phone-700">
    <mdb-accordion *ngFor="let item of cryptoBankFiatCurrencyList; let idx = index">
      <mdb-accordion-item #collapsesCrypto [ngClass]="selectedCrypto == idx ? '' : 'bg-unset'">
        <!-- <mdb-accordion-item-head > -->
        <account-crypto-box [idx]="idx" [currency]="item.currencyType" [balance]="item.available"
          [currencyId]="item.currencyId" [isDepositActive]="item.isDepositActive" [isSendActive]="item.isSendActive"
          [item]="item" (isLoadingMDBAccordionBody)="getCryptoAccountDetails($event)">
        </account-crypto-box>
        <!-- </mdb-accordion-item-head> -->
        <mdb-accordion-item-body>
          <div class="custom-mx-px">
            <mdb-tabset #staticTabs [buttonClass]="'nav md-pills pills-primary mb-2'" [justified]="true"
              class="recipientTab">
              <mdb-tab heading={{getTabHeaderData1(item.currencyType)}}>
                <div class="background px-5 py-4" *ngIf="!cryptoLoading && !accountDetailsCryptoData">
                  <h4 class="text-dark mb-4 font-weight-bolder">
                    Get your {{ getCurrencyName(item.currencyType) }} address
                  </h4>
                  <p class="text-dark font-weight-normal">
                    With {{ getCurrencyName(item.currencyType) }} address, people can send
                    {{ getCurrencyName(item.currencyType) }} to your account.
                  </p>
                  <div class="mt-4">
                    <button class="btn btn-outline-primary rounded text-primary btn-sm"
                      (click)="onAccountReadyPage(item, 'Crypto')">
                      Get address
                    </button>
                  </div>
                </div>
                <div class="px-5 mx-5 mt-3 text-center" *ngIf="cryptoLoading">
                  <mdb-spinner spinnerColor="blue"></mdb-spinner>
                </div>
                <div *ngIf="accountDetailsCryptoData">
                  <div class="py-4 pb-0">
                    <p class="font-weight-bold text-primary-dark">
                      Your Blockchain details to receive {{ item.currencyType }}
                    </p>
                    <p class="font-weight-normal text-dark">
                      <!-- Use your {{ item.currencyType }} address to receive
                      {{ item.currencyType }}. -->
                    </p>
                  </div>
                  <div class="row background px-5 py-4">
                    <div class="d-flex justify-content-center w-100">
                      <div class="w-75">
                        <h5 class="text-dark mb-3 font-weight-bolder">
                          <!-- {{ item.currencyType }} Deposit -->
                        </h5>
                      </div>
                      <div class="f-shrink-1 p-1" style="background-color: white;"
                        *ngIf="item.currencyType === 'BNB' || item.currencyType==='CAS' || item.currencyType==='XRP'">
                        <span class="mb-3 font-weight-normal">
                          <mdb-icon far icon="question-circle"></mdb-icon> <a class="p-1" target="_blank"
                            routerLinkActive="customactive"
                            href="https://help.cashaa.com/knowledgebase/how-to-deposit-cryptocurrencies-to-your-cashaa-account/">How
                            to deposit?</a>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="mb-5">
                        <div class="input-group mb-3 sm-nowrap qr-code-inp-grp">
                          <div class="sm-qr-box sm-padding-qr-box">
                            <div class="sm-text-center">
                              <h4 class="sm-heading-text">Address</h4>
                            </div>
                            <div class="sm-qr-box-inner">
                              <qr-code [size]="200" [value]="accountDetailsCryptoData.address"></qr-code>
                            </div>
                            <div class="sm-qr-input-box">
                              <span class="sm-spam-text sm-span-text-padding">{{accountDetailsCryptoData.address
                                }}</span>
                              <div class="input-group-append">
                                <span class="sm-input-group-text"><i mdbTooltip="Copy address" placement="top"
                                    class="fas fa-clone sm-copy-color" [ngClass]="copied ? 'text-primary' : ''"
                                    (click)="copyAddress()"></i></span>
                              </div>
                            </div>
                          </div>
                          <div
                            *ngIf="item.currencyType == 'CAS' || item.currencyType == 'BNB' || item.currencyType == 'XRP'"
                            class="input-group mb-3 pl-4 sm-border-line">
                            <div class="sm-qr-box">
                              <div class="sm-text-center">
                                <h4 class="sm-heading-text">{{item.currencyType == 'XRP'
                                  ? 'TAG': 'MEMO' }}</h4>
                              </div>
                              <div class="sm-qr-box-inner">
                                <qr-code [size]="200" [value]="accountDetailsCryptoData.memo"></qr-code>
                              </div>
                              <div class="sm-qr-input-box">
                                <span class="sm-spam-text">{{accountDetailsCryptoData.memo}}</span>
                                <div class="input-group-append">
                                  <span class="sm-input-group-text"><i mdbTooltip="Copy memo" placement="top"
                                      class="fas fa-clone sm-copy-color" [ngClass]="copiedMemo ? 'text-primary' : ''"
                                      (click)="copyMemoAddress()"></i></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p class="text-dark font-weight-bolder">Important</p>
                      <ul class="text-dark font-weight-bolder ul-not">
                        <li>
                          Send only {{getContentData(item.currencyType)}} to this
                          address. Sending
                          any other coins or token to this address may result in the
                          loss of your deposit.
                        </li>
                        <li>
                          Coins will be deposited immediately after 3 network
                          confirmation.
                        </li>
                        <li>
                          After making a deposit, you can track its progress on the
                          reports.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </mdb-tab>
              <mdb-tab heading="BEP-20" *ngIf="!(item.currencyType === 'BTC' || item.currencyType === 'BNB')">
                <div class="background px-5 py-4" *ngIf="!cryptoLoading && !accountDetailsETHCryptoData">
                  <h4 class="text-dark mb-4 font-weight-bolder">
                    Get your {{ getCurrencyName(item.currencyType) }} address
                  </h4>
                  <p class="text-dark font-weight-normal">
                    With {{ getCurrencyName(item.currencyType) }} address, people can send
                    {{ getCurrencyName(item.currencyType) }} to your account.
                  </p>
                  <div class="mt-4">
                    <button class="btn btn-outline-primary rounded text-primary btn-sm"
                      (click)="onAccountReadyPage(item, 'Crypto', 'BEP-20')">
                      Get address
                    </button>
                  </div>
                </div>
                <div class="px-5 mx-5 mt-3 text-center" *ngIf="cryptoLoading">
                  <mdb-spinner spinnerColor="blue"></mdb-spinner>
                </div>
                <div *ngIf="accountDetailsETHCryptoData">
                  <div class="py-4 pb-0">
                    <p class="font-weight-bold text-primary-dark">
                      Your Blockchain details to receive {{ item.currencyType }}
                    </p>
                    <p class="font-weight-normal text-dark">
                      <!-- Use your {{ item.currencyType }} address to receive
                      {{ item.currencyType }}. -->
                    </p>
                  </div>
                  <div class="row background px-5 py-4">
                    <div class="d-flex justify-content-center w-100">
                      <div class="w-75">
                        <h5 class="text-dark mb-3 font-weight-bolder">
                          <!-- {{ item.currencyType }} Deposit -->
                        </h5>
                      </div>
                      <div class="f-shrink-1 p-2" style="background-color: white;"
                        *ngIf="item.currencyType === 'BNB' || item.currencyType==='CAS' || item.currencyType==='XRP'">
                        <span class="mb-3 font-weight-normal">
                          <mdb-icon far icon="question-circle"></mdb-icon> <a class="p-2" target="_blank"
                            routerLinkActive="customactive"
                            href="https://help.cashaa.com/knowledgebase/how-to-deposit-cryptocurrencies-to-your-cashaa-account/">How
                            to deposit?</a>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="mb-5">
                        <div class="input-group mb-3 sm-nowrap">
                          <div class="sm-qr-box sm-padding-qr-box">
                            <div class="sm-text-center">
                              <h4 class="sm-heading-text">Address</h4>
                            </div>
                            <div class="sm-qr-box-inner">
                              <qr-code [size]="200" [value]="accountDetailsETHCryptoData.address"></qr-code>
                            </div>
                            <div class="sm-qr-input-box">
                              <span class="sm-spam-text sm-span-text-padding">{{accountDetailsETHCryptoData.address
                                }}</span>
                              <div class="input-group-append">
                                <span class="sm-input-group-text"><i mdbTooltip="Copy address" placement="top"
                                    class="fas fa-clone sm-copy-color" [ngClass]="copied ? 'text-primary' : ''"
                                    (click)="copyAddressETH()"></i></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p class="text-dark font-weight-bolder">Important</p>
                      <ul class="text-dark font-weight-bolder ul-not">
                        <li>
                          Send only {{item.currencyType}} (BEP-20) to this
                          address. Sending
                          any other coins or token to this address may result in the
                          loss of your deposit.
                        </li>
                        <li>
                          Coins will be deposited immediately after 3 network
                          confirmation.
                        </li>
                        <li>
                          After making a deposit, you can track its progress on the
                          reports.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </mdb-tab>
              <mdb-tab heading="Polygon" *ngIf="item.currencyType === 'CAS'">
                <div class="background px-5 py-4" *ngIf="!cryptoLoading && !accountDetailsETHCryptoData">
                  <h4 class="text-dark mb-4 font-weight-bolder">
                    Get your {{ getCurrencyName(item.currencyType) }} address
                  </h4>
                  <p class="text-dark font-weight-normal">
                    With {{ getCurrencyName(item.currencyType) }} address, people can send
                    {{ getCurrencyName(item.currencyType) }} to your account.
                  </p>
                  <div class="mt-4">
                    <button class="btn btn-outline-primary rounded text-primary btn-sm"
                      (click)="onAccountReadyPage(item, 'Crypto', 'BEP-20')">
                      Get address
                    </button>
                  </div>
                </div>
                <div class="px-5 mx-5 mt-3 text-center" *ngIf="cryptoLoading">
                  <mdb-spinner spinnerColor="blue"></mdb-spinner>
                </div>
                <div *ngIf="accountDetailsETHCryptoData">
                  <div class="py-4 pb-0">
                    <p class="font-weight-bold text-primary-dark">
                      Your Blockchain details to receive {{ item.currencyType }}
                    </p>
                    <p class="font-weight-normal text-dark">
                      <!-- Use your {{ item.currencyType }} address to receive
                      {{ item.currencyType }}. -->
                    </p>
                  </div>
                  <div class="row background px-5 py-4">
                    <div class="d-flex justify-content-center w-100">
                      <div class="w-75">
                        <h5 class="text-dark mb-3 font-weight-bolder">
                          <!-- {{ item.currencyType }} Deposit -->
                        </h5>
                      </div>
                      <div class="f-shrink-1 p-2" style="background-color: white;"
                        *ngIf="item.currencyType === 'BNB' || item.currencyType==='CAS' || item.currencyType==='XRP'">
                        <span class="mb-3 font-weight-normal">
                          <mdb-icon far icon="question-circle"></mdb-icon> <a class="p-2" target="_blank"
                            routerLinkActive="customactive"
                            href="https://help.cashaa.com/knowledgebase/how-to-deposit-cryptocurrencies-to-your-cashaa-account/">How
                            to deposit?</a>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="mb-5">
                        <div class="input-group mb-3 sm-nowrap">
                          <div class="sm-qr-box sm-padding-qr-box">
                            <div class="sm-text-center">
                              <h4 class="sm-heading-text">Address</h4>
                            </div>
                            <div class="sm-qr-box-inner">
                              <qr-code [size]="200" [value]="accountDetailsETHCryptoData.address"></qr-code>
                            </div>
                            <div class="sm-qr-input-box">
                              <span class="sm-spam-text sm-span-text-padding">{{accountDetailsETHCryptoData.address
                                }}</span>
                              <div class="input-group-append">
                                <span class="sm-input-group-text"><i mdbTooltip="Copy address" placement="top"
                                    class="fas fa-clone sm-copy-color" [ngClass]="copied ? 'text-primary' : ''"
                                    (click)="copyAddressETH()"></i></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p class="text-dark font-weight-bolder">Important</p>
                      <ul class="text-dark font-weight-bolder ul-not">
                        <li>
                          Send only CAS (Polygon) to this
                          address. Sending
                          any other coins or token to this address may result in the
                          loss of your deposit.
                        </li>
                        <li>
                          Coins will be deposited immediately after 3 network
                          confirmation.
                        </li>
                        <li>
                          After making a deposit, you can track its progress on the
                          reports.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </mdb-tab>
            </mdb-tabset>
            <div class="d-flex">
              <!-- <div class="text-left mt-3"> -->
                <!-- <span> -->
                  <!-- <button *ngIf="isEarnAndFixedDepositActive() && item.isFDActive; else US" type="submit" (click)="openEarnPlan()"
                    class="earn-upto m-2 w-10">Earn
                    up to {{rate}}%
                  </button>
                  <ng-template #US>
                    <button *ngIf="userType !== '4' && item.isFDActive" type="submit" (click)="openEarnPlan()"
                    class="earn-upto m-2 w-10">Earn
                    up to {{rate}}%
                  </button>
                  </ng-template> -->
                <!-- </span> -->
                <!-- <span [ngClass]="{'sm-disable-span':item.available===0 || isCryptoDisable(item.currencyType)}"
                  [title]="getTootlTip(item.available,item.currencyType)"> -->
                  <!-- <button class="app-secondary-btn m-2 w-10" (click)="onConvertClick(item.currencyType)"
                    >Exchange</button> -->
                    <button class="app-secondary-btn m-2 w-10 ml-auto" (click)="onHide(idx, item.currencyType)"
                    >Hide</button>
                <!-- </span> -->
                <!-- <span [ngClass]="{'sm-disable-span':item.available===0 || isCryptoSendDisable(item.currencyType)}"
                  [title]="getTootlTip(item.available,item.currencyType)"> -->
                  <!-- <button *ngIf="!isHidden(item.currencyType)" type="submit" [disabled]="!item.isSendActive"
                    class="app-tertiary-btn m-2 w-10" (click)="onSendClick(item.currencyType)">Send</button> -->
                <!-- </span> -->
                <span *ngIf="isEarnAndFixedDepositActive() && ((item.currencyType === 'DOG' && userType === '4') || item.currencyType !== 'DOG') && 
                        (item.earnRate && item.earnRate > 0)" [ngClass]="{'sm-disable-span':item.available===0}">
                </span>
              <!-- </div> -->
            </div>
          </div>
        </mdb-accordion-item-body>
      </mdb-accordion-item>
    </mdb-accordion>
  </section>
  <!-- section for crypto currency ends here -->

  <!-- <section class="p-5" *ngIf="!loading"> -->
    <!-- <h6 class="share-wd-friend mb-4">Share with your friends and family</h6> -->
    <!-- <div class="d-flex justify-content-center social-icon" *ngIf="!loading">
      <a target="_blank" href="https://www.linkedin.com/company/cashaa">
        <i class="fab fa-linkedin-in"></i>
      </a>
      <a target="_blank" href="https://www.facebook.com/cashaaLtd/">
        <i class="fab fa-facebook-f"></i>
      </a>
      <a target="_blank" href="https://twitter.com/yourCashaa">
        <i class="fab fa-twitter"></i>
      </a>
      <a target="_blank" href="https://www.instagram.com/yourcashaa/">
        <i class="fab fa-instagram"></i>
      </a>
      <a target="_blank" href="https://t.me/cashaanews/">
        <i class="fas fa-paper-plane"></i>
      </a>
    </div> -->
  <!-- </section> -->

  <div class="banks p-4 text-center" *ngIf="loading">
    <mdb-spinner spinnerColor="blue"></mdb-spinner>
  </div>
</cashaa-sidenav>