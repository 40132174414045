import {
    Component
  } from "@angular/core";
  
  @Component({
    selector: "social-media",
    template: `<div class="d-flex justify-content-center social-icon">
    <a target="_blank" href="https://www.linkedin.com/company/cashaa">
      <i class="fab fa-linkedin-in"></i>
    </a>
    <a target="_blank" href="https://www.facebook.com/cashaaLtd/">
      <i class="fab fa-facebook-f"></i>
    </a>
    <a target="_blank" href="https://twitter.com/yourCashaa">
      <i class="fab fa-twitter"></i>
    </a>
    <a target="_blank" href="https://www.instagram.com/yourcashaa/">
      <i class="fab fa-instagram"></i>
    </a>
    <a target="_blank" href="https://t.me/cashaanews/">
      <i class="fas fa-paper-plane"></i>
    </a>
  </div>`,
    styles: [`.fab:before,.fa-paper-plane:before { right: 0;
      position: absolute;
      left: 0;
      text-align: center;
      top: 12px;
      font-size: 15px;
    }
    .fab,
    .fa-paper-plane {
      width: 2rem;
      height: 2em;
      border-radius: 50%;
      color: #1531aa;
      position: relative;
    }
    .social-icon {
      position: fixed;
      right: 4px;
      bottom: 0;
      flex-direction: column;
      a {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
    }`
   ]
  })
  export class SocialMediaComponent  {}