import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "cashaa-ticket-type",
  templateUrl: "./ticket-type.component.html",
  styleUrls: ["./ticket-type.component.scss"],
})
export class TicketTypeComponent  {
 loading = false;
  @Output() onContinue = new EventEmitter();
  @Output() onSelect = new EventEmitter();

  select(index) {
    this.onSelect.emit(index);
  }

  // continue() {
  //   this.onContinue.emit();
  // }
}