import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserPlanRoutingModule } from './user-plan-routing.module';
import { PlanDetailsComponent } from './plan-details/plan-details.component';
import { SharedModule } from '../shared/shared.module';
import { EarnPlanDetailsComponent } from './earn-plan-details/earn-plan-details.component';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';


@NgModule({
  declarations: [PlanDetailsComponent, EarnPlanDetailsComponent],
  imports: [
    CommonModule,
    UserPlanRoutingModule,
    SharedModule,
    MDBBootstrapModulesPro.forRoot()
  ]
})
export class UserPlanModule { }
