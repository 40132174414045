import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { APP_ROUTES } from 'src/app/app.constants';
import { IApiListRespose, ICreate } from 'src/app/core/models/api-manager/api-manager';
import { IFeature } from 'src/app/core/models/api-manager/feature';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { CommonSavedData } from 'src/app/shared/services/commonSavedData.service';
import { ApiManagerService } from '../services/api-manager.service';

@Component({
  selector: 'cashaa-api-manager-confirm',
  templateUrl: './api-manager-confirm.component.html',
  styleUrls: ['./api-manager-confirm.component.scss']
})
export class ApiManagerConfirmComponent implements OnInit {

  @Input()
  resposeData: IApiListRespose;
  conditionsAccepted: boolean = false;
  loading: boolean = false;
  copied: boolean = false;
  sub: Subscription;
  constructor(private apiManager: ApiManagerService, private toastrService: ToastrService, private router: Router,
    private modalService: ModalService, private commonSavedData: CommonSavedData) { }

  ngOnInit(): void {
  }

  onFormSubmit(): void {
    this.modalService.openConfirmationModal("Have you copy the secret key for future reference?");
    this.sub = this.commonSavedData.getConfirmationBoxStatus().subscribe((status: boolean) => {
      if (status) {
        this.router.navigateByUrl("/api-manager");
      }
      this.sub.unsubscribe();
    });
  }

  copyAddress(): void {
    this.copied = true;
    navigator.clipboard.writeText(this.resposeData.secretKey);
    this.toastrService.toastrConfig.preventDuplicates = true;
    this.toastrService.success("Text copied to clipboard");
    setTimeout(() => (this.copied = false), 300);
  }
}
