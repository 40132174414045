import { Component, OnInit } from "@angular/core";
import { ModalService } from "src/app/core/services/modal/modal.service";

@Component({
  selector: "cashaa-profile-home",
  templateUrl: "./profile-home.component.html",
  styleUrls: ["./profile-home.component.scss"],
})
export class ProfileHomeComponent implements OnInit {
  constructor(private modalService: ModalService) {}

  ngOnInit(): void {}

  /**
   * Edit user profile
   */
  editProfile(): void {
    this.modalService.openBusinessProfileModal();
  }
}
