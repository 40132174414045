export interface IConvertMoneyRequest {
    fromCurrencyId: string;
    toCurrencyId: string;
    feeTypeId: string;
    fromAmount: number;
    toAmount: number;
    rate: number;

}
export class ConvertMoneyRequest implements IConvertMoneyRequest {
    constructor(
        public fromCurrencyId: string,
        public toCurrencyId: string,
        public feeTypeId: string,
        public fromAmount: number,
        public toAmount: number,
        public rate: number,

    ) { }
}

