import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { IFormSelect } from "src/app/core/models/form-select";

@Component({
  selector: "cashaa-form-select-activity-type-filter",
  templateUrl: "./form-select-activity-type-filter.component.html",
  styleUrls: ["./form-select-activity-type-filter.component.scss"],
})
export class FormSelectActivityTypeFilterComponent implements OnInit {
  /** Placeholder for input */
  @Input() placeholder = "Activity type";

  @Input() label = "";

  @Output() changed = new EventEmitter<IFormSelect>();

  public options: IFormSelect[] = [
    {
      label: "All",
      value: "0",
    },
    {
      label: "Deposited",
      value: "1",
    },
    {
      label: "Sent",
      value: "8",
    },
    {
      label: "Exchanged",
      value: "9",
    },
    {
      label: "Fixed Deposit",
      value: "23",
    },
    {
      label: "Interest Earned",
      value: "25",
    },

    {
      label: "Fees",
      value: "22",
    },
    {
      label: "Credit Availed",
      value: "26",
    },
    {
      label: "Credit Repayments",
      value: "27",
    },
    {
      label: "Collateral Locked",
      value: "29",
    },
    {
      label: "Collateral Released",
      value: "31",
    },
    {
      label: "Credit Interest Debited",
      value: "30",
    },
    {
      label: "Rolling Reserve",
      value: "32",
    },
    {
      label: "Staking Rolling Reserve",
      value: "33",
    }

  ];

  /** selected fee */
  selectedOption: IFormSelect;

  isDropdownOpened = false;

  constructor() { }

  ngOnInit(): void {
  }

  onValueChange(value: IFormSelect): void {
    this.selectedOption = value;
    this.changed.emit(value);
  }
}
