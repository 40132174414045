export interface IDepositInterestPlan {
  minAmount: number,
  maxAmount: number,
  duration: number,
  rate: number,
  rateType: number | string,
  currencyId: string,
  isDefault: boolean | string,
  isActive: boolean | string,
  id: string,
  planName: string,
  currency: string,
  description: string,
  currencyNature: string,
  interestOptType?: number,
  interestEarned?: number,
  interestEarnedCurrency?: string
}

export interface ICreateDepositPlan {
  amount: string,
  depositData: IDepositInterestPlan,
  isAutoRenew: boolean,
  interestOptType?: number
}

export interface IDepositPlan {
  amount: number,
  interestPlanId: string,
  isAutoRenew: boolean
}

export class DepositPlan {
  constructor(public amount: number, public interestPlanId: string, public rate: number, public isAutoRenew: boolean, public interestOptType: number) { }
}

export class CreateDepositPlan implements ICreateDepositPlan {
  constructor(public amount: string, public depositData: IDepositInterestPlan, public isAutoRenew: boolean, public interestOptType: number) { }
}

export interface ICancelAutoRenewal {
  id: string
}

export interface CancelFeeDetails {
  duration: string,
  fromDays: number,
  toDays: number,
  feeInPercent: number,
  isLongTerm: boolean
}

export interface CancelFee {
  cancelFee: number,
  currency: string,
  feePercent: number,
  cancelFeeDetails: CancelFeeDetails[]
}
