import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { IFormSelect } from "src/app/core/models/form-select";

@Component({
  selector: "cashaa-form-select",
  templateUrl: "./form-select.component.html",
  styleUrls: ["./form-select.component.scss"],
})
export class FormSelectComponent implements OnInit {
  /** Placeholder for input */
  @Input() placeholder = "";

  /** input form group */
  @Input() form: FormGroup;

  /** form control */
  @Input() control: FormControl;

  /** name of control */
  @Input() name: string;

  /** Form select values */
  @Input() set options(source: IFormSelect[]) {
    if (source) {
        this.sourceData = source;
        this.fillData(source);
    }
  }


  @Input() label: string;

  /** disable type */
  @Input() disable = false;

  @Input() onlyValue = false;

  @Input() loading;

  @Output() changed = new EventEmitter<IFormSelect>();

  selectedValue;

  optionIcon;

  searchText: string;

  filteredOptions: any;

  private sourceData:IFormSelect[];

  constructor() {}

  ngOnInit(): void {
    this.control.valueChanges.subscribe((val) => {
      this.selectedValue = this.sourceData.filter((option: any) => {
        return option.value === this.control.value;
      })[0];
    });
  }

  fillData(source:IFormSelect[]) {
    let fuc = setInterval(() => {
      if (source && source.length > 0) {
          clearInterval(fuc);
          this.filteredOptions = JSON.parse(JSON.stringify(source));
          if (this.control.value) {
              this.selectedValue = source.filter((option: any) => {
              return option.value === this.control.value;
              })[0];
          }
      }
    }, 1000);
  }

  onValueChange(data: any): void {
    this.selectedValue = data;
    if (this.name === "walletType") {
      this.control.setValue(data.value);
    } else {
      this.control.setValue(data.value, { emitEvent: false });
    }
    this.changed.emit(this.selectedValue);
  }

  filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.sourceData.filter((option: any) =>
      option.label.toLowerCase().includes(filterValue)
    );
  }

  getName(): string {
    if (this.control && this.sourceData.length > 0) {
      return this.sourceData.filter((o) => o.value === this.control.value).pop()
        .label;
    }
    return "";
  }

  selectedImg(v) {
    if (v?.icon) {
      this.optionIcon = v.icon;
    }
  }

  /**
   * On search of currency
   */
  onSearch(event: Event): void {
    event.stopPropagation();
    this.filteredOptions = this.sourceData.filter((val) => {
      if (val.label && this.searchText) {
        return (
          val.label
            .toLocaleLowerCase()
            .indexOf(this.searchText.toLocaleLowerCase()) !== -1
        );
      } else {
        return true;
      }
    });
  }
}
