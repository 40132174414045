<form>
  <div class="file-field">
    <div mdbBtn floating="true" size="md" gradient="blue" mdbWavesEffect>
      <mdb-icon fas icon="cloud-upload-alt" aria-hidden="true"></mdb-icon>
      <input [disabled]="isFileUploadCompleted" type="file" mdbFileSelect (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" 
      [options]="{ concurrency: 1, allowedContentTypes: ['application/pdf', 'image/jpeg', 'image/png'] }">
    </div>
    <mdb-icon *ngIf="isFileUploadCompleted" fas icon="check" class="check-icon"></mdb-icon>
    <div class="file-path-wrapper mt-3 pl-button">
      <button *ngIf="!isFileUploadCompleted" [disabled]="!fileUploadStatus || files.length === 0" mdbBtn type="button"
        outline="true" mdbWavesEffect class="sm-btn-input-hover btn-border btn-sm btn-des" (click)="startUpload()">
        <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"
          *ngIf="isOperationCompleted"></span>
        Upload
      </button>
      <button *ngIf="!isFileUploadCompleted && files.length > 0" [disabled]="!fileUploadStatus || files.length === 0"
        mdbBtn type="button" outline="true" mdbWavesEffect class="sm-btn-input-hover btn-border btn-sm btn-des"
        (click)="cancelUpload()">
        Cancel
      </button>
      <button *ngIf="isFileUploadCompleted && isDeletedActive" mdbBtn type="button" outline="true" mdbWavesEffect
        class="btn-border btn-sm btn-des btn-border-delete" (click)="deleteFileEvent()">
        <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"
          *ngIf="isOperationCompleted"></span>
        Delete
      </button>
    </div>
  </div>
</form>