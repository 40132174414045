import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { SBItemComponent } from 'ng-uikit-pro-standard';
import { ToastrService } from 'ngx-toastr';
import { UNABLE_TO_PERFORM_ERROR } from 'src/app/app.constants';
import { SettingsService } from '../services/settings.service';

@Component({
  selector: 'cashaa-setting-close-account',
  templateUrl: './setting-close-account.component.html',
  styleUrls: ['./setting-close-account.component.scss']
})
export class SettingCloseAccountComponent implements OnInit {

  /** is opened */
  isOpen = false;
  firstScreen = true;
  secondScreen = false;
  thirdScreen = false;
  fourthScreen = false;
  selectedIndex = -1;

  @ViewChildren(SBItemComponent) collapses: QueryList<SBItemComponent>[];

  /** is some call in progress */
  isInProgress = false;
 
  requirements = [{label: "All your wallets should have zero balance", value: 1}, {label: "You shouldn't have any outstanding loans", value: 2},
  {label: "You shouldn't have any pending withdrawals", value: 3}];
  feedback = [{label: "Have trouble getting started", value: 1}, {label: "Some Cashaa services are not available in my region", value: 2},
  {label: "I don't feel my assets are safe on Cashaa", value: 3}, {label: "Cashaa does not support assets that I’m interested in", value: 4},
  {label: "I'm getting too many emails", value: 5}, {label: "I no longer find Cashaa services useful", value: 6},
  {label: "I’m not using any of the Cashaa products/services", value: 7}, {label: "Other", value: 8}];
 
  isOtherTextBox = false;
  otherText = '';
  agreement;

  constructor(private router: Router, private settingsService: SettingsService, private toastrService: ToastrService) {
  }

  ngOnInit(): void {
   
  }
  /**
   * On close Account
   */
   closeAccount(): void {
    // this.isInProgress = true;
    this.firstScreen = false;
    this.secondScreen = true;
    this.thirdScreen = false;
  }

  continue() {
    this.firstScreen = false;
    this.secondScreen = false;
    this.thirdScreen = true;
  }
  backToFirst() {
    this.firstScreen = true;
    this.secondScreen = false;
    this.thirdScreen = false;
  }
  confirmClose(){
    let payload = { reason: '' }
    if(this.selectedIndex === 7) {
     payload.reason = this.otherText;
    } else {
      let res = this.feedback.filter(item => item.value === this.selectedIndex);
      payload.reason = res[0].label;
    }
    this.isInProgress = true;
   this.settingsService.closeAccount(payload).subscribe(res => {
    this.isInProgress = false;
    if(res.statusCode === 200) {
    this.fourthScreen = true;
    this.thirdScreen = false;
    this.secondScreen = false;
    this.firstScreen = false;
   }
   }, err => {
    this.isInProgress = false;
    if (err.error.message) {
      this.toastrService.error(err.error.message);
    } else {
      this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
    }
   })
  }

  navigateToActivity() {
    this.router.navigateByUrl('activity');
  }


  selectFeedback(event, i) {
    this.selectedIndex = event.checked ? i : -1;
    if(this.selectedIndex == 7) {
    this.isOtherTextBox = true
    } else {
      this.isOtherTextBox = false;
    }
  }

  selectAgreement(event)  {
    this.agreement = event.checked;
    console.log(this.agreement);
  }

  collapse(collapsed: boolean) {
    this.collapses.forEach((el: any) => {
      el.toggle(collapsed);
    });
  }
}
