import { CryptoAccountRequestModel } from "./../../core/models/crypto-account-request-model";
import { FiatAccountRequestModel } from "./../../core/models/fiat-account-request-model";
import { WalletService } from "./../services/wallet.service";
import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { APP_ROUTES, USER_TYPE } from "../../app.constants";

@Component({
  selector: "cashaa-wallet-ready",
  templateUrl: "./wallet-ready.component.html",
  styleUrls: ["./wallet-ready.component.scss"],
})
export class WalletReadyComponent implements OnInit {
  getEmittedCurrency: string;
  currencyType: string;

  userType = sessionStorage.getItem(USER_TYPE);

  constructor(private router: Router, private walletService: WalletService) { }

  ngOnInit(): void {
    this.currencySymbolLoad();
  }

  currencySymbolLoad(): void {
    this.walletService.getCurrencySymbolEmitter().subscribe((res) => {
      this.getEmittedCurrency = res.currencySymbol;
      this.currencyType = res.currencyType;
    });
  }

  onAccountOpenClick(): void {
    if (this.currencyType === "Fiat") {

      const inputCurrencyFiat = new FiatAccountRequestModel(
        this.getEmittedCurrency
      );
      this.walletService
        .openFiatAccount(inputCurrencyFiat)
        .subscribe((res) => {

          this.router.navigateByUrl(APP_ROUTES.WALLET_HOME);
        });
    }

    if (this.currencyType === "Crypto") {

      const inputCurrencyCrypto = new CryptoAccountRequestModel(
        this.getEmittedCurrency
      );
      this.walletService
        .OpenCryptoAccount(inputCurrencyCrypto)
        .subscribe((res) => {
          this.router.navigateByUrl(APP_ROUTES.WALLET_HOME);

        });
    }
  }




  onPersonalAccountOpenClick(): void {
    if (this.currencyType === "Fiat") {

      const inputCurrencyFiat = new FiatAccountRequestModel(
        this.getEmittedCurrency
      );
      this.walletService
        .openPersonalFiatAccount(inputCurrencyFiat)
        .subscribe((res) => {

          this.router.navigateByUrl(APP_ROUTES.WALLET_HOME);
        });
    }

    if (this.currencyType === "Crypto") {

      const inputCurrencyCrypto = new CryptoAccountRequestModel(
        this.getEmittedCurrency
      );
      this.walletService
        .OpenCryptoAccount(inputCurrencyCrypto)
        .subscribe((res) => {
          this.router.navigateByUrl(APP_ROUTES.WALLET_HOME);

        });
    }
  }
}
