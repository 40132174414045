<mdb-card>
  <mdb-card-body class="p-4 p-sm-5 wrapper-box position-relative">

    <div class="mb-4 text-center">
      <img src="../../../assets/media/2FA-security-mobile.png" />
    </div>
    <h4 class="text-center text-primary font-weight-bold mb-4">
      Skip your approval Request for future balance transfer to
      {{ fiatRecipientData?.name }}
    </h4>

    <button mdbBtn (click)="skipApprovalRequest()" block="true" type="button"
      class="rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block mb-3" mdbWavesEffect>
      Skip approval requests
    </button>

    <button mdbBtn (click)="requireApprovalRequest()" block="true" type="button" outline="true"
      class="rounded-sm position-relative btn waves-effect waves-light btn-block mb-4" mdbWavesEffect>
      Always require approval
    </button>

    <div class="d-flex align-items-center justify-content-center">
      <p class="small mb-0 ml-3">
        You can always change in the recipients section
      </p>
    </div>

    <!-- Progress bar -->
    <mdb-progress-bar *ngIf="isInProgress" class="progress primary-color-dark position-absolute" mode="indeterminate">
    </mdb-progress-bar>
  </mdb-card-body>
</mdb-card>