import { KycBusinessUser } from "./../../core/models/kyc-business-user-model";
import { IKycBusinessUser } from "src/app/core/models/kyc-business-user-model";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ValidatorUtility } from "../../core/utility/validator.utility";
import { KycService } from "../services/kyc.service";
import {
  CreateBusinessUser,
  ICreateBusinessUser,
} from "../../core/models/business-account/kyc-business-user";
import { IFormSelect } from "src/app/core/models/form-select";
import {
  DATA_SUCCESS_MESG,
  UNABLE_TO_PERFORM_ERROR,
} from "src/app/app.constants";
import { ToastrService } from "ngx-toastr";
import { IMyOptions } from "ng-uikit-pro-standard";
import { ICountry } from "src/app/core/models/country";

@Component({
  selector: "cashaa-kyc-business-user-add",
  templateUrl: "./kyc-business-user-add.component.html",
  styleUrls: ["./kyc-business-user-add.component.scss"],
})
export class KycBusinessUserAddComponent implements OnInit {
  form: FormGroup;
  loader = false;
  errorMessage: string;
  businessSourceOfFunds: IFormSelect[];
  businessUserdetails: IKycBusinessUser = {};
  initialValue;

  @Output()
  public continue = new EventEmitter<void>();

  // @Input() plan;
  myDatePickerOptions: IMyOptions = {
    minYear: 1900,
    maxYear: new Date().getFullYear() - 18
  };

  constructor(
    private fb: FormBuilder,
    private kycService: KycService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.getSourceOfFund();
    this.getBusinessUserDetails();
  }
  getSourceOfFund() {
    this.kycService.getSourceOfFund().subscribe((resData) => {
      this.businessSourceOfFunds = resData.map((d) => {
        return {
          value: d.key,
          label: d.key,
        };
      });
    });
  }

  createForm(): void {
    this.form = this.fb.group({
      // accountType: ["", [ValidatorUtility.Required]],
      firstName: ["", [ValidatorUtility.Required]],
      lastName: ["", [ValidatorUtility.Required]],
      email: ["", [ValidatorUtility.Required]],
      sourceOfFunds: ["", [ValidatorUtility.Required]],
      mobileNumber: ["", [ValidatorUtility.Required, ValidatorUtility.mobilenumber]],
      dateOfBirth: ["", [ValidatorUtility.Required]],
      countryId: ["", [ValidatorUtility.Required]],
      houseNameNumber: ["", [ValidatorUtility.Required]],
      street: ["", [ValidatorUtility.Required]],
      city: ["", [ValidatorUtility.Required]],
      postCode: ["", [ValidatorUtility.Required, Validators.maxLength(13), Validators.minLength(2)]],
    });
  }
  setFormControl(date: any) {
    this.form.controls["dateOfBirth"].setValue(date.actualDateFormatted);
  }

  getBusinessUserDetails(): void {
    // KYCID required
    this.kycService.getBusinessUserDetails().subscribe((res) => {
      if (res) {
        this.businessUserdetails = res;
        this.form.controls["firstName"].setValue(res.firstName);
        this.form.controls["lastName"].setValue(res.lastName);
        this.form.controls["email"].setValue(res.email);
        this.form.controls["sourceOfFunds"].setValue(res.sourseOfFund);
        this.form.controls["mobileNumber"].setValue(res.mobile);
        this.form.controls["dateOfBirth"].setValue(res.dob);
        this.initialValue = res.countryId;
        // this.form.controls["countryId"].setValue(res.countryId);
        this.form.controls["houseNameNumber"].setValue(res.houeseNumber);
        this.form.controls["street"].setValue(res.street);
        this.form.controls["city"].setValue(res.city);
        this.form.controls["postCode"].setValue(res.postCode);
      }
    });
  }

  onCountryChange(data: { option: any; country: ICountry }): void {
    console.log(data.option);
  }

  onFormSubmit(): void {
    this.loader = true;
    const data = this.form.value;

    const businessUserData = new CreateBusinessUser(
      data.firstName,
      data.lastName,
      data.email,
      data.sourceOfFunds,
      false,
      "",
      data.mobileNumber,
      true,
      data.dateOfBirth,
      data.countryId.value,
      data.houseNameNumber,
      data.street,
      data.city,
      data.postCode,
      0
    );

    this.kycService.createBusinessUser(businessUserData).subscribe(
      (res) => {
        this.toastrService.success(DATA_SUCCESS_MESG);
        this.loader = false;
        this.continue.emit();
      },
      (err) => {
        this.loader = false;
        if (err.error.message) {
          this.toastrService.error(err.error.message);
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
      }
    );
  }
}
