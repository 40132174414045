<cashaa-sidenav>
    <section class="mh-100">
        <div class="bg-white p-4 sm-media-padding">
            <div class="d-flex sm-phone-display-none mr-3" style="height: 47px;">
                <button (click)="goBack()" mdbBtn type="button"
                    class="cursor-pointer btn-sm app-primary-btn">Back</button>
            </div>
        </div>
    </section>

<div class="card p-4 mt-4 mx-auto w-50">
    <form [formGroup]="addWebhookForm" class="my-4">
        <div class="col-sm-6 my-3">
            <cashaa-form-input name="callbackURL" type="callbackURL" [form]="addWebhookForm" [control]="addWebhookForm.controls.callbackURL"
                placeholder="Enter the callback URL" label="Callback URL">
            </cashaa-form-input>
        </div>
        <div class="col-sm-6 my-3">
            <cashaa-form-input name="sendCallbackURL" type="sendCallbackURL" [form]="addWebhookForm" [control]="addWebhookForm.controls.sendCallbackURL"
                placeholder="Enter the sendcallback URL" label="Send Callback URL">
            </cashaa-form-input>
        </div>
        <div class="col-sm-6 my-3">
            <cashaa-form-input name="sendStatusCallbackURL" type="sendStatusCallbackURL" [form]="addWebhookForm" [control]="addWebhookForm.controls.sendStatusCallbackURL"
                placeholder="Enter the send Status callback URL" label="Send Status Callback URL">
            </cashaa-form-input>
        </div>
        <div class="ml-1 mt-4 sm-pos-right">
            <button *ngIf="isWebhookURLAdded; else add" mdbBtn (click)="onFormSubmit()" block="true" type="button"
                class="rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block"
                mdbWavesEffect [disabled]="addWebhookForm.invalid">
                Update
            </button>
            <ng-template #add>
                <button mdbBtn (click)="onFormSubmit()" block="true" type="button"
                class="rounded-sm position-relative btn btn-primary waves-effect waves-light btn-block"
                mdbWavesEffect [disabled]="addWebhookForm.invalid">
                Add
            </button>
            </ng-template>
            
        </div>
    </form>
</div>

</cashaa-sidenav>