export enum Status {
    All = 0,
    Pending = 1,
    Success = 2,
    Failed = 3,
    Initiated = 4,
    Approved = 5,
    Rejected = 6,
    RefundInitiated = 7,
    RefundSuccess = 8,
    Canceled = 9,
    Revived = 10,
    Sent = 11,
    TransferNotConfirmed = 12,
    TransferConfirmed = 13,
    Swapped = 14
}
