import { Router } from "@angular/router";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ModalService } from "src/app/core/services/modal/modal.service";
import { UserService } from "src/app/core/services/user/user.service";
import { DepositInterestService } from "src/app/deposit-interest/service/deposit-interest.service";
import { IDepositInterestPlan } from "src/app/core/models/deposit-interest/deposit-interest";
import { USER_COUNTRY_CODE, USER_INTEREST_MODE, USER_TYPE } from "src/app/app.constants";
import { IUserPlan } from "src/app/core/models/user-plan/user-detail";
import { IUserBalance } from "src/app/core/models/user-balance";
import { IAccountDetails } from "src/app/core/models/account-details";
import { ICryptoAccountDetails } from "src/app/core/models/crypto-account-details";

@Component({
  selector: "account-crypto-box",
  templateUrl: "./account-crypto-box.component.html",
  styleUrls: ["./account-crypto-box.component.scss"]
})
export class AccountCryptoBoxComponent implements OnInit {
  /** Currency type */
  @Input() currency: string;
  @Input() item;
  @Input() currencyId: string;
  @Input() isDepositActive: boolean;
  @Input() isSendActive: boolean;
  @Input() idx = -1;
  @Input() creditBalanceInUSD;
  @Input() userBalanceInUSD;
  @Input() isEarn;
  selectedCrypto = -1

  /** balance */
  @Input() balance = 0;
  @Output() isLoadingMDBAccordionBody = new EventEmitter();

  diamondPlan: IUserPlan;
  rate: number;
  allCurrenciesPlan: IUserPlan[] = [];
  rateType: string = sessionStorage.getItem(USER_INTEREST_MODE);
  currencyDepositRate: number[] = [];
  maxInterestModeRate: number[] = [];
  nonBankingFiatCurrency = ["AUD", "JPY", "CAD", "CNH", "SGD", "NZD", "CHF"];
  excludeCryptoDisable: string[] = ["BNB20"];
  cryptoLoading = false;
  accountDetailsByCurrencyData: IAccountDetails;
  accountDetailsByAccountCurrencyData: IAccountDetails;
  accountDetailsCryptoData: ICryptoAccountDetails;
  accountDetailsETHCryptoData: ICryptoAccountDetails;
  currencyDepositRateLessThanEqual365: number[] = [];
  currencyDepositRateGreaterThan365: number[] = [];
  userType = sessionStorage.getItem(USER_TYPE);
  constructor(private router: Router, private modalService: ModalService,
    private userService: UserService, private depositService: DepositInterestService) { }

  ngOnInit(): void {
    // if (!this.isEarnAndFixedDepositActive()) {
      if(this.currency === 'DOG') {
        this.currency = 'DOGE';
      }
    if (this.isFixedDepositEnable()) {
      this.loadFixedDepositRate();
    } else if (this.isFixedDepositAndEarnEnable()) {
      if (this.currency === 'CAS') {
        this.loadFixedDepositRate();
      } else {
        let rate: number;
        this.userService.getPlanDetailsByCurrencyId(this.currencyId).subscribe((data: IUserPlan[]) => {

          if (data.length > 0) {
            this.allCurrenciesPlan = data;
            this.diamondPlan = data.filter((plan: IUserPlan) => plan.loyaltyPlanName === 'Diamond')[0];
            rate = Math.trunc(this.diamondPlan.casRate) + Math.trunc(this.diamondPlan.kindRate);
          }
            // if (!this.isEarnModuleNotActive()) {
            this.depositService.getByDepositFDCurrency(this.currencyId).subscribe((data: IDepositInterestPlan[]) => {
              if (data) {
                const plans: IDepositInterestPlan[] = data.filter((plan) => plan.isActive && plan.isDefault);
                const plangreaterThan365: IDepositInterestPlan[] = data.filter((plan) => plan.isActive && plan.isDefault && plan.duration > 365);
                const planLessThanEqual365: IDepositInterestPlan[] = data.filter((plan) => plan.isActive && plan.isDefault && plan.duration <= 365);
                plans.forEach(plan => {
                  if (plan.rateType == (this.rateType === 'InCas' ? 2 : 1)) {
                    this.maxInterestModeRate.push(plan.rate)
                  }
                  this.currencyDepositRate.push(plan.rate)
                });
                plangreaterThan365.forEach(plan => {
                  this.currencyDepositRateGreaterThan365.push(plan.rate)
                });
                planLessThanEqual365.forEach(plan => {
                  this.currencyDepositRateLessThanEqual365.push(plan.rate)
                });
                if(rate) {
                this.currencyDepositRate.push(rate);
                }
                if (this.currencyDepositRate.length > 0) {
                  this.rate = Math.max(...this.currencyDepositRate);
                }
              }
            });
            // } else {
            //   this.rate = rate;
            // }
          // }
        });
      }
    }
    // }
  }

  loadFixedDepositRate() {
    // if (!this.isEarnModuleNotActive()) {
    this.depositService.getByDepositFDCurrency(this.currencyId).subscribe((data: IDepositInterestPlan[]) => {
      if (data.length > 0) {
        const plans: IDepositInterestPlan[] = data.filter((plan) => plan.isActive && plan.isDefault);
        plans.forEach(plan => {
          if (plan.rateType == (this.rateType === 'InCas' ? 2 : 1)) {
            this.maxInterestModeRate.push(plan.rate);
          }
          this.currencyDepositRate.push(plan.rate);
        });
        if (this.currencyDepositRate.length > 0) {
          this.rate = Math.max(...this.currencyDepositRate);
        }
      }
    });
    // }

  }

  goToCurrency(): void {
    this.router.navigateByUrl(`/wallet?c=${this.currency}#${this.currency}`);
  }

  getCurrencyName(curr): string {
    if (curr === "AUSD") return "USD";
    if (curr === "DOG") return "DOGE";
    else return curr;
  }

  openEarnPlan(event): void {
    event.stopPropagation();
    if (this.currency === 'CAS') {
      this.router.navigateByUrl(`deposit-interest?c=${this.currency}`);
    } else {
      this.modalService.openEarnPlanDetailPopup(this.allCurrenciesPlan, this.diamondPlan, this.balance, this.currencyId, this.currency, this.currencyDepositRate, this.isFixedDepositEnable(), this.isFixedDepositAndEarnEnable(), this.maxInterestModeRate, false, this.currencyDepositRateLessThanEqual365, this.currencyDepositRateGreaterThan365, this.item.minFlexAmount);
    }
  }

  isEarnAndFixedDepositActive(): boolean {
    return sessionStorage.getItem(USER_COUNTRY_CODE) !== 'US';
  }

  isFixedDepositEnable(): boolean {
    //return sessionStorage.getItem(USER_TYPE) !== '4' || this.currency === 'CAS';
    return this.currency === 'CAS';
  }

  isFixedDepositAndEarnEnable(): boolean {
    //return sessionStorage.getItem(USER_TYPE) === '4' && this.currency !== 'CAS';

    return (this.userType === '4' || this.userType === '5' || this.userType === '6') && this.currency !== 'CAS';
  }

  // isEarnModuleNotActive(): boolean {
  //   return sessionStorage.getItem(USER_TYPE) === '4' && this.currency === 'DOG';
  // }

  isCryptoDisable(currency: string): boolean {
    if (this.excludeCryptoDisable.includes(currency)) {
      return true;
    } else {
      return false;
    }
  }

  isHide(): boolean {
    if (this.rate && this.rate > 0) {
      return false;
    } else {
      return true;
    }
  }
  isHidden(currency: string): boolean {
    if (this.nonBankingFiatCurrency.includes(currency)) {
      return true;
    } else {
      return false;
    }
  }
  onAddClick(currency: string): void {
    this.router.navigateByUrl(`/deposit?cur=${currency}`);
  }
  onConvertClick(currency: string): void {
    this.router.navigateByUrl(`/convert?c=${currency}`);
  }

  onSendClick(currency: string): void {
    this.router.navigateByUrl(`/fiat?c=${currency}`);
  }
  getCryptoAccountDetails(event, currency: IUserBalance, idx: number): void {
    if (currency && currency.currencynature === 2) {
      this.router.navigateByUrl(`/deposit?cur=${currency.symbol}`);
    } else {
      currency.isSelected = !currency.isSelected;
      this.isLoadingMDBAccordionBody.emit({ 'rate': this.rate, 'id': idx, 'currency': currency });
    }
  }

  onBuyClick(currency: string): void {
    this.router.navigateByUrl(`/buy?c=${currency}&`);
  }

}
