<section
  class="wrapper bg-white d-flex justify-content-center position-relative sm-width-phone-700 sm-width-phone-800-height">
  <img class="position-absolute logo" src="../../../assets/media/logo-dark.svg" />

  <div class="position-absolute close text-primary-light"  (click)="backRoute()">
    <mdb-icon fas icon="times"></mdb-icon>
  </div>

  <mdb-stepper #stepper [linear]="true" class="w-stepper-60 w-phone-100" [hidden]="completed">
    <mdb-step name="Amount" class="step" [stepForm]="amountForm">
      <cashaa-fiat-flow-amount (continue)="onAmountFormContinue($event)"></cashaa-fiat-flow-amount>
    </mdb-step>
    <mdb-step name="Recipient" class="step" [stepForm]="recipientForm">
      <cashaa-fiat-flow-recipient (continue)="onRecipientFormContinue()" [skipped]="isRecipientSkipped" [selected]="sendMoneyRecipient"></cashaa-fiat-flow-recipient>
    </mdb-step>
    <mdb-step name="Pay" class="step">
      <cashaa-fiat-flow-review (goback)="onGoBack($event)" (completed)="response($event); onGoBack(2)">
      </cashaa-fiat-flow-review>
    </mdb-step>
  </mdb-stepper>

  <div class="sm-width-phone-500 completed-div" [hidden]="!completed">
    <cashaa-fiat-completed (continue)="completed = false"></cashaa-fiat-completed>
  </div>
</section>
