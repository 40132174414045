import { CarouselComponent } from "ng-uikit-pro-standard";
import {
  Google2faDto,
  Enabletwofarequest,
  ICreatetwofa,
} from "./../../core/models/EnableTwoFa";
import { ChangePasswordRequest } from "./../../core/models/chnagepassword";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { ChangeEmailRequest } from "../../core/models/changeEmail";
import { CashaaUserFeeMode } from "src/app/core/models/cashaa-user-fee-request";
import { IInterestMode } from "src/app/core/models/interest-mode";

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  private apiUrl = environment.api;

  constructor(private http: HttpClient) { }

  changeEmail(
    data: ChangeEmailRequest
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${this.apiUrl}/account/changeemail`,
      data
    );
  }

  changePassword(
    data: ChangePasswordRequest
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${this.apiUrl}/users/changepassword`,
      data
    );
  }

  createTwoFaAuth(createkey: ICreatetwofa): Observable<Google2faDto> {
    return this.http.post<Google2faDto>(
      `${this.apiUrl}/account/createTwoFaAuth`,
      createkey
    );
  }
  subscriptionPlan(): Observable<{ statusCode: number; message: string }> {
    return this.http.get<{ statusCode: number; message: string }>(
      `${this.apiUrl}/subscriptions/premiumStatus`
    );
  }

  enablegoogleAuth(data: Enabletwofarequest): Observable<Google2faDto> {
    return this.http.post<Google2faDto>(
      `${this.apiUrl}/account/enableTwoFaAuth`,
      data
    );
  }
  /**
   * Update user transactional fee currency mode
   *
   * @param {CashaaUserFeeMode} data
   * @returns {Observable<any>}
   *
   * @memberOf SettingsService
   */
  updateUserMode(
    data: CashaaUserFeeMode
  ): Observable<{ statusCode: number; message: string }> {
    return this.http.post<{ statusCode: number; message: string }>(
      `${this.apiUrl}/feemodes/Update`,
      data
    );
  }

  disableTwofaCode(code?: string, token?: string): Observable<{ statusCode: number; message: string }> {
    let url = `${this.apiUrl}/users/resettwofa`;
    if (code && token) {
      url = `${url}?code=${code}&confirmationToken=${token}`;
    }
    return this.http.post<{ statusCode: number; message: string }>(
      url, null
    );
  }

  createInterestChoice(mode: IInterestMode): Observable<{ statusCode: number; message: string, userInterestMode: string }> {
    return this.http.post<{ statusCode: number; message: string, userInterestMode: string }>(
      `${this.apiUrl}/userchoice/create`,
      mode
    );
  }

  closeAccount(payload) {
    return this.http.post<{ statusCode: number; message: string }>(
      `${this.apiUrl}/account/accountclose`,
      payload
    );
  }
}
