<cashaa-form-select
  (changed)="onValueChange($event)"
  [placeholder]="placeholder"
  [form]="form"
  [control]="control"
  [name]="name"
  [options]="options"
  [label]="label"
>
</cashaa-form-select>
