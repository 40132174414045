import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../core/services/auth-guard/auth-guard.service';
import { PlanDetailsComponent } from './plan-details/plan-details.component';
// import { EarnGuardService } from '../core/services/earn-guard/earn-guard.service';


const routes: Routes = [{
  path: "plans",
  component: PlanDetailsComponent,
  canActivate: [AuthGuardService]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserPlanRoutingModule { }
