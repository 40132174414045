import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { RecipientRoutingModule } from "./recipient-routing.module";
import { SharedModule } from "../shared/shared.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MDBBootstrapModulesPro } from "ng-uikit-pro-standard";
import { RecipientAddComponent } from "./recipient-add/recipient-add.component";
import { RecipientHomeComponent } from "./recipient-home/recipient-home.component";
import { RecipientListComponent } from "./recipient-list/recipient-list.component";
import { RecipientAddBankaccountComponent } from "./recipient-add-bankaccount/recipient-add-bankaccount.component";
import { RecipientAddressConfirmationComponent } from "./recipient-address-confirmation/recipient-address-confirmation.component";
import { ToastrModule } from "ngx-toastr";
import { RecipientComponent } from './recipient/recipient.component';
import { UpdateMemoComponent } from "./update-memo/update-memo.component";

@NgModule({
  declarations: [
    RecipientAddComponent,
    RecipientHomeComponent,
    RecipientListComponent,
    RecipientAddBankaccountComponent,
    RecipientAddressConfirmationComponent,
    RecipientComponent,
    UpdateMemoComponent
  ],
  imports: [
    CommonModule,
    RecipientRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    FormsModule,
    MDBBootstrapModulesPro.forRoot(),
  ],
})
export class RecipientModule {}
