import { USER_ID, USER_NAME_ID } from "./../../app.constants";
import { UserService } from "src/app/core/services/user/user.service";
import { Component, Input, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "cashaa-invite-share-link",
  templateUrl: "./invite-share-link.component.html",
  styleUrls: ["./invite-share-link.component.scss"],
})
export class InviteShareLinkComponent implements OnInit {
  constructor(private toastrService: ToastrService) {}
  @Input() refcode: string;
  userId = sessionStorage.getItem(USER_NAME_ID);

  invite: string;

  isDropdownOpened = false;

  ngOnInit(): void {
    this.invite = `https://platform.cashaa.com/account/signup?referralId=${this.refcode}`;
  }

  /**
   * get user balance
   */
  getUserId(): void {}

  onLinkClick(input: HTMLInputElement): void {
    if (input) {
      input.select();
    }
    navigator.clipboard.writeText(this.invite);
    this.toastrService.toastrConfig.preventDuplicates = true;
    this.toastrService.success("Text copied to clipboard");
  }

  copyLink(): void {
    navigator.clipboard.writeText(this.invite);
    this.toastrService.toastrConfig.preventDuplicates = true;
    this.toastrService.success("Text copied to clipboard");
  }

  shareTwitter(): void {
    window.open(
      `https://twitter.com/intent/tweet?url=${this.invite}&text=Please%20join%20Cashaa%20using%20following%20link`,
      "_blank"
    );
  }

  shareFacebook(): void {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${this.invite}`,
      "_blank"
    );
  }

  shareWhtsapp(): void {
    window.open(`https://api.whatsapp.com/send?text=${this.invite}`, "_blank");
  }
}
