<div class="md-form sm-currency-select">
    <label *ngIf="label" class="heading">{{label}}</label>
    <img *ngIf="optionIcon !== undefined" [src]="optionIcon" class="rounded-circle sm-curr-img" height="34" width="34" />
    <mdb-select-2 [outline]="true"
      [ngClass]="{'sm-form-select':optionIcon !== undefined, 'sm-text-indent':optionIcon === undefined}"
      placeholder="Select Currency" (selected)="onCurrencyChange($event)" [formControl]="control"
      [dropdownClass]="'sm-currency-select'" class="sm-currency-select">
      <i class="fas fa-search sm-search-icon" aria-hidden="true"></i>
      <mdb-select-filter [ngModel]="searchText | async" (ngModelChange)="searchText.next($event)" placeholder="Search...">
      </mdb-select-filter>
      <mdb-select-option *ngFor="let option of filteredOptions | async" [value]="option">
        <div *ngIf="option.icon !== undefined" class="d-flex-row justify-content-between align-items-center">
          <img [src]="option.icon" class="rounded-circle" height="34" width="34" />
          <span class="ml-3">{{option.label === "DOG"? "DOGE" : option.label }}</span>
        </div>
        <span *ngIf="option.icon === undefined">{{ option.label }}</span>
      </mdb-select-option>
    </mdb-select-2>
  </div>
  