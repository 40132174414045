<div class="modal-header position-relative sm-form-title">
    <h3 class="modal-title w-100 mt-3 sm-custom-header" id="myModalLabel">Warning!!</h3>
</div>
<div class="modal-body font-weight-bold">
    • It is mandatory to enter both {{memoOrTag}} and address.<br><br>
    • Deposit address support only {{XrpOrREP}} token.<br><br>
    • If you forgot to enter {{memoOrTag}} while depositing {{XrpOrREP}} supported token on Cashaa platform, then a recovery fees will
    be charged to recover.<br><br>
    <span class="pl-1 ml-1"><u>Note: Recovery of token is not guaranteed.</u></span>
</div>
<div class="text-center m-3">
    <hr>
    <button mdbBtn type="button" (click)="onClick(true)" color="primary" mdbWavesEffect>
        I Understand
    </button>
</div>