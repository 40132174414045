import { CurrencyTypeData } from "./../../core/models/currency-type-data";
import { ICryptoAccountDetails } from "./../../core/models/crypto-account-details";
import { IAccountDetails } from "./../../core/models/account-details";
import { Router, ActivatedRoute } from "@angular/router";
import { WalletService } from "./../services/wallet.service";
import {
    Component,
    OnInit,
    ViewChildren,
    QueryList,
    ViewChild,
    OnDestroy,
    Output,
    EventEmitter,
    ElementRef,
} from "@angular/core";
import {
    APP_ROUTES,
    USER_COUNTRY_CODE,
    USER_INTEREST_MODE,
    USER_TYPE,
} from "../../app.constants";
import { IUserBalance } from "src/app/core/models/user-balance";
import { CarouselComponent, SBItemComponent } from "ng-uikit-pro-standard";
import { KycService } from "src/app/kyc/services/kyc.service";
import { ICreateKYC } from "src/app/core/models/create-kyc";
import { CryptoAccountRequestModel } from "./../../core/models/crypto-account-request-model";
import { ToastrService } from "ngx-toastr";
import { AccountUpgradeService } from "src/app/account-upgrade/services/account-upgrade.service";
import { environment } from "src/environments/environment";
import { ModalService } from "src/app/core/services/modal/modal.service";
import { UserService } from "src/app/core/services/user/user.service";
import { IDepositInterestPlan } from "src/app/core/models/deposit-interest/deposit-interest";
import { DepositInterestService } from "src/app/deposit-interest/service/deposit-interest.service";
import { IUserPlan } from "src/app/core/models/user-plan/user-detail";
import { SubSink } from "subsink";
import { ActivityService } from "src/app/activity/services/activity.service";

@Component({
    selector: "cashaa-wallet-page",
    templateUrl: "./wallet-page.component.html",
    styleUrls: ["./wallet-page.component.scss"],
})
export class WalletPageComponent implements OnInit, OnDestroy {

    @ViewChild("slide", { static: true }) slideController: CarouselComponent;
    /** Balance data */
    balanceData?: IUserBalance[];
    slides: any = [[]];
    loading = false;
    subSink = new SubSink();
    localBankFiatCurrencyList: IUserBalance[] = [];
    accountBankFiatCurrencyList: IUserBalance[] = [];
    cryptoBankFiatCurrencyList: IUserBalance[] = [];
    hideZeroBalance = false;
    tempFiat = [];
    tempCrypto = [];
    nonBankingFiatCurrency = ["AUD", "JPY", "CAD", "CNH", "SGD", "NZD", "CHF"];
    excludeFiatCurrencies: string[] = ["SGD", "AUD", "CNH", "CAD", "CHF", "NZD", "JPY", "USD", "GBP"];

    excludeCryptoDisable: string[] = ["BNB20"];
    /** selected crypto bank */
    selectedCrypto = -1;
    localBankLoading = false;
    personalKycVerifiedInfo: ICreateKYC;
    usdKycStatus;
    usdButtonText: string;
    usdButtonRoute: string;
    usdButtonDisable: boolean;
    accountBankLoading = false;
    cryptoLoading = false;
    copied = false;
    copiedMemo = false;
    currencyToEmit: string;
    currencyType: string;
    accountDetailsByCurrencyData: IAccountDetails;
    accountDetailsByAccountCurrencyData: IAccountDetails;
    accountDetailsCryptoData: ICryptoAccountDetails;
    accountDetailsETHCryptoData: ICryptoAccountDetails;
    isPersonalKycVerified = false;
    isBusinessKycVerified = false;
    isBusinessVerified
    // get logged user type
    userType = sessionStorage.getItem(USER_TYPE);
    /** Error message if any */
    errorMessage?: string;

    /** Error message if any */
    errorMessageCrypto?: string;

    // hold personal user kyc details
    casDepositRates: number[] = [];
    maxInterestModeRate: number[] = [];
    @ViewChildren("collapsesCrypto")
    collapsesCrypto: QueryList<SBItemComponent>[];
    rateType: string = sessionStorage.getItem(USER_INTEREST_MODE);
    selectedLocalBank = -1;
    selectedAccountBank = -1;
    showCryptoTopBtn = true;
    rate: number;
    isBalance: boolean;
    /** selected crypto bank */
    popDetails;
    // get logged user type
    whiteListUserId = environment.whiteListUserId;
    @Output() getSelectedStatusChange = new EventEmitter<boolean>();
    // @ViewChild('cryptoSection') cryptoSection: ElementRef;
    constructor(
        private activityService: ActivityService,
        private userService: UserService,
        private router: Router,
        private toastrService: ToastrService,
        private upgardeService: AccountUpgradeService,
        private modalService: ModalService,
        private depositService: DepositInterestService,
        private walletService: WalletService,
        private activatedRoute: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.getBalance();
        this.subSink.add(
            this.activityService.getBalanceStatus().subscribe((data) => {
                if (data) {
                    this.getBalance();
                }
            }));
    }
    /**
     * Get balance data
     */
    getBalance(): void {
        this.loading = true;
        this.userService.getUserBalance().subscribe((balanceData) => {
            this.loading = false;
            if (balanceData) {
                this.balanceData = balanceData;
                this.isBalance = this.balanceData.some(ele => ele.isBalanceAvailable === true);
                this.balanceData.sort((a, b) => (a.available > b.available ? -1 : 1));
                this.balanceData.filter(
                    (el) => {
                        if (el.currencynature === 2 && el.currencyType !== "AUSD") {
                            this.localBankFiatCurrencyList.push(el);
                        } else {
                            if (el.currencynature === 1) {
                                el['isSelected'] = true;
                                this.cryptoBankFiatCurrencyList.push(el);
                            }
                        }
                    }
                );

                // if (window.matchMedia("(min-width: 1199px)").matches) {
                //     this.slides = this.chunk(this.balanceData, 5);
                // } else if (window.matchMedia("(min-width: 991px)").matches) {
                //     this.slides = this.chunk(this.balanceData, 3);
                // } else if (window.matchMedia("(min-width: 767px)").matches) {
                //     this.slides = this.chunk(this.balanceData, 2);
                // } else {
                //     this.slides = this.chunk(this.balanceData, 1);
                // }
            }
            this.loading = false;
            setTimeout(() => this.getGivenCurrency(), 100);
        });
    }
    getGivenCurrency(): void {
        this.activatedRoute.queryParamMap.subscribe((params) => {
            const currency = params.get("c");
            const crytp = params.get("crypto");
            if (crytp) {
                window.scroll(0, 1000);
            }
            if (currency) {
                const foundCrypto = this.cryptoBankFiatCurrencyList.filter(
                    (lc) => lc.currencyType === currency
                );
                if (foundCrypto.length > 0) {
                    window.scroll(0, 1000);
                    const idx = this.cryptoBankFiatCurrencyList.indexOf(foundCrypto[0]);
                    const obj = {
                        currency: { ...foundCrypto[0], ...{ isSelected: false } },
                        id: idx
                    };
                    this.getCryptoAccountDetails(obj);
                    //   this.selectedCrypto = i;
                    // }
                    //   });
                }
            }
        });
    }

    // chunk(arr, chunkSize): any {
    //     let R = [];
    //     for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    //         R.push(arr.slice(i, i + chunkSize));
    //     }
    //     return R;
    // }

    next(): void {
        this.slideController.nextSlide();
    }

    previous(): void {
        this.slideController.previousSlide();
    }
    getCryptoAccountDetails(event): void {
        this.rate = event.rate;
        this.popDetails = event.currency;
        if (this.selectedCrypto === event.id) {
            return;
        }
        this.cryptoLoading = true;
        this.collapsesCrypto.forEach((el: any, i: number) => {
            const sbItemBody = el.body.bodyEl.nativeElement;
            if (event.id !== i) {
                el.toggle(true);
                this.cryptoBankFiatCurrencyList[i].isSelected = true;
                sbItemBody.style.height = "0";
                sbItemBody.style.visibility = 'hidden';
            } else {
                if (event.currency.isSelected) {
                    sbItemBody.style.height = "0";
                    sbItemBody.style.visibility = 'hidden';
                } else {
                    this.cryptoBankFiatCurrencyList[event.id].isSelected = false;
                    sbItemBody.style.height = "auto";
                    sbItemBody.style.visibility = 'visible';
                }
            }
        });
        this.getCryptoCurrency(event.currency);
        // if (event) {
        //     $("html,body").animate(
        //         {
        //             scrollTop: event.srcElement.offsetParent.offsetTop,
        //         },
        //         600
        //     );
        // }
    }

    onHide(id, currency) {
        this.collapsesCrypto.forEach((el: any, i: number) => {
            const sbItemBody = el.body.bodyEl.nativeElement;
            if (id !== i) {
                el.toggle(true);
                this.cryptoBankFiatCurrencyList[i].isSelected = true;
                sbItemBody.style.height = "0";
                sbItemBody.style.visibility = 'hidden';
            } else {
                if (currency.isSelected) {
                    sbItemBody.style.height = "auto";
                    sbItemBody.style.visibility = 'visible';
                } else {
                    this.cryptoBankFiatCurrencyList[id].isSelected = true;
                    sbItemBody.style.height = "0";
                    sbItemBody.style.visibility = 'hidden';
                }
            }
        });
        // this.getCryptoCurrency(currency);  
    }

    openEarnPlan(): void {
        if (this.popDetails.currencyType === 'CAS') {
            this.router.navigateByUrl(`deposit-interest?c=${this.popDetails.currencyType}`);
        } else {
            // this.modalService.openEarnPlanDetailPopup(this.popDetails.plan, this.popDetails.diamondPlan, this.popDetails.available, this.popDetails.currencyId, this.popDetails.currencyType,
            //     this.casDepositRates, this.isFixedDepositEnable(this.popDetails.currencyType), this.isFixedDepositAndEarnEnable(this.popDetails.currencyType),
            //     this.maxInterestModeRate, false);
        }
    }

    getCryptoCurrency(currency: IUserBalance, networkType?: string) {
        if (networkType === 'BEP-20') {
            this.accountDetailsETHCryptoData = null;
        } else {
            this.accountDetailsCryptoData = null;
        }
        this.walletService.getCryptoAccountDetails(currency.currencyType).subscribe(
            (res) => {
                if (networkType === 'BEP-20') {
                    this.accountDetailsETHCryptoData = res;
                } else {
                    this.accountDetailsCryptoData = res;
                }
                // if (this.isEarnAndFixedDepositActive()) {
                if (this.isFixedDepositEnable(currency.currencyType)) {
                    this.loadFixedDepositRate(currency);
                } else if (this.isFixedDepositAndEarnEnable(currency.currencyType)) {
                    if (currency.currencyType === 'CAS') {
                        this.loadFixedDepositRate(currency);
                    } else {
                        let rate: number;
                        this.userService.getPlanDetailsByCurrencyId(currency.currencyId).subscribe((data: IUserPlan[]) => {
                            if (data.length > 0) {
                                currency.plans = data;
                                this.popDetails.plan = data;
                                currency.diamondPlan = data.filter((plan: IUserPlan) => plan.loyaltyPlanName === 'Diamond')[0];
                                let savingRate: number = Math.trunc(currency.diamondPlan.casRate) + Math.trunc(currency.diamondPlan.kindRate);
                                // if (!this.isEarnModuleNotActive(currency.currencyType)) {
                                this.depositService.getByDepositFDCurrency(currency.currencyId).subscribe((data: IDepositInterestPlan[]) => {
                                    if (data.length > 0) {
                                        this.casDepositRates = [];
                                        this.maxInterestModeRate = [];
                                        const plans: IDepositInterestPlan[] = data.filter((plan) => plan.isActive && plan.isDefault);
                                        plans.forEach(plan => {
                                            if (plan.rateType == (this.rateType === 'InCas' ? 2 : 1)) {
                                                this.maxInterestModeRate.push(plan.rate)
                                            }
                                            this.casDepositRates.push(plan.rate);
                                        });
                                        if (this.casDepositRates.length > 0) {
                                            let rates: number[] = [...this.casDepositRates];
                                            rates.push(savingRate);
                                            currency.earnRate = Math.max(...rates);
                                            this.rate = currency.earnRate;
                                        }
                                    } else {
                                        currency.earnRate = savingRate;
                                        this.rate = savingRate;
                                    }
                                });
                                // } else {
                                //     currency.earnRate = savingRate;
                                // }
                            }
                            this.cryptoLoading = false;
                        });
                    }
                }
                // } else {
                //     this.cryptoLoading = false;
                // }
                this.cryptoLoading = false;
            },
            (err) => {
                this.cryptoLoading = false;
            }
        );
    }

    loadFixedDepositRate(currency) {
        // if (!this.isEarnModuleNotActive(currency)) {
        this.depositService.getDepositInterestPlan(currency.currencyId).subscribe((data: IDepositInterestPlan[]) => {
            if (data.length > 0) {
                this.casDepositRates = [];
                this.maxInterestModeRate = [];
                const plans: IDepositInterestPlan[] = data.filter((plan) => plan.isActive && plan.isDefault);
                plans.forEach(plan => {
                    if (plan.rateType == (this.rateType === 'InCas' ? 2 : 1)) {
                        this.maxInterestModeRate.push(plan.rate);
                    }
                    this.casDepositRates.push(plan.rate);
                });
                if (this.casDepositRates.length > 0) {
                    currency.earnRate = Math.max(...this.casDepositRates);
                    this.rate = Math.max(...this.casDepositRates);
                }
            }
        });
        // }
    }

    filterNonZeroBalance() {
        if (this.localBankFiatCurrencyList.length > 0 && !this.hideZeroBalance) {
            this.tempFiat = this.localBankFiatCurrencyList;
            this.tempCrypto = this.cryptoBankFiatCurrencyList
        }
        if (this.hideZeroBalance) {
            this.localBankFiatCurrencyList = this.tempFiat;
            this.cryptoBankFiatCurrencyList = this.tempCrypto;
        } else {
            this.localBankFiatCurrencyList = this.localBankFiatCurrencyList.filter(item => item.available > 0);
            this.cryptoBankFiatCurrencyList = this.cryptoBankFiatCurrencyList.filter(item => item.available > 0);

        }
    }
    getUsdStatus(): void {
        this.loading = true;
        this.usdButtonDisable = false;
        this.upgardeService.usdStatus().subscribe(
            (flag) => {
                this.usdKycStatus = flag.status;
                switch (flag.status) {
                    case 1:
                        this.usdButtonText = "Apply";
                        break;

                    case 2:
                        this.usdButtonText = "Update KYC Details";
                        break;

                    case 3:
                        this.usdButtonText = "KYC in Progress";
                        break;

                    case 4:
                        this.usdButtonText = "Update KYC Details";
                        break;

                    case 5:
                        this.usdButtonText = "KYC in Progress";
                        break;

                    case 6:
                        this.usdButtonText = "Account Creation in Progress";
                        this.usdButtonDisable = true;
                        break;

                    case 7:
                        this.usdButtonText = "";
                        this.usdButtonDisable = true;
                        break;
                }

                // if (flag.status === 7) {
                //   this.enableActiveForPremierFlag = true;
                // } else {
                //   this.enableActiveForPremierFlag = false;
                // }
                if (flag.status === 1) {
                    this.usdButtonRoute = "/subscription/plan";
                } else {
                    this.usdButtonRoute =
                        this.userType === "5" || this.userType === "6" ? "/kyc/business" : "/kyc/US/personal";
                }
                this.loading = false;
            },
            (err) => {
                this.loading = false;
            }
        );
    }

    //get given currency

    onAccountReadyPage(currency: IUserBalance, currencyType: string, networkType?: string): void {
        if ("BEP-20" === networkType) {
            this.currencyToEmit = 'ETH';
            currency = this.cryptoBankFiatCurrencyList.filter(cur => cur.currencyType === 'ETH')[0];
        } else {
            this.currencyToEmit = currency.currencyType;
        }
        this.currencyType = currencyType;
        const currencyData = new CurrencyTypeData(
            this.currencyToEmit,
            this.currencyType
        );

        if (this.currencyType === "Crypto") {
            this.cryptoLoading = true;
            const inputCurrencyCrypto = new CryptoAccountRequestModel(this.currencyToEmit);
            this.walletService
                .OpenCryptoAccount(inputCurrencyCrypto)
                .subscribe((res) => {
                    this.getCryptoCurrency(currency, networkType);
                });
        } else {
            this.walletService.emitCurrencySymbol(currencyData);
            this.router.navigateByUrl(APP_ROUTES.WALLET_READY_TO_GO);
        }
    }

    copyAddress(): void {
        if (this.accountDetailsCryptoData.address) {
            this.copied = true;
            navigator.clipboard.writeText(this.accountDetailsCryptoData.address);
            this.toastrService.toastrConfig.preventDuplicates = true;
            this.toastrService.success("Text copied to clipboard");
            setTimeout(() => (this.copied = false), 300);
        }
    }

    copyAddressETH(): void {
        if (this.accountDetailsETHCryptoData.address) {
            this.copied = true;
            navigator.clipboard.writeText(this.accountDetailsETHCryptoData.address);
            this.toastrService.toastrConfig.preventDuplicates = true;
            this.toastrService.success("Text copied to clipboard");
            setTimeout(() => (this.copied = false), 300);
        }
    }

    copyMemoAddress(): void {
        if (this.accountDetailsCryptoData.memo) {
            this.copiedMemo = true;
            navigator.clipboard.writeText(this.accountDetailsCryptoData.memo);
            this.toastrService.toastrConfig.preventDuplicates = true;
            this.toastrService.success("Text copied to clipboard");
            setTimeout(() => (this.copiedMemo = false), 300);
        }
    }
    isHidden(currency: string): boolean {
        if (this.nonBankingFiatCurrency.includes(currency)) {
            return true;
        } else {
            return false;
        }
    }
    isDisable(currency: string): boolean {
        if (this.excludeFiatCurrencies.includes(currency)) {
            return true;
        } else {
            return false;
        }
    }

    isSendDisable(currency: string): boolean {
        if (this.excludeFiatCurrencies.includes(currency)) {
            return true;
        } else {
            return false;
        }
    }

    getTootlTip(value, currency) {
        return value === 0 ? "You don't have any " + currency + " to convert" : "";
    }

    getEarnTootlTip(value, currency) {
        return value === 0 ? "You don't have any " + currency + " to Earn Interest" : "";
    }
    onAddClick(currency: string): void {
        this.router.navigateByUrl(`/deposit?cur=${currency}`);
    }
    onConvertClick(currency: string): void {
        this.router.navigateByUrl(`/convert?c=${currency}`);
    }

    onSendClick(currency: string): void {
        this.router.navigateByUrl(`/fiat?c=${currency}`);
    }

    isEarnAndFixedDepositActive(): boolean {
        return sessionStorage.getItem(USER_COUNTRY_CODE) !== 'US';
    }

    isFixedDepositEnable(symbol: string): boolean {
        return sessionStorage.getItem(USER_TYPE) !== '4' || symbol === 'CAS';
    }

    isFixedDepositAndEarnEnable(symbol: string): boolean {
        return sessionStorage.getItem(USER_TYPE) === '4' && symbol !== 'CAS';
    }

    // isEarnModuleNotActive(symbol: string): boolean {
    //     return sessionStorage.getItem(USER_TYPE) === '4' && symbol === 'DOG';
    // }

    isCryptoDisable(currency: string): boolean {
        if (this.excludeCryptoDisable.includes(currency)) {
            return true;
        } else {
            return false;
        }
    }

    isCryptoSendDisable(currency: string): boolean {
        let excludeCurr = [...this.excludeCryptoDisable]
        excludeCurr.push('USDC');
        if (excludeCurr.includes(currency)) {
            return true;
        } else {
            return false;
        }
    }

    public getTabHeaderData1(symbol: string): string {
        if (symbol === 'BNB' || symbol === 'CAS') {
            return 'BEP-2';
        } else if (symbol === 'USDT' || symbol === 'LINK' || symbol === 'UNI' || symbol === 'USDC' || symbol === 'ETH' || symbol === 'BNB') {
            return 'ERC-20';
        } else if (symbol === 'XRP' || symbol === 'DOG' || symbol === 'LTC' || symbol === 'BTC' || symbol === 'BCH') {
            return this.getCurrencyName(symbol);
        }
    }
    getCurrencyName(curr): string {
        if (curr === "AUSD") return "USD";
        if (curr === "DOG") return "DOGE";
        else return curr;
    }

    public getContentData(symbol: string) {
        const tabData = this.getTabHeaderData1(symbol);
        if (tabData === 'BEP-2' || tabData === 'ERC-20') {
            return `${symbol} (${tabData})`;
        } else {
            return symbol;
        }
    }

    onDepositInterestClick(currency: IUserBalance): void {
        // this.modalService.openEarnPlanDetailPopup(currency.plans, currency.diamondPlan, currency.available, currency.currencyId, currency.currencyType,
        //     this.casDepositRates, this.isFixedDepositEnable(currency.currencyType), this.isFixedDepositAndEarnEnable(currency.currencyType),
        //     this.maxInterestModeRate, false);
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }
}
