import { AfterContentInit, Component, ElementRef, EventEmitter, Inject, OnInit, Output, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { USER_INTEREST_MODE, WALLET_CURRENCY } from 'src/app/app.constants';
import { CurrencyType } from 'src/app/core/enums/currencyType';
import { ICurrency } from 'src/app/core/models/currency';
import { CreateDepositPlan, ICreateDepositPlan, IDepositInterestPlan } from 'src/app/core/models/deposit-interest/deposit-interest';
import { DomainService } from 'src/app/core/services/domain/domain.service';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { ValidatorUtility } from 'src/app/core/utility/validator.utility';
import { DepositService } from 'src/app/deposit/services/deposit.service';
import { ReferralService } from 'src/app/referral/services/referral.service';
import { DepositInterestService } from '../service/deposit-interest.service';

@Component({
  selector: 'cashaa-deposit-interest-amount',
  templateUrl: './deposit-interest-amount.component.html',
  styleUrls: ['./deposit-interest-amount.component.scss']
})
export class DepositInterestAmountComponent implements OnInit{

  //width:any;
  progressBarWidth = "width: 0%";
  customMobApp
  sendCurrencySymbol: string;
  form: FormGroup;
  fiatCurrencies = ["CUSD", "CGBP", "CEUR", "CSGD", "CAUD", "CCNH", "CCAD", "CCHF", "CNZD", "CJPY"];
  loading: boolean = false;
  sendCurrency;
  sendMoneyCurrency: string;
  interestPlanList: IDepositInterestPlan[] = [];
  validInterestPlan: IDepositInterestPlan[] = [];
  conditionsAccepted: boolean = false;
  hideCas: boolean = false;
  setInitialCurrencyValue: string;
  defaultSelectedInkind = true;
  minAmount: number;
  showMonthlyMessage = false;
  @Output() continue = new EventEmitter<ICreateDepositPlan>();
  currencyBalance = 0;
  depositInterest;
  interestType: number;
  feeSlabLesserThan365 = false;
  feeSlabGreaterThan365 = false;
  cancelFeeDetails = [];
  isLongTerm = false;
  clienttype?:number;
  totalUsdPoolSize?:number;
  elementRef: ElementRef;
  selectedPlanName:string;
  // prematureWithdrawl = [{percentage: '25%', time: 'First Year', desc: 'Any interest given will be also adjusted from principle'},
  // {percentage: '15%', time: 'Second Year', desc: 'Any interest given will be also adjusted from principle'},
  // {percentage: '10%', time: 'Third Year', desc: 'Any interest given will be also adjusted from principle'}];
  // feeFDCancellation = [{percentage: '5%', duration: '0-3'},{percentage: '3%', duration: '3-6'},
  // {percentage: '2%', duration: '9-12'}]
  constructor(@Inject(ElementRef) elementRef: ElementRef,private renderer: Renderer2,private fb: FormBuilder, private depositService: DepositInterestService, private domainService: DomainService,
    private activatedRoute: ActivatedRoute, private referralService: ReferralService, private modalService: ModalService) {
    this.createForm();
    this.elementRef = elementRef;
    this.setMode();
  }
  @ViewChildren("itemElement") private itemElements: QueryList<ElementRef>;
  ngOnInit(): void {
 
    this.getGivenCurrency();
  }

  ngAfterViewInit(): void {
    
   
}

  createForm(): void {
    this.form = this.fb.group({
      sendercurrencyId: ["", [ValidatorUtility.Required]],
      senderAmount: ["", [ValidatorUtility.Required]],
      rateType: ["2", ValidatorUtility.Required],
      planName: ["", ValidatorUtility.Required]
      // interestType: ["", ValidatorUtility.Required]
    })
    this.listenToCurrencyChange();
    this.listenToInterestPlanChange();

  }

  listenToCurrencyChange(): void {
    this.form.controls.sendercurrencyId.valueChanges.subscribe(() => {
      this.form.controls.senderAmount.setValue(0);
      this.form.controls.planName.setValue("");
      // this.radioOptionToShow = [];
      // this.feeSlabLesserThan365 = false;
      // this.feeSlabGreaterThan365 = false;
      this.cancelFeeDetails = [];
    });
  }

  getAmount(): boolean {
    // let amount: number = Number(this.form.controls.senderAmount.value.replaceAll(",", ""));
    let amount: number = Number(this.form.controls.senderAmount.value.replaceAll(",", ""));
    return amount > 0 ? true : false;
  }

  listenToInterestPlanChange(): void {
    this.form.controls.rateType.valueChanges.subscribe((value) => {
      this.loading = true;
      // this.feeSlabLesserThan365 = false;
      // this.feeSlabGreaterThan365 = false;
      this.cancelFeeDetails = [];
      this.interestPlanList = this.validInterestPlan.filter((plan) => plan.rateType.toString() === value);
      
      setTimeout(() => {
        this.loading = false;
      }, 1000)
    });
  }

  getGivenCurrency(): void {
    this.activatedRoute.queryParamMap.subscribe((val) => {
      let currency = val.get("c");
      if (currency) {
        this.sendMoneyCurrency = val.get("cur");
        if (this.sendMoneyCurrency) {
          currency = this.sendMoneyCurrency;
        }
        let found: ICurrency[];
        this.domainService
          .getCurrenciesList(CurrencyType.all)
          .subscribe((list) => {
            if (currency) {
              found = list.currencies.filter((c) => c.symbol === currency);
              if (found.length > 0) {
                this.form.controls.sendercurrencyId.setValue(found[0].id);
              } else {
                found = list.currencies.filter(
                  (c) => c.symbol === WALLET_CURRENCY.GBP
                );
                if (found.length > 0) {
                  this.setInitialCurrencyValue = found[0].id;
                  this.form.controls.sendercurrencyId.setValue(found[0].id);
                }
              }
            } else {
              found = list.currencies.filter(
                (c) => c.symbol === WALLET_CURRENCY.GBP
              );
              if (found.length > 0) {
                this.form.controls.sendercurrencyId.setValue(found[0].id);
              }
            }
            this.loadInterestDepositPlan(found[0].id)
          });
      }
    });
  }

  onsendCurrencyChangeEvent(data: ICurrency): void {
    this.selectedPlanName = "";
 
    let toFix = 0;
    if (this.fiatCurrencies.includes(data.symbol)) {
      toFix = 2;
    } else {
      toFix = 4;
    }
    this.form.patchValue(
      {
        senderAmount: parseInt(this.form.controls.senderAmount.value).toFixed(
          toFix
        ),
      },
      {
        emitEvent: false,
        onlySelf: true
      }
    );
    this.sendCurrencySymbol = data.symbol;
    this.sendCurrency = data;
    this.loadInterestDepositPlan(data.id);
    if (data.symbol === 'CAS') {
      this.hideCas = true;
    } else {
      this.hideCas = false;
    }
  }

  loadInterestDepositPlan(currencyId: string): void {
    this.loading = true;
    this.depositService.getDepositInterestPlan(currencyId).subscribe((data: IDepositInterestPlan[]) => {
      this.loading = false;
      if (data.length > 0) {
        this.minAmount = data[0].minAmount;
        this.activatedRoute.queryParamMap.subscribe((param) => {
          if (param.get('longTerm')) {
            this.isLongTerm = true;
            this.validInterestPlan = this.interestPlanList = data.filter((plan) => plan.isActive && plan.isDefault && plan.duration > 365);
          } else {
            this.isLongTerm = false;
            this.validInterestPlan = this.interestPlanList = data.filter((plan) => plan.isActive && plan.isDefault && plan.duration <= 365);
          }
        });
        // this.validInterestPlan = this.interestPlanList = data.filter((plan) => plan.isActive && plan.isDefault);
        this.interestPlanList = this.validInterestPlan.filter(plan => plan.rateType == (this.sendCurrencySymbol === 'CAS' ? 1 : this.form.controls["rateType"].value));
      } else {
        this.interestPlanList = [];
        // this.radioOptionToShow = [];
      }
    });
  }

  onContinue(): void {
    this.fixedDepositCalculator();
  }

  setMode() {
    this.defaultSelectedInkind = !this.defaultSelectedInkind;
    this.selectedPlanName = "";
    const rateType = this.form.get('rateType').value;
    if (rateType === '1') {
      this.form.get('rateType').setValue('2');
    } else {
      this.form.get('rateType').setValue('1');
    }
  }

  getSelectedOption(selectedItem) {
    
    this.showMonthlyMessage = false;
   
    this.form.patchValue({ planName: selectedItem.id });
    this.interestType = selectedItem.interestOptType
    
    this.cancelFeeDetails = selectedItem.cancelFeeDetails;
    this.clienttype= selectedItem?.clientType;
    this.selectedPlanName =  selectedItem.id;
    this.totalUsdPoolSize= selectedItem?.totalUsdPoolSize;

    let percentage=((100*this.totalUsdPoolSize)/selectedItem?.maxUsdPoolSize) as number;
    percentage=percentage<1?1:percentage;
    this.progressBarWidth="width: "+percentage+"%";

    this.itemElements.changes.subscribe(() => {
     
      const htmlElement = document.getElementById("for_tooltip");
      htmlElement.textContent="$"+selectedItem?.totalUsdPoolSize;
      htmlElement.style.left=Math.round(percentage-4)+"%";
      
    
    });



    //angular.element('.className').css('color','#DBCLOR');

   // this.progressBarWidth=progressBarWidth
    // if(selectedItem.duration > 365) {
    //   this.feeSlabLesserThan365 = false;
    //   this.feeSlabGreaterThan365 = true;

    // } else {
    //   this.feeSlabLesserThan365 = true;
    //   this.feeSlabGreaterThan365 = false;
    // }

    this.customMobApp =  selectedItem.rate == 2.5 && selectedItem.planName == "3 Month Term"? true : false;
    

  }

  getCurrencyBalance(balance) {
    this.currencyBalance = balance;
    if (this.currencyBalance <= 0) {
      this.form.controls.senderAmount.disable();
    } else {
      this.form.controls.senderAmount.enable();
    }
  }
  fixedDepositCalculator() {
    let selectedPlan = this.form.controls["planName"].value;
    const plan: IDepositInterestPlan = this.validInterestPlan.filter(plan => plan.id === selectedPlan)[0];
    let createDepositInterest: ICreateDepositPlan = new CreateDepositPlan(this.form.controls["senderAmount"].value, plan, this.conditionsAccepted,
      this.interestType);
    this.depositInterest = createDepositInterest;
    this.referralService.getFixedDepositCalculationInterest(this.form.value, this.depositInterest.depositData.rate, this.depositInterest.depositData.currency, this.interestType, this.depositInterest.depositData.duration).subscribe((res: any) => {
      createDepositInterest.depositData['interestEarned'] = res.interestEarned;
      createDepositInterest.depositData['interestEarnedCurrency'] = res.interestEarnedCurrency;
      this.continue.next(createDepositInterest);
      
    })
  }

  cancelFixedDeposit() {
    this.modalService.showCancelFeeInfo('')
  }

  aboveTemplate = `<div class="color-red">Top Template<button style="background: white; color: black">Press me</button></div>`;

}


