<div class="btn-group fee-dropdown w-100 h-100" mdbDropdown>
  <a mdbDropdownToggle mdbBtn class="dropdown-toggle dropdown waves-light black-text text-left d-flex p-2" type="button" mdbWavesEffect (click)="isDropdownOpened = !isDropdownOpened">
      <i class="fa fa-filter" aria-hidden="true" style="color:#3570f4; margin-right: 5px;"></i>
      <p class="mb-0 text-dark label font-weight-500">
          {{ selectedOption?.label || placeholder }}
      </p>

      <mdb-icon class="ml-2 text-primary-light" fas [icon]="'chevron-' + (isDropdownOpened ? 'up' : 'down')"></mdb-icon>
  </a>
  <div class="dropdown-menu m-0 p-2">
      <div *ngFor="let option of options" (click)="onValueChange(option)">
          <ng-template [ngTemplateOutlet]="optionsUI" [ngTemplateOutletContext]="{ option: option }"></ng-template>
      </div>
  </div>
</div>

<ng-template #optionsUI let-option="option">
  <div class="p-2 dropdown-item">
      <div class="d-flex">
          <img *ngIf="optionImg" [src]="'../../../../assets/images/' + option?.label + '.png'" class="mr-2 icon" />
          <h6 class="font-weight-bolder option-label">{{ option?.label }}</h6>
      </div>
  </div>
</ng-template>