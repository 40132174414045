import { Component, OnInit, NgZone } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ReceiveService } from "../services/receive.service";
import { IUserBalance } from "src/app/core/models/user-balance";
import { ReceiveFundRequest, ReceiveFundResponse } from "src/app/core/models/receive-fund";

@Component({
  selector: "cashaa-receive-fund",
  templateUrl: "./receive-fund.component.html",
  styleUrls: ["./receive-fund.component.scss"]
})
export class ReceiveFundComponent implements OnInit {

  createReceiveFund: FormGroup;

  serviceResponse: ReceiveFundResponse;

  constructor(private zone: NgZone, private receiveService: ReceiveService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(): void {
    this.createReceiveFund = this.fb.group({


    });
  }

  OnCurrencySelect(UserBalance: IUserBalance): void {

    const receiveFundData = new ReceiveFundRequest(
      "", UserBalance.currencyType,
    );

    this.receiveService.createReceiveFund(receiveFundData).subscribe(
      (res) => {

        this.zone.run(() => { // <== added
          this.serviceResponse = res;
        });

      },
      (err) => {

      }
    );

  }


  copyText(val: string): void {

    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);

  }

}
