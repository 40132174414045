import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { FiatService } from "src/app/fiat/services/fiat.service";
import {
  CUSTOMER_DELETED_SUCCESS,
  KYC_VERIFACTION_MESG,
  RECIPIENT_DELETED_SUCCESS,
  UNABLE_TO_PERFORM_ERROR,
} from "src/app/app.constants";
import { BrokerStatus } from "src/app/core/enums/broker-status.enum";
import { IResendKYC, ResendKYC } from "src/app/core/models/customer/customer";
import { ICustomer } from "src/app/core/models/recipient/recipient-add-usd";
import { CustomerService } from "../services/customer.service";
import { Subscription } from "rxjs";
import { CommonSavedData } from "src/app/shared/services/commonSavedData.service";
import { RecipientService } from "src/app/recipient/services/recipient.service";
import { RecipientDelete } from "src/app/core/models/fIat/recipients";
import { ModalService } from "src/app/core/services/modal/modal.service";
import { Router } from "@angular/router";

@Component({
  selector: "cashaa-customer-details",
  templateUrl: "./customer-details.component.html",
  styleUrls: ["./customer-details.component.scss"],
})
export class CustomerDetailsComponent implements OnInit {
  @Input() public data: ICustomer;
  isloading: boolean = false;
  isloadingKYC: boolean[] = [];
  @Output() customer = new EventEmitter<{
    selectedCustomer: ICustomer;
    isUploadKyc: boolean;
  }>();
  sub: Subscription;
  timerFlag = {};
  timerStart = {};
  timerTime = {};
  disableBtn = {};
  verificationClickCount = {};

  constructor(
    private customerService: CustomerService,
    private toastrService: ToastrService,
    private fiatService: FiatService,
    private commonSavedData: CommonSavedData,
    private recipientService: RecipientService,
    private modalService: ModalService,
    private router: Router
  ) { }

  ngOnInit(): void {
    console.log(this.data)
    // this.loadCustomerDetails();

    // this.customerService.getCustomerAdd().subscribe((data: boolean) => {
    //   if (data) {
    //     this.loadCustomerDetails();
    //   }
    // });
  }

  // loadCustomerDetails(): void {
  //   this.isloading = true;
  //   this.customerService.getAllCustomers().subscribe(
  //     (customerData: ICustomer[]) => {
  //       this.data = customerData;
  //       this.isloading = false;
  //     },
  //     (err) => {
  //       this.isloading = false;
  //       if (err.error.message) {
  //         this.toastrService.error(err.error.message);
  //       } else {
  //         this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
  //       }
  //     }
  //   );
  // }

  editCustomer(editCustomer: ICustomer): void {
    this.modalService.openCustomerEditModal({ selectedCustomer: editCustomer, isUploadKyc: false });
  }

  removeCustomer(editCustomer: ICustomer): void {
    this.modalService.openConfirmationModal("Do you want to delete the recipient?");
    this.sub = this.commonSavedData.getConfirmationBoxStatus().subscribe((status: boolean) => {
      if (status) {
        editCustomer.isOperationCompleted = true;
        let recipientDelete = new RecipientDelete(editCustomer.customerId);
        this.recipientService.deleteRecipient(recipientDelete).subscribe((res) => {
          editCustomer.isOperationCompleted = false;
          if (res.message === "success") {
            this.customerService.emitDeleteRecipientFlag(true);
            this.toastrService.success(CUSTOMER_DELETED_SUCCESS);
          } else {
            this.toastrService.error("Please try again!");
          }
        }, (err) => {
          if (err.error.message) {
            this.toastrService.error(err.error.message);
          } else {
            this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
          }
          editCustomer.isOperationCompleted = false;
        });
      }
      this.sub.unsubscribe();
    });
  }

  resendKYC(customerId: string, currency: string, i): void {
    this.isloadingKYC[i] = true;
    this.fiatService.verifyRecipient(customerId, currency).subscribe(
      (flag) => {
        if (flag.message) {
          this.isloadingKYC[i] = false;
          this.timerTime[i] = 60;
          this.timerFlag[i] = true;
          this.timerStart[i] = true;
          if (isNaN(this.verificationClickCount[i])) {
            this.verificationClickCount[i] = 1;
          } else {
            this.verificationClickCount[i]++;
          }
          this.toastrService.success(KYC_VERIFACTION_MESG);
        }
      },
      (err) => {
        this.isloadingKYC[i] = false;
        if (err.error.message) {
          this.toastrService.error(err.error.message);
        } else {
          this.toastrService.error(UNABLE_TO_PERFORM_ERROR);
        }
      }
    );
  }

  uploadDoc(customer: ICustomer): void {
    this.customer.emit({ selectedCustomer: customer, isUploadKyc: true });
  }

  getResendLinkStatus(customer: ICustomer): boolean {
    return customer.status === BrokerStatus.PENDING &&
      !(customer.failedCount > 3 || customer.count > 3)
      ? true
      : false;
  }

  getEditStatus(customer: ICustomer): boolean {
    return customer.status === BrokerStatus.APPROVED ||
      customer.status === BrokerStatus.REJECTED
      ? false
      : true;
  }

  getUploadDocStatus(customer: ICustomer): boolean {
    return customer.status === BrokerStatus.PENDING &&
      (customer.failedCount > 3 || customer.count > 3)
      ? true
      : false;
  }

  getStatus(status: number) {
    return BrokerStatus[status];
  }

  getNameInitials(): string {
    if (this.data) {
      let splitName = this.data.currency === 'USD' ? this.data.firstName.concat('').concat(this.data.middleName).concat(' ').concat(this.data.lastName).trim().split(" ") : this.data.firstName.trim().split(" ");
      switch (splitName.length) {
        case 1:
          return (splitName[0][0] + splitName[0][1]).toUpperCase();
        case 2:
          return (splitName[0][0] + splitName[1][0]).toUpperCase();
        case 3:
          return (splitName[0][0] + splitName[2][0]).toUpperCase();
      }
    } else {
      return "";
    }
  }

  timerExpire(i): void {
    if (this.verificationClickCount[i] < 3) {
      this.timerFlag[i] = false;
      this.timerStart[i] = false;
    } else {
      this.disableBtn[i] = true;
      this.timerStart[i] = false;
    }
  }

  onSendClick(currency: string): void {
    this.router.navigateByUrl(`/fiat?c=${currency}&recipientType=${this.data.recipientType}`);
  }

  onReceiveClick(currency: string): void {
    this.router.navigateByUrl(`/deposit?c=${currency}&recipientType=${this.data.recipientType}`);
  }
}

