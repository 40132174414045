<div *ngIf="!islinkSent"
  class="wrapper-box  p-md-5 p-3 rounded-lg bg-white animated fadeInLeft position-relative sm-width-phone-370">
  <h4 class="text-center text-primary font-weight-bold">Forget Password?</h4>
  <p *ngIf="!isrestnew" class="text-center font-weight-normal">
    Don't worry, just enter the email address you registered with and we will
    send you a link to reset your password.
  </p>

  <p *ngIf="isrestnew" class="text-center font-weight-normal">
    You need to reset your password because this is the first time you are signing in.
  </p>

  <form *ngIf="form" [formGroup]="form" class="my-4">
    <div class="mb-4">
      <cashaa-form-input name="email" type="email" [form]="form" [control]="form.controls.email"
        placeholder="Enter email address"></cashaa-form-input>
    </div>
    <div class="position-relative" *ngIf="errorMessage">
      <mdb-error class="mt-2">{{ errorMessage }}</mdb-error>
    </div>


    <button mdbBtn [disabled]="form.invalid || showLoadingIndicator" (click)="onForgetPasswordSubmit()" block="true"
      type="button"
      class="rounded-sm position-relative btn btn-secondary  sm-custom-sec waves-effect waves-light btn-block"
      mdbWavesEffect>
      <i class="fa-lock fas position-absolute"></i>Send password reset link
    </button>
  </form>


  <mdb-progress-bar *ngIf="showLoadingIndicator" class="progress primary-color-dark position-absolute"
    mode="indeterminate"></mdb-progress-bar>
</div>


<div *ngIf="islinkSent" class="modal-body py-md-5 px-md-5 py-sm-3 px-sm-3 rounded-lg bg-white">
  <p style="width: 420px;" class="text-center font-weight-normal sm-width-phone-330">
    We have sent you the password reset link on your registered email address. Please login to your email and click on the given link to reset the password
  </p>
  <p class="text-center font-weight-normal">
    Already signed up? <a routerLink="/account/signin">Log in</a>
  </p>

</div>