import { Component, EventEmitter, Output, OnInit } from "@angular/core"
import { ToastrService } from "ngx-toastr";
import { EventFeeService } from "../services/event-fee.service";

@Component({
    selector: "cashaa-payment-history",
    templateUrl: "./payment-history.component.html",
    styleUrls: ["./payment-history.component.scss"],
})
export class PaymentHistoryComponent implements OnInit {
    
    depositHistoryData = [];
    ticketDetails;
    copiedTransactionID: boolean;;
    copiedFromAddress: boolean;
    copiedToAddress: boolean;
    constructor(private eventFeeService: EventFeeService, private toastrService: ToastrService) { }

    ngOnInit(): void {
        this.getTicketDetails();
        this.getDepositHistory();
    }

    getDepositHistory() {
        this.eventFeeService.getDepositPaymentHistory().subscribe(res => {
         this.depositHistoryData = res;
        }, err => {

        })
    }

    getTicketDetails() {
        this.eventFeeService.getTicketDetails().subscribe(res => {
         this.ticketDetails = res;
        }, err => {

        })
    }

    copyFromAddress(info) {
        if (info?.fromAddress) {
            this.copiedFromAddress = true;
            navigator.clipboard.writeText(info?.fromAddress);
            this.toastrService.toastrConfig.preventDuplicates = true;
            this.toastrService.success("Text copied to clipboard");
            setTimeout(() => (this.copiedFromAddress = false), 2000);
          }
    }

    copyToAddress(info) {
        if (info?.toAddress) {
            this.copiedToAddress = true;
            navigator.clipboard.writeText(info?.toAddress);
            this.toastrService.toastrConfig.preventDuplicates = true;
            this.toastrService.success("Text copied to clipboard");
            setTimeout(() => (this.copiedToAddress = false), 2000);
          }
    }

    copyTransactionID(info) {
        if (info?.transactionId) {
            this.copiedTransactionID = true;
            navigator.clipboard.writeText(info?.transactionId);
            this.toastrService.toastrConfig.preventDuplicates = true;
            this.toastrService.success("Text copied to clipboard");
            setTimeout(() => (this.copiedTransactionID = false), 2000);
          }
    }

}