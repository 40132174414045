import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from "ng-uikit-pro-standard";

@Component({
  selector: 'cashaa-activity-balance-attention-cancel-trasnfer',
  templateUrl: './activity-balance-attention-cancel-transfer.component.html',
  styleUrls: ['./activity-balance-attention-cancel-transfer.component.scss']
})
export class ActivityBalanceAttentionCancelTrasnferComponent implements OnInit {

  constructor(public modalRef: MDBModalRef) { }

  ngOnInit(): void {
  }

}
